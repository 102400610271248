import initialState from "../initialState";
import { cartConstants } from "../_constants/cart.constants";

export function shopReducer(state = initialState.user_cart, action) {
  switch (action.type) {
    case cartConstants.ADD_TO_CART:
      return {
        ...state,
        items: [...state.items, action.payload],
      };
    case cartConstants.DELETE_FROM_CART:
 
      return {
        ...state,
        items: state.items.filter((currele:any) => {
          return currele.id !== action.payload.id;
        }),
      };

    default:
      return state;
  }
}
