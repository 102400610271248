import {
  Box,
  Divider,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { display } from "@mui/system";
import thirds from "../../../../../assets/images/Services/thirds.png";
import second from "../../../../../assets/images/Services/second.png";
import DisplayData2Styles from "./DisplayData2.styles";
import { boldFont } from "../../../../../utils/styles";
import { regularFont } from "../../../../../utils/styles";
const DisplayData2 = () => {
  const classes = DisplayData2Styles();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <>
      {isDesktop ? (
        <Box
          className={isDesktop ? classes.Box4 : classes.Box4Mobile}
          style={{}}
        >
          <Box
            className={isDesktop ? classes.Box5 : classes.Box5Mobile}
            style={{}}
          >
            <Typography
              className={isDesktop ? classes.Typo2 : classes.Typo2Mobile}
              style={{}}
            >
              Sleep apnea is commonly known for causing physical and mental
              fatigue, but another frequent symptom noted in this disorder is a
              rapid deterioration in oral health. Dental problems caused due to
              Sleep Apnea such as gingivitis, tooth decay, dry mouth, and
              bruxism leads to chipped teeth, bad breath, and severe gum
              diseases.<br /><br /> Diagnosis and treatment of Sleep Apnea in the early
              stage can significantly improve these bad oral conditions. Timely
              treatment ensures quality sleep that can result in improved oral
              condition and lesser chances of tooth decay.
            </Typography>
            <Typography
              className={isDesktop ? classes.Typo1 : classes.Typo1Mobile}
              style={{}}
            >
              Test At Your Home!
            </Typography>
          </Box>
          <Box
            className={isDesktop ? classes.Box6 : classes.Box6Mobile}
            style={{}}
          >
            <img
              className={
                isDesktop ? classes.imagestyle : classes.imagestyleMobile
              }
              style={{}}
              src={thirds}
            />
          </Box>
        </Box>
      ) : (
        <Box
          className={isDesktop ? classes.Box1 : classes.Box1Mobile}
          style={{}}
        >
          <Box
            className={isDesktop ? classes.Box2 : classes.Box2Mobile}
            style={{}}
          >
            <img
              className={
                isDesktop ? classes.imagestyle : classes.imagestyleMobile
              }
              style={{}}
              src={thirds}
            />
          </Box>
          <Box
            className={isDesktop ? classes.Box3 : classes.Box3Mobile}
            style={{}}
          >
            <Typography
              className={isDesktop ? classes.Typo2 : classes.Typo2Mobile}
              style={{}}
            >
              According to the American Dental association guideline ,<br /> 60%
              of teeth and gum diseases can be linked to
              <br /> undiagnosed Sleep Apnea.
              <br />
              <br />
              Sleep Apnea shows up in forms such as toothaches,
              <br /> grinded teeth, gum decay/swelling, cavity formation <br />{" "}
              and so on.
              <br />
              <br /> If left untreated, it can lead to long-drawn, expensive
              <br /> treatments for patients, such as root canal.
              <br />
              <br /> If Sleep Apnea is diagnosed in time, your sleep
              <br />
              physician can recommend an oral appliance to be worn
              <br /> only during sleep.
            </Typography>
            <Typography
              className={isDesktop ? classes.Typo1 : classes.Typo1Mobile}
              style={{}}
            >
              Get Sleep Apnea tested today.
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default DisplayData2;
