import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const FormStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    fontWeight: 500,
    fontSize: "16px",
    color: "#ffffff",
    backgroundColor: "#1A3A6C",
    width: "100px",
    height: "50px",
    borderRadius: "30px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    curson: 'pointer',
  }
}));

export default FormStyles;
