import { cartConstants } from "../_constants/cart.constants";

export const addToCart = (products) => {
  return {
    type: cartConstants.ADD_TO_CART,
    payload: {
      ...products,
    },
  };
};

export const deleteFromCart = (products) => {
  return {
    type: cartConstants.DELETE_FROM_CART,
    payload: {
      ...products,
    },
  };
};

export const documentPrescription = (documents) => {
  return {
    type: cartConstants.ADD_TO_DOCUMENT,
    payload: {
      documents,
    },
  };
};


export const deleteFromDocument = (documents) => {

  return {
    type: cartConstants.DELETE_FROM_DOCUMENTS,
    payload: {
      ...documents,
    },
  };
};



export const clearDocumentPrescription = () => {
  return {
    type: cartConstants.CLEAR_DOCUMENT,
    payload: {},
  };
};
