import Header from "../../shared/Header/Header";
import { Link } from "react-router-dom";
import {
  Button,
  Box,
  Divider,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import LandingPageStyles from "./LandingPage.styles";
import { CustomIcon } from "../../global/components";
import TestStagesSteps from "./components/TestStageSteps/TestStagesSteps";
import Symptoms from "../LandingPage/components/Symptoms/Symptoms";
import IconTabs from "../LandingPage/components/IconTabs/IconTabs";
import HomeSlider from "../LandingPage/components/HomeSlider/HomeSlider";
import about from "../../assets/images/about.png";
import Testimonials from "../../screens/LandingPage/components/Testimonials/Testimonials";
import { connect } from "react-redux";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import sign from "../../assets/images/Gaurav Patel.svg";
import Footer from "../../shared/Footer/Footer";
import { boldFont } from "../../utils/styles";
import { regularFont } from "../../utils/styles";
import { useEffect, useState } from "react";
import urls from "../../global/constants/UrlConstants";
import Bag from "../../assets/images/icons/Bag.png";

const LandingPage = (props) => {
  const [displayname, setName] = useState("");
  const classes = LandingPageStyles();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const getUser = async () => {
    try {
      let user = JSON.parse(localStorage.getItem("current_user")!);
      return user;
    } catch (err) {
      return null;
    }
  };

  useEffect(() => {
    document.title = "Welcome to Deepsleep Test";
  }, []);

  const loggedInUser = async () => {
    try {
      const current_user = await getUser();
      if (current_user !== null) {
        return current_user;
      }
    } catch (err) {}
  };

  const checkUser = async () => {
    try {
      const user = await loggedInUser();
      const display = user.data;
      const displayName = display.displayName;

      if (user !== null) {
        setName(displayName);
      }
    } catch (err) {}
  };
  let current_user = localStorage.getItem("current_user");

  const getLandingPage = () => {
    return (
      <>
        <Header />
        <HomeSlider />
        <Box className={classes.safeBox}>
          <Box className={classes.safeBoxForDesktop} style={{ zIndex: 10 }}>
            <Typography
              className={isDesktop ? classes.desktopTitle : classes.mobileTitle}
            >
              Safe, Non-invasive, Cost-effective and at your home.
            </Typography>
            <Typography
              className={
                isDesktop ? classes.desktopTitle2 : classes.mobileTitle2
              }
            >
              Order your Home Sleep Apnea Test Kit today.
            </Typography>
              {current_user?(<Box>
                {props.user.role==="Patient"?( <Link
              to="/buytest"
              className={classes.btn}
              style={{
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
                padding: "2px 10px",
                borderRadius: "15px",
              }}
            >
              <img src={Bag} />
              <Button style={{ color: "white" }}>Buy Test</Button>
            </Link>):""}
              </Box>):( <Link
              to="/login"
              className={classes.btn}
              style={{
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
                padding: "2px 10px",
                borderRadius: "15px",
              }}
            >
              <img src={Bag} />
              <Button style={{ color: "white" }}>Buy Test</Button>
            </Link>)}
           
          </Box>
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            margin: "75px 0",
          }}
        >
          <Box style={{ width: "75%" }}>
            <TestStagesSteps />
          </Box>
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box className={classes.SignificantSymptoms}>
            <Symptoms />
          </Box>
        </Box>
        <IconTabs />
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Testimonials />
        </Box>
        <Box style={{ margin: "50px 15%  0px 15%", display: "flex" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <img
                  src={about}
                  alt="about"
                  className={isDesktop ? "" : classes.responsiveDoctorsImage}
                  style={{ width: "80%" }}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={7}
              lg={7}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Box display="flex" alignItems="center">
                <Divider
                  style={{
                    width: "10%",
                    backgroundColor: "#EB6523",
                    marginRight: "10px",
                  }}
                />
                <Typography
                  style={{
                    color: "#EB6523",
                    fontSize: "20px",
                    fontWeight: 400,
                    ...regularFont,
                  }}
                >
                  About DeepSleep
                </Typography>
              </Box>
              <Typography
                style={{
                  color: "#1A3A6C",
                  fontSize: "48px",
                  fontWeight: 900,
                  ...boldFont,
                }}
              >
                We Are The Sleep Saviours!
              </Typography>
              <Typography
                style={{
                  color: "#283035",
                  fontSize: "20px",
                  fontWeight: 400,
                  ...regularFont,
                  marginBottom: "10px",
                }}
              >
                DeepSleep Test has been created with years of study and research
                on sleep apnea which gives us the confidence to empathize with
                the crumbling lives of Millions of Americans who are living in
                stress because of it.
              </Typography>
              <Typography
                style={{
                  color: "#283035",
                  fontSize: "20px",
                  fontWeight: 400,
                  ...regularFont,
                  marginBottom: "10px",
                }}
              >
                Even when so many are suffering because of this choking disorder
                yet applying for diagnosis is often ignored. But not anymore…
              </Typography>
              <Typography
                style={{
                  color: "#283035",
                  fontSize: "20px",
                  fontWeight: 400,
                  ...regularFont,
                  marginBottom: "10px",
                }}
              >
                Testing for sleep apnea is the first stage to cure and our goal
                is to provide a comfortable, cost- effective, and better outcome
                providing test, for you.
              </Typography>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                style={{ marginBottom: "50px" }}
              >
                <Box pr={1}>
                  <img src={sign} style={{ width: "100%" }}></img>
                </Box>
                <Box>
                  <Typography
                    className={
                      isDesktop
                        ? classes.aboutDeepsleepBoXDeskTop
                        : classes.aboutDeepsleepBoXMobile
                    }
                  >
                    Dr. Gaurav Patel
                  </Typography>
                  <Typography
                    style={{
                      color: "#EB6523",
                      fontSize: "24px",
                      ...regularFont,
                    }}
                    className={
                      isDesktop
                        ? classes.aboutDeepsleepBoXDeskTop1
                        : classes.aboutDeepsleepBoXMobile1
                    }
                  >
                    Board certified sleep physician
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          className={
            isDesktop
              ? classes.clickHereComponentDesktop
              : classes.clickHereComponentMobile
          }
        >
          <Typography
            className={isDesktop ? classes.desktopTitle : classes.mobileTitle}
            style={{
              marginTop: "90px",
              color: "#ffffff",
              fontSize: "48px",
              fontWeight: 900,
              textAlign: "center",
              ...regularFont,
            }}
          >
            For the free and confidential Sleep Apnea
            <br /> pre-assessment
          </Typography>
          <Link
            to="/freeAssessment"
          >
            <Button
              endIcon={<ArrowRightAltIcon />}
              style={{
                width: "150px",
                backgroundColor: "#ffffff",
                borderRadius: "15px",
                marginBottom: "100px",
                marginTop: "20px",
                color: "#283035",
                height: "55px",
              }}
            >
              <Typography
                style={{
                  ...regularFont,
                  fontWeight: 500,
                  fontSize: "16px",
                  color: "#283035",
                }}
              >
                Click Here
              </Typography>
            </Button>
          </Link>
        </Box>
        <Footer />
      </>
    );
  };
  return getLandingPage();
};

const mapState = (state) => {
  const { authentication, alert, loggedIn, shopReducer } = state;
  const { user } = authentication;
  return { authentication, user, alert, loggedIn, shopReducer };
};



export default connect(mapState)(LandingPage);
