export class StringConstants {
  //contentTypes
  applicationJSON = { "Content-Type": "application/json" };
  multipartForm = {};

  notification = "notification";
  error = "error";
  success = "success";
  warning = "warning";
  info = "info";
  autoHideDuration = 600 * 1000; //in milliseconds

  //date
  am = "AM";
  pm = "PM";

  SEARCH_TIME_OUT = 350;
}

let strings = new StringConstants();
export default strings;
