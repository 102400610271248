import { Badge, Box, Divider, Grid, Paper, Typography } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import DataCardStyles from "./DataCard.styles";
import { boldFont } from "../../../../utils/styles";
import { regularFont } from "../../../../utils/styles";

interface Props {
  img: string;
  title: string;
  data: string;
}

const DataCard = ({ img, title, data }: Props) => {
  const classes = DataCardStyles();
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: 'auto' }}
    >
      <Box
        style={{
          boxShadow: "0px 15px 30px 0px rgba(0, 0, 0, 0.05)",
          height: "188px",
          width: "188px",
          borderRadius: "50px",
          marginBottom: "20px",
        }}
      >
        <img
          src={img}
          style={{ transform: `translate(${100}%, ${100}%)` }}
        ></img>
      </Box>
      <Typography
        sx={{ mb: 1.5 }}
        variant="h5"
        component="div"
        style={{
          textAlign: "center",
          color: "#1A3A6C",
          fontSize: "24px",
          fontWeight: 900,
          ...regularFont,
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{ mb: 1.5 }}
        color="text.secondary"
        style={{
          textAlign: "center",
          color: "#283035",
          fontSize: "24px",
          fontWeight: 400,
          ...regularFont,
        }}
      >
        {data}
      </Typography>
      <Typography
        variant="body2"
        style={{
          textAlign: "center",
          fontWeight: 400,
          fontSize: "18px",
          ...regularFont,
          color: "#1A3A6C",
        }}
      >
        We will promptly respond you.
      </Typography>
    </div>
  );
};

export default DataCard;
