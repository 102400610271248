// export class User {
//   id: string = "";
//   pwd: string = "";
//   newPwd: string = "";
//   authToken: string = "";
//   name: string = "";
//   contactNo: string = "";
//   email: string = "";
//   role: string = "";
//   resources: string[] = [];
//   app: String = "";
//   appAccess: App[] = [];
//   account: string = "";
//   currentApp: App = new App();
//   pwdType: string = "";
//   roleLevel: number = 0;
//   status: string="";
// }

export class User {
  id: string = "";
  parentEmail: string = "";
  firstName: string = "";
  lastName: string = "";
  middleName: string = "";
  shippingAddress: Address = new Address();
  billingAddress: Address = new Address();
  primaryContactNumber: ContactNumber = new ContactNumber();
  secondaryContactNumber: ContactNumber = new ContactNumber();
  status: String = "";
  customAttributes: Metadata[] = [];
  clientSecret: string = "";
  legalAgreementSignatureId: App = new App();
  trackingNumber: string = "";
  promoCode: string = "";
  associations: Entity = new Entity();
  payableAmount: number = 0;

}

export class ContactNumber {
  number: string = "";
  extension: string = "";
  field: string = "";
  type: string = "";
}

export class Address {
  type: string = "";
  line1: string = "";
  line2: string = "";
  line3: string = "";
  city: string = "";
  state: string = "";
  pinCode: string = "";
  country: string = "";
}

export class Email {
  value: string = "";
  type: string = "";
}

export class LegalIdentifier {
  name: string = "";
  id: string = "";
  type: string = "";
  value: string = "";
}

export class Entity {
  id: string = "";
  displayId: string = "";
  type: string = "";
  subType: string = "";
  profilePicture: string = "";
  firstName: string = "";
  middleName: string = "";
  lastName: string = "";
  name: string = "";
  status: string = "Active";
  gender: string = "";
  dateOfBirth: string = "";
  hireDate: string = "";
  terminationDate: string = "";
  nationality: string = "";
  jobTitle: string = "";
  workStatus: string = "";
  managerId: string = "";
  managerName: string = "";
  managerEmail: string = "";
  mobileContactNumber: ContactNumber = new ContactNumber();
  homeContactNumber: ContactNumber = new ContactNumber();
  workContactNumber: ContactNumber = new ContactNumber();
  otherContactNumber: ContactNumber = new ContactNumber();
  primaryAddress: Address = new Address();
  otherAddress: Address = new Address();
  primaryEmail: Email = new Email();
  secondaryEmail: Email = new Email();
  primaryLegalIdentifier: LegalIdentifier = new LegalIdentifier();
  metadata: Metadata[] = [];
  comments: Comment[] = [];
  app: string = "";
}

export class Metadata {
  entityType: string = "";
  entityContext: string = "";
  id: string = "";
  name: string = "";
  value: string = "";
  mandatory: boolean = false;
  dataType: string = "";
  webComponentType: string = "";
  webComponentSource: any;
}



export class Role {
  app: string = "";
  name: string = "";
  resources: string[] = [];
  account: string = "";
  level: number = 0;
}

export class Resource {
  name: string = "";
  account: string = "";
  app: string = "";
}

export class App {
  app: string = "";
  role: string = "";
}

export class Account {
  name: string = "";
  type: string = "";
  status: string = "";
  app: string = "";
  parent: string = "";
  children: string[] = [];
}

