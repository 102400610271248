import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DownloadComponent from "../DownloadReport/DownloadReport";
import { regularFont } from "../../../../../utils/styles";
import { withStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { Pagination } from "@mui/material";

interface CustomProps {
  tableData: any[];
  deleteDocument: Function;
}

export default function FinalReport(props: CustomProps) {
  const [role, setRole] = useState("");
  useEffect(() => {
    let username = JSON.parse(localStorage.getItem("current_user")!);
    var role = username.role;
    setRole(role);
 
  }, []);

  return (
    <TableContainer>
      <Table size="small" style={{ borderBottom: "none" }}>
        <TableBody>
          {props.tableData.map((row: any) => (
            <TableRow>
              <TableCell style={{ borderBottom: "none", color: "#fff" }}>
                {row.title}
              </TableCell>
              <TableCell
                style={{
                  color: "#ffffff",
                  fontSize: "20px",
                  fontWeight: 400,
                  ...regularFont,
                  marginRight: "30px",
                  borderBottom: "none",
                }}
              >
                {row.name}
              </TableCell>
              <TableCell style={{ borderBottom: "none" }}>
                <DownloadComponent
                  fileStoragePath={row.path}
                  app="deepsleeptest"
                  ownerId={row.ownerId}
                  status={row.status}
                ></DownloadComponent>
                {role === "Admin"||role==="SleepPhysician" ? (
                  <DeleteIcon
                    style={{ color: "#EB6523", cursor: "pointer" }}
                    onClick={() => props.deleteDocument(row.id, row.path)}
                  />
                ) : (
                  ""
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
