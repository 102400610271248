import Header from "../../shared/Header/Header";
import {
  Box,
  Button,
  Divider,
  Container,
  Grid,
  Typography,
  Paper,
  FormControlLabel,
  Checkbox,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Repeat } from "@mui/icons-material";
import Banner from "../../shared/Banner/Banner";
import Footer from "../../shared/Footer/Footer";
import { boldFont } from "../../utils/styles";
import { regularFont } from "../../utils/styles";
import { Link as RouterLink } from "react-router-dom";
import icon from "../../assets/images/BuyTest/icon.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PaymentDetailsStyles from "./PaymentDetails.styles";

const PaymentDetails = () => {
  const classes = PaymentDetailsStyles();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <>
      <Header />

      {isDesktop ? (
        <Box
          className={isDesktop ? classes.Box1 : classes.Box1Mobile}

        >
          <Box
            className={isDesktop ? classes.Box2 : classes.Box2Mobile}

          >
            <Grid
              item
              lg
              container
              direction="column"
              alignItems="start"
              marginLeft="30%"
              paddingRight="30%"
              marginTop="40px"
            >
              <Typography
                // className={isDesktop ? classes.Typo1 : classes.Typo1Mobile}
                style={{
                  fontSize: "28px",
                  fontWeight: 900,
                  color: "#283035 !important",
                  ...boldFont,
                }}
              >
                Card Payment
              </Typography>
              <Typography
                style={{
                  fontSize: "20px",
                  fontWeight: 400,
                  color: "#283035",
                  marginTop: "5px",
                  ...regularFont,
                }}
              // className={isDesktop ? classes.Typo2 : classes.Typo2Mobile}
              >
                All transactions are secure and encrypted.
              </Typography>
              <input
                className={isDesktop ? classes.Input1 : classes.Input1Mobile}
                type="text"
                name="email"
                placeholder="Card Number"

              />
              <input
                className={isDesktop ? classes.Input2 : classes.Input2Mobile}
                type="text"
                name="email"
                placeholder="Name On Card"

              />
              <Box style={{ display: "flex", width: "85%" }}>
                <input
                  // className={isDesktop ? classes.Input2 : classes.Input2Mobile}
                  type="text"
                  name="email"
                  placeholder="MM/YY"
                  style={{
                    marginTop: "10px",
                    marginRight: "4%",
                    width: "100%",
                    height: "55px",
                    fontSize: "20px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    border: "1px solid rgba(40, 48, 53, 0.15) ",
                    borderRadius: "15px ",
                  }}
                />
                <input
                  // className={isDesktop ? classes.Input2 : classes.Input2Mobile}
                  type="text"
                  name="email"
                  placeholder="CVC"
                  style={{
                    marginTop: "10px",
                    width: "100%",
                    height: "55px",
                    fontSize: "20px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    border: "1px solid rgba(40, 48, 53, 0.15) ",
                    borderRadius: "15px ",
                    // marginRight:"15%"
                  }}
                />
              </Box>
              <input
                className={isDesktop ? classes.Input2 : classes.Input2Mobile}
                type="text"
                name="email"
                placeholder="Zipcode"

              />
            </Grid>
            <Box
              className={isDesktop ? classes.Box4 : classes.Box4Mobile}

            >
              <Button
                className={isDesktop ? classes.Button1 : classes.Button1Mobile}

              >
                Pay Now
              </Button>
              <Button
                className={isDesktop ? classes.Button2 : classes.Button2Mobile}
                startIcon={<ArrowBackIcon />}

              >
                Back To Home
              </Button>
            </Box>
          </Box>
          <Box
            className={isDesktop ? classes.Box5 : classes.Box5Mobile}

          >
            <Box
              className={isDesktop ? classes.Box6 : classes.Box6Mobile}

            >
              <Box
                className={isDesktop ? classes.Box7 : classes.Box7Mobile}

              >
                <img src={icon} />
                <Typography className={classes.Typo3}>
                  Sleep Apnea Test
                  <br /> Equipment
                </Typography>
              </Box>
              <Box
                className={isDesktop ? classes.Box8 : classes.Box8Mobile}

              >
                <Typography className={classes.Typo4}>
                  $175
                </Typography>
              </Box>
            </Box>
            <Divider className={classes.Divider1} />

            <Box
              className={isDesktop ? classes.Box9 : classes.Box9Mobile}

            >
              <input
                className={classes.Input3}
                type="text"
                name="email"
                placeholder="Promo Code"

              />

              <Button className={classes.Button3}>
                Apply
              </Button>
            </Box>

            <Box className={classes.Box10}>
              <Box className={classes.Box11}>
                <Typography className={classes.Typo6}>
                  Sub Total
                </Typography>
              </Box>
              <Box className={classes.Box12}>
                <Typography className={classes.Typo5}>
                  $175
                </Typography>
              </Box>
            </Box>
            <Box className={classes.Box13}>
              <Box className={classes.Box14}>
                <Typography className={classes.Typo6}>
                  Shipping
                </Typography>
              </Box>
              <Box className={classes.Box15}>
                <Typography className={classes.Typo5}>
                  $30
                </Typography>
              </Box>
            </Box>
            <Box className={classes.Box16}>
              <Box className={classes.Box17}>
                <Typography className={classes.Typo6}>
                  Discount
                </Typography>
              </Box>
              <Box className={classes.Box18}>
                <Typography className={classes.Typo5}>
                  0%
                </Typography>
              </Box>
            </Box>

            <Divider className={classes.Divider2} />
            <Box className={classes.Box19}>
              <Box className={classes.Box20}>
                <Typography className={classes.Typo7}>
                  Total
                </Typography>
              </Box>
              <Box className={classes.Box21}>
                <Typography className={classes.Typo7}>
                  $205
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          className={isDesktop ? classes.Box1 : classes.Box1Mobile}

        >
          <Box
            className={isDesktop ? classes.Box5 : classes.Box5Mobile}

          >
            <Box
              className={isDesktop ? classes.Box6 : classes.Box6Mobile}

            >
              <Box
                className={isDesktop ? classes.Box7 : classes.Box7Mobile}

              >
                <img src={icon} />
                <Typography className={classes.Typo3Mobile}>
                  Sleep Apnea Test
                  <br /> Equipment
                </Typography>
              </Box>
              <Box
                className={isDesktop ? classes.Box8 : classes.Box8Mobile}

              >
                <Typography className={classes.Typo4Mobile}>
                  $175
                </Typography>
              </Box>
            </Box>
            <Divider className={classes.Divider1Mobile} />

            <Box
              className={isDesktop ? classes.Box9 : classes.Box9Mobile}

            >
              <input
                className={classes.Input3Mobile}
                type="text"
                name="email"
                placeholder="Promo Code"

              />

              <Button className={classes.Button3Mobile}>
                Apply
              </Button>
            </Box>

            <Box className={classes.Box10Mobile}>
              <Box className={classes.Box11Mobile}>
                <Typography className={classes.Typo6Mobile}>
                  Sub Total
                </Typography>
              </Box>
              <Box className={classes.Box12Mobile}>
                <Typography className={classes.Typo5Mobile}>
                  $175
                </Typography>
              </Box>
            </Box>
            <Box className={classes.Box13Mobile}>
              <Box className={classes.Box14Mobile}>
                <Typography className={classes.Typo6Mobile}>
                  Shipping
                </Typography>
              </Box>
              <Box className={classes.Box15Mobile}>
                <Typography className={classes.Typo5Mobile}>
                  $30
                </Typography>
              </Box>
            </Box>
            <Box className={classes.Box16Mobile}>
              <Box className={classes.Box17Mobile}>
                <Typography className={classes.Typo6Mobile}>
                  Discount
                </Typography>
              </Box>
              <Box className={classes.Box18Mobile}>
                <Typography className={classes.Typo5Mobile}>
                  0%
                </Typography>
              </Box>
            </Box>

            <Divider className={classes.Divider2Mobile} />
            <Box className={classes.Box19Mobile}>
              <Box className={classes.Box20Mobile}>
                <Typography className={classes.Typo7Mobile}>
                  Total
                </Typography>
              </Box>
              <Box className={classes.Box21Mobile}>
                <Typography className={classes.Typo7Mobile}>
                  $205
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            className={isDesktop ? classes.Box2 : classes.Box2Mobile}

          >
            <Grid
              item
              lg
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              marginTop="40px"
            >
              <Typography
                className={isDesktop ? classes.Typo1 : classes.Typo1Mobile}

              >
                Contact Information
              </Typography>
              <input
                className={isDesktop ? classes.Input1 : classes.Input1Mobile}
                type="text"
                name="email"
                placeholder="Email Address"

              />
              <Typography
                className={isDesktop ? classes.Typo2 : classes.Typo2Mobile}

              >
                Billing Address
              </Typography>
              <input
                className={isDesktop ? classes.Input1 : classes.Input1Mobile}
                type="text"
                name="email"
                placeholder="Address 1"

              />
              <input
                className={isDesktop ? classes.Input2 : classes.Input2Mobile}
                type="text"
                name="email"
                placeholder="Address 2"

              />
              <input
                className={isDesktop ? classes.Input2 : classes.Input2Mobile}
                type="text"
                name="email"
                placeholder="State"

              />
              <input
                className={isDesktop ? classes.Input2 : classes.Input2Mobile}
                type="text"
                name="email"
                placeholder="City"

              />
              <input
                className={isDesktop ? classes.Input2 : classes.Input2Mobile}
                type="text"
                name="email"
                placeholder="Zipcode"

              />
              <Typography
                className={isDesktop ? classes.Typo2 : classes.Typo2Mobile}

              >
                Shipping Address
              </Typography>
              <Box className={isDesktop ? classes.Box3 : classes.Box3Mobile}>
                <FormControlLabel
                  control={
                    <Checkbox value="allowExtraEmails" color="primary" />
                  }
                  label="Same as billing Address"
                />
              </Box>
              <input
                className={isDesktop ? classes.Input1 : classes.Input1Mobile}
                type="text"
                name="email"
                placeholder="Address 1"

              />
              <input
                className={isDesktop ? classes.Input2 : classes.Input2Mobile}
                type="text"
                name="email"
                placeholder="Address 2"

              />
              <input
                className={isDesktop ? classes.Input2 : classes.Input2Mobile}
                type="text"
                name="email"
                placeholder="State"

              />
              <input
                className={isDesktop ? classes.Input2 : classes.Input2Mobile}
                type="text"
                name="email"
                placeholder="City"

              />
              <input
                className={isDesktop ? classes.Input2 : classes.Input2Mobile}
                type="text"
                name="email"
                placeholder="Zipcode"

              />
            </Grid>
            <Box
              className={isDesktop ? classes.Box4 : classes.Box4Mobile}

            >
              <Button
                className={isDesktop ? classes.Button1 : classes.Button1Mobile}

              >
                <Typography className={classes.Typo8Mobile}>
                  Continue To Shipping
                </Typography>
              </Button>
              <Button
                className={isDesktop ? classes.Button2 : classes.Button2Mobile}
                startIcon={<ArrowBackIcon />}

              >
                <Typography className={classes.Typo8Mobile}>
                  Back To Home
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      )}

      <Footer />
    </>
  );
};

export default PaymentDetails;
