import Header from "../../../shared/Header/Header";
import Footer from "../../../shared/Footer/Footer";
import { Box, Button, Divider, Container, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { Link, Link as RouterLink } from "react-router-dom";
import { boldFont } from "../../../utils/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { regularFont } from "../../../utils/styles";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import Banner from "../../../shared/Banner/Banner";
import invoice from "../../../assets/images/Invoice/invoice.png";
import deeplogo from "../../../assets/images/OrderDetails/deeplogo.png";
import { userService } from "../../../services";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReactToPrint from "react-to-print";
import ReciptStyles from "./Recipt.styles";
import CustomizedTables from "./Table";
import Loading from "../../Loading/Loading";

const Reciept = (props) => {
  const classes = ReciptStyles();
  const [isLoading, setIsLoading] = useState(false);

  const [user, setUser] = useState({
    item: {
      title1: "View Order Detail",
    },
    loading: false,
    orderId: "",
    total: 0,
    ShippingCost: 0,
    shippingAddress: {},
    billingAddress: {},
    firstName: "",
    lastName: "",
    mobileContactNumber: "",
    primaryEmail: "",
    documentPreview: [],
    orderItems: [],
    date: "",
    status: "",
  });

  const [orderlist, setOrderList] = useState([]);

  const [billingAddress, setBillingAddress] = useState({
    line1: "",
    line2: "",
    line3: "",
    line4: "",
    city: "",
    state: "",
    pinCode: "",
    country: "United States of America",
  });

  const [shippingAddress, setShippingAddress] = useState({
    line1: "",
    line2: "",
    line3: "",
    line4: "",
    city: "",
    state: "",
    pinCode: "",
    country: "United States of America",
  });

  useEffect(() => {
    const orderId = new URLSearchParams(props.location.search).get("id");
    handleGetMyOrder(orderId);
  }, []);

  const handleGetMyOrder = (id) => {
    setIsLoading(true);

    userService
      .getOrder(id)
      .then((response) => {
        setBillingAddress({
          ...billingAddress,
          line1: response.customer.billingAddress.line1,
          line2: response.customer.billingAddress.line2,
          line3: response.customer.billingAddress.line3,
          line4: response.customer.billingAddress.line4,
          city: response.customer.billingAddress.city,
          state: response.customer.billingAddress.state,
          pinCode: response.customer.billingAddress.pinCode,
          country: response.customer.billingAddress.country,
        });
        setShippingAddress({
          ...shippingAddress,
          line1: response.customer.shippingAddress.line1,
          line2: response.customer.shippingAddress.line2,
          line3: response.customer.shippingAddress.line3,
          line4: response.customer.shippingAddress.line4,
          city: response.customer.shippingAddress.city,
          state: response.customer.shippingAddress.state,
          pinCode: response.customer.shippingAddress.pinCode,
          country: response.customer.shippingAddress.country,
        });
        setOrderList(response.orderItems);
        setUser({
          ...user,
          shippingAddress: shippingAddress,
          billingAddress: billingAddress,
          orderId: response?.id,
          date: response?.audit?.createdOn,
          mobileContactNumber: response.customer?.mobileContactNumber?.number,
          primaryEmail: response.customer?.primaryEmail?.value,
          firstName: response.customer?.firstName,
          lastName: response.customer?.lastName,
          status: response?.status,
          orderItems: response?.orderItems,
          total: response?.totalAmount,
          ShippingCost: response?.shippingCost,
        });
        setIsLoading(false);
      })

      .catch((error) => {});
  };

  var img = new Image();
  img.src = deeplogo;
  const exportPDF = () => {
    window.open("localhost:5000/dashboard");
    // const doc = new jsPDF();
    // doc.addImage(img, "JPEG", 10, 8, 53, 15);
    // doc.setFontSize(30);
    // doc.setFont("bold");
    // doc.setTextColor("#1A3A6C");
    // doc.text("INVOICE", 150, 22);
    // doc.line(10, 30, 195, 30);
    // doc.setFontSize(22);
    // doc.setFont("bold");
    // doc.setTextColor("#000000");
    // doc.setFontSize(18);
    // doc.text("Invoice To", 15, 45);
    // doc.setFont("regular");
    // doc.text("Id", 140, 45);
    // doc.text("Date", 140, 55);
    // doc.text(
    //   user.orderId.substr(user.orderId.length - 5).toUpperCase(),
    //   165,
    //   45
    // );
    // doc.text(user.date.substring(0, 10), 165, 55);
    // doc.text(user.firstName, 15, 55);
    // doc.text(shippingAddress.line1, 15, 65);
    // doc.text(shippingAddress.line2, 15, 75);
    // doc.text(shippingAddress.city + "," + shippingAddress.state, 15, 85);
    // doc.text(shippingAddress.pinCode + "," + shippingAddress.country, 15, 95);

    // autoTable(doc, {
    //   head: [["Product", "SubTotal"]],
    //   body: [
    //     ["Sleep Apnea test equipment", "$175"],
    //     ["Shipping", "$30"],
    //     ["Total", "$205"],
    //   ],
    //   startY: 100,
    // });
    // doc.save(user.orderId.substr(user.orderId.length - 5).toUpperCase());
  };
  const componentRef = useRef(null);

  return (
    <>
      <Header />
      <Banner
        img={invoice}
        header1="Home / View Receipt/ Invoice"
        header2="Invoice"
      />

      {isLoading ? (
        <Loading />
      ) : (
        <Container
          maxWidth={false}
          style={{ marginBottom: "20px", width: "71%" }}
        >
          <Box style={{ display: "flex", justifyContent: "flex-end" }}>
            <Box style={{ marginRight: "40px" }}>
              <ReactToPrint
                trigger={() => {
                  return (
                    <Button
                      // onClick={exportPDF}
                      startIcon={
                        <SystemUpdateAltIcon style={{ color: "#EB6523" }} />
                      }
                      style={{
                        boxShadow: "0px 15px 30px 0px rgba(0, 0, 0, 0.09)",
                        width: "100%",
                        height: "70px",
                        borderRadius: "20px",
                      }}
                    >
                      <Typography
                        style={{
                          color: "#1A3A6C",
                          fontSize: "18px",
                          fontWeight: 900,
                          ...regularFont,
                        }}
                      >
                        Download
                      </Typography>
                    </Button>
                  );
                }}
                content={() => componentRef.current}
                documentTitle="Invoice"
              />
            </Box>
            <Box>
              <Link style={{ textDecoration: "none" }} to="/dashboard">
                <Button
                  startIcon={<ArrowBackIcon style={{ color: "#EB6523" }} />}
                  style={{
                    boxShadow: "0px 15px 30px 0px rgba(0, 0, 0, 0.09)",
                    width: "100%",
                    height: "70px",
                    borderRadius: "20px",
                  }}
                >
                  <Typography
                    style={{
                      color: "#1A3A6C",
                      fontSize: "18px",
                      fontWeight: 900,
                      ...regularFont,
                    }}
                  >
                    Back To dashboard
                  </Typography>
                </Button>
              </Link>
            </Box>
          </Box>

          <Box style={{margin:"0 30px 0 30px"}} ref={componentRef}>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "40px",
              }}
            >
              <Box>
                <img src={deeplogo}></img>
              </Box>

              <Box>
                <Typography
                  style={{
                    fontSize: "58px",
                    color: "#1A3A6C",
                    fontWeight: "bold",
                  }}
                >
                  INVOICE
                </Typography>
              </Box>
            </Box>

            <Divider />

            <Box style={{ marginTop: "35px" }}>
              <Table
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  borderBottom: "none",
                }}
              >
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{
                        borderBottom: "none",
                        padding: "0 15px 0 0",
                        ...boldFont,
                        fontSize: "20px",
                      }}
                    >
                      Id
                    </TableCell>
                 
                    <TableCell
                      style={{
                        borderBottom: "none",
                        padding: "0 0 0 0",
                        ...regularFont,
                        fontSize: "20px",
                      }}
                    >
                      {user.orderId
                        .substr(user.orderId.length - 5)
                        .toUpperCase()}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        borderBottom: "none",
                        padding: "10px 15px 0 0",
                        ...boldFont,
                        fontSize: "20px",
                      }}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: "none",
                        padding: "10px 0 0 0",
                        ...regularFont,
                        fontSize: "20px",
                      }}
                    >
                      {user.date.substring(0, 10)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>

            <Box>
              <Box>
                <Typography
                  style={{
                    ...boldFont,
                    fontSize: "28px",
                    marginBottom: "15px",
                  }}
                >
                  Invoice To
                </Typography>
                <Typography
                  style={{
                    ...regularFont,
                    fontSize: "28px",
                    marginBottom: "15px",
                  }}
                >
                  {user.firstName}
                </Typography>
                <Typography style={{ ...regularFont, fontSize: "24px" }}>
                  {shippingAddress.line1}
                </Typography>
                <Typography style={{ ...regularFont, fontSize: "24px" }}>
                  {shippingAddress.line2}
                </Typography>
                <Typography style={{ ...regularFont, fontSize: "24px" }}>
                  {shippingAddress.city}, {shippingAddress.state}
                </Typography>
                <Typography style={{ ...regularFont, fontSize: "24px" }}>
                  {shippingAddress.pinCode}, {shippingAddress.country}
                </Typography>
              </Box>
            </Box>
            <Box style={{ marginTop: "30px" }}>
              <CustomizedTables tabledata={orderlist} user={user} />
            </Box>
          </Box>
        </Container>
      )}

      <Footer />
    </>
  );
};

export default Reciept;
