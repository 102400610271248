import { Box, Button, Typography } from "@mui/material";
import pageNotFoundStyles from "./PageNotFound.styles";
import Header from "../../shared/Header/Header";
import Footer from "../../shared/Footer/Footer";
import { regularFont, boldFont } from "../../utils/styles";
import bg from "../../assets/images/bg.png";
import notfound from "../../assets/images/notfound.png";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  const classes = pageNotFoundStyles();

  const getPageNotFound = () => {
    return (
      <Box>
        <Header />
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            backgroundImage: "url(" + bg + ")",
            backgroundSize:"100%"
          }}
        >
          <img src={notfound}></img>
          <Link to="/">
          <Button style={{backgroundColor:"#1A3A6C",marginTop:"15px",marginBottom:"20px",color:"#ffffff",textDecoration:"none"}}>Back to Home</Button>
          </Link>
        </Box>
        <Footer />
      </Box>
    );
  };
  return getPageNotFound();
};

export default PageNotFound;
