import Footer from "../../../shared/Footer/Footer";
import Header from "../../../shared/Header/Header";
import { Container, Grid, TextField, Typography } from "@mui/material";

import { boldFont } from "../../../utils/styles";
import { regularFont } from "../../../utils/styles";
import { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    document.title = "Privacy & Policy";
  }, []);
  return (
    <div>
      <Header />
      <Container>
        <Grid container spacing={2} style={{ ...regularFont ,fontSize:"20px"}}>
          <Grid item sm={12} md={12} lg={12} mt={5}>
          <Typography variant="h3" mb={4} style={{ color: "#EB6523",...boldFont}}>
              Privacy and Policy
            </Typography>
            <p>
              At Deep Sleep, accessible from www.deepsleeptest.com, one of our
              main priorities is the privacy of our visitors. This Privacy
              Policy document contains types of information that is collected
              and recorded by Deep Sleep and how we use it.
            </p>
            <p>
              If you have additional questions or require more information about
              our Privacy Policy, do not hesitate to contact us.
            </p>
            <p>
              This Privacy Policy applies only to our online activities and is
              valid for visitors to our website with regards to the information
              that they shared and/or collect in Deep Sleep. This policy is not
              applicable to any information collected offline or via channels
              other than this website.
            </p>
          </Grid>
          <Grid item sm={12} md={12} lg={12}>
            <Typography variant="h4" style={{ color: "#1A3A6C",...boldFont }}>
              Consent
            </Typography>
            <p>
              By using our website, you hereby consent to our Privacy Policy and
              agree to its terms.
            </p>
          </Grid>
          <Grid item sm={12} md={12} lg={12}>
            <Typography variant="h4" style={{ color: "#1A3A6C",...boldFont }}>
              Information we collect
            </Typography>
            <p>
              The personal information that you are asked to provide, and the
              reasons why you are asked to provide it, will be made clear to you
              at the point we ask you to provide your personal information.
            </p>
            <p>
              If you contact us directly, we may receive additional information
              about you such as your name, email address, phone number, the
              contents of the message and/or attachments you may send us, and
              any other information you may choose to provide.
            </p>
            <p>
              When you register for an Account, we may ask for your contact
              information, including items such as name, company name, address,
              email address, and telephone number.
            </p>
            <p>How we use your information</p>

            <Typography variant="h4" style={{ color: "#1A3A6C",...boldFont }}>
              We use the information we collect in various ways, including to:
            </Typography>
            <p>1. Provide, operate, and maintain our website</p>
            <p>2. Improve, personalize, and expand our website</p>
            <p>3. Understand and analyze how you use our website</p>
            <p>
              4. Develop new products, services, features, and functionality
            </p>
            <p>
              5. Communicate with you, either directly or through one of our
              partners, including for customer service, to provide you with
              updates and other information relating to the website, and for
              marketing and promotional purposes
            </p>
            <p>6. Send you emails</p>
            <p>7. Find and prevent fraud</p>
          </Grid>
          <Grid item sm={12} md={12} lg={12} mb={5}>
            <Typography variant="h4" style={{ color: "#1A3A6C",...boldFont }}>
              Cookies and Web Beacons
            </Typography>
            <p>
              Like any other website, Deep Sleep uses ‘cookies’. These cookies
              are used to store information including visitors’ preferences, and
              the pages on the website that the visitor accessed or visited. The
              information is used to optimize the users’ experience by
              customizing our web page content based on visitors’ browser type
              and/or other information.
            </p>
          </Grid>
        </Grid>
      </Container>

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
