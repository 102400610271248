import * as React from "react";
import { useEffect, useState } from "react";
import { styled, Box } from "@mui/system";
import ModalUnstyled from "@mui/base/ModalUnstyled";
// import high from "../../../../assets/images/Model/high.png";
// import low from "../../../../assets/images/Model/low.png";
import { Button, Typography } from "@material-ui/core";
import { boldFont, regularFont } from "../../../utils/styles";
// import AddUser from "../../../../assets/images/Model/AddUser.png";
// import Login from "../../../../assets/images/Model/Login.png";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import LoginIcon from "@mui/icons-material/Login";
import { TextField } from "@mui/material";
import { Link } from "react-router-dom";
import urls from "../../../global/constants/UrlConstants";
import history from "../../../utils/history";
import loading2 from "../../../assets/images/Login/loading2.gif";
import { userActions } from "../../../redux/_actions/user.action";
import { userService } from "../../../services/sleepTestUserService-service";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { openErrorNotification } from "../../../helpers/methods";
import ModalStyles from "./modal.styles";

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  p: 2,
  px: 4,
  pb: 3,
};

function ModalUnstyledDemo1(open) {
  const [user, setUser] = useState({});
  const [physician, setphysician] = useState({});
  const [loading, setLoading] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [physicians, setPhysicians] = useState([]);

  useEffect(() => {
    sleepPhysicians();
  }, []);

  const sleepPhysicians = async () => {
    try {
      const response = await userService.getSleepPhysicians();
      setPhysicians(response);
    } catch (error: any) {
      openErrorNotification(error);
    }
  };

  const changetracking = (e) => {
    setLoading(false);
    setphysician(e.target.value);
  };

  let current_user = localStorage.getItem("current_user");

  const EquipmentRecieved = (e) => {
    var doc;
    doc = physician;
    var docEmail = doc.email;
    var paitent = open.user;
    paitent.parentEmail = docEmail;
    // console.log(paitent);

    if (physician !== undefined && physician !== "" && physician !== null) {
      setInvalid(false);
      setLoading(true);
      userService.equipmentRecieved(paitent).then(
        () => {
          setLoading(false);
          open.handleClose();
        },
        (error: any) => {
          alert("something Went wrong");
          openErrorNotification(error);
          setLoading(false);
        }
      );
    } else {
      setInvalid(true);
    }
  };

  const handleChangeDoctor = (e) => {
    setphysician(e.target.value);
  };

  const classes = ModalStyles();

  return (
    <div>
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open.open}
        onClose={open.handleClose}
        BackdropComponent={Backdrop}
      >
        <Box
          style={{
            backgroundColor: "white",
            width: "20%",
            height: "50%",
            borderRadius: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "0% 2% 1% 2%",
          }}
        >
          {/* <img src={high}></img> */}
          <h2 id="unstyled-modal-title">Equipment Recieved</h2>
          <Typography style={{ textAlign: "center" }}>
            Assisgn Physician
          </Typography>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "30px",
            }}
          >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Doctor</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={physician}
                onChange={handleChangeDoctor}
                label="Doctor"
                style={{ width: "300px" }}
              >
                {physicians.map((physicians: any) => {
                  return (
                    <MenuItem className={classes.root} value={physicians}>
                      {physicians.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          {invalid ? (
            <span style={{ color: "red" }}>Please enter tracking number</span>
          ) : (
            ""
          )}
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Button
              type="submit"
              fullWidth
              onClick={EquipmentRecieved}
              variant="contained"
              style={{
                ...regularFont,
                fontWeight: 500,
                fontSize: "16px",
                color: "#ffffff",
                backgroundColor: "#EB6523",
                width: "130px",
                height: "55px",
                borderRadius: "15px",
                marginTop: "20px",
                marginRight: "20px",
              }}
            >
              {loading ? (
                <img
                  style={{ width: "30%", height: "100%" }}
                  src={loading2}
                ></img>
              ) : (
                "Submit"
              )}
            </Button>
            <Button
              type="submit"
              fullWidth
              onClick={open.handleClose}
              variant="contained"
              style={{
                ...regularFont,
                fontWeight: 500,
                fontSize: "16px",
                color: "#ffffff",
                backgroundColor: "#EB6523",
                width: "130px",
                height: "55px",
                borderRadius: "15px",
                marginTop: "20px",
                marginLeft: "20px",
              }}
            >
              Back
            </Button>
          </Box>
        </Box>
      </StyledModal>
    </div>
  );
}

export default ModalUnstyledDemo1;
