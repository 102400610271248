import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";

interface Props {
  header: string;
  data: string;
}

const DataList = ({ header, data }: Props) => {
  return (
    <div style={{ marginBottom: "30px" }}>
      <Accordion
        style={{
          boxShadow: "0px 15px 30px 0px rgba(0, 0, 0, 0.05)",
          borderRadius: "20px",
        }}
      >
        <AccordionSummary
          style={{ height: "100px", marginLeft: "20px" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            style={{ fontSize: "24px", color: "#283035", fontWeight: 600 }}
          >
            {header}
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ marginLeft: "20px" }}>
          <Typography style={{ fontSize: "20px", color: "#283035" }}>
            {data}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default DataList;
