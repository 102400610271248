import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import NavbarStyles from "./Navbar.styles";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Menu, MenuItem, Typography } from "@mui/material";
import { useState } from "react";

const Navbar = () => {
  const classes = NavbarStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getDesktopMenu = () => {
    return (
      <>
        <Box
          style={{ display: "flex", alignItems: "center", textAlign: "center" }}
        >
          <Typography className={classes.customMargin} mx={2} >
            <Link to="/" className={classes.link}>
              HOME
            </Link>
          </Typography>
          <Typography  className={classes.customMargin} mx={2} >
            <Link to="/products" className={classes.link}>
              STORE
            </Link>
          </Typography>
          <Typography className={classes.customMargin} mx={2} >
            <Link to="/freeAssessment" className={classes.link}>
              FREE ASSESSMENT
            </Link>
          </Typography>
          <Typography mx={2} onClick={handleClick} className={classes.link} >
            SERVICES
          </Typography>
          <Typography className={classes.customMargin} mx={2} >
            <Link to="/contact" className={classes.link}>
              CONTACT
            </Link>
          </Typography>
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "10px",
              alignItems: "flex-start",
            }}
          >
            <MenuItem style={{ padding: "5px" }}>
              <Link to="/sleepProblemIndIviduals" className={classes.link}>
                Sleep Problem Individuals
              </Link>
            </MenuItem>
            <MenuItem style={{ padding: "5px" }}>
              <Link
                to="/commercialDriversLicenseHolders"
                className={classes.link}
              >
                Commerical Driving Licence Holders
              </Link>
            </MenuItem>
            <MenuItem style={{ padding: "5px" }}>
              <Link to="/dentistRecommendPatients" className={classes.link}>
                Dentist Recommend Patients
              </Link>
            </MenuItem>
          </Box>
        </Menu>
      </>
    );
  };

  const getMobileMenu = () => {
    return <Box>Mobile</Box>;
  };

  const getMenu = () => {
    return isDesktop ? getDesktopMenu() : getMobileMenu();
  };

  return getMenu();
};

export default Navbar;
