import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import ViewUserTableStyles from "./ViewUserTable.style";
import { boldFont } from "../../../../../utils/styles";
import { TablePagination } from "@mui/material";


interface CustomProps {
  tableData: any[]
}

export default function ViewUserTable(props: CustomProps) {
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const classes = ViewUserTableStyles();


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  return (<>
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 700 }}
        style={{
          borderCollapse: "unset",
          borderSpacing: "0px 10px",
        }}
        aria-label="customized table"
      >
        <TableHead>
          <TableRow>
            <TableCell align="center">Email</TableCell>
            <TableCell align="center">Name</TableCell>
            <TableCell align="center">Contact No</TableCell>
            <TableCell align="center">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? props.tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : props.tableData).map((row, index) => (
              <TableRow>
                <TableCell
                  style={{ padding: "20px", color: "#1A3A6C", ...boldFont, fontSize: "15px", fontWeight: 900 }}
                  align="center"
                  component="th"
                  scope="row"
                >
                  <Link style={{ color: "#1A3A6C", ...boldFont, fontSize: "15px", textDecoration: "none" }}
                    to={
                      "viewUserDetail?id=" +
                      row.email +
                      "&name=" +
                      row.firstName
                    }
                    className="dashboard-link"
                  >
                    {row.email}
                  </Link>

                </TableCell>
                <TableCell align="center">{row.name}</TableCell>
                <TableCell align="center">{row.primaryContactNumber?.number ? row.primaryContactNumber?.number : ''}</TableCell>
                <TableCell align="center">{row.status}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer >
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={props.tableData.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  </>
  );
}
