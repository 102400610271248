import Header from "../../shared/Header/Header";
import {
  Box,
  Button,
  Divider,
  Container,
  Grid,
  Typography,
  Paper,
  CardMedia,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Repeat } from "@mui/icons-material";
import AboutUsStyles from "./AboutUs.styles";
import Banner from "../../shared/Banner/Banner";
import Footer from "../../shared/Footer/Footer";
import AboutUsFooter from "./components/AboutUsFooter/AboutUsFooter";
import bp from "../../assets/images/AboutUs/bp.png";
import doctor from "../../assets/images/AboutUs/doctor.png";
import firstaid from "../../assets/images/AboutUs/firstaid.png";

import { boldFont } from "../../utils/styles";
import { regularFont } from "../../utils/styles";
import AboutUsBanner from "../../assets/images/AboutUs/AboutUsBanner.png";
import { useEffect } from "react";
const AboutUs = () => {
  const classes = AboutUsStyles();

  useEffect(() => {
    document.title = "About Us";
  }, []);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <>
      <Header />
      <Banner img={AboutUsBanner} header1="Home/ About Us" header2="About Us" />
      <Box className={isDesktop ? classes.Box1 : classes.Box1Mobile} style={{}}>
        <Box
          className={isDesktop ? classes.Box2 : classes.Box2Mobile}
          style={{}}
        >
          <img
            className={
              isDesktop ? classes.imagestyle : classes.imagestyleMobile
            }
            style={{}}
            src={doctor}
          />
        </Box>
        <Box
          className={isDesktop ? classes.Box3 : classes.Box3Mobile}
          style={{}}
        >
          <Typography
            className={isDesktop ? classes.Typo1 : classes.Typo1Mobile}
            style={{}}
          >
            We are the sleep warriors
          </Typography>
          <Typography
            className={isDesktop ? classes.Typo2 : classes.Typo2Mobile}
            style={{}}
          >
            DeepSleep Test has been created with years of study and research on
            sleep apnea which gives us the confidence to empathize with the
            crumbling lives of Millions of Americans who are living in stress
            because of it. <br />
            <br />
            Even when so many are suffering because of this choking disorder yet
            applying for diagnosis is often ignored. But not anymore… <br />
            <br />
            Testing for sleep apnea is the first stage to cure and our goal is
            to provide a comfortable, cost- effective, and better outcome
            providing test, for you.
          </Typography>
        </Box>
      </Box>

      {isDesktop ? (
        <Box
          className={isDesktop ? classes.Box4 : classes.Box4Mobile}
          style={{}}
        >
          <Box
            className={isDesktop ? classes.Box5 : classes.Box5Mobile}
            style={{}}
          >
            <Typography
              className={isDesktop ? classes.Typo1 : classes.Typo1Mobile}
              style={{}}
            >
              Test At Your Home!
            </Typography>
            <Typography
              className={isDesktop ? classes.Typo2 : classes.Typo2Mobile}
              style={{}}
            >
              We make sure to give you a comfortable test session right at your
              home instead of spending a night at a sleep lab. Our home sleep
              apnea test kit- a more comfortable and cost-effective option,
              comes with a compact home sleep testing device that records your
              sleep pattern throughout the night. <br />
              <br />
              We realized that Sleep Apnea is a curable disorder, and we work
              hard to extend our knowledge and services to gift you a healthy
              life that comes from a peaceful sleep.
            </Typography>
          </Box>
          <Box
            className={isDesktop ? classes.Box6 : classes.Box6Mobile}
            style={{}}
          >
            <img
              className={
                isDesktop ? classes.imagestyle : classes.imagestyleMobile
              }
              style={{}}
              src={bp}
            />
          </Box>
        </Box>
      ) : (
        <Box
          className={isDesktop ? classes.Box1 : classes.Box1Mobile}
          style={{}}
        >
          <Box
            className={isDesktop ? classes.Box2 : classes.Box2Mobile}
            style={{}}
          >
            <img
              className={
                isDesktop ? classes.imagestyle : classes.imagestyleMobile
              }
              style={{}}
              src={bp}
            />
          </Box>
          <Box
            className={isDesktop ? classes.Box3 : classes.Box3Mobile}
            style={{}}
          >
            <Typography
              className={isDesktop ? classes.Typo1 : classes.Typo1Mobile}
              style={{}}
            >
              Test At Your Home!
            </Typography>
            <Typography
              className={isDesktop ? classes.Typo2 : classes.Typo2Mobile}
              style={{}}
            >
              We make sure to give you a comfortable test session right at your
              home instead of spending a night at a sleep lab. Our home sleep
              apnea test kit- a more comfortable and cost-effective option,
              comes with a compact home sleep testing device that records your
              sleep pattern throughout the night. <br />
              <br />
              We realized that Sleep Apnea is a curable disorder, and we work
              hard to extend our knowledge and services to gift you a healthy
              life that comes from a peaceful sleep.
            </Typography>
          </Box>
        </Box>
      )}

      <Box className={isDesktop ? classes.Box7 : classes.Box7Mobile} style={{}}>
        <Box className={isDesktop ? classes.Box8 : classes.Box8Mobile}>
          <img
            src={firstaid}
            className={
              isDesktop ? classes.imagestyle1 : classes.imagestyle1Mobile
            }
            style={{ height: "67.07vh" }}
          />
        </Box>
        <Box
          className={isDesktop ? classes.Box9 : classes.Box9Mobile}
          style={{}}
        >
          <Typography
            className={isDesktop ? classes.Typo3 : classes.Typo3Mobile}
            style={{}}
          >
            How DeepSleep has revolutionized the process of diagnosing Sleep
            Apnea?
          </Typography>
          <ol
            className={isDesktop ? classes.liststyle : classes.liststyleMobile}
            style={{}}
          >
            <li>
              Free self-assessment test that can guide you to the next step.
            </li>
            <br />
            <li>
              Home Sleep Apnea Test Kit that eliminates dependency on sleep
              labs.
            </li>
            <br />
            <li>
              Cost-effective testing kit inclusive of home sleep testing device.
            </li>
            <br />
            <li>Personalized support & counselling.</li>
            <br />
            <li>Upcoming E-commerce service.</li>
            <br />
          </ol>
        </Box>
      </Box>
      <AboutUsFooter />
      <Footer />
    </>
  );
};

export default AboutUs;
