import {
  Box,
  Button,
  Divider,
  Container,
  Grid,
  Typography,
  Paper,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import ForgotPasswordStyles from "./ForgotPassword.styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import Header from "../../../shared/Header/Header";
import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import AOS from "aos";
import urls from "../../../global/constants/UrlConstants";
import { Repeat } from "@mui/icons-material";
import { Link } from "react-router-dom";
import Footer from "../../../shared/Footer/Footer";
import { useState } from "react";
import history from "../../../utils/history";
import { alertActions } from "../../../redux/_actions/alert.actions";
import { userActions } from "../../../redux/_actions/user.action";
import {
  openSuccessNotification,
  openErrorNotification,
} from "../../../helpers/methods";

import loading2 from "../../../assets/images/Login/loading2.gif";
import { userService } from "../../../services/sleepTestUserService-service";

import { ToastContainer, toast } from "react-toastify";

const ForgotPassword = (props) => {
  const classes = ForgotPasswordStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [formData, setFormData] = useState({
    email: "",
    pwd: "",
    submitted: false,
    ariahidden: false,
    disabled: true,
    hidden: true,
  });

  const [email, setEmail] = useState("");
  const [userErr, setUserErr] = useState(false);
  const [loading, setLoading] = useState(false);

  function userHandler(e) {
    setLoading(false);
    var item = e.target.value;
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (regex.test(item)) {
      setUserErr(false);
    } else {
      setUserErr(true);
    }
    setEmail(item);
  }

  useEffect(() => {
    document.title = "Forgot Password";
  }, []);
  

  const handleSubmit = (values: any) => {
    setLoading(true);
    let user = { email: email };
    userService.resetUserPwd(user).then(
      (resetUser) => {
        history.push(urls.SignInPath);
        openSuccessNotification(
          "We have sent you an email with your password reset link."
        );
      },
      (error: any) => {
        setLoading(false);
        var errorMsg = error.toString();
        openErrorNotification(errorMsg);
      }
    );
  };
  


  return (
    <>
      <Header />
      <Box className={isDesktop ? classes.Box : classes.MobileBox}>
        <form>
        <Grid
          item
          lg
          container
          direction="column"
          alignItems="center"
          marginTop="30px"
        >
          <Typography
            className={isDesktop ? classes.Typo1 : classes.Typo1Mobile}
          >
            Reset Password
          </Typography>
          <Typography
            className={isDesktop ? classes.Typo2 : classes.Typo2Mobile}
          >
            We will send you an email to reset <br /> your password
          </Typography>
          <OutlinedInput
            // className={isDesktop ? classes.input1 : classes.input1Mobile}
            type="text"
            onChange={userHandler}
            name="email"
            placeholder="Email"
            style={
              isDesktop
                ? {
                    marginTop: "30px",
                    width: "450px",
                    height: "70px",
                    fontSize: "20px !important",
                    // border: "1px solid #283035",
                    borderRadius: "15px",
                    backgroundColor: "#ffffff",
                  }
                : {
                    marginTop: "20px",
                    width: "80%",
                    height: "65px",
                    fontSize: "20px",
                    // border: "1px solid #283035",
                    borderRadius: "15px",
                    backgroundColor: "#ffffff",
                  }
            }
          />
          {userErr ? <span style={{ color: "red" }}>Email Not Valid</span> : ""}
          <Button type="submit"
            onClick={handleSubmit}
            className={isDesktop ? classes.button : classes.buttonMobile}
          >
            {props.authentication.loading ? (
              <img
                style={{ width: "20%", height: "100%" }}
                src={loading2}
              ></img>
            ) : (
              <Typography>Submit</Typography>
            )}
          </Button>
          <Link to="/login" style={{ textDecoration: "none" }}>
            <Button
              className={isDesktop ? classes.button2 : classes.button2Mobile}
            >
              Cancel
            </Button>
          </Link>
        </Grid>
        </form>
      </Box>
      <Footer />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
      />
    </>
  );
};

const mapState = (state) => {
  const { authentication, alert, loggedIn } = state;
  return { authentication, alert, loggedIn };
};

const actionCreators = {
  resetUserPwd: userActions.resetUserPwd,
  clearAlerts: alertActions.clear,
};

export default connect(mapState, actionCreators)(ForgotPassword);
