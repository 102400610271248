import {
  Box,
  Divider,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { display } from "@mui/system";
import firsts from "../../../../../assets/images/Services/firsts.png";
import second from "../../../../../assets/images/Services/second.png";
import DisplayDataStyles from "./DisplayData.styles";
import { boldFont } from "../../../../../utils/styles";
import { regularFont } from "../../../../../utils/styles";
const DisplayData = () => {
  const classes = DisplayDataStyles();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <>
      {isDesktop ? (
        <Box
          className={isDesktop ? classes.Box4 : classes.Box4Mobile}
          style={{}}
        >
          <Box
            className={isDesktop ? classes.Box5 : classes.Box5Mobile}
            style={{}}
          >
            <Typography
              className={isDesktop ? classes.Typo2 : classes.Typo2Mobile}
              style={{}}
            >
              <span style={{ fontStyle: "italic", fontFamily: "bold" }}>
                “Sleep Apnea is a serious health problem that impacts
                approximately 43 million US adults. It is estimated that more
                than 80% of the US population remain undiagnosed.”
              </span>
              <br />
              <br />
              If not diagnosed on time, individuals suffering from Sleep Apnea
              have to face various health complications such as daytime fatigue,
              high blood pressure, heart complications, metabolic syndrome, Type
              II Diabetes, liver problems, etc. Apart from all the health risks,
              Sleep Apnea also cause disturbance in the everyday schedule, such
              as difficulty concentrating and tired feeling that results from
              lack of sleep.
            </Typography>
            <Typography
              className={isDesktop ? classes.Typo1 : classes.Typo1Mobile}
              style={{}}
            >
              Test At Your Home!
            </Typography>
          </Box>
          <Box
            className={isDesktop ? classes.Box6 : classes.Box6Mobile}
            style={{}}
          >
            <img
              className={
                isDesktop ? classes.imagestyle : classes.imagestyleMobile
              }
              style={{}}
              src={firsts}
            />
          </Box>
        </Box>
      ) : (
        <Box
          className={isDesktop ? classes.Box1 : classes.Box1Mobile}
          style={{}}
        >
          <Box
            className={isDesktop ? classes.Box2 : classes.Box2Mobile}
            style={{}}
          >
            <img
              className={
                isDesktop ? classes.imagestyle : classes.imagestyleMobile
              }
              style={{}}
              src={firsts}
            />
          </Box>
          <Box
            className={isDesktop ? classes.Box3 : classes.Box3Mobile}
            style={{}}
          >
            <Typography
              className={isDesktop ? classes.Typo2 : classes.Typo2Mobile}
              style={{}}
            >
              Approximately 45 million Americans suffer from Sleep
              <br /> Apnea today. This disease does not only disturbs your
              <br /> nights, but also your daily functionings – From mild
              <br /> headache to temporary memory loss.
              <br />
              <br />
              If not treated quickly and accurately, Sleep Apnea can
              <br /> escalate at a rapid pace, leading up to heart attacks,
              <br /> strokes and dementia.
              <br />
              <br />
              Sleep Apnea is self-diagnosable and doctor-curable.
              <br /> Therefore, we recommend you get tested as soon as
              <br /> possible.
            </Typography>
            <Typography
              className={isDesktop ? classes.Typo1 : classes.Typo1Mobile}
              style={{}}
            >
              Get Sleep Apnea tested today.
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default DisplayData;
