import { cartConstants } from "../_constants/cart.constants";

const initialState = {
  document: [],
};
export function documentPrescriptionReducer(state = initialState, action) {
  let document = state.document;
  switch (action.type) {
    case cartConstants.ADD_TO_DOCUMENT:
      return {
        ...state,
        document: action.payload.documents,
      };
    case cartConstants.CLEAR_DOCUMENT:
      return {
        ...state,
        document: [],
      };

    case cartConstants.DELETE_FROM_DOCUMENTS:
      return {
        ...state,
        document: state.document.filter((currentdata: any) => {
          return currentdata.title !== action.payload.title;
        }),
      };

    default:
      return state;
  }
}
