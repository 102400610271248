import Header from "../../shared/Header/Header";
import {
  Box,
  Button,
  Divider,
  Container,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  TextField,
} from "@mui/material";
import Banner from "../../shared/Banner/Banner";
import React, { useState, useEffect } from "react";
import Footer from "../../shared/Footer/Footer";
import { boldFont } from "../../utils/styles";
import { regularFont } from "../../utils/styles";
import { Link, Link as RouterLink } from "react-router-dom";
import OrderDetailsStyles from "./OrderDetails.styles";
import order from "../../assets/images/OrderDetails/order.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import orderticket from "../../assets/images/OrderDetails/orderticket.png";
import location from "../../assets/images/OrderDetails/Location.png";
import StapperSleepTest from "./components/StapperSleepTest";

import { userService } from "../../services";
import CustomizedSteppers from "./components/Steps";
import Loading from "../Loading/Loading";
import { openErrorNotification } from "../../helpers/methods";

const OrderDetails = (props) => {
  const classes = OrderDetailsStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [total, setTotal] = useState(Number);
  const [customerFirstName, setCustomerFirstName] = useState("");
  const [customerLastName, setCustomerLastName] = useState("");
  
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerMobileContactNumber, setCustomerMobileContactNumber] =
    useState("");
  const [statusdata, setStatusData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [orderIdResult, setOrderIdResult] = useState("");
  const [shippingCostResult, setShippingCostResult] = useState("");
  const [orderlist, setOrderList] = useState<any[]>([]);

  const [billingAddress, setBillingAddress] = useState({
    line1: "",
    line2: "",
    line3: "",
    line4: "",
    city: "",
    state: "",
    pinCode: "",
    country: "United States of America",
  });

  useEffect(() => {
    document.title = "Order Details";
  }, []);

  const [shippingAddress, setShippingAddress] = useState({
    line1: "",
    line2: "",
    line3: "",
    line4: "",
    city: "",
    state: "",
    pinCode: "",
    country: "United States of America",
  });

  useEffect(() => {
    const orderId = new URLSearchParams(props.location.search).get("id");
    handleGetMyOrder(orderId);
  }, []);

  const handleGetMyOrder = async (id) => {
    try {
      setIsLoading(true);
      const response = await userService.getOrder(id);
      setOrderList(response.orderItems);
      setTotal(response.totalAmount);
      setCustomerFirstName(response.customer.firstName);
      setCustomerLastName(response.customer.lastName);
      setCustomerEmail(response.customer.primaryEmail.value);
      if (response.customer.mobileContactNumber!==undefined) {
        setCustomerMobileContactNumber(
          response.customer.mobileContactNumber.number
        );
      }
      setOrderIdResult(response.id);
      setStatusData(response.status);
      setBillingAddress({
        ...billingAddress,
        line1: response.customer?.billingAddress?.line1,
        line2: response.customer?.billingAddress?.line2,
        line3: response.customer?.billingAddress?.line3,
        line4: response.customer?.billingAddress?.line4,
        city: response.customer?.billingAddress?.city,
        state: response.customer?.billingAddress?.state,
        pinCode: response.customer?.billingAddress?.pinCode,
        country: response.customer?.billingAddress?.country,
      });
      setShippingAddress({
        ...shippingAddress,
        line1: response.customer?.shippingAddress?.line1,
        line2: response.customer?.shippingAddress?.line2,
        line3: response.customer?.shippingAddress?.line3,
        line4: response.customer?.shippingAddress?.line4,
        city: response.customer?.shippingAddress?.city,
        state: response.customer?.shippingAddress?.state,
        pinCode: response.customer?.shippingAddress?.pinCode,
        country: response.customer?.shippingAddress?.country,
      });
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(error);
    }
  };

  return (
    <>
      <Header />
      <Banner
        img={order}
        header1="Home / Order Details"
        header2="Order Details"
      />

      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Container
            maxWidth={false}
            style={{ marginBottom: "20px", width: "71%" }}
          >
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                style={{
                  color: "#1A3A6C",
                  fontSize: "3vh",
                  fontWeight: 900,
                  marginTop: "25px",
                  ...regularFont,
                }}
              >
                Your Order Status
              </Typography>
              <Link style={{ textDecoration: "none" }} to="/dashboard">
                <Button
                  startIcon={<ArrowBackIcon style={{ color: "#EB6523" }} />}
                  style={{
                    boxShadow: "0px 15px 30px 0px rgba(0, 0, 0, 0.09)",
                    width: "100%",
                    height: "70px",
                    borderRadius: "20px",
                    marginTop: "10px",
                  }}
                >
                  <Typography
                    style={{
                      color: "#1A3A6C",
                      fontSize: "18px",
                      fontWeight: 900,
                      ...regularFont,
                    }}
                  >
                    Back To dashboard
                  </Typography>
                </Button>
              </Link>
            </Box>

            <Box style={{ marginTop: "5%", marginBottom: "3%" }}>
              {/* {console.log('orderlist',orderlist[0]?.productName)} */}
              {orderlist[0]?.productName === "Sleep Apnea Test Equipment" ? (
                <CustomizedSteppers stepNumber={statusdata} />
              ) : (
                <StapperSleepTest stepNumber={statusdata} />
              )}
            </Box>

            <Box
              style={{
                backgroundColor: "#1A3A6C",
                width: "100%",
               
              }}
            >
              <Box
                style={{
                  display: "flex",
                  margin: "2% 5% 2% 5%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box style={{ width: "75%", margin: "3% 0% 3% 0%" }}>
                  <Typography
                    style={{
                      color: "#ffffff",
                      ...boldFont,
                      fontSize: "3.71vh",
                      fontWeight: 900,
                    }}
                  >
                    YOUR ORDER
                  </Typography>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginRight: "8%",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#ffffff",
                        ...boldFont,
                        fontSize: "2.47vh",
                        fontWeight: 900,
                      }}
                    >
                      Product
                    </Typography>
                    <Typography
                      style={{
                        color: "#ffffff",
                        ...boldFont,
                        fontSize: "2.47vh",
                        fontWeight: 900,
                      }}
                    >
                      Sub Total
                    </Typography>
                  </Box>

                  {orderlist.map((order) => (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "1% 8% 0 0",
                      }}
                    >
                      <Typography
                        style={{
                          color: "#ffffff",
                          ...regularFont,
                          fontSize: "1.85vh",
                          fontWeight: 400,
                        }}
                      >
                        {order.productName}
                      </Typography>
                      <Typography
                        style={{
                          color: "#ffffff",
                          ...boldFont,
                          fontSize: "1.85vh",
                          fontWeight: 900,
                        }}
                      >
                        ${order.actualUnitPrice}
                      </Typography>
                    </Box>
                  ))}

                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "1% 8% 0 0",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#ffffff",
                        ...regularFont,
                        fontSize: "1.85vh",
                        fontWeight: 400,
                      }}
                    >
                      Shippping
                    </Typography>
                    <Typography
                      style={{
                        color: "#ffffff",
                        ...boldFont,
                        fontSize: "1.85vh",
                        fontWeight: 900,
                      }}
                    >
                      ${30}
                    </Typography>
                  </Box>

                  <Divider
                    style={{
                      borderBottomWidth: 2,
                      backgroundColor: "#ffffff",
                      margin: "1% 8% 0 0",
                    }}
                  />
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "1% 8% 0 0",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#ffffff",
                        ...regularFont,
                        fontSize: "1.85vh",
                        fontWeight: 400,
                      }}
                    >
                      Total
                    </Typography>
                    <Typography
                      style={{
                        color: "#ffffff",
                        ...boldFont,
                        fontSize: "1.85vh",
                        fontWeight: 900,
                      }}
                    >
                      ${total}
                    </Typography>
                  </Box>
                </Box>
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ backgroundColor: "#ffffff", margin: "3% 0 3% 0" }}
                ></Divider>
                <Box
                  style={{
                    width: "25%",
                    // borderLeft: "2px solid #ffffff",
                    margin: "4% 0 4% 2%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    style={{
                      color: "#ffffff",
                      fontSize: "3.50vh",
                      fontWeight: 400,
                      ...regularFont,
                    }}
                  >
                    Total
                  </Typography>
                  <Typography
                    style={{
                      color: "#ffffff",
                      fontSize: "7.01vh",
                      fontWeight: 900,
                      ...boldFont,
                    }}
                  >
                    ${total}
                  </Typography>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={"/invoice?id=" + orderIdResult + ""}
                  >
                    <Button
                      style={{
                        color: "#ffffff",
                        backgroundColor: "#EB6523",
                        width: "8.07vw",
                        height: "45px",
                        whiteSpace:"nowrap",
                        minWidth:"150px"
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "1.85vh",
                          ...regularFont,
                          textTransform: "none",
                        }}
                      >
                        View receipt
                      </Typography>
                    </Button>
                  </Link>
                </Box>
              </Box>
            </Box>

            <Box
              style={{
                display: "grid",
                flexDirection: "column",
                marginBottom: "60px",
              }}
            >
              <form noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="fname"
                      disabled
                      name="firstName"
                      variant="outlined"
                      fullWidth
                      id="firstName"
                      label="First Name"
                      value={customerFirstName}
                      style={{ backgroundColor: "#ffffff" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      disabled
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      value={customerLastName}
                      name="lastName"
                      autoComplete="lname"
                      style={{ backgroundColor: "#ffffff" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      disabled
                      fullWidth
                      id="email"
                      label="Email"
                      value={customerEmail}
                      name="email"
                      autoComplete="email"
                      style={{ backgroundColor: "#ffffff" }}
                    />
                  </Grid>
                
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        disabled
                        fullWidth
                        id="email"
                        label="Phone Number"
                        value={customerMobileContactNumber}
                        name="email"
                        autoComplete="email"
                        style={{ backgroundColor: "#ffffff" }}
                      />
                    </Grid>
    

                  <Grid item xs={12} sm={6}>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        margin: "30px 0 30px 0",
                      }}
                    >
                      <img src={location}></img>
                      <Typography style={{ marginLeft: "10px" }}>
                        Billing Addess
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        margin: "30px 0 30px 0",
                      }}
                    >
                      <img src={location}></img>
                      <Typography style={{ marginLeft: "10px" }}>
                        Shipping Addess
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled
                      autoComplete="line1"
                      name="line1"
                      variant="outlined"
                      fullWidth
                      id="line1"
                      label="Line1"
                      value={billingAddress.line1}
                      style={{ backgroundColor: "#ffffff" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      disabled
                      fullWidth
                      id="line2"
                      label="Line1"
                      value={shippingAddress.line1}
                      name="line1"
                      autoComplete="line1"
                      style={{ backgroundColor: "#ffffff" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      disabled
                      fullWidth
                      id="line2"
                      label="Line2"
                      value={billingAddress.line2}
                      name="line2"
                      autoComplete="line2"
                      style={{ backgroundColor: "#ffffff" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      disabled
                      fullWidth
                      id="line2"
                      label="Line2"
                      value={shippingAddress.line2}
                      name="line2"
                      autoComplete="line2"
                      style={{ backgroundColor: "#ffffff" }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="city"
                      disabled
                      name="city"
                      variant="outlined"
                      value={billingAddress.city}
                      fullWidth
                      id="city"
                      label="City"
                      style={{ backgroundColor: "#ffffff" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      disabled
                      fullWidth
                      id="city"
                      value={shippingAddress.city}
                      label="City"
                      name="city"
                      autoComplete="city"
                      style={{ backgroundColor: "#ffffff" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      disabled
                      fullWidth
                      id="state"
                      label="State"
                      value={billingAddress.state}
                      name="state"
                      autoComplete="state"
                      style={{ backgroundColor: "#ffffff" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      disabled
                      fullWidth
                      id="state"
                      label="State"
                      value={shippingAddress.state}
                      name="state"
                      autoComplete="state"
                      style={{ backgroundColor: "#ffffff" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      disabled
                      fullWidth
                      id="pinCode"
                      label="Zipcode"
                      value={billingAddress.pinCode}
                      name="pinCode"
                      autoComplete="pinCode"
                      style={{ backgroundColor: "#ffffff" }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      disabled
                      fullWidth
                      id="pinCode"
                      value={shippingAddress.pinCode}
                      label="Zipcode"
                      name="pinCode"
                      autoComplete="pinCode"
                      style={{ backgroundColor: "#ffffff" }}
                    />
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Container>
        </>
      )}
      <Footer />
    </>
  );
};

export default OrderDetails;
