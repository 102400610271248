import {
  Button,
  Box,
  Divider,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import * as React from "react";
import IconTabsStyles from "./IconTabs.styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CommercialDrivers from "../../../../assets/images/whichOneYouAre/CommercialDrivers.png";
import DentalServices from "../../../../assets/images/whichOneYouAre/DentalServices.png";
import AffectedIndividuals from "../../../../assets/images/whichOneYouAre/AffectedIndividuals.png";
import DentalPatients from "../../../../assets/images/whichOneYouAre/DentalPatients.svg";
import commercialdrivers from "../../../../assets/images/whichOneYouAre/commercialdrivers.svg";
import Affectedindividuals from "../../../../assets/images/whichOneYouAre/Affectedindividuals.svg";
import { boldFont } from "../../../../utils/styles";
import { connect } from "react-redux";

import { regularFont } from "../../../../utils/styles";
import { Link } from "react-router-dom";

const data = [
  {
    label: "Drivers",
    title: "Commercial drivers license holders",
    description: (
      <p>
        {" "}
        Approximately 30% of all American commercial vehicle drivers have Sleep
        Apnea today. This sleep disorder, when left undiagnosed will lead to a
        five times higher risk of fatal road accidents.
        <br />
        The DOT, therefore, requires truckers and commercial drivers to undergo
        Sleep Apnea testing regularly to ensure your safety. Don’t let Sleep
        Apnea potentially take your life.
      </p>
    ),
    image: (
      <img src={CommercialDrivers} alt={"CommercialDrivers"} width="100%" />
    ),
    redirecationUrl: "",
  },
  {
    label: "Patient",
    title: "Affected individuals",
    description:
      "Sleep Apnea is underdiagnosed in 4 out of 5 adults.Because it appears and escalates rapidly, Sleep Apnea can lead to serious complications such as uncontrolled blood pressure and irregular heartbeats that can lead to a heart attack.You need 7 hours of undisturbed sleep every night. Read More...",
    image: <img src={DentalServices} alt={"DentalServices"} width="100%" />,
    redirecationUrl: "",
  },
  {
    label: "Dental Patient",
    title: "Dental Service Patient",
    description:
      "Sleep Apnea is underdiagnosed in 4 out of 5 adults.Because it appears and escalates rapidly, Sleep Apnea can lead to serious complications such as uncontrolled blood pressure and irregular heartbeats that can lead to a heart attack.You need 7 hours of undisturbed sleep every night. Read More...",
    image: (
      <img src={AffectedIndividuals} alt={"AffectedIndividuals"} width="100%" />
    ),
    redirecationUrl: "",
  },
];

const IconTabs = (props) => {
  const classes = IconTabsStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  let current_user = localStorage.getItem("current_user");

  const getIconTabs = () => {
    return (
      <>
        <Box
          mb={5}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "65%",
          }}
        >
          <Tabs
            value={value}
            TabIndicatorProps={{
              style: {
                backgroundColor: "#EB6523",
              },
            }}
            onChange={handleChange}
            aria-label="icon label tabs example"
            className={classes.overStyle}
          >
            <Tab
              style={{ color: "#ffffff" }}
              icon={
                <img
                  src={Affectedindividuals}
                  alt="Affectedindividuals"
                  className={isDesktop ? "" : classes.test}
                />
              }
              className={isDesktop ? "" : classes.mobileTabFix}
              label="Commercial Drivers"
            />
            <Tab
              style={{ color: "#ffffff" }}
              icon={
                <img
                  src={commercialdrivers}
                  alt="commercialdrivers"
                  className={isDesktop ? "" : classes.test}
                />
              }
              className={isDesktop ? "" : classes.mobileTabFix}
              label="Dental Services"
            />
            <Tab
              style={{ color: "#ffffff" }}
              icon={
                <img
                  src={DentalPatients}
                  alt="DentalPatients"
                  className={isDesktop ? "" : classes.test}
                />
              }
              className={isDesktop ? "" : classes.mobileTabFix}
              label="Affected Individuals"
            />
          </Tabs>
        </Box>
      </>
    );
  };

  const getSelectedIconContent = () => {
    return (
      <>
        <Box
          className={
            isDesktop
              ? classes.iconTabDescriptionBoxDesktop
              : classes.iconTabDescriptionBoxMobile
          }
        >
          <Grid container>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              {data[value].image}
            </Grid>
            <Grid item xs={12} sm={12} md={7} lg={7}>
              <Box
                px={3}
                py={2}
                display="flex"
                justifyContent="space-between"
                flexDirection="column"
              >
                <Box display="flex" alignItems="center">
                  <Divider
                    style={{
                      width: "10%",
                      backgroundColor: "#EB6523",
                      marginRight: "10px",
                    }}
                  />
                  <Typography
                    style={{
                      color: "#EB6523",
                      fontSize: "20px",
                      fontWeight: 400,
                      ...regularFont,
                    }}
                  >
                    {data[value].label}
                  </Typography>
                </Box>
                <Typography
                  className={
                    isDesktop ? classes.desktopTitle : classes.mobileTitle
                  }
                >
                  {data[value].title}
                </Typography>
                <Typography
                  style={{
                    color: "#8B8B8B",
                    fontSize: "16px",
                    fontWeight: 400,
                    ...regularFont,
                  }}
                >
                  {data[value].description}
                </Typography>

                {current_user ? (
                  <Box>
                    {props.user.role === "Patient" ? (
                      <Button
                        className={classes.btn}
                        style={{
                          width: "200px",
                          backgroundColor: "##EB6523",
                          borderRadius: "15px",
                          marginBottom: "10px",
                          marginTop: "20px",
                          color: "#283035",
                        }}
                      >
                        <Typography
                          style={{
                            ...regularFont,
                            fontWeight: 500,
                            fontSize: "16px",
                            color: "#fff",
                          }}
                        >
                          <Link
                            to="/buytest"
                            style={{ textDecoration: "none", color: "#fff" }}
                          >
                            Buy Sleep Apnea Test{" "}
                          </Link>
                        </Typography>
                      </Button>
                    ) : (
                      ""
                    )}
                  </Box>
                ) : (
                  <Button
                    className={classes.btn}
                    style={{
                      width: "200px",
                      backgroundColor: "##EB6523",
                      borderRadius: "15px",
                      marginBottom: "10px",
                      marginTop: "20px",
                      color: "#283035",
                    }}
                  >
                    <Typography
                      style={{
                        ...regularFont,
                        fontWeight: 500,
                        fontSize: "16px",
                        color: "#fff",
                      }}
                    >
                      <Link
                        to="/login"
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        Buy Sleep Apnea Test{" "}
                      </Link>
                    </Typography>
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  };

  const getStaticText = () => {
    return (
      <Box
        style={{
          marginTop: "100px",
          marginBottom: "50px",
          display: "flex",
          justifyContent: "flex-start",
          width: "65%",
        }}
      >
        <Typography
          className={
            isDesktop ? classes.staticTextDeskTop : classes.staticTextMobile
          }
          style={{
            fontWeight: 900,
            color: "#fff",
            fontSize: "48px",
            ...boldFont,
          }}
        >
          Which One Are you?
        </Typography>
      </Box>
    );
  };

  const getBody = () => {
    return (
      <Box className={classes.backgroundImg}>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            paddingBottom: "50px",
          }}
        >
          {getStaticText()}
          {getIconTabs()}
          {getSelectedIconContent()}
        </Box>
      </Box>
    );
  };

  return getBody();
};

const mapState = (state) => {
  const { authentication, alert, loggedIn, shopReducer } = state;
  const { user } = authentication;
  return { authentication, user, alert, loggedIn, shopReducer };
};

export default connect(mapState)(IconTabs);
