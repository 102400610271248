import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { boldFont } from "../../../../utils/styles";
import AboutUsFooterStyles from "./AboutUsFooter.styles";
import { regularFont } from "../../../../utils/styles";

const AboutUsFooter = () => {
  const classes = AboutUsFooterStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <>
      <Box className={isDesktop ? classes.Box : classes.BoxMobile} style={{}}>
        <Typography
          className={isDesktop ? classes.Typo1 : classes.Typo1Mobile}
          style={{}}
        >
          Noticing the symptoms of Sleep Apnea
          <br /> lately? Take this Free Test Now.
        </Typography>
        <Button
          className={isDesktop ? classes.Button : classes.ButtonMobile}
          endIcon={<ArrowRightAltIcon />}
          style={{}}
        >
          <Typography
            className={isDesktop ? classes.Typo2 : classes.Typo2Mobile}
           
          >
            Free Test
          </Typography>
        </Button>
      </Box>
    </>
  );
};

export default AboutUsFooter;
