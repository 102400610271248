import {
  Box,
  Button,
  Divider,
  Container,
  Grid,
  ListItem,
  Typography,
  Paper,
  FormControlLabel,
  Checkbox,
  useTheme,
  useMediaQuery,
  ListItemText,
  List,
} from "@mui/material";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Repeat } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Header from "../../../shared/Header/Header";
import Footer from "../../../shared/Footer/Footer";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ProductImg from "../../../assets/images/Product/productImg.png";
import { addToCart, deleteFromCart } from "../../../redux/_actions";
import Counter from "../common/counter";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  convertPriceToDollarFormat,
  openSuccessNotification,
} from "../../../helpers/methods";
import { Notifications } from "../../../helpers/Notifications";
import { Link } from "react-router-dom";

const cardTotalDummyData = [
  { itemText: "Sub Total", price: "455" },
  { itemText: "Shipping", price: "300" },
  { itemText: "Discount", price: "200" },
];

const shoppingCardData = [
  {
    imgURL: "../../../assets/images/Product/productImg.png",
    productName: "Sleep8 Automated CPAP Cleaning Device",
    totalPrice: "$249",
  },
  {
    imgURL: "../../../assets/images/Product/productImg.png",
    productName: "Sleep8 Automated CPAP Cleaning Device",
    totalPrice: "$400",
  },
];

const initialState = {
  cardData: [],
};

const Cart = (props) => {

  const [price, setPrice] = useState();
  const [displaydata, setdisplayData] = useState(initialState);
  const [quantity, setQuantity] = useState(2);
  const [numberItems, setNumberItems] = useState(0);

  useEffect(() => {
    setNumberItems(props.shopReducer.items.length);

    setdisplayData({
      cardData: props?.shopReducer?.items,
    });
  }, [props?.shopReducer?.items.length]);

  // const handleRemoveItem = async (product) => {
  // console.log('product',product)
  // const {cardData} = displaydata
  // const updateList = {
  //   cardData.filter((item) => (item.id === id ? false : true))
  // }
  //   await props.removeItem({});
  // };

  const addToCartProductItem = async (product, quantity) => {
    const newProduct = {
      ...product,
      quantity,
      totalPrice: price,
    };
    props.addToCart(newProduct);
  };

  useEffect(() => {
    document.title = "Cart";
  }, []);

  const getCartValue = () => {
    let cartValue = 0;
    if (props.shopReducer.items.length) {
      cartValue = props.shopReducer.items.reduce((acc, obj) => {
        return acc + obj.totalPrice;
      }, 0);
    }
    return cartValue;
  };

  const handleRemoveItem = async (product) => {
    props.deleteFromCart(product);
    openSuccessNotification("Product Deleted Successfully");
  };

  const productValueHandler = (type: string) => {
    if (type === "increase") {
      setQuantity(quantity + 1);
    } else if (type === "decrease") {
      setQuantity(quantity - 1);
    }
  };

  return (
    <>
      <Header />
      <Container>
        <Grid style={{ display: "flex", flexWrap: "wrap" }}>
          <Grid
            item
            md={8}
            sm={12}
            xs={12}
            style={{ marginTop: "35px", paddingRight: "35px" }}
          >
            <Grid style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h5" component="h5">
                <Box style={{ fontWeight: 900, color: "#283035" }}>
                  Shopping Cart
                </Box>
              </Typography>
              <Typography
                style={{ color: "#283035", fontWeight: 900, fontSize: "14px" }}
              >
                <Box>{numberItems} Items</Box>
              </Typography>
            </Grid>
            {/* <div
              style={{ borderTop: "1px solid #8B8B8B33", marginTop: "10px" }}
            ></div> */}
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Product</TableCell>
                    <TableCell align="center">Quantity</TableCell>
                    <TableCell align="center">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displaydata.cardData.map((item: any, key) => (
                    <TableRow
                      key={key}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="right">
                        {" "}
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Grid item md={3}>
                            <img
                              src={`data:image/png;base64,${item.productPicture}`}
                              height={80}
                              width={80}
                            />
                          </Grid>
                          <Grid
                            item
                            md={9}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              alignItems: "flex-start",
                            }}
                          >
                            <Typography style={{ textAlign: "left" }}>
                              {item.name}
                            </Typography>
                            <Button
                              style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#EB6523",
                                marginRight: "0px",
                              }}
                              onClick={() => {
                                handleRemoveItem(item);
                              }}
                            >
                              <DeleteOutlinedIcon />
                              <span>Remove</span>
                            </Button>
                          </Grid>
                        </Box>
                      </TableCell>
                      <TableCell align="right">
                        <Counter
                          inStockQuantity={item.inStockQuantity}
                          currentQuantity={item.quantity}
                          productDetials={item}
                          componentName="cart"
                          setQuantity={() => {}}
                        />
                      </TableCell>

                      <TableCell align="right">
                        <Grid item md={3} style={{ margin: "auto" }}>
                          {item.totalPrice}
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* <div
              style={{ borderTop: "1px solid #8B8B8B33", marginTop: "10px" }}
            ></div> */}
            <Grid
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                color: "#8B8B8B",
                fontSize: "14px",
                fontWeight: "400",
                marginTop: "20px",
              }}
            ></Grid>

            {/* <Grid
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                color: "#8B8B8B",
                fontSize: "14px",
                fontWeight: "400",
                marginTop: "20px",
              }}
            >
              <Grid
                item
                md={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <KeyboardBackspaceIcon style={{ marginRight: "6px" }} />
                <span>Continue shopping</span>
              </Grid>
            </Grid> */}
          </Grid>

          <Grid item md={4} sm={12} xs={12} style={{ marginTop: "35px" }}>
            <Typography variant="h5" component="h5">
              <Box style={{ fontWeight: 900, color: "#283035" }}>
                Card Total
              </Box>
            </Typography>
            {/* <div
              style={{ borderTop: "1px solid #8B8B8B33", marginTop: "10px" }}
            ></div> */}

            <List
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0px",
              }}
            >
              <ListItem style={{ paddingLeft: "0px" }}>
                <ListItemText
                  style={{
                    color: "#283035",
                    fontSize: "18px",
                    paddingLeft: "0px",
                  }}
                >
                  Sub Total
                </ListItemText>
              </ListItem>
              <ListItem style={{ justifyContent: "end", paddingRight: "0px" }}>
                {convertPriceToDollarFormat(getCartValue())}
              </ListItem>
            </List>

            <List
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0px",
              }}
            >
              <ListItem style={{ paddingLeft: "0px" }}>
                <ListItemText
                  style={{
                    color: "#283035",
                    fontSize: "18px",
                    paddingLeft: "0px",
                  }}
                >
                  Shipping
                </ListItemText>
              </ListItem>
              <ListItem style={{ justifyContent: "end", paddingRight: "0px" }}>
                {props.shopReducer.items.length ? "$30" : "Nil"}
              </ListItem>
            </List>

            <List
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0px",
              }}
            >
              <ListItem style={{ paddingLeft: "0px" }}>
                <ListItemText
                  style={{
                    color: "#283035",
                    fontSize: "18px",
                    paddingLeft: "0px",
                  }}
                >
                  Discount
                </ListItemText>
              </ListItem>
              <ListItem style={{ justifyContent: "end", paddingRight: "0px" }}>
                0%
              </ListItem>
            </List>

            <div style={{ borderTop: "1px dashed #283035" }}></div>

            <List
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0px",
              }}
            >
              <ListItem style={{ paddingLeft: "0px" }}>
                <ListItemText
                  style={{
                    color: "#283035",
                    fontSize: "18px",
                    fontWeight: "800",
                    paddingLeft: "0px",
                  }}
                >
                  Total
                </ListItemText>
              </ListItem>
              <ListItem style={{ justifyContent: "end", paddingRight: "0px" }}>
                {convertPriceToDollarFormat(
                  getCartValue() +
                    (props?.shopReducer?.items?.length > 0 ? 30 : 0)
                )}
              </ListItem>
            </List>
            {props.authentication.loggedIn ? (
              <Link to="/checkout">
                {" "}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    style={{
                      width: "100%",
                      maxWidth: "400px",
                      backgroundColor: "#1A3A6C",
                      borderRadius: "15px",
                      padding: "10px 0px",
                    }}
                  >
                    Checkout
                  </Button>
                </div>
              </Link>
            ) : (
              <Link
                to={`/${
                  JSON.parse(localStorage.getItem("current_user")!)
                    ? "checkout"
                    : "login"
                }`}
              >
                {" "}
                <Button
                  variant="contained"
                  style={{
                    width: "100%",
                    marginTop: "40px",
                    backgroundColor: "#1A3A6C",
                    borderRadius: "15px",
                  }}
                >
                  Checkout
                </Button>
              </Link>
            )}
          </Grid>
        </Grid>
        <Link to="/products" style={{ textDecoration: "none" }}>
          <Box
            style={{
              color: "#8B8B8B",
              fontSize: "14px",
              fontWeight: "400",
              display: "flex",
              alignItems: "center",
              marginBottom: "20vh",
              marginTop: "14px",
            }}
          >
            <KeyboardBackspaceIcon style={{ marginRight: "6px" }} />
            <span>Continue shopping</span>
          </Box>
        </Link>
      </Container>
      <Footer />
    </>
  );
};

const mapState = (state) => {
  const { authentication, alert, loggedIn, shopReducer } = state;
  const { items } = shopReducer;
  const { user } = authentication;
  return { authentication, user, alert, loggedIn, shopReducer, items };
};

const actionCreators = {
  addToCart: addToCart,
  deleteFromCart: deleteFromCart,
};

export default connect(mapState, actionCreators)(Cart);
