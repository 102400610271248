import { userConstants } from "../_constants/user.constants";
import { userService } from "../../services/sleepTestUserService-service";
import { User } from "../../models/user.models";
import history from "../../utils/history";
import urls from "../../global/constants/UrlConstants";
import Notifications from "../../utils/notifications";
import { openErrorNotification, openSuccessNotification } from "../../helpers/methods";

export const userActions = {
  login,
  logout,
  resetUserPwd,
  updatePwd,
  verifyTempPwd,
  register,
  executePaymentSuccessFlow,
  riskEmail,
  addUser,
  updateProfile
};

let successMsg = "";
let errorMsg = "";
let warningMsg = "";

function login(user: User, state: any) {
  return (dispatch: any) => {
    dispatch(request(user));
    userService.login(user).then(
      (user) => {
        const userObject = {
          ...user,
          productCode: "ResMed",
          promotionCode: "NA"
        }
        localStorage.setItem("current_user", JSON.stringify(userObject));
        dispatch(success(user));
        history.push("/");
        openSuccessNotification("Login successfully.");
      },
      (error: any) => {
        dispatch(failure(error));
        errorMsg = error.toString();
        openErrorNotification(errorMsg);

      }
    );
  };

  function request(user: User) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user: User) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function register(user: User, state: any) {
  return (dispatch: any) => {
    dispatch(request(user));
    userService.register(user).then(
      (user) => {
        const userObject = {
          ...user,
          productCode: "ResMed",
          promotionCode: "NA"
        }
        dispatch(success(userObject));
        history.push(urls.SignInPath);
        openSuccessNotification("User registrarion successfully! Please Login.");
      },
      (error: any) => {
        dispatch(failure(error));
        errorMsg = error.toString();
        openErrorNotification(errorMsg);

      }
    );
  };

  function request(user: User) {
    return { type: userConstants.USERS_REGISTER_REQUEST, user };
  }
  function success(user: User) {
    return { type: userConstants.USERS_REGISTER_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: userConstants.USERS_REGISTER_FAILURE, error };
  }
}



function logout() {
  return (dispatch: any) => {
    localStorage.clear();
    dispatch(success());
    openSuccessNotification("Logout successfully.");
    history.push("/login");
  };
  function success() {
    return { type: userConstants.LOGOUT };
  }
}

function resetUserPwd(user: User) {
  return (dispatch: any) => {
    dispatch(request(user));
    userService.resetUserPwd(user).then(
      (resetUser) => {
        dispatch(success(resetUser));
        history.push(urls.SignInPath);
        openSuccessNotification("We have sent you an email with your password reset link.");
      },
      (error: any) => {
        dispatch(failure(error));
        errorMsg = error.toString();
        openErrorNotification(errorMsg);
      }
    );
  };
  function request(user: User) {
    return { type: userConstants.RESETUSERPASSOWRD_REQUEST, user };
  }
  function success(user: User) {
    return { type: userConstants.RESETUSERPASSOWRD_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: userConstants.RESETUSERPASSOWRD_FAILURE, error };
  }
}


function updatePwd(user) {
  localStorage.setItem("current_user", JSON.stringify(user));
  return (dispatch: any) => {
    dispatch(request(user));
    userService.updatePwd(user).then(
      (updateUserPwd) => {
        localStorage.clear()
        dispatch(success(updateUserPwd));
        openSuccessNotification("Your password has been changed successfully!");
        history.push(urls.SignInPath);
      },
      (error: any) => {
        dispatch(failure(error));
        errorMsg = error.toString();
        openErrorNotification(errorMsg);
      }
    );
  };
  function request(user: User) {
    return { type: userConstants.UPDATEPASSOWRD_REQUEST, user };
  }
  function success(user: User) {
    return { type: userConstants.UPDATEPASSOWRD_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: userConstants.UPDATEPASSOWRD_FAILURE, error };
  }
}

function verifyTempPwd(user: User) {
  console.log(user)
  return (dispatch: any) => {
    dispatch(request(user));
    userService.verifyTempPwd(user).then(
      (verifyTempPassword) => {
        dispatch(success(verifyTempPassword));
      },
      (error: any) => {
        dispatch(failure(error));
        errorMsg = error.toString();
        openErrorNotification(errorMsg);
      }
    );
  };
  function request(user: User) {
    return { type: userConstants.VERYFYTEMPPWD_REQUEST, user };
  }
  function success(user: User) {
    return { type: userConstants.VERYFYTEMPPWD_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: userConstants.VERYFYTEMPPWD_FAILURE, error };
  }
}


function executePaymentSuccessFlow(user) {
  return (dispatch) => {
    dispatch(request(user));
    console.log(user,"this is exe flow");
    userService.executePaymentSuccessFlow(user).then(
      (user) => {
        dispatch(success(user));
        openSuccessNotification('Your order has been placed successfully');
        history.push("/epworth");
      },
      (error: any) => {
        dispatch(failure(error));
        errorMsg = error.toString();
        openErrorNotification(errorMsg);
      }
    );
  };
  function request(user) {
    return { type: userConstants.PURCHES_REQUEST, user };
  }
  function success(user) {

    return { type: userConstants.PURCHES_SUCCESS, user };
  }
  function failure(error) {

    return { type: userConstants.PURCHES_FAILURE, error };
  }
}

function riskEmail(user) {
  return (dispatch) => {
    // dispatch(request(user));
    userService.riskEmail(user).then(
      (user) => {
        dispatch(success(user));
        openSuccessNotification('Detail report has been sent successfully.');
      },
      (error: any) => {
        dispatch(failure(error));
        errorMsg = error.toString();
        openErrorNotification(errorMsg);
      }
    );
  };
  function request(user) {
    // return { type: userConstants.RISKEMAIL_REQUEST, user };
  }
  function success(user) {
    // return { type: userConstants.RISKEMAIL_SUCCESS, user };
  }
  function failure(error) {
    // return { type: userConstants.RISKEMAIL_FAILURE, error };
  }
}

function addUser(user: User) {
  return (dispatch: any) => {
    dispatch(request(user));
    userService.adduser(user).then(
      (addUser) => {
        dispatch(success(addUser));
        history.push(urls.viewUser);
        openSuccessNotification("User has been added successfully.");
      },
      (error: any) => {
        dispatch(failure(error));
        errorMsg = error.toString();
        openErrorNotification(errorMsg);

      }
    );
  };
  function request(user: User) {
    return { type: userConstants.ADD_USER_REQUEST, user };
  }
  function success(user: User) {
    return { type: userConstants.ADD_USER_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: userConstants.ADD_USER_FAILURE, error };
  }
}

function updateProfile(user: User) {
  return (dispatch: any) => {
    dispatch(request(user));
    userService.updateProfile(user).then(
      (user) => {
        localStorage.setItem("current_user", JSON.stringify(user));
        dispatch(success(user));
        history.push(urls.viewUser);
        openSuccessNotification("Profile has been updated successfully.");
      },
      (error: any) => {
        dispatch(failure(error));
        errorMsg = error.toString();
        openErrorNotification(errorMsg);
      }
    );
  };
  function request(user: User) {
    return { type: userConstants.UPDATEPROFILE_REQUEST, user };
  }
  function success(user: User) {
    return { type: userConstants.UPDATEPROFILE_SUCCESS, user };
  }
  function failure(error: any) {
    return { type: userConstants.UPDATEPROFILE_FAILURE, error };
  }
}
