export const userConstants = {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    LOGOUT: 'LOGOUT',

    UPDATEPASSOWRD_REQUEST: 'UPDATEPASSOWRD_REQUEST',
    UPDATEPASSOWRD_SUCCESS: 'UPDATEPASSOWRD_SUCCESS',
    UPDATEPASSOWRD_FAILURE: 'UPDATEPASSOWRD_FAILURE',

    RESETUSERPASSOWRD_REQUEST: 'RESETUSERPASSOWRD_REQUEST',
    RESETUSERPASSOWRD_SUCCESS: 'RESETUSERPASSOWRD_SUCCESS',
    RESETUSERPASSOWRD_FAILURE: 'RESETUSERPASSOWRD_FAILURE',

    USERCARTVALUE: "USERCARTVALUE",

    VERYFYTEMPPWD_REQUEST: 'VERYFYTEMPPWD_REQUEST',
    VERYFYTEMPPWD_SUCCESS: 'VERYFYTEMPPWD_SUCCESS',
    VERYFYTEMPPWD_FAILURE: 'VERYFYTEMPPWD_FAILURE',

    PURCHES_REQUEST: "PURCHES_REQUEST",
    PURCHES_SUCCESS: "PURCHES_SUCCESS",
    PURCHES_FAILURE: "PURCHES_FAILURE",


    USERS_REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
    USERS_REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
    USERS_REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

    ADD_USER_REQUEST: "ADD_USER_REQUEST",
    ADD_USER_SUCCESS: "ADD_USER_SUCCESS",
    ADD_USER_FAILURE: "ADD_USER_FAILURE",


    UPDATEPROFILE_REQUEST: "UPDATEPROFILE_REQUEST",
    UPDATEPROFILE_SUCCESS: "UPDATEPROFILE_SUCCESS",
    UPDATEPROFILE_FAILURE: "UPDATEPROFILE_FAILURE",


};
