import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { boldFont } from "../../../utils/styles";
import { regularFont } from "../../../utils/styles";

const ViewUserDetailStyle = makeStyles((theme: Theme) => ({
  patientDetailsText: {
    color: "#ffffff",
    ...regularFont,
    fontSize: "20px",
  },
  preliminaryTextDesktop: {
    color: "#ffffff",
    fontSize: "30px",
    fontWeight: 200,
    ...regularFont,
  },
  preliminaryTextMobile: {
    color: "#ffffff",
    fontSize: "20px",
    fontWeight: 200,
    ...regularFont,
  },
  containerDesktop: {
    marginBottom: "20px",
    width: "70%",
    marginTop: "50px",
  },
  containerMobile: {
    marginBottom: "20px",
    width: "90%",
    marginTop: "50px",
  },
  dropZoneArea: {
    minHeight: "100px !important",
    backgroundColor: "white",
  },
}));

export default ViewUserDetailStyle;
