import { Box, Button, Grid, Typography } from "@mui/material";
import StopBangQuestionsStyles from "./StopBangQuestions.styles";
import first from "../../../../../../assets/images/FreeAssessment/first.png";
import second from "../../../../../../assets/images/FreeAssessment/second.png";
import third from "../../../../../../assets/images/FreeAssessment/third.png";
import fourth from "../../../../../../assets/images/FreeAssessment/fourth.png";
import fifth from "../../../../../../assets/images/FreeAssessment/fifth.png";
import sixth from "../../../../../../assets/images/FreeAssessment/sixth.png";
import seventh from "../../../../../../assets/images/FreeAssessment/seventh.png";
import eight from "../../../../../../assets/images/FreeAssessment/eight.png";
import { boldFont } from "../../../../../../utils/styles";
import { alertActions } from "../../../../../../redux/_actions/alert.actions";
import { regularFont } from "../../../../../../utils/styles";
import Switch from "@mui/material/Switch";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

interface CustomProps {
  questions: any;
  handleQuetionsSwitch: Function;
  bmi: any;
  handleBmiChange: Function;
}

const StopBangQuestions = (props: CustomProps) => {
  const classes = StopBangQuestionsStyles();

  // const [email, setEmail] = useState("");
  // const [firstName, setFirstName] = useState("");
  // const [totalScore, setTotalScore] = useState(Number);

  // let current_user = localStorage.getItem("current_user");
  // let username = JSON.parse(localStorage.getItem("current_user")!);
  // let useremail = username.email;
  // let user;

  const handleChangeCalculate = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    if (name === "height") {
      props.handleBmiChange(Number(value) * 0.25, "height");
    }
    if (name === "weight") {
      props.handleBmiChange(Number(value) * 0.45359237, "weight");
    }
  };

  useEffect(() => {
    let TotalCal = Math.round(
      (props.bmi.weight / Math.pow(props.bmi.height, 2)) * 100
    );
    props.handleBmiChange(TotalCal, "totalCal");
  }, [props.bmi.weight, props.bmi.height]);

  const getFreeAssessment = () => {
    return (
      <>
        <Box>
          <Box style={{ width: "100%" }}>
            <Box>
              <Grid
                item
                md
                container
                direction="row"
                justifyContent="space-evenly"
                marginTop="20px"
              >
                <Box style={{ display: "grid", justifyContent: "center" }}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={first}
                      style={{
                        boxShadow: "0px 15px 30px 0px rgba(0, 0, 0, 0.05)",
                        borderRadius: "50px",
                      }}
                    ></img>
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "300px",
                    }}
                  >
                    <Typography
                      style={{
                        textAlign: "center",
                        color: "#1A3A6C",
                        fontSize: "24px",
                        fontWeight: 900,
                        ...regularFont,
                      }}
                    >
                      So you snore loudly?
                    </Typography>
                  </Box>
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <Switch
                      className={classes.customSwitch}
                      inputProps={{ "aria-label": "controlled" }}
                      // checked={props.questions.ques1}
                      onChange={(event) =>
                        props.handleQuetionsSwitch(event, "ques1")
                      }
                    />
                  </Box>
                </Box>

                <Box style={{ display: "grid", justifyContent: "center" }}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={second}
                      style={{
                        boxShadow: "0px 15px 30px 0px rgba(0, 0, 0, 0.05)",
                        borderRadius: "50px",
                      }}
                    ></img>
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "300px",
                    }}
                  >
                    <Typography
                      style={{
                        textAlign: "center",
                        color: "#1A3A6C",
                        fontSize: "24px",
                        fontWeight: 900,
                        ...regularFont,
                      }}
                    >
                      Do you often feel tired, fatigued, or sleepy during the
                      daytime?
                    </Typography>
                  </Box>
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <Switch
                      className={classes.customSwitch}
                      inputProps={{ "aria-label": "controlled" }}
                      checked={props.questions.ques2}
                      onChange={(event) =>
                        props.handleQuetionsSwitch(event, "ques2")
                      }
                    />
                  </Box>
                </Box>

                <Box style={{ display: "grid", justifyContent: "center" }}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={third}
                      style={{
                        boxShadow: "0px 15px 30px 0px rgba(0, 0, 0, 0.05)",
                        borderRadius: "50px",
                      }}
                    ></img>
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "300px",
                    }}
                  >
                    <Typography
                      style={{
                        textAlign: "center",
                        color: "#1A3A6C",
                        fontSize: "24px",
                        fontWeight: 900,
                        ...regularFont,
                      }}
                    >
                      Has anyone observed you stop breathing?
                    </Typography>
                  </Box>
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <Switch
                      className={classes.customSwitch}
                      inputProps={{ "aria-label": "controlled" }}
                      checked={props.questions.ques3}
                      onChange={(event) =>
                        props.handleQuetionsSwitch(event, "ques3")
                      }
                    />
                  </Box>
                </Box>

                <Box style={{ display: "grid", justifyContent: "center" }}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={fourth}
                      style={{
                        boxShadow: "0px 15px 30px 0px rgba(0, 0, 0, 0.05)",
                        borderRadius: "50px",
                      }}
                    ></img>
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "300px",
                    }}
                  >
                    <Typography
                      style={{
                        textAlign: "center",
                        color: "#1A3A6C",
                        fontSize: "24px",
                        fontWeight: 900,
                        ...regularFont,
                      }}
                    >
                      Do you have or are being treated for high blood pressure?
                    </Typography>
                  </Box>
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <Switch
                      className={classes.customSwitch}
                      inputProps={{ "aria-label": "controlled" }}
                      checked={props.questions.ques4}
                      onChange={(event) =>
                        props.handleQuetionsSwitch(event, "ques4")
                      }
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                md
                container
                direction="row"
                justifyContent="space-evenly"
                marginTop="20px"
              >
                <Box style={{ display: "grid", justifyContent: "center" }}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={fifth}
                      style={{
                        boxShadow: "0px 15px 30px 0px rgba(0, 0, 0, 0.05)",
                        borderRadius: "50px",
                      }}
                    ></img>
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "300px",
                    }}
                  >
                    <Typography
                      style={{
                        textAlign: "center",
                        color: "#1A3A6C",
                        fontSize: "24px",
                        fontWeight: 900,
                        ...regularFont,
                      }}
                    >
                      Are you male?
                    </Typography>
                  </Box>
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <Switch
                      className={classes.customSwitch}
                      inputProps={{ "aria-label": "controlled" }}
                      checked={props.questions.ques5}
                      onChange={(event) =>
                        props.handleQuetionsSwitch(event, "ques5")
                      }
                    />
                  </Box>
                </Box>

                <Box style={{ display: "grid", justifyContent: "center" }}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={sixth}
                      style={{
                        boxShadow: "0px 15px 30px 0px rgba(0, 0, 0, 0.05)",
                        borderRadius: "50px",
                      }}
                    ></img>
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "300px",
                    }}
                  >
                    <Typography
                      style={{
                        textAlign: "center",
                        color: "#1A3A6C",
                        fontSize: "24px",
                        fontWeight: 900,
                        ...regularFont,
                      }}
                    >
                      Are you over 50 years old?
                    </Typography>
                  </Box>
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <Switch
                      className={classes.customSwitch}
                      inputProps={{ "aria-label": "controlled" }}
                      checked={props.questions.ques6}
                      onChange={(event) =>
                        props.handleQuetionsSwitch(event, "ques6")
                      }
                    />
                  </Box>
                </Box>

                <Box style={{ display: "grid", justifyContent: "center" }}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={seventh}
                      style={{
                        boxShadow: "0px 15px 30px 0px rgba(0, 0, 0, 0.05)",
                        borderRadius: "50px",
                      }}
                    ></img>
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "300px",
                    }}
                  >
                    <Typography
                      style={{
                        textAlign: "center",
                        color: "#1A3A6C",
                        fontSize: "24px",
                        fontWeight: 900,
                        ...regularFont,
                      }}
                    >
                      Is your BMI more than 35?
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <input
                      name="height"
                      onChange={handleChangeCalculate}
                      placeholder="Inches(H)"
                      style={{ width: "60px", marginRight: "5px" }}
                    ></input>
                    <input
                      name="weight"
                      onChange={handleChangeCalculate}
                      placeholder="Lbs(W)"
                      style={{ width: "60px", marginRight: "5px" }}
                    ></input>
                    =
                    <input
                      defaultValue={props.bmi.totalCal}
                      key={props.bmi.totalCal}
                      disabled
                      style={{ width: "30px", marginLeft: "5px" }}
                    />
                  </Box>
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <Typography>Note: 1 Feet = 12 Inches</Typography>
                  </Box>
                  {/* <Box style={{ display: "flex", justifyContent: "center" }}>
                    {props.bmi.totalCal > 35 ? (
                      <Switch
                        defaultChecked
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    ) : (
                      ""
                    )}
                  </Box> */}
                </Box>



                <Box style={{ display: "grid", justifyContent: "center" }}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={eight}
                      style={{
                        boxShadow: "0px 15px 30px 0px rgba(0, 0, 0, 0.05)",
                        borderRadius: "50px",
                      }}
                    ></img>
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "300px",
                    }}
                  >
                    <Typography
                      style={{
                        textAlign: "center",
                        color: "#1A3A6C",
                        fontSize: "24px",
                        fontWeight: 900,
                        ...regularFont,
                      }}
                    >
                      Is your neck size bigger than 16in or 41cm? (use Shirt
                      collar for reference)
                    </Typography>
                  </Box>
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <Switch
                      className={classes.customSwitch}
                      inputProps={{ "aria-label": "controlled" }}
                      checked={props.questions.ques7}
                      onChange={(event) =>
                        props.handleQuetionsSwitch(event, "ques7")
                      }
                    />
                  </Box>
                </Box>
              </Grid>
            </Box>
          </Box>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            draggable
          />
        </Box>
      </>
    );
  };
  return getFreeAssessment();
};

const mapState = (state) => {
  const { authentication, alert, loggedIn } = state;
  return { authentication, alert, loggedIn };
};

const actionCreators = {
  clearAlerts: alertActions.clear,
};
export default connect(mapState, actionCreators)(StopBangQuestions);
