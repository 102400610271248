import Carousel from "react-material-ui-carousel";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import slide1 from "../../../../assets/images/slide1.png";
import slide2 from "../../../../assets/images/slide2.png";
import slide3 from "../../../../assets/images/slide3.png";
import HomeSliderStyles from "./HomeSlider.styles";
import { useTheme } from "@mui/system";

const data = [
  {
    image: slide1,
    title: "You Deserve Quality Sleep",
    description1: <b>45 Million+ Americans suffer from Sleep Apnea.</b>,
    description2: "Order your Home Sleep Apnea Test Kit today.Safe, Non-invasive,Cost-effective and at your home.",
  },
  {
    image: slide2,
    title: "Getting tested for Sleep Apnea was never this easy!",
    description1: "Experience a Smooth and reliable test Process",
    description2: "with an Accurate Diagnosis within 24 hours.",
  },
  {
    image: slide3,
    title: "Slide- Effective Treatment that fits in your budget",
    description1: "Get Cost-effective Treatment including",
    description2: "CPAP equipmentand alternate options.",
  },
];

const HomeSlider = () => {
  const classes = HomeSliderStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const getHomeSlider = () => {
    return (
      <>
        <Box className={classes.grid}>
          <Carousel
            navButtonsAlwaysVisible={true}
            indicators={false}
            className={
              isDesktop ? classes.desktopCarousel : classes.mobileCarousel
            }
            navButtonsProps={{
              style: {
                backgroundColor: "#fff",
                color: "black",
              },
            }}
          >
            {data.map((item, i) => (
              <Grid container direction={isDesktop ? "row" : "column-reverse"}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box
                    // display="flex"
                    // justifyContent="center"
                    // alignItems="center"
                    // flexDirection="column"
                    className={isDesktop ? classes.desktopBox : classes.mobileBox}
                  >
                    <Typography
                      className={
                        isDesktop ? classes.desktopTitle : classes.mobileTitle
                      }
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      className={
                        isDesktop
                          ? classes.desktopDescription
                          : classes.mobileDescription
                      }
                      style={{ paddingTop: "10px" }}
                    >
                      {item.description1}
                    </Typography>
                    <Typography
                      className={
                        isDesktop
                          ? classes.desktopDescription
                          : classes.mobileDescription
                      }
                    >
                      {item.description2}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box
                  // style={{
                  //   width: "100%",
                  //   display: "flex",
                  //   justifyContent: "center",
                  // }}
                  >
                    <img
                      className={
                        isDesktop
                          ? classes.desktopCarouselImage
                          : classes.mobileCarouselImage
                      }
                      src={item.image}
                      alt={item.title}
                    />
                  </Box>
                </Grid>
              </Grid>
            ))}
          </Carousel>
        </Box>
      </>
    );
  };

  const getBody = () => {
    return <Box>{getHomeSlider()}</Box>;
  };
  return getBody();
};

export default HomeSlider;
