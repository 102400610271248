import Header from "../../../shared/Header/Header";
import {
    Box,
    Button,
    Divider,
    Container,
    Grid,
    Typography,
    Paper,
    FormControlLabel,
    Checkbox,
    useTheme,
    useMediaQuery,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Repeat } from "@mui/icons-material";
import Banner from "../../../shared/Banner/Banner";
import Footer from "../../../shared/Footer/Footer";
import { boldFont } from "../../../utils/styles";
import { regularFont } from "../../../utils/styles";
import { Link, Link as RouterLink } from "react-router-dom";
import icon from "../../assets/images/BuyTest/icon.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ViewProductStyles from "./ViewProduct.styles";
import productBaner from "../../../assets/images/Product/productBaner.png";
import ProductCard from "./Component/ProductCard";
import { userService } from "../../../services/sleepTestUserService-service";
import Loading from "../../Loading/Loading";
import { openErrorNotification } from "../../../helpers/methods";


const ViewProduct = () => {
    const classes = ViewProductStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
    const [productList, setproductList] = useState([]);
    const [productCount, setproductCount] = useState();
    
    const [isLoading, setIsLoading] = useState(false);

    const [role, setRole] = useState("");
    useEffect(() => {
        let username = JSON.parse(localStorage.getItem("current_user")!);
        if (username) {
            var role = username.role;
            setRole(role);
        }
    }, []);

    useEffect(() => {
        getProductsByType();
    }, [])


    useEffect(() => {
        document.title = "Products";
      }, []);

    const getProductsByType = async () => {
        try {
            setIsLoading(true);
            const response = await userService.getProductsByType();
            setproductCount(response.length)
            setproductList(response);
          
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            openErrorNotification(error.toString());
        }
    }


    return (
        <>
            <Header />
            <Banner
                img={productBaner}
                header1="Home/ Store"
                header2="Sleep Testing Products"
            />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <Container
                        maxWidth={false}
                        style={{ marginBottom: "20px", width: "71%", marginTop: "50px" }}
                    >
                        <Box
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                margin: "5% 0 3% 0",
                            }}
                        >
                            <Typography
                                style={{
                                    color: "#1A3A6C",
                                    fontSize: "30px",
                                    fontWeight: 900,
                                    ...regularFont,
                                }}
                            >
                                We found {productCount} products for you
                            </Typography>
                            {role == "Admin" ? (
                                <Link style={{ textDecoration: "none" }} to="/addproduct">
                                    <Button
                                        style={{
                                            boxShadow: "0px 15px 30px 0px rgba(0, 0, 0, 0.09)",
                                            height: "60px",
                                            borderRadius: "20px",
                                            textTransform: "none",
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                color: "#1A3A6C",
                                                fontSize: "18px",
                                                fontWeight: 700,
                                                ...regularFont,
                                            }}
                                        >
                                            Add Product
                                        </Typography>
                                    </Button>
                                </Link>
                            ) : (
                                ""
                            )}

                        </Box>


                        <ProductCard tableData={productList} />
                    </Container>
                    <Footer />
                </>
            )}

        </>
    );
};

export default ViewProduct;
