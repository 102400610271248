import { autocompleteClasses, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import counts from "../../assets/images/counts.png";
import { customButtonStyle } from "../../utils/styles";
import { boldFont } from "../../utils/styles";
import { regularFont } from "../../utils/styles";
import freeTest from "../../assets/images/freeTest.png";

const LandingPageStyles = makeStyles((theme: Theme) => ({
  safeBox: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  safeBoxForDesktop: {
    marginTop: "-50px",
    padding: "80px",
    boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.1)",
    borderRadius: "25px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
    // zIndex: 1000,
    backgroundColor: "white",
    backgroundImage: "url(" + counts + ")",
    objectFit: "cover",
  },

  SignificantSymptoms: {
    // boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.1)",
    // borderRadius: "25px",
    width: "60%",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#F9F9FF",
    padding: "3rem",
  },
  btn: {
    ...boldFont,
    ...customButtonStyle,
    backgroundColor: "#EB6523 !important",
    "&:hover": {
      backgroundColor: "#EB6523 !important",
    },
  },
  responsiveDoctorsImage: {
    width: "100%",
  },
  desktopTitle: {
    fontSize: "48px !important",
    textAlign: "center",
    fontWeight: 900,
    color: "#1A3A6C",
    ...boldFont,
  },
  mobileTitle: {
    fontSize: "28px !important",
    textAlign: "center",
    fontWeight: 900,
    color: "#1A3A6C",
    ...boldFont,
  },
  desktopTitle2: {
    fontSize: "48px !important",
    textAlign: "center",
    fontWeight: 400,
    color: "#283035",
    ...regularFont,
  },
  mobileTitle2: {
    fontSize: "18px !important",
    textAlign: "center",
    fontWeight: 400,
    color: "#283035",
    ...regularFont,
  },
  aboutDeepsleepBoXDeskTop: {
    color: "#1A3A6C",
    fontSize: "32px !important",
    fontWeight: 900,
    ...regularFont,
  },
  aboutDeepsleepBoXMobile: {
    color: "#1A3A6C",
    fontSize: "16px !important",
    fontWeight: 900,
    ...regularFont,
  },
  aboutDeepsleepBoXDeskTop1: {
    color: "#EB6523",
    fontSize: "24px !important",
    ...regularFont,
  },
  aboutDeepsleepBoXMobile1: {
    color: "#EB6523",
    fontSize: "14px !important",
    ...regularFont,
    marginBottom: "10px !important",
  },
  clickHereComponentDesktop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: "-50px",
    height: "350px",
    backgroundImage: "url(" + freeTest + ")",
  },
  clickHereComponentMobile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: "350px",
    backgroundImage: "url(" + freeTest + ")",
  },
}));

export default LandingPageStyles;
