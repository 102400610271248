import {
  Box,
  Button,
  Divider,
  Container,
  Grid,
  Typography,
  Paper,
} from "@mui/material";
import { Repeat } from "@mui/icons-material";
import React, { useState } from "react";
import Header from "../../../../shared/Header/Header";
import Footer from "../../../../shared/Footer/Footer";
import Banner from "../../../../shared/Banner/Banner";
import expandblog1 from "../../../../assets/images/Blog/expandblog1.png";
import blogbanner from "../../../../assets/images/Blog/blogbanner.png";

const BlogExpand1 = () => {
  return (
    <>
      <Header />
      <Banner img={blogbanner} header1="Home/ Blog" header2="Blog" />
      <Container maxWidth="lg" style={{ marginBottom: "20px", width: "100%" }}>
        <Grid
          item
          xl
          container
          direction="column"
          justifyContent="center"
          alignContent="center"
          marginTop="30px"
        >
          <img style={{ width: "85%" }} src={expandblog1} />
          <Box>
            <Typography
              style={{
                marginTop: "30px",
                fontSize: "42px",
                color: "#EB6523",
                fontWeight: 600,
              }}
            >
              Commercial Driver's License holders
            </Typography>
            <Typography
              style={{ marginTop: "30px", fontSize: "20px", color: "#8B8B8B" }}
            >
              By Admin | Oct 18- 2020
            </Typography>
            <Typography
              style={{
                marginTop: "60px",
                marginBottom: "100px",
                fontSize: "20px",
                color: "#283035",
              }}
            >
              <span style={{ fontStyle: "italic", fontFamily: "bold" }}>
                “A study by the Federal Motor Carrier Safety Administration
                (FMCSA) found that nearly 30% of truck drivers have mild to
                severe sleep apnea.”
              </span>
              <br />
              <br />
              Sleep Apnea symptoms can range from mild to severe. Still,
              irrespective of the severity, they are known to cause regular
              disruption in the sleeping pattern, resulting in a lack of
              alertness during the daytime. While driving, it is crucial to be
              vigilant and focused on the changing traffic conditions, which can
              be hampered if the driver lacks alertness.
              <br />
              <br />
              For Commercial Driving License holders, getting tested for Sleep
              Apnea is necessary as drowsy driving can be hazardous, leading to
              terrible accidents.
            </Typography>
          </Box>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default BlogExpand1;
