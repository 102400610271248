import Header from "../../../shared/Header/Header";
import {
  Box,
  Button,
  Divider,
  Container,
  Grid,
  Typography,
  Paper,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Repeat } from "@mui/icons-material";
import { Link } from "react-router-dom";
import history from "../../../utils/history";
import { openSuccessNotification,openErrorNotification } from "../../../helpers/methods";
import urls from "../../../global/constants/UrlConstants";
import { connect } from "react-redux";
import Footer from "../../../shared/Footer/Footer";
import RegisterStyles from "./Register.styles";
import { alertActions } from "../../../redux/_actions/alert.actions";
import { userActions } from "../../../redux/_actions/user.action";
import { useState, useEffect } from "react";
import loading2 from "../../../assets/images/Register/loading2.gif";
import { ToastContainer, toast } from "react-toastify";
import { userService } from "../../../services/sleepTestUserService-service";

import OutlinedInput from "@mui/material/OutlinedInput";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const Register = (props) => {
  const classes = RegisterStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [firstNameErr, setFirstNameErr] = useState(false);
  const [lastNameErr, setLastNameErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [passErr, setPassErr] = useState(false);
  const [cpassErr, setCPassErr] = useState(false);
  const [loading, setLoading] = useState(Boolean);
  const [eye, setEye] = useState(true);
  const [eye1, setEye1] = useState(true);

  useEffect(() => {
    document.title = "Deepsleep Register";
  }, []);

  function fnameHandler(e) {
    setLoading(false);
    let item = e.target.value;
    const regex = /^[A-Za-z]+$/;
    if (regex.test(item)) {
      setFirstNameErr(false);
    } else {
      setFirstNameErr(true);
    }
    setFname(item);
  }
  function lnameHandler(e) {
    setLoading(false);
    let item = e.target.value;
    const regex = /^[A-Za-z]+$/;
    if (regex.test(item)) {
      setLastNameErr(false);
    } else {
      setLastNameErr(true);
    }
    setLname(item);
  }
  function emailHandler(e) {
    setLoading(false);
    let item = e.target.value;
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (regex.test(item)) {
      setEmailErr(false);
    } else {
      setEmailErr(true);
    }
    setEmail(item);
  }
  function passwordHandler(e) {
    setLoading(false);
    let item = e.target.value;
    if (item.length < 8) {
      setPassErr(true);
    } else {
      setPassErr(false);
    }
    setPwd(item);
  }

  function cpasswordHandler(e) {
    setLoading(false);
    let item = e.target.value;
    if (item.length < 8 || item !== pwd) {
      setCPassErr(true);
    } else {
      setCPassErr(false);
    }
  }
  const handleSubmit = (e) => {
    if (email === "" || pwd === "" || fname === "" || lname === "") {
      alert("Please Enter Valid details");
    } else {
      if (emailErr || passErr || firstNameErr || lastNameErr) {
        alert("Please Enter Valid details");
      } else {
        setLoading(true);
        e.preventDefault();
        props.clearAlerts();
        let user = {
          email: email,
          pwd: pwd,
          firstName: fname,
          lastName: lname,
        };
        props.register(user);
      }
    }
  };

  return (
    <>
      <Header />
      <Box className={isDesktop ? classes.Box : classes.MobileBox} style={{}}>
        <form>
        <Grid
          item
          lg
          container
          direction="column"
          alignItems="center"
          marginTop="30px"
        >
          <Typography
            className={isDesktop ? classes.Typo1 : classes.Typo1Mobile}
            style={{}}
          >
            Create Account
          </Typography>
          <OutlinedInput
            className={isDesktop ? classes.input1 : classes.input1Mobile}
            type="text"
            name="fname"
            onChange={fnameHandler}
            onClick={() => {
              setLoading(false);
            }}
            placeholder="First Name"
            style={
              isDesktop
                ? {
                  marginTop: "30px",
                  width: "450px",
                  height: "70px",
                  fontSize: "20px !important",
                  // border: "1px solid #283035",
                  borderRadius: "15px",
                  backgroundColor: "#ffffff",
                }
                : {
                  marginTop: "20px",
                  width: "80%",
                  height: "65px",
                  fontSize: "20px",
                  // border: "1px solid #283035",
                  borderRadius: "15px",
                  backgroundColor: "#ffffff",
                }
            }
          />
          {firstNameErr ? (
            <span style={{ color: "red" }}>Only Alphabets allowed</span>
          ) : (
            ""
          )}

          <OutlinedInput
            className={isDesktop ? classes.input2 : classes.input2Mobile}
            type="text"
            name="lname"
            onChange={lnameHandler}
            onClick={() => {
              setLoading(false);
            }}
            placeholder="Last Name"
            style={
              isDesktop
                ? {
                  marginTop: "30px",
                  width: "450px",
                  height: "70px",
                  fontSize: "20px !important",
                  // border: "1px solid #283035",
                  borderRadius: "15px",
                  backgroundColor: "#ffffff",
                }
                : {
                  marginTop: "20px",
                  width: "80%",
                  height: "65px",
                  fontSize: "20px",
                  // border: "1px solid #283035",
                  borderRadius: "15px",
                  backgroundColor: "#ffffff",
                }
            }
          />
          {lastNameErr ? (
            <span style={{ color: "red" }}>Only Alphabets allowed</span>
          ) : (
            ""
          )}
          <OutlinedInput
            className={isDesktop ? classes.input2 : classes.input2Mobile}
            type="text"
            name="email"
            onChange={emailHandler}
            onClick={() => {
              setLoading(false);
            }}
            placeholder="Email"
            style={
              isDesktop
                ? {
                  marginTop: "30px",
                  width: "450px",
                  height: "70px",
                  fontSize: "20px !important",
                  // border: "1px solid #283035",
                  borderRadius: "15px",
                  backgroundColor: "#ffffff",
                }
                : {
                  marginTop: "20px",
                  width: "80%",
                  height: "65px",
                  fontSize: "20px",
                  // border: "1px solid #283035",
                  borderRadius: "15px",
                  backgroundColor: "#ffffff",
                }
            }
          />
          {emailErr ? (
            <span style={{ color: "red" }}>Email Not Valid</span>
          ) : (
            ""
          )}
          <OutlinedInput
            className={isDesktop ? classes.input2 : classes.input2Mobile}
            type={eye ? "password" : "text"}
            name="pwd"
            onChange={passwordHandler}
            onClick={() => {
              setLoading(false);
            }}
            placeholder="Password"
            endAdornment={
              <InputAdornment
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setEye(!eye);
                }}
                position="end"
              >
                {eye ? <VisibilityOff /> : <Visibility />}
              </InputAdornment>
            }
            style={
              isDesktop
                ? {
                  marginTop: "30px",
                  width: "450px",
                  height: "70px",
                  fontSize: "20px !important",
                  // border: "1px solid #283035",
                  borderRadius: "15px",
                  backgroundColor: "#ffffff",
                }
                : {
                  marginTop: "20px",
                  width: "80%",
                  height: "65px",
                  fontSize: "20px",
                  // border: "1px solid #283035",
                  borderRadius: "15px",
                  backgroundColor: "#ffffff",
                }
            }
          />
          {passErr ? (
            <span style={{ color: "red" }}>Password Not Valid</span>
          ) : (
            ""
          )}
          <OutlinedInput
            className={isDesktop ? classes.input2 : classes.input2Mobile}
            type={eye1 ? "password" : "text"}
            name="cpwd"
            onChange={cpasswordHandler}
            onClick={() => {
              setLoading(false);
            }}
            placeholder="Confirm Password"
            endAdornment={
              <InputAdornment
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setEye1(!eye1);
                }}
                position="end"
              >
                {eye1 ? <VisibilityOff /> : <Visibility />}
              </InputAdornment>
            }
            style={
              isDesktop
                ? {
                  marginTop: "30px",
                  width: "450px",
                  height: "70px",
                  fontSize: "20px !important",
                  // border: "1px solid #283035",
                  borderRadius: "15px",
                  backgroundColor: "#ffffff",
                }
                : {
                  marginTop: "20px",
                  width: "80%",
                  height: "65px",
                  fontSize: "20px",
                  // border: "1px solid #283035",
                  borderRadius: "15px",
                  backgroundColor: "#ffffff",
                }
            }
          />
          {cpassErr ? (
            <span style={{ color: "red" }}>Confirm password not matched</span>
          ) : (
            ""
          )}
          <Button type="submit"
            onClick={handleSubmit}
            className={isDesktop ? classes.button : classes.buttonMobile}

          >
            {props.authentication.loading ? (
              <img
                style={{ width: "20%", height: "100%" }}
                src={loading2}
              ></img>
            ) : (
              <Typography>Register</Typography>
            )}
          </Button>
          <Link to="/login">
            <Typography
              className={isDesktop ? classes.Typo2 : classes.Typo2Mobile}

            >
              Already Have a Account? Go to Login
            </Typography>
          </Link>
        </Grid>
        </form>
      </Box>
      <Footer />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
      />
    </>
  );
};

const mapState = (state) => {
  const { authentication, alert, loggedIn } = state;
  return { authentication, alert, loggedIn };
};

const actionCreators = {
  register: userActions.register,
  clearAlerts: alertActions.clear,
};

export default connect(mapState, actionCreators)(Register);
