import { autocompleteClasses, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { boldFont } from "../../../../utils/styles";
import { regularFont } from "../../../../utils/styles";

const TestStagesStepsStyles = makeStyles((theme: Theme) => ({
  grid: {
    display: "flex",
    alignItems: "center",
  },
  banner: {
    width: "100%",
    position: "relative",
    height: "auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  staticContentBox: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  staticContentBox2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  staticContentBoxDividerForDeskTop: {
    fontSize: "20px !important",
    width: "300px !important",
    fontWeight: 400,
    ...regularFont,
    color: "#EB6523",
  },
  staticContentBoxDividerForMobile: {
    fontSize: "15px !important",
    width: "300px !important",
    fontWeight: 400,
    ...regularFont,
    color: "#EB6523",
  },
  TitleForDeskTop: {
    fontSize: "48px !important",
    textAlign: "center",
    color: "#1A3A6C",
    fontWeight: 900,
    ...boldFont,
  },
  TitleBoxForMobile: {
    fontSize: "28px !important",
    textAlign: "center",
    color: "#1A3A6C",
    fontWeight: 900,
    ...boldFont,
  },
}));

export default TestStagesStepsStyles;
