import Header from "../../shared/Header/Header";
import freeassessment from "../../assets/images/freeassessment.png";
import counts from "../../assets/images/counts.png";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import Banner from "../../shared/Banner/Banner";
import FreeAssessmentStyles from "./FreeAssessment.styles";
import Footer from "../../shared/Footer/Footer";
import { Repeat, Translate } from "@mui/icons-material";
import first from "../../assets/images/FreeAssessment/first.png";
import second from "../../assets/images/FreeAssessment/second.png";
import third from "../../assets/images/FreeAssessment/third.png";
import fourth from "../../assets/images/FreeAssessment/fourth.png";
import fifth from "../../assets/images/FreeAssessment/fifth.png";
import sixth from "../../assets/images/FreeAssessment/sixth.png";
import seventh from "../../assets/images/FreeAssessment/seventh.png";
import eight from "../../assets/images/FreeAssessment/eight.png";
import high from "../../assets/images/Model/high.png";
import low from "../../assets/images/Model/low.png";
import { boldFont } from "../../utils/styles";
import { alertActions } from "../../redux/_actions/alert.actions";
import { regularFont } from "../../utils/styles";
import CustomizedSwitches from "./components/CustomizedSwitch/CustomizedSwitches";
import Switch from "@mui/material/Switch";
import { useEffect, useState } from "react";
import { userActions } from "../../redux/_actions";
import QuestionCard from "./components/QuestionCard/QuestionCard";
import freeassessmentbanner from "../../assets/images/FreeAssessment/freeassessmentbanner.png";
import { height } from "@mui/system";
import { ToastContainer, toast } from "react-toastify";
import ModalUnstyledDemo from "./components/Model/Model";
import { userService } from "../../services/sleepTestUserService-service";
import ModalUnstyledDemo1 from "./components/Model/Model";
import Modal from "@mui/material/Modal";

import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

const FreeAssessment = (props) => {
  const [ques1, setQues1] = useState(false);
  const [ques2, setQues2] = useState(false);
  const [ques3, setQues3] = useState(false);
  const [ques4, setQues4] = useState(false);
  const [ques5, setQues5] = useState(false);
  const [ques6, setQues6] = useState(false);
  const [ques7, setQues7] = useState(false);
  const [ques8, setQues8] = useState(false);
  const [model, setModel] = useState(false);
  const [height, setHeight] = useState(Number);
  const [weight, setWeight] = useState(Number);
  const [totalCal, setTotalCal] = useState(Number);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [totalScore, setTotalScore] = useState(Number);

  let current_user = localStorage.getItem("current_user");
  if (current_user !== null) {
    let username = JSON.parse(localStorage.getItem("current_user")!);
    var useremail = username.email;
    var user;
  }

  useEffect(() => {
    document.title = "Free Assessment";
  }, []);

  const classes = FreeAssessmentStyles();

  var TotalCal = 0;
  var BMIweight = 0;
  var BMIheight = 0;
  const handleChangeCalculate = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    if (name === "height") {
      BMIheight = Number(value) * 0.25;
      setHeight(BMIheight);
    }
    if (name === "weight") {
      BMIweight = Number(value);
      BMIweight = BMIweight * 0.45359237;
      setWeight(BMIweight);
    }
    TotalCal = Math.round((BMIweight / Math.pow(height, 2)) * 100);
    setTotalCal(TotalCal);
  };

  const submit = () => {
    if (height > 0 && weight > 0) {
      if (height > 24) {
        toast.error("Please enter valid height", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          pauseOnFocusLoss: false,
          draggable: true,
          progress: undefined,
        });
      } else if (weight > 635.2) {
        toast.error("Please enter valid weight", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          pauseOnFocusLoss: false,
          draggable: true,
          progress: undefined,
        });
      } else {
        setModel(true);
        var total =
          Number(ques1) + Number(ques2) + Number(ques3) + Number(ques4);
        setTotalScore(total);
        if (current_user) {
          if (
            Number(ques1) + Number(ques2) + Number(ques3) + Number(ques4) >=
            4
          ) {
            setFirstName("HighRiskAssessmentNotification");
            setEmail(useremail);
            let user = { email: email, firstName: firstName };
            userService.riskEmail(user);
          }
          if (
            Number(ques1) + Number(ques2) + Number(ques3) + Number(ques4) <
            4
          ) {
            setFirstName("LowRiskAssessmentNotification");
            setEmail(useremail);
            let user = { email: email, firstName: firstName };
            userService.riskEmail(user);
          }
        }
      }
    } else {
      toast.error("Please enter bmi score", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        pauseOnFocusLoss: false,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const modalClose = () => {
    setModel(false);
  };

  const getFreeAssessment = () => {
    return (
      <>
        <Box>
          <Header />
          <Banner
            img={freeassessmentbanner}
            header1="Home/ Free Assessment"
            header2="Free Assessment"
          />
          <Box style={{ width: "100%" }}>
            <Box
              style={{
                padding: "15px 5% 5% 5%",
              }}
            >
              <Typography
                style={{
                  fontSize: 20,
                  fontWeight: 400,
                  ...regularFont,
                  color: "#283035",
                }}
              >
                Approximately 45 million Americans suffer from Sleep Apnea
                today. This disease does not only disturbs your nights,
                <br /> but also your daily functionings – From mild headache to
                temporary memory loss.
                <br />
                If not treated quickly and accurately, Sleep Apnea can escalate
                at a rapid pace, leading up to heart attacks, strokes and
                dementia.
                <br /> Sleep Apnea is self-diagnosable and doctor-curable.
                Therefore, we recommend you get tested as soon as possible.
              </Typography>
            </Box>

            <Box>
              <Grid
                item
                md
                container
                direction="row"
                justifyContent="space-evenly"
                marginTop="20px"
              >
                <Box style={{ display: "grid", justifyContent: "center" }}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "auto",
                      height: "200px",
                    }}
                  >
                    <img
                      src={first}
                      style={{
                        boxShadow: "0px 15px 30px 0px rgba(0, 0, 0, 0.05)",
                        borderRadius: "50px",
                      }}
                    ></img>
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "300px",
                    }}
                  >
                    <Typography
                      style={{
                        textAlign: "center",
                        color: "#1A3A6C",
                        fontSize: "24px",
                        fontWeight: 900,
                        ...regularFont,
                      }}
                    >
                      So you snore loudly?
                    </Typography>
                  </Box>
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <Switch
                      inputProps={{ "aria-label": "controlled" }}
                      onChange={() => setQues1(!ques1)}
                    />
                  </Box>
                </Box>

                <Box style={{ display: "grid", justifyContent: "center" }}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "auto",
                      height: "200px",
                    }}
                  >
                    <img
                      src={second}
                      style={{
                        boxShadow: "0px 15px 30px 0px rgba(0, 0, 0, 0.05)",
                        borderRadius: "50px",
                      }}
                    ></img>
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "300px",
                    }}
                  >
                    <Typography
                      style={{
                        textAlign: "center",
                        color: "#1A3A6C",
                        fontSize: "24px",
                        fontWeight: 900,
                        ...regularFont,
                      }}
                    >
                      Do you often feel tired, fatigued, or sleepy during the
                      daytime?
                    </Typography>
                  </Box>
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <Switch
                      inputProps={{ "aria-label": "controlled" }}
                      onChange={() => setQues2(!ques2)}
                    />
                  </Box>
                </Box>

                <Box style={{ display: "grid", justifyContent: "center" }}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "auto",
                      height: "200px",
                    }}
                  >
                    <img
                      src={third}
                      style={{
                        boxShadow: "0px 15px 30px 0px rgba(0, 0, 0, 0.05)",
                        borderRadius: "50px",
                      }}
                    ></img>
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "300px",
                    }}
                  >
                    <Typography
                      style={{
                        textAlign: "center",
                        color: "#1A3A6C",
                        fontSize: "24px",
                        fontWeight: 900,
                        ...regularFont,
                      }}
                    >
                      Has anyone observed you stop breathing?
                    </Typography>
                  </Box>
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <Switch
                      onChange={() => setQues3(!ques3)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Box>
                </Box>

                <Box style={{ display: "grid", justifyContent: "center" }}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "auto",
                      height: "200px",
                    }}
                  >
                    <img
                      src={fourth}
                      style={{
                        boxShadow: "0px 15px 30px 0px rgba(0, 0, 0, 0.05)",
                        borderRadius: "50px",
                      }}
                    ></img>
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "300px",
                    }}
                  >
                    <Typography
                      style={{
                        textAlign: "center",
                        color: "#1A3A6C",
                        fontSize: "24px",
                        fontWeight: 900,
                        ...regularFont,
                      }}
                    >
                      Do you have or are being treated for high blood pressure?
                    </Typography>
                  </Box>
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <Switch
                      onChange={() => setQues4(!ques4)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                md
                container
                direction="row"
                justifyContent="space-evenly"
                marginTop="20px"
              >
                <Box style={{ display: "grid", justifyContent: "center" }}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "auto",
                      height: "200px",
                    }}
                  >
                    <img
                      src={fifth}
                      style={{
                        boxShadow: "0px 15px 30px 0px rgba(0, 0, 0, 0.05)",
                        borderRadius: "50px",
                      }}
                    ></img>
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "300px",
                    }}
                  >
                    <Typography
                      style={{
                        textAlign: "center",
                        color: "#1A3A6C",
                        fontSize: "24px",
                        fontWeight: 900,
                        ...regularFont,
                      }}
                    >
                      "Are you male?
                    </Typography>
                  </Box>
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <Switch
                      onChange={() => setQues5(!ques5)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Box>
                </Box>

                <Box style={{ display: "grid", justifyContent: "center" }}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "auto",
                      height: "200px",
                    }}
                  >
                    <img
                      src={sixth}
                      style={{
                        boxShadow: "0px 15px 30px 0px rgba(0, 0, 0, 0.05)",
                        borderRadius: "50px",
                      }}
                    ></img>
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "300px",
                    }}
                  >
                    <Typography
                      style={{
                        textAlign: "center",
                        color: "#1A3A6C",
                        fontSize: "24px",
                        fontWeight: 900,
                        ...regularFont,
                      }}
                    >
                      Are you over 50 years old?
                    </Typography>
                  </Box>
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <Switch
                      onChange={() => setQues6(!ques6)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Box>
                </Box>

                <Box style={{ display: "grid", justifyContent: "center" }}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "auto",
                      height: "200px",
                    }}
                  >
                    <img
                      src={seventh}
                      style={{
                        boxShadow: "0px 15px 30px 0px rgba(0, 0, 0, 0.05)",
                        borderRadius: "50px",
                      }}
                    ></img>
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "300px",
                    }}
                  >
                    <Typography
                      style={{
                        textAlign: "center",
                        color: "#1A3A6C",
                        fontSize: "24px",
                        fontWeight: 900,
                        ...regularFont,
                      }}
                    >
                      Is your BMI more than 35?
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <input
                      name="height"
                      onChange={handleChangeCalculate}
                      placeholder="Inches(H)"
                      style={{ width: "60px", marginRight: "5px" }}
                    ></input>
                    <input
                      name="weight"
                      onChange={handleChangeCalculate}
                      placeholder="Lbs(W)"
                      style={{ width: "60px", marginRight: "5px" }}
                    ></input>
                    =
                    <input
                      defaultValue={totalCal}
                      key={totalCal}
                      disabled
                      style={{ width: "30px", marginLeft: "5px" }}
                    />
                  </Box>
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <Typography>Note: 1 Feet = 12 Inches</Typography>
                  </Box>
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    {totalCal > 35 ? (
                      <Switch
                        defaultChecked
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>

                <Box style={{ display: "grid", justifyContent: "center" }}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "auto",
                      height: "200px",
                    }}
                  >
                    <img
                      src={eight}
                      style={{
                        boxShadow: "0px 15px 30px 0px rgba(0, 0, 0, 0.05)",
                        borderRadius: "50px",
                      }}
                    ></img>
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "300px",
                    }}
                  >
                    <Typography
                      style={{
                        textAlign: "center",
                        color: "#1A3A6C",
                        fontSize: "24px",
                        fontWeight: 900,
                        ...regularFont,
                      }}
                    >
                      Is your neck size bigger than 16in or 41cm? (use Shirt
                      collar for reference)
                    </Typography>
                  </Box>
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <Switch
                      onChange={() => setQues8(!ques8)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "50px",
                  marginBottom: "50px",
                }}
              >
                <Button
                  type="submit"
                  fullWidth
                  onClick={submit}
                  variant="contained"
                  style={{
                    ...regularFont,
                    fontWeight: 500,
                    fontSize: "16px",
                    color: "#ffffff",
                    backgroundColor: "#EB6523",
                    width: "230px",
                    height: "55px",
                    borderRadius: "15px",
                  }}
                >
                  Submit
                </Button>

                <ModalUnstyledDemo
                  open={model}
                  handleClose={() => modalClose()}
                  score={totalScore}
                />
              </Box>
            </Box>
          </Box>
          <Footer />
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            draggable
          />
        </Box>
      </>
    );
  };
  return getFreeAssessment();
};

const mapState = (state) => {
  const { authentication, alert, loggedIn } = state;
  return { authentication, alert, loggedIn };
};

const actionCreators = {
  riskEmail: userActions.riskEmail,
  clearAlerts: alertActions.clear,
};
export default connect(mapState, actionCreators)(FreeAssessment);
