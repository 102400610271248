import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  ButtonGroup,
  Button,
} from "@mui/material";
import EpworthSleepinessStyles from "./EpworthSleepiness.styles";
import clsx from "clsx";
import CustomPaper from "../../../../../../global/components/CustomPaper/CustomPaper";
import { boldFont } from "../../../../../../utils/styles";
import lodash from "lodash";
import { useEffect, useState } from "react";

interface CustomProps {
  customArray: any;
  handleChangeInCustomArray: Function;
}

const EpworthSleepiness = (props: CustomProps) => {
  const classes = EpworthSleepinessStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const onChangeScaleButtonSet = (ele: any, scale: number) => {
    ele.value = scale;
    const abc = lodash.cloneDeep(props.customArray);
    const index = abc.indexOf((a) => a.name === ele.name);
    abc.slice(index, 1, ele);
    props.handleChangeInCustomArray(abc);
  };

  const data2 = [
    "Would never doze",
    "Slight chance of dozing",
    "Moderate chance of dozing",
    "High chance of dozing",
  ];

  const getStaticButton = () => {
    return (
      <Box>
        <Box style={{ display: "flex", paddingBottom: "20px" }}>
          <Typography
            style={{
              fontSize: "24px",
              fontWeight: 900,
              ...boldFont,
              color: "#283035",
            }}
          >
            Use the following scale to choose the most appropriate number for
            each situation.
          </Typography>
        </Box>

        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: "20px",
          }}
        >
          <Grid
            container
            spacing={2}
            style={{ position: "relative", margin: "0px" }}
          >
            {data2.map((text: string, index: number) => {
              return (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={3}
                    lg={3}
                    style={{ padding: "8px" }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <ButtonGroup className={classes.buttonGroup}>
                        {[0, 1, 2, 3].map((num: number) => {
                          return (
                            <>
                              <Button
                                key={num}
                                className={clsx(classes.numScaleBtn, {
                                  [classes.activeBtn]: num === index,
                                })}
                              >
                                {num}
                              </Button>
                            </>
                          );
                        })}
                      </ButtonGroup>
                      <Typography
                        style={{ justifyContent: "center", display: "flex" }}
                      >
                        {text}
                      </Typography>
                    </Box>
                  </Grid>
                </>
              );
            })}
            <div
              style={{ width: "100%", height: "100%", position: "absolute" }}
            ></div>
          </Grid>
        </Box>

        <Box style={{ display: "flex", paddingBottom: "20px" }}>
          <Typography
            style={{
              fontSize: "18px",
              fontWeight: 900,
              ...boldFont,
              color: "#283035",
            }}
          >
            It is important that you answer each question as best you can.
          </Typography>
        </Box>
      </Box>
    );
  };

  const getScaleButtons = (ele) => {
    // main card
    return (
      <Box>
        <ButtonGroup
          className={classes.buttonGroup}
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "0px 12px",
          }}
        >
          {[0, 1, 2, 3].map((num: number) => (
            <Button
              key={num}
              className={clsx(classes.numScaleBtn, {
                [classes.activeBtn1]: num === ele.value,
              })}
              onClick={() => onChangeScaleButtonSet(ele, num)}
            >
              {num}
            </Button>
          ))}
        </ButtonGroup>
      </Box>
    );
  };

  const getCardsWithScaleButtons = (ele: any) => {
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          paddingBottom: "20px",
          height: "100%",
          width: "100%",
        }}
      >
        <CustomPaper className={classes.cards}>
          <div style={{ display: "flex", height: "100%" }}>
            <Typography
              style={{
                marginLeft: "4px",
                marginRight: "4px",
                textAlign: "center",
              }}
            >
              {ele.name}
            </Typography>
          </div>
          {getScaleButtons(ele)}
        </CustomPaper>
      </Box>
    );
  };

  const getCard = () => {
    return (
      <Grid container spacing={4}>
        {props.customArray.map((ele: any) => {
          return (
            <Grid item xs={12} sm={3} md={3} lg={3}>
              {getCardsWithScaleButtons(ele)}
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return (
    <>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "0 20px",
        }}
      >
        <Box style={{ width: "70%" }}>
          {getStaticButton()}
          {getCard()}
        </Box>
      </Box>
    </>
  );
};

export default EpworthSleepiness;
