import {
  Box,
  Button,
  Divider,
  Container,
  Grid,
  Typography,
  Paper,
} from "@mui/material";
import Header from "../../shared/Header/Header";
import Footer from "../../shared/Footer/Footer";
import banner from "../../assets/images/ContactUs/banner.png";
import Message from "../../assets/images/ContactUs/Message.png";
import Calling from "../../assets/images/ContactUs/Calling.png";
import Discovery from "../../assets/images/ContactUs/Discovery.png";
import Form from "./components/Form/Form";
import ContactUsStyles from "./ContactUs.styles";
import { boldFont } from "../../utils/styles";
import { regularFont } from "../../utils/styles";
import DataCard from "./components/DataCard/DataCard";
import Banner from "../../shared/Banner/Banner";

const cardData = [
  { img: Calling, title: "CALL US", data: "tel:+1 (888) 410-0212" },
  { img: Message, title: "EMAIL US", data: "info@deepsleeptest.com" },
  { img: Discovery, title: "FIND US", data: "Reach Us" },
];

const ContactUs = () => {
  const classes = ContactUsStyles();
  return (
    <>
      <Header />
      <Banner img={banner} header1="Home/ Contact Us" header2="Contact Us" />
      <Container maxWidth="xl" style={{ marginBottom: "20px", width: "100%" }}>
        <Grid sm={12} md={12} lg={12} xl={12}>
          <Grid item>
            <Typography
              className={classes.header}
              style={{
                ...boldFont,
              }}
            >
              Get In Touch
            </Typography>
            <Typography
              className={classes.subHeader}
              style={{
                ...regularFont,
              }}
            >
              Let us know your basic, non-confidential information. We will
              reach out to you
              <br /> within 24 hours.
            </Typography>
          </Grid>
        </Grid>
        <Grid>
          <Grid
            sm={12}
            md={12}
            lg={12}
            xl={12}
            item
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            {cardData.map((item) => (
              <DataCard img={item.img} title={item.title} data={item.data} />
            ))}
          </Grid>
        </Grid>
        <Grid className={classes.formWrapper}>
          <Box className={classes.formContainer}>
            <Typography
              style={{
                fontSize: "48px",
                fontWeight: 900,
                color: "#1A3A6C",
                ...boldFont,
              }}
            >
              Leave Us Your Info
            </Typography>
            <Typography
              style={{
                marginTop: "20px",
                fontWeight: 400,
                color: "#1A3A6C",
                ...regularFont,
              }}
            >
              Let us know your basic, non-confidential information. We will
              reach out to you
              <br /> within 24 hours
            </Typography>
          </Box>
          <Container
            maxWidth="lg"
            style={{ marginBottom: "20px", width: "100%" }}
          >
            <Form />
          </Container>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default ContactUs;
