import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { boldFont } from "../../../utils/styles";
import { regularFont } from "../../../utils/styles";

const UpdatePasswordStyles = makeStyles((theme: Theme) => ({
  Box: {
    width: "100% !important",
    height: "864px !important",
    backgroundColor: "#F9F9FF !important",
    justifyContent: "center",
    display: "flex",
  },
  MobileBox: {
    width: "100%",
    height: "680px",
    backgroundColor: "#F9F9FF",
    justifyContent: "center",
    display: "flex",
  },
  Typo1: {
    fontSize: "7vh !important",
    fontWeight: 700,
    color: "#283035",
    ...boldFont,
  },
  Typo1Mobile: {
    fontSize: "50px !important",
    fontWeight: 700,
    color: "#283035",
    ...boldFont,
  },
  input1: {
    marginTop: "60px",
    width: "420px",
    height: "65px",
    fontSize: "20px !important",
    paddingLeft: "15px",
    paddingRight: "15px",
    border: "1px solid #283035",
    borderRadius: "15px",
  },
  input1Mobile: {
    marginTop: "40px",
    width: "80%",
    height: "65px",
    fontSize: "20px",
    paddingLeft: "15px",
    paddingRight: "15px",
    border: "1px solid #283035",
    borderRadius: "15px",
  },
  input2: {
    marginTop: "20px",
    width: "420px",
    height: "65px",
    border: "1px solid #283035",
    borderRadius: "15px",
    fontSize: "20px",
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  input2Mobile: {
    marginTop: "20px",
    width: "80%",
    height: "65px",
    border: "1px solid #283035",
    borderRadius: "15px",
    fontSize: "20px",
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  button: {
    marginTop: "20px !important",
    width: "450px !important",
    height: "65px !important",
    border: "1px solid #283035 !important",
    borderRadius: "15px !important",
    backgroundColor: "#1A3A6C !important",
    color: "#ffffff !important",
  },
  buttonMobile: {
    marginTop: "20px !important",
    width: "40% !important",
    height: "70px !important",
    border: "1px solid #283035 !important",
    borderRadius: "15px !important",
    backgroundColor: "#1A3A6C !important",
    color: "#ffffff !important",
  },
  Typo2: {
    marginTop: "20px !important",
    color: "#1A3A6C !important",
  },
  Typo2Mobile: {
    marginTop: "20px !important",
    color: "#1A3A6C !important",
  },
  Typo3: {
    marginTop: "25px !important",
    color: "#1A3A6C !important",
  },
  Typo3Mobile: {
    marginTop: "25px !important",
    color: "#1A3A6C !important",
  },
  Typo4: {
    marginTop: "40px !important",
    color: "#EB6523 !important",
  },
  Typo4Mobile: {
    marginTop: "40px !important",
    color: "#EB6523 !important",
  },
}));

export default UpdatePasswordStyles;
