import history from "./utils/history";
import { Router, Switch, Route } from "react-router-dom";
import urls from "./global/constants/UrlConstants";
import withClearCache from "./ClearCache";
import LandingPage from "./screens/LandingPage/LandingPage";
import PageNotFound from "./screens/PageNotFound/PageNotFound";
import Services from "./screens/Services/Services1/Services";
import Services1 from "./screens/Services/Services2/Services1";
import Services2 from "./screens/Services/Services3/Services2";
import ContactUs from "./screens/ContactUs/ContactUs";
import Register from "./screens/Auth/Register/Register";
import Login from "./screens/Auth/Login/Login";
import ForgotPassword from "./screens/Auth/ForgotPassword/ForgotPassword";
import FreeAssessment from "./screens/FreeAssessment/FreeAssessment";
// import { useAppSelector } from "./utils/hooks";
// import { selectAuthenticated } from "./redux/authSlice";
import AboutUs from "./screens/AboutUs/AboutUs";
import Faq from "./screens/Faq/Faq";
import Blog from "./screens/Blog/Blog";
import BuyTest from "./screens/BuyTest/BuyTest";
import OrderDetails from "./screens/OrderDetails/OrderDetails";
import Dashboard from "./screens/Dashboard/Dashboard";
import UpdatePassword from "./screens/Auth/UpdatePassword/UpdatePassword";
import PaymentDetails from "./screens/PaymentDetails/PaymentDetails";
import Epworth from "./screens/BuyTest/components/Epworth/Epworth";
import AddUser from "./screens/User/AddUser/AddUser";
import ViewUser from "./screens/User/ViewUser/ViewUser";
import UpdateProfile from "./screens/User/UpdateUserProfile/ProfileUpdate";
import ViewUserDetail from "./screens/User/ViewUserDetail/ViewUserDetail";
import Reciept from "./screens/OrderDetails/Reciept/Reciept";
import Notifications from "./utils/notifications";
import BlogExpand1 from "./screens/Blog/components/BlogExpand/BlogExpand1";
import BlogExpand2 from "./screens/Blog/components/BlogExpand/BlogExpand2";
import Cart from "./screens/Product/cart/Cart";
import BlogExpand3 from "./screens/Blog/components/BlogExpand/BlogExpand3";
import BlogExpand from "./screens/Blog/components/BlogExpand/BlogExpand";
import ViewProduct from "./screens/Product/ViewProduct/ViewProduct";
import ViewProductDetail from "./screens/Product/ViewProductDetail/ViewProductDetail";
import AddProduct from "./screens/Product/AddProducts/AddProduct";
import CheckOut from "./screens/Product/CheckOut/CheckOut";
import EditProduct from "./screens/Product/EditProducts/EditProduct";
import TermPolicy from "./screens/TermAndCondition/components/TermsCondition";
import PrivacyPolicy from "./screens/Privacy/components/Privacy";

const App = () => {
  return <ClearCacheComponent />;
};

const MainApp = () => {
  // const authenticated = useAppSelector(selectAuthenticated);
  return (
    <>
      <Router history={history}>
        <Switch>
          <Route exact path={urls.landingViewPath} component={LandingPage} />
          <Route
            exact
            path={urls.FreeAssessmentViewPath}
            component={FreeAssessment}
          />
          <Route path={urls.AboutUsPath} component={AboutUs} />
          <Route path={urls.sleepProblemIndIviduals} component={Services} />
          <Route
            path={urls.commercialDriversLicenseHolders}
            component={Services1}
          />
          <Route path={urls.dentistRecommendPatients} component={Services2} />
          <Route path={urls.ContactUsPath} component={ContactUs} />
          <Route path={urls.SignInPath} component={Login} />
          <Route path={urls.RegisterPath} component={Register} />
          <Route path={urls.ForgotPasswordPath} component={ForgotPassword} />
          <Route path={urls.AboutUsPath} component={AboutUs} />
          <Route path={urls.faqPath} component={Faq} />
          <Route path={urls.paymentdetailspath} component={PaymentDetails} />
          <Route path={urls.privacyPolicy} component={PrivacyPolicy} />
          <Route path={urls.termsConditions} component={TermPolicy} />
          <Route path={urls.blogPath} component={Blog} />
          <Route path={urls.cart} component={Cart} />

          {/* <PrivateRoute
          exact
          isLoggedIn={authenticated}
          path={urls.thankyouViewPath}
          component={ThankYou}
        /> */}
          {/* <Layout /> */}
          <Route path={urls.pageNotFoundViewPath} component={PageNotFound} />
          <Route path={urls.buytestPath} component={BuyTest} />
          <Route path={urls.orderdetailsexpand} component={OrderDetails} />
          <Route path={urls.dashboardpath} component={Dashboard} />
          <Route path={urls.updatepasspath} component={UpdatePassword} />
          <Route path={urls.epworth} component={Epworth} />
          <Route path={urls.adduserpath} component={AddUser} />
          <Route path={urls.updateprofilepath} component={UpdateProfile} />
          <Route path={urls.viewUser} component={ViewUser} />
          <Route path={urls.viewUserDetail} component={ViewUserDetail} />
          <Route path={urls.reciepturl} component={Reciept} />
          <Route path={urls.blogexpand1} component={BlogExpand} />
          <Route path={urls.blogexpand2} component={BlogExpand1} />
          <Route path={urls.blogexpand3} component={BlogExpand2} />
          <Route path={urls.blogexpand4} component={BlogExpand3} />
          <Route path={urls.viewProduct} component={ViewProduct} />
          <Route path={urls.addproduct} component={AddProduct} />
          <Route path={urls.editproduct} component={EditProduct} />
          <Route path={urls.checkoutproduct} component={CheckOut} />
          <Route path={urls.viewProductDetail} component={ViewProductDetail} />
          <Route path={""} component={PageNotFound} />
        </Switch>
      </Router>
      <Notifications />
    </>
  );
};

const ClearCacheComponent = withClearCache(MainApp);

export default App;
