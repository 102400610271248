import {
  Box,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import BannerStyles from "./Banner.styles";
import { Link } from "react-router-dom";
import { boldFont } from "../../utils/styles";
import { regularFont } from "../../utils/styles";
interface Props {
  img: string;
  header1: string;
  header2:string;
}

const Banner = ({img,header1,header2 }: Props) => {
  const classes = BannerStyles();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <>
      <Grid
        item
        lg
        container
        direction="row"
        style={{backgroundImage: `url(${img})`}}
        className={isDesktop ? classes.desktopbg : classes.mobilebg}
      >
        <Box className={classes.textWrapper}>
          <Typography
            className={isDesktop ? classes.desktopheaderline : classes.mobileheaderline}
            style={{
             
            }}
          >
            {header1}
          </Typography>
          <Box>
            <Typography
              className={isDesktop ? classes.desktopsecondaryline : classes.mobilesecondaryline}
              style={{
               
              }}
            >
              {header2}
            </Typography>
          </Box>
        </Box>
      </Grid>

      {/* <Box className={classes.bgimage}>
        <Box className={classes.textWrapper}>
          <Typography style={{ fontSize: 24 }}> Home/ Contact Us </Typography>
          <Box >
            <Typography style={{ fontSize: 85, fontWeight: "bold", color: "#1A3A6C" }}> Contact Us </Typography>
          </Box>
        </Box>
      </Box> */}
    </>
  );
};

export default Banner;
