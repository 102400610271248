import {
  Box,
  Typography,
} from "@mui/material";

interface CustomProps {
  question: string;
  answer: string;
  image: string;
}

const StopBang = (props: CustomProps) => {
 

  return (
    <>
      <Box
        style={{
          boxShadow: "0px 15px 30px 0px rgba(0, 0, 0, 0.05)",
          borderRadius: "20px",
          backgroundColor: "#FFFFFF",
          height: "100px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Box
          style={{
            marginLeft: "30px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box style={{ marginRight: "20px" }}>
            <img src={require("../../../../../assets/images/ViewUser/" +
              props.image +
              ".png").default}></img>
          </Box>
          <Typography>{props.question}</Typography>
        </Box>

        <Box style={{ marginRight: "50px" }}>
          <Typography>{props.answer === 'Yes' ? "Yes" : "No"}</Typography>
        </Box>
      </Box>
    </>
  );
};

export default StopBang;
