import LoginStyles from "./Login.styles";
import Header from "../../../shared/Header/Header";
import React, { Component, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "../../../shared/Footer/Footer";
import { useState } from "react";
import { alertActions } from "../../../redux/_actions/alert.actions";
import { userActions } from "../../../redux/_actions/user.action";
import loading2 from "../../../assets/images/Login/loading2.gif";
import Visibility from "@material-ui/icons/Visibility";
import { openSuccessNotification } from "../../../helpers/methods";
import history from "../../../utils/history";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { ToastContainer, toast } from "react-toastify";
import OutlinedInput from "@mui/material/OutlinedInput";
import initialState from "../../../redux/initialState";
import { openErrorNotification } from "../../../helpers/methods";
import { FormControl } from "@mui/material";
import { userService } from "../../../services/sleepTestUserService-service";



const Login = (props) => {
  const classes = LoginStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [formData, setFormData] = useState({
    submitted: false,
    ariahidden: false,
    disabled: true,
    hidden: true,
  });

  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [userErr, setUserErr] = useState(false);
  const [passErr, setPassErr] = useState(false);
  const [loading, setLoading] = useState(initialState.user.loading);
  const [eye, setEye] = useState(true);

  useEffect(() => {
    document.title = "Deepsleep Login";
  }, []);

  function userHandler(e) {
    setLoading(false);
    var item = e.target.value;
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (regex.test(item)) {
      setUserErr(false);
    } else {
      setUserErr(true);
    }
    setEmail(item);
  }
  function passwordHandler(e) {
    setLoading(false);
    let item = e.target.value;
    if (item.length < 8) {
      setPassErr(true);
    } else {
      setPassErr(false);
    }
    setPwd(item);
  }

  const handleSubmit = (e) => {
    if (email === "" || pwd === "") {
      openErrorNotification("Please Enter Valid details");
      setUserErr(true);
      setPassErr(true);
    } else {
      if (userErr || passErr) {
        openErrorNotification("Please Enter Valid details");
      } else {
        setLoading(true);
        e.preventDefault();
        props.clearAlerts();
        let user = { email: email, pwd: pwd };
        props.login(user);
      }
    }
  };

  return (
    <>
      <Header />
      <Box className={isDesktop ? classes.Box : classes.MobileBox}>
        <form>
          <Grid
            item
            lg
            container
            direction="column"
            alignItems="center"
            marginTop="30px"
          >
            <Typography
              className={isDesktop ? classes.Typo1 : classes.Typo1Mobile}
            >
              Login
            </Typography>
            <OutlinedInput
              // className={isDesktop ? classes.input1 : classes.input1Mobile}
              type="text"
              onChange={userHandler}
              name="email"
              placeholder="Email"
              style={
                isDesktop
                  ? {
                      marginTop: "30px",
                      width: "450px",
                      height: "70px",
                      fontSize: "20px !important",
                      // border: "1px solid #283035",
                      borderRadius: "15px",
                      backgroundColor: "#ffffff",
                    }
                  : {
                      marginTop: "20px",
                      width: "80%",
                      height: "65px",
                      fontSize: "20px",
                      // border: "1px solid #283035",
                      borderRadius: "15px",
                      backgroundColor: "#ffffff",
                    }
              }
            />
            {userErr ? (
              <span style={{ color: "red" }}>Email Not Valid</span>
            ) : (
              ""
            )}
            <OutlinedInput
              // className={isDesktop ? classes.input2 : classes.input2Mobile}
              type={eye ? "password" : "text"}
              onChange={passwordHandler}
              name="pwd"
              placeholder="Password"
              endAdornment={
                <InputAdornment
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setEye(!eye);
                  }}
                  position="end"
                >
                  {eye ? <VisibilityOff /> : <Visibility />}
                </InputAdornment>
              }
              style={
                isDesktop
                  ? {
                      marginTop: "30px",
                      width: "450px",
                      height: "70px",
                      fontSize: "20px !important",
                      // border: "1px solid #283035",
                      borderRadius: "15px",
                      backgroundColor: "#ffffff",
                    }
                  : {
                      marginTop: "20px",
                      width: "80%",
                      height: "65px",
                      fontSize: "20px",
                      // border: "1px solid #283035",
                      borderRadius: "15px",
                      backgroundColor: "#ffffff",
                    }
              }
            />

            {passErr ? (
              <span style={{ color: "red" }}>Password Not Valid</span>
            ) : (
              ""
            )}
            <Button
              type="submit"
              onClick={handleSubmit}
              className={isDesktop ? classes.button : classes.buttonMobile}
              style={{}}
            >
              
              {props.authentication.loading ? (
                <img
                  style={{ width: "20%", height: "100%" }}
                  src={loading2}
                ></img>
              ) : (
                <Typography>Login</Typography>
              )}
            </Button>

            {/* <LoadingButton
            onClick={handleSubmit}
            className={isDesktop ? classes.button : classes.buttonMobile}
            loading
            variant="outlined"
          >
            Sign In
          </LoadingButton> */}
            <Link to="/forgotpassword" style={{ textDecoration: "none" }}>
              <Typography
                className={isDesktop ? classes.Typo2 : classes.Typo2Mobile}
              >
                Forgot your password?
              </Typography>
            </Link>
            <Link to="/register" style={{ textDecoration: "none" }}>
              <Typography
                className={isDesktop ? classes.Typo3 : classes.Typo3Mobile}
              >
                Create an account
              </Typography>
            </Link>
            <Typography
              className={isDesktop ? classes.Typo4 : classes.Typo4Mobile}
            >
              (We are HIPAA Compliant)*
            </Typography>
          </Grid>
        </form>
      </Box>
      <Footer />
    </>
  );
};

const mapState = (state) => {
  const { authentication, alert, loggedIn } = state;
  return { authentication, alert, loggedIn };
};

const actionCreators = {
  login: userActions.login,
  clearAlerts: alertActions.clear,
};

export default connect(mapState, actionCreators)(Login);
