import * as React from "react";
import { useEffect, useState } from "react";
import { styled, Box } from "@mui/system";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import high from "../../../../assets/images/Model/high.png";
import low from "../../../../assets/images/Model/low.png";
import { Button, Typography } from "@material-ui/core";
import { boldFont, regularFont } from "../../../../utils/styles";
import AddUser from "../../../../assets/images/Model/AddUser.png";
import Login from "../../../../assets/images/Model/Login.png";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import LoginIcon from "@mui/icons-material/Login";

import { Link } from "react-router-dom";
import { userActions } from "../../../../redux/_actions/user.action";

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  p: 2,
  px: 4,
  pb: 3,
};

function ModalUnstyledDemo(open) {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  var score = open.score;

  let current_user = localStorage.getItem("current_user");
  // let username = JSON.parse(localStorage.getItem("current_user")!);
  // var useremail = username.email;
  // console.log(useremail);

  return (
    <div>
      {current_user ? (
        <StyledModal
          aria-labelledby="unstyled-modal-title"
          aria-describedby="unstyled-modal-description"
          open={open.open}
          onClose={open.handleClose}
          BackdropComponent={Backdrop}
        >
          {score > 3 ? (
            <Box
              style={{
                backgroundColor: "white",
                width: "20%",
                height: "50%",
                borderRadius: "30px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "1% 2% 1% 2%",
              }}
            >
              <img src={high}></img>
              <h2 id="unstyled-modal-title">
                High Risk!! (Score {open.score}/8)
              </h2>
              <text style={{ textAlign: "center" }}>
                You are at high risk for Sleep Apnea. A medically prescribed
                sleep test is needed to diagnose sleep apnea. With advances in
                telemedicine, this can all be done in the confort of your home!{" "}
                <br />
                <br /> Report has been successfully sent to your registered
                email address.
              </text>
              <Box
                onClick={open.handleClose}
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  ...regularFont,
                  fontWeight: 500,
                  fontSize: "16px",
                  color: "#ffffff",
                  backgroundColor: "#EB6523",
                  width: "150px",
                  height: "55px",
                  borderRadius: "15px",
                  marginTop: "20px",
                }}
              >
                <Box>ok</Box>
              </Box>
            </Box>
          ) : (
            <Box
              style={{
                backgroundColor: "white",
                width: "20%",
                height: "50%",
                borderRadius: "30px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "1% 2% 1% 2%",
              }}
            >
              <img src={low}></img>
              <h2 id="unstyled-modal-title">
                Low Risk!! (Score {open.score}/8)
              </h2>
              <text style={{ textAlign: "center" }}>
                There is a possibility of having sleep apnea and in more than
                30% of such cases further evaluation is required. <br />
                <br /> Report has been successfully sent to your registered
                email address.
              </text>
              <Box
                onClick={open.handleClose}
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  ...regularFont,
                  fontWeight: 500,
                  fontSize: "16px",
                  color: "#ffffff",
                  backgroundColor: "#EB6523",
                  width: "150px",
                  height: "55px",
                  borderRadius: "15px",
                  marginTop: "20px",
                }}
              >
                <Box>ok</Box>
              </Box>
            </Box>
          )}
        </StyledModal>
      ) : (
        <StyledModal
          aria-labelledby="unstyled-modal-title"
          aria-describedby="unstyled-modal-description"
          open={open.open}
          onClose={open.handleClose}
          BackdropComponent={Backdrop}
        >
          {score > 3 ? (
            <Box
              style={{
                backgroundColor: "white",
                width: "22%",
                height: "55%",
                borderRadius: "30px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "1% 2% 1% 2%",
              }}
            >
              <img src={high}></img>
              <h2 id="unstyled-modal-title">
                High Risk!! (Score {open.score}/8)
              </h2>
              <text style={{ textAlign: "center" }}>
                You are likely at high risk for Sleep Apnea and should consider
                definitive diagnosis with Home Sleep Test.
              </text>
              <br />
              <br />
              <Link to="/login" style={{ textDecoration: "none" }}>
                <Box
                  style={{
                    backgroundColor: "#DEE1EB",
                    width: "400px",
                    height: "70px",
                    borderRadius: "15px",
                    display: "flex",
                    justifyContent: "start",
                    marginBottom: "20px ",
                    alignItems: "center",
                  }}
                >
                  <LoginIcon
                    style={{
                      backgroundColor: "#ffffff",
                      padding: "15px 15px 15px 15px",
                      borderRadius: "10px",
                      marginLeft: "10px",
                      marginRight: "40px",
                    }}
                  />
                  Login into your account
                </Box>
              </Link>
              <Link to="/register" style={{ textDecoration: "none" }}>
                <Button
                  startIcon={
                    <PersonAddAltIcon
                      style={{
                        backgroundColor: "#ffffff",
                        padding: "15px 15px 15px 15px",
                        borderRadius: "10px",
                        marginLeft: "10px",
                        marginRight: "50px",
                      }}
                    />
                  }
                  style={{
                    backgroundColor: "#F7E1DB",
                    width: "400px",
                    height: "70px",
                    borderRadius: "15px",
                    display: "flex",
                    justifyContent: "start",
                  }}
                >
                  Create An Account
                </Button>
              </Link>
            </Box>
          ) : (
            <Box
              style={{
                backgroundColor: "white",
                width: "22%",
                height: "55%",
                borderRadius: "30px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "1% 2% 1% 2%",
              }}
            >
              <img src={low}></img>
              <h2>Low Risk!! (Score {open.score}/8)</h2>
              <text style={{ textAlign: "center" }}>
                There is a possibility of having sleep apnea and in more than
                30% of such cases further evaluation is required. <br />
                <br />
              </text>
              <Link to="/login" style={{ textDecoration: "none" }}>
                <Box
                  style={{
                    backgroundColor: "#DEE1EB",
                    width: "400px",
                    height: "70px",
                    borderRadius: "15px",
                    display: "flex",
                    justifyContent: "start",
                    marginBottom: "20px ",
                    alignItems: "center",
                  }}
                >
                  <LoginIcon
                    style={{
                      backgroundColor: "#ffffff",
                      padding: "15px 15px 15px 15px",
                      borderRadius: "10px",
                      marginLeft: "10px",
                      marginRight: "40px",
                    }}
                  />
                  Login into your account
                </Box>
              </Link>
              <Link to="/register" style={{ textDecoration: "none" }}>
                <Box
                  style={{
                    backgroundColor: "#F7E1DB",
                    width: "400px",
                    height: "70px",
                    borderRadius: "15px",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <PersonAddAltIcon
                    style={{
                      backgroundColor: "#ffffff",
                      padding: "15px 15px 15px 15px",
                      borderRadius: "10px",
                      marginLeft: "10px",
                      marginRight: "50px",
                    }}
                  />
                  Create An Account
                </Box>
              </Link>
            </Box>
          )}
        </StyledModal>
      )}
    </div>
  );
}

export default ModalUnstyledDemo;
