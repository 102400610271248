import { combineReducers } from "redux";
import { authentication } from "./authentication.reducer";
import { executePaymentSuccessFlow } from './user.reducer';
import { shopReducer } from './cart.reducer';
import {documentPrescriptionReducer} from "./documentPrescription.reducer";


const rootReducer = combineReducers({
  authentication,
  executePaymentSuccessFlow,
  shopReducer,
  documentPrescriptionReducer
});

export default rootReducer;
