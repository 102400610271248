import Header from "../../shared/Header/Header";
import Footer from "../../shared/Footer/Footer";
import {
  Box,
  Button,
  Divider,
  Container,
  Grid,
  Typography,
  Paper,
} from "@mui/material";
import { Repeat } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import DataCard from "./components/DataCard/DataCard";
import blogfirst from "../../assets/images/Blog/blogfirst.png";
import blogsecond from "../../assets/images/Blog/blogsecond.png";
import blogthird from "../../assets/images/Blog/blogthird.png";
import blogfourth from "../../assets/images/Blog/blogfourth.png";
import Banner from "../../shared/Banner/Banner";
import blogbanner from "../../assets/images/Blog/blogbanner.png";
import { Link } from "react-router-dom";
const Blog = () => {
  useEffect(() => {
    document.title = "Blog";
  }, []);
  return (
    <>
      <Header />
      <Banner img={blogbanner} header1="Home/ Blogs" header2="Blogs" />
      <Box>
        <Grid
          item
          lg
          container
          direction="row"
          justifyContent="center"
          marginTop="20px"
        >
          <Link style={{textDecoration:"none"}} to="/blog1">
            <DataCard
              img={blogfirst}
              title="Dental Services Patients"
              date="Oct 18- 2020"
              by=" By Admin"
            />
          </Link>
          <Link style={{textDecoration:"none"}} to="/blog2">
            <DataCard
              img={blogsecond}
              title="Commercial Driver's License holders"
              date="Oct 18- 2020"
              by=" By Admin"
            />
          </Link>
        </Grid>
        <Grid
          item
          lg
          container
          direction="row"
          justifyContent="center"
          marginTop="20px"
        >
          <Link style={{textDecoration:"none"}} to="/blog3">
            <DataCard
              img={blogthird}
              title="Sleep Problems Individuals"
              date="Oct 18- 2020 "
              by=" By Admin"
            />
          </Link>
          <Link style={{textDecoration:"none"}} to="/blog4">
            <DataCard
              img={blogfourth}
              title="Sleep Problems Individuals"
              date="Oct 18- 2020 "
              by="By Admin"
            />
          </Link>
        </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default Blog;
