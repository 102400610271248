import React from "react";
import customPaperStyles from "./CustomPaper.styles";
import clsx from "clsx";
import { Paper } from "@mui/material";

interface CustomProps {
  children: React.ReactNode;
  className?: string;
  isWeb?: boolean;
  removeBorder?: boolean;
}

const CustomPaper = (props: CustomProps) => {
  const classes = customPaperStyles();
  if (props.removeBorder) {
    if (!props.isWeb) {
      return (
        <Paper
          variant="outlined"
          className={clsx(classes.customPaperMobile, props.className)}
        >
          {props.children}
        </Paper>
      );
    }
  }
  return (
    <Paper
      variant="outlined"
      className={clsx(classes.customPaper, props.className)}
    >
      {props.children}
    </Paper>
  );
};

export default CustomPaper;