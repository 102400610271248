import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { userService } from "../../../services/sleepTestUserService-service";
import { toast } from "react-toastify";
import { openErrorNotification, openSuccessNotification } from "../../../helpers/methods";
import {
    Box,
    Button,
    Typography,
    Paper,
} from "@mui/material";
import loading2 from "../../../assets/images/Login/loading2.gif";


interface CustomProps {
    billingAddress: any;
    shippingAddress: any;
    user: any
    onHandleSubmit: Function;
    paymentSecret: string
    handleAddressValidation: Function;
}

const CheckoutForm = (props: CustomProps) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [succeeded] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [processing, setProcessing] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState(false);

    const cardStyle = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: "Arial, sans-serif",
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#32325d",
                },
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
            },

        },
    };

    const validateAddressFields = () => {
        let valid = false;
        if (props.handleAddressValidation()) {
            valid = true
        }
        return valid
    }

    const handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
    };


    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setProcessing(true);
        setIsLoading(true);
        if (stripe !== null && elements !== null) {
            if (validateAddressFields()) {
                const payload = await stripe.confirmCardPayment(props.paymentSecret, {
                    receipt_email: props.user.email,
                    payment_method: {
                        card: elements.getElement(CardElement)!,
                    },
                    setup_future_usage: "off_session",
                });
                if (payload.error) {
                    setProcessing(false);
                    setIsLoading(false);
                    userService.purchaseFailed(props.user);
                    openErrorNotification(payload.error.message);
                    // Show error
                } else {
                    props.user.billingAddress = props.billingAddress;
                    props.user.shippingAddress = props.shippingAddress;
                    await props.onHandleSubmit(props.user);

                }
            } else {
                openErrorNotification('Please fill all the requried fildes.')
                setProcessing(false);
                setIsLoading(false);
            }
        }
    }

    return (
        <form id="payment-form">
            <Box style={{ border: "1px solid #eee", padding: "10px", borderRadius: "15px", marginBottom: "10px" }}>
                <CardElement
                    id="card-element"
                    options={cardStyle}
                    onChange={handleChange}
                />
            </Box>
            <Box style={{
                justifyContent: "center",
                alignContent: 'center',
                display: "flex",
                top: "20px ",
            }}>
                <Button
                    style={{
                        marginTop: "20px ",
                        width: "200px ",
                        height: "50px ",
                        border: "1px solid #283035 ",
                        borderRadius: "15px ",
                        backgroundColor: "#1A3A6C ",
                        color: "#ffffff ",
                        justifyContent: "center",
                        alignContent: 'center',
                        display: "flex"
                    }}
                    onClick={(event) => handleSubmit(event)}
                >
                    {isLoading ? (
                        <img
                            style={{ width: "30%", height: "100%" }}
                            src={loading2}
                        ></img>
                    ) : (
                        <> {"Pay"}</>
                    )}
                </Button>
            </Box>
            {/* Show any error that happens when processing the payment */}
            {/* {error && (
                <div className="card-error" role="alert">
                    {error}
                </div>
            )}
            Show a success message upon completion
            <p className={succeeded ? "result-message" : "result-message hidden"}>
                Payment succeeded, see the result in your
                <a href={`https://dashboard.stripe.com/test/payments`}>
                    {" "}
                    Stripe dashboard.
                </a>{" "}
                Refresh the page to pay again.
            </p>
 */}

        </form>
    );
};

export default CheckoutForm
