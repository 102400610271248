import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import rootReducer from "../redux/_reducers";

const loggerMiddleware = createLogger();

const loadState = () => {
    try {
        const loadedState = localStorage.getItem("state");
        if (loadedState === null) {
            return undefined;
        }
        return JSON.parse(loadedState);
    } catch (err) {
        return undefined;
    }
};

const saveState = (state) => {
    const serializedState = JSON.stringify(state, (key, value) =>
        value === undefined ? "" : value
    );
    localStorage.setItem("state", serializedState);
};

const persistedState = loadState();

const store = createStore(
    rootReducer,
    persistedState,
    applyMiddleware(thunkMiddleware, loggerMiddleware)
);

store.subscribe(() => {
    saveState(store.getState());
});

export default store;
