import { autocompleteClasses, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Testimonial from "../../../../assets/images/Testomonial/Testimonial.png";
import { boldFont } from "../../../../utils/styles";
import { regularFont } from "../../../../utils/styles";

const TestimonialsStyles = makeStyles((theme: Theme) => ({
  getStaticBox: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "-37px",
  },
  backgroundImg: {
    backgroundImage: "url(" + Testimonial + ")",
    padding: "100px 0",
    backgroundSize: "cover",
  },
  staticContentBoxDividerForDeskTop: {
    fontSize: "20px",
    width: "300px",
    fontWeight: 400,
    ...regularFont,
    color: "#EB6523",
  },
  staticContentBoxDividerForMobile: {
    fontSize: "16px",
    width: "300px",
    fontWeight: 400,
    ...regularFont,
    color: "#EB6523",
  },
  staticTextDeskTop: {
    fontSize: "48px",
    fontWeight: 900,
    color: "#1A3A6C",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ...boldFont,
  },
  staticTextMobile: {
    fontSize: "28px",
    fontWeight: 900,
    color: "#1A3A6C",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ...boldFont,
  },
  staticImgDeskTop: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  staticImgMobile: {
    width: "60%",
  },
  tabPersonNameDeskTop: {
    textAlign: "center",
    fontSize: "28px",
    fontWeight: 900,
    color: "#1A3A6C",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    ...regularFont,
  },
  tabPersonNameMobile: {
    textAlign: "center",
    fontSize: "20px",
    fontWeight: 900,
    color: "#1A3A6C",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    ...regularFont,
  },
  desktopCarousel: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    width: "70%",
  },
  mobileCarousel: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  tabDescriptionDeskTop: {
    fontSize: "28px",
    fontWeight: 400,
    color: "#283035",
    ...regularFont,
    textAlign: "center",
    marginBottom: "10px",
  },
  tabDescriptionMobile: {
    fontSize: "18px",
    fontWeight: 400,
    color: "#283035",
    ...regularFont,
    textAlign: "center",
    marginBottom: "10px",
  },
  getTestominalBox: {
    padding: "30px",
    borderRadius: "15px",
    width: "60%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  getTestominalTypo: {
    color: "#1A3A6C",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
}));

export default TestimonialsStyles;
