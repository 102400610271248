import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { boldFont } from "../../../../../../utils/styles";
import { regularFont } from "../../../../../../utils/styles";

const StopBangQuestionsStyles = makeStyles((theme: Theme) => ({
  customSwitch: {
    "& .MuiButtonBase-root": {
      padding: '9px',
      position: 'absolute',
      color: '#fff',
    },
    "& .Mui-checked": {
      color: '#1976d2',
    }
  },
}));

export default StopBangQuestionsStyles;
