import {
  Box,
  Button,
  Divider,
  Container,
  Grid,
  Typography,
  Paper,
  TextField,
  Checkbox,
} from "@mui/material";
import { CheckBox, Repeat } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import Header from "../../../shared/Header/Header";
import Footer from "../../../shared/Footer/Footer";
import Banner from "../../../shared/Banner/Banner";
import blogbanner from "../../../assets/images/Blog/blogbanner.png";
import { Link } from "react-router-dom";
import { regularFont } from "../../../utils/styles";
import Loading from "../../Loading/Loading";
import { userService } from "../../../services";
import history from "../../../utils/history";
import { isConstructorDeclaration } from "typescript";
import {
  openErrorNotification,
  openSuccessNotification,
} from "../../../helpers/methods";
import loading2 from "../../../assets/images/Login/loading2.gif";
import urls from "../../../global/constants/UrlConstants";
import productBaner from "../../../assets/images/Product/productBaner.png";
import { Metadata } from "../../../models/user.models";
import TextEditor from "./TextEditor/EditTextEditior";
import { convertFromRaw, convertToRaw } from "draft-js";

interface metadata {
  name: string;
  value: boolean;
}

interface product {
  id: string;
  code: string;
  description: string;
  name: string;
  vendor: {};
  type: string;
  status: string;
  unit: number;
  currency: string;
  unitPrice: number;
  comment: string;
  shippingCost: number;
  inStockQuantity: number;
  orderedQuantity: number;
  weight: number;
  lastOrderDate: string;
  productPictureByteArray: Array<string>;
  productPicture: string;
  metadata: Array<object>;
}

const EditProduct = (props) => {
  const [role, setRole] = useState("");
  const id = new URLSearchParams(props.location.search).get("id");
  const [product, setProduct] = useState<product>({
    id: "",
    code: "",
    description: "",
    name: "",
    vendor: {},
    type: "Equipment",
    status: "Product Created",
    unit: 0,
    currency: "$",
    unitPrice: 0,
    comment: "",
    shippingCost: 0,
    inStockQuantity: 0,
    orderedQuantity: 0,
    weight: 0,
    lastOrderDate: "",
    productPictureByteArray: [],
    productPicture: "",
    metadata: [] as metadata[],
  });

  const [vendor, setVendor] = useState({
    webSite: "",
  });

  const [getData, setGetData] = useState("");
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  const [profileImage, setProfileImage] = useState("");

  const [prescription, setPrescription] = useState(false);

  const imageChange = (event: any) => {
    let reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        var imagedata = reader.result;
        if (imagedata) {
          const dataformatted = imagedata;
          if (typeof dataformatted === "string") {
            setProfileImage(dataformatted);
          } else {
            setProfileImage(dataformatted.toString);
          }

          const csv = dataformatted.slice(22);
          if (typeof csv === "string") {
            setProduct({
              ...product,
              productPicture: csv,
            });
          } else {
            setProduct({
              ...product,
              productPicture: csv.toString(),
            });
          }
        }
      }
    };

    reader.readAsDataURL(event.target.files[0]);
  };

  const handleNameChange = (event: any) => {
    setProduct({
      ...product,
      name: event.target.value,
    });
  };

  const handleDescriptionChange = (description: any) => {
    setProduct({
      ...product,
      description: description,
    });
  };

  const handleCodeChange = (event: any) => {
    setProduct({
      ...product,
      code: event.target.value,
    });
  };

  const handleUnitChange = (event: any) => {
    setProduct({
      ...product,
      unit: event.target.value,
    });
  };

  const handleUnitPriceChange = (event: any) => {
    setProduct({
      ...product,
      unitPrice: event.target.value,
    });
  };

  const handleTypeChange = (event: any) => {
    setProduct({
      ...product,
      type: event.target.value,
    });
  };

  const handleCommentChange = (event: any) => {
    setProduct({
      ...product,
      comment: event.target.value,
    });
  };

  const handleWeightChange = (event: any) => {
    setProduct({
      ...product,
      weight: event.target.value,
    });
  };

  const handleInStockQuantityChange = (event: any) => {
    setProduct({
      ...product,
      inStockQuantity: event.target.value,
    });
  };

  const handleOrderedQuantityChange = (event: any) => {
    setProduct({
      ...product,
      orderedQuantity: event.target.value,
    });
  };

  const handleLastOrderDateChange = (event: any) => {
    setProduct({
      ...product,
      lastOrderDate: event.target.value,
    });
  };

  function format(input) {
    var array = (input || "").toString().split(/\-/g);
    array.push(array.shift());
    return array.join("/") || null;
  }

  const handleVendorChange = (event: any) => {
    setVendor({
      ...vendor,
      webSite: event.target.value,
    });
  };

  const handleSubmit = () => {
    setLoading(true);
    const metainfo: metadata = { name: "Prescription", value: prescription };
    if (product.metadata.length > 0) {
      product.metadata.map((metadata: any) => {
        if (metadata.name === "Prescription") {
          metadata.value = prescription;
        }
      });
    } else {
      product.metadata.push(metainfo);
    }
    product.vendor = vendor;
    product.lastOrderDate = format(product.lastOrderDate);

    userService.updateProduct(product).then(
      (response) => {
        history.push(urls.viewProduct);
        openSuccessNotification("product updated successfully");
      },
      (error: any) => {
        setLoading(false);

        var errorMsg = error.toString();
        openErrorNotification(errorMsg);
      }
    );
  };

  useEffect(() => {
    let username = JSON.parse(localStorage.getItem("current_user")!);
    if (username) {
      var role = username.role;
      setRole(role);
    }
    handleGetProductView(id);
  }, []);

  useEffect(() => {
    document.title = "Edit Product";
  }, []);

  const handleGetProductView = async (id) => {
    setDataLoading(true);
    await userService
      .getProductById(id)
      .then((response) => {
        setProduct({
          ...product,
          id: response.id,
          code: response.code,
          description: response.description,
          name: response.name,
          type: response.type,
          status: response.status,
          unit: response.unit,
          currency: response.currency,
          unitPrice: response.unitPrice,
          comment: response.comment,
          shippingCost: response.shippingCost,
          inStockQuantity: response.inStockQuantity,
          orderedQuantity: response.orderedQuantity,
          weight: response.weight,
          lastOrderDate: response.lastOrderDate,
          productPictureByteArray: response.productPictureByteArray,
          productPicture: response.productPicture,
          metadata: response.metadata,
        });
        setVendor({
          ...vendor,
          webSite: response.vendor.webSite,
        });
        response.metadata.map((data) => {
          console.log(typeof(data.value))
          
          if (data.name == "Prescription") {
            if(data.value==="true"){
              setPrescription(true);
            }else{
              setPrescription(false);
            }
            
          }
        });
        setDataLoading(false);
      })

      .catch((error) => {});
  };

  return (
    <>
      <Header />
      <Banner
        img={productBaner}
        header1="Home/ Store/ Edit Product"
        header2="Edit Product"
      />
      {dataLoading ? (
        <Loading />
      ) : (
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Box style={{ marginTop: "35px" }}>
            <Container maxWidth={false} style={{ marginBottom: "20px" }}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "30px",
                }}
              >
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <Button
                    style={{
                      width: "330px",
                      marginRight: "40px",
                      backgroundColor: "#1A3A6C",
                    }}
                    variant="contained"
                    component="label"
                  >
                    Upload Product Image
                    <input onChange={imageChange} hidden type="file" />
                  </Button>
                  {product.productPicture ? (
                    <Box>
                      {" "}
                      <img
                        style={{ width: "330px", height: "80px" }}
                        src={"data:image/png;base64," + product.productPicture}
                      />
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "30px",
                  justifyContent: "space-between",
                }}
              >
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <TextField
                    id="outlined-basic"
                    label="Name"
                    variant="outlined"
                    value={product.name}
                    onChange={handleNameChange}
                    placeholder="Name"
                    style={{ width: "330px", marginRight: "40px" }}
                  />
                </Box>
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <TextField
                    id="outlined-basic"
                    label="Code"
                    variant="outlined"
                    value={product.code}
                    onChange={handleCodeChange}
                    placeholder="Code"
                    style={{ width: "330px" }}
                  />
                </Box>
              </Box>{" "}
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "30px",
                }}
              >
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <TextField
                    id="outlined-basic"
                    label="Unit"
                    variant="outlined"
                    type="number"
                    value={product.unit}
                    onChange={handleUnitChange}
                    placeholder="Unit"
                    style={{ width: "330px", marginRight: "40px" }}
                  />
                </Box>
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <TextField
                    id="outlined-basic"
                    label="Unit Price"
                    type="number"
                    variant="outlined"
                    value={product.unitPrice}
                    onChange={handleUnitPriceChange}
                    placeholder="Unit Price"
                    style={{ width: "330px" }}
                  />
                </Box>
              </Box>{" "}
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "30px",
                }}
              >
                {/* <Box style={{ display: "flex", flexDirection: "column" }}>
                  <TextField
                    id="outlined-basic"
                    label="Type"
                    variant="outlined"
                    value={product.type}
                    onChange={handleTypeChange}
                    placeholder="Type"
                    style={{ width: "330px", marginRight: "40px" }}
                  />
                </Box> */}
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <TextField
                    id="outlined-basic"
                    label="Comment"
                    variant="outlined"
                    value={product.comment}
                    onChange={handleCommentChange}
                    placeholder="Comment"
                    style={{ width: "330px", marginRight: "40px" }}
                  />
                </Box>
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <TextField
                    id="outlined-basic"
                    label="Weight"
                    variant="outlined"
                    type="number"
                    value={product.weight}
                    onChange={handleWeightChange}
                    placeholder="Weight"
                    style={{ width: "330px" }}
                  />
                </Box>
              </Box>{" "}
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "30px",
                }}
              >
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <TextField
                    id="outlined-basic"
                    label="In Stock Quantity"
                    type="number"
                    variant="outlined"
                    value={product.inStockQuantity}
                    onChange={handleInStockQuantityChange}
                    placeholder="In Stock Quantity"
                    style={{ width: "330px", marginRight: "40px" }}
                  />
                </Box>
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <TextField
                    id="outlined-basic"
                    label="Order Quantity"
                    type="number"
                    variant="outlined"
                    value={product.orderedQuantity}
                    onChange={handleOrderedQuantityChange}
                    placeholder="Order Quantity"
                    style={{ width: "330px" }}
                  />
                </Box>
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "30px",
                }}
              >
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <TextField
                    id="outlined-basic"
                    label="Vendor"
                    variant="outlined"
                    value={vendor.webSite}
                    onChange={handleVendorChange}
                    placeholder="Vendor"
                    style={{ width: "330px" }}
                  />
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    width: "330px",
                  }}
                >
                  <Box>
                    <Checkbox
                      checked={prescription}
                      onChange={() => {
                        setPrescription(!prescription);
                      }}
                    ></Checkbox>{" "}
                  </Box>
                  <Box style={{ marginTop: "10px" }}>
                    <Typography>Prescription required</Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "30px",
                }}
              >
                
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "700px",
                    }}
                  >
                    <TextEditor
                      description={product?.description}
                      handleDescriptionChange={handleDescriptionChange}
                    />
                  </Box>
             
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "30px",
                }}
              >
                <Box>
                  <Button
                    onClick={handleSubmit}
                    // className={isDesktop ? classes.button : classes.buttonMobile}
                    style={{
                      width: "200px",
                      height: "60px",
                      border: "1px solid #283035",
                      borderRadius: "15px",
                      backgroundColor: "#1A3A6C",
                      color: "#ffffff",
                      marginRight: "300px",
                    }}
                  >
                    {loading ? (
                      <img
                        style={{ width: "50%", height: "100%" }}
                        src={loading2}
                      ></img>
                    ) : (
                      <Typography>Update Product</Typography>
                    )}
                  </Button>
                </Box>
                <Box>
                  <Link to="/products" style={{ textDecoration: "none" }}>
                    <Button
                      // className={isDesktop ? classes.button : classes.buttonMobile}
                      style={{
                        width: "200px",
                        height: "60px",
                        border: "1px solid #283035",
                        borderRadius: "15px",
                        backgroundColor: "#1A3A6C",
                        color: "#ffffff",
                        marginLeft: "0px",
                      }}
                    >
                      <Typography>Back to Products</Typography>
                    </Button>
                  </Link>
                </Box>
              </Box>
            </Container>
          </Box>
        </Box>
      )}

      <Footer />
    </>
  );
};

export default EditProduct;
