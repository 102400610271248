export const baseService = {
  serviceEndPoint,
  sleepTestUserServiceEndPoint,
  documentServiceEndPoint,
  getApp,
  getBaseUrl,
  getStripePublicKey,
};

function serviceEndPoint() {
//  return "http://localhost:6020/sat/v1";
   return "https://api.deepsleeptest.com/sat/v1";
}

function getStripePublicKey() {
  return "pk_live_51IEQUwHfokPNPBxWFstnSZPOQ6faZOMYiUp3hevmZvZU6IC4PKaaUs2EdaXXyV61exvP4V1QJSrzOdZW9lYHNLvG00R0eETAuQ";
}

function sleepTestUserServiceEndPoint() {
  return this.serviceEndPoint() + "/v1";
}

function documentServiceEndPoint() {
  return this.serviceEndPoint() + "/v1";
}
function getApp() {
  return "DST";
}
function getBaseUrl() {
  return window.location.origin;
}
