import Header from "../../../shared/Header/Header";
import Footer from "../../../shared/Footer/Footer";
import { connect } from "react-redux";
// import addright from "../../assets/images/Dashboard/addright.png";
import {
  Box,
  Button,
  Divider,
  Container,
  Grid,
  Typography,
  Paper,
} from "@mui/material";
import { Repeat } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import history from "../../../utils/history";
import urls from "../../../global/constants/UrlConstants";
import { openSuccessNotification,openErrorNotification } from "../../../helpers/methods";
import Banner from "../../../shared/Banner/Banner";
import { regularFont } from "../../../utils/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import { notifications } from "../../../helpers/notifications";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { boldFont } from "../../../utils/styles";
import { userService } from "../../../services/sleepTestUserService-service";
import CustomizedTables from "../../Dashboard/components/Table";
import OutlinedInput from "@mui/material/OutlinedInput";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { userActions } from "../../../redux/_actions/user.action";
import { alertActions } from "../../../redux/_actions/alert.actions";
import {
  FormControlLabel,
  Checkbox,
  useTheme,
  useMediaQuery,
  TextField,
} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { Link } from "react-router-dom";
import loading2 from "../../../assets/images/Login/loading2.gif";

// import BuyTestStyles from "../BuyTest/BuyTest.styles";
import { ToastContainer, toast } from "react-toastify";
import initialState from "../../../redux/initialState";

const AddUser = (props) => {
  const [eye, setEye] = useState(true);
  const [eye1, setEye1] = useState(true);
  const [stateList, setStateList] = useState([]);
  const [promoList, setPromoList] = useState([]);
  const [loading, setLoading] = useState(initialState.user.loading);
  const [cityList, setCityList] = useState([]);
  const [pinCodeList, setPinCodeList] = useState([]);
  const [isrole, setRole] = useState("");
  const [firstNameErr, setFirstNameErr] = useState(false);
  const [lastNameErr, setLastNameErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [add1Err, setAdd1Err] = useState(false);
  const [add2Err, setAdd2Err] = useState(false);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [user, setUser] = useState({
    id: "",
    role: "",
    email: "",
    pwd: "12345678",
    cpwd: "12345678",
    firstName: "",
    lastName: "",
    middleName: "",
    displayName: "",
    shippingAddress: {},
    billingAddress: {},
    primaryContactNumber: { number: "", extension: "" },
    secondaryContactNumber: {},
    promoCode: "",
    productCode: "ResMed",
    authToken: "string",
    status: "string",
  });
  const [billingAddress, setBillingAddress] = useState({
    line1: "",
    line2: "",
    line3: "",
    line4: "",
    city: "",
    state: "",
    pinCode: "",
    country: "United States of America",
  });

  const [primaryContactNumber, setPrimaryContactNumber] = useState({
    number: "",
    extension: "",
  });

  useEffect(() => {
    document.title = "Add User";
  }, []);


  const onChangeTextForBilling = (event: any) => {
    setLoading(false);
    setBillingAddress({
      ...billingAddress,
      [event.target.name]: event.target.value,
    });
    setAdd1Err(false);
    setAdd2Err(false);
  };

  const handleChangeState = (event: any) => {
    setLoading(false);
    setBillingAddress({
      ...billingAddress,
      state: event.target.value,
    });
  };

  const handleChangeCity = (event: any) => {
    setLoading(false);
    setBillingAddress({
      ...billingAddress,
      city: event.target.value,
    });
  };

  const handleChangeZipcode = (event: any) => {
    setLoading(false);
    setBillingAddress({
      ...billingAddress,
      pinCode: event.target.value,
    });
  };

  const handleRoleChange = (event: any) => {
    setLoading(false);
    setUser({
      ...user,
      role: event.target.value,
    });
  };

  const handlePromoChange = (event: any) => {
    setLoading(false);
    setUser({
      ...user,
      promoCode: event.target.value,
    });
  };

  const handleEmailChange = (event: any) => {
    setLoading(false);
    setUser({
      ...user,
      email: event.target.value,
    });
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (regex.test(event.target.value)) {
      setEmailErr(false);
    } else {
      setEmailErr(true);
    }
  };
  const handleFNameChange = (event: any) => {
    setLoading(false);
    setUser({
      ...user,
      firstName: event.target.value,
    });
    const regex = /^[A-Za-z]+$/;
    if (regex.test(event.target.value)) {
      setFirstNameErr(false);
    } else {
      setFirstNameErr(true);
    }
  };

  const onChangePhone = (event: any) => {
    setLoading(false);
    setPrimaryContactNumber({
      ...primaryContactNumber,
      number: event.target.value,
    });
  };

  const handleLNameChange = (event: any) => {
    setLoading(false);
    setUser({
      ...user,
      lastName: event.target.value,
    });
    const regex = /^[A-Za-z]+$/;
    if (regex.test(event.target.value)) {
      setLastNameErr(false);
    } else {
      setLastNameErr(true);
    }
  };
  // const handlePasswordChange = (event: any) => {
  //   setUser({
  //     ...user,
  //     pwd: event.target.value,
  //   });
  // };
  // const handleCPasswordChange = (event: any) => {
  //   setUser({
  //     ...user,
  //     cpwd: event.target.value,
  //   });
  // };

  useEffect(() => {
    if (billingAddress.state || billingAddress.city) {
      loadCity("United States of America", billingAddress.state);
      loadZipCode(
        "United States of America",
        billingAddress.state,
        billingAddress.city
      );
    }
    loadState("United States of America");
    loadPromo(user.productCode);
  }, ["United States of America", billingAddress.state, billingAddress.city]);

  useEffect(() => {
    let username = JSON.parse(localStorage.getItem("current_user")!);
    var isrole = username.role;
    setRole(isrole);
  }, []);

  const loadState = async (country: string) => {
    try {
      const response = await userService.getState(country);
      setStateList(response);
    } catch (error) {
      // notifications.openErrorNotification(error);
    }
  };

  const loadPromo = async (productCode: string) => {
    try {
      const response = await userService.getPromoOffers(productCode);
      const promo_object = response.offers;
      setPromoList(promo_object);
    } catch (error) {
      // notifications.openErrorNotification(error);
    }
  };

  const loadCity = async (country: string, state: string) => {
    try {
      const response = await userService.getCity(country, state);
      setCityList(response);
    } catch (error) {
      // notifications.openErrorNotification(error);
    }
  };

  const loadZipCode = async (country: string, state: string, city: string) => {
    try {
      const response = await userService.getZipcode(country, state, city);
      setPinCodeList(response);
    } catch (error) {
      // notifications.openErrorNotification(error);
    }
  };

  const handleSubmit = () => {
    user.billingAddress = billingAddress;
    user.primaryContactNumber = primaryContactNumber;
    if (user.email == "") {
      setEmailErr(true);
    }

    if (user.firstName == "") {
      setFirstNameErr(true);
    }
    if (user.lastName == "") {
      setLastNameErr(true);
    }
    if (billingAddress.line1 == "") {
      setAdd1Err(true);
    }
    if (billingAddress.line2 == "") {
      setAdd2Err(true);
    } else {
 
      setLoading(!loading);
      userService.adduser(user).then(
        (addUser) => {
         
          history.push(urls.viewUser);
          openSuccessNotification("User has been added successfully.");
        },
        (error: any) => {
          setLoading(false);
          var errorMsg = error.toString();
          openErrorNotification(errorMsg);
  
        }
      );
    }
  };

  return (
    <>
      <Header />
      <Box style={{ display: "flex", flexDirection: "row", justifyContent: 'center' }}>
        <Box style={{ marginTop: "35px", marginRight: "250px" }}>
          <Container
            maxWidth={false}
            style={{ marginBottom: "20px", width: "60%" }}
          >
            <Box>
              {isrole == "Admin" ? (
                <Box style={{ display: "flex", flexDirection: "row" }}>
                  <Box>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Role
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Role"
                        value={user.role}
                        onChange={handleRoleChange}
                        style={{ width: "330px", marginRight: "40px" }}
                      >
                        <MenuItem value="Patient">Patient</MenuItem>
                        <MenuItem value="Dentist">Dentist</MenuItem>
                        <MenuItem value="GeneralPhysician">
                          General Physician
                        </MenuItem>
                        <MenuItem value="Admin">Admin</MenuItem>
                        <MenuItem value="SleepPhysician">
                          Sleep Physician
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                  {user.role === "Patient" ? (
                    <Box>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label2">
                          Promo Code
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label2"
                          id="demo-simple-select2"
                          label="Promo Code"
                          value={user.promoCode}
                          onChange={handlePromoChange}
                          style={{ width: "330px" }}
                        >
                          {promoList.map((promo: any) => {
                            return (
                              <MenuItem value={promo.name}>
                                {promo.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
              ) : (
                <Box style={{ display: "flex", flexDirection: "row" }}>
                  <Box>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Role
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Role"
                        value={user.role}
                        onChange={handleRoleChange}
                        style={{ width: "330px", marginRight: "40px" }}
                      >
                        <MenuItem value="Patient">Patient</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                  {user.role === "Patient" ? (
                    <Box>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Promo Code
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Promo Code"
                          value={user.promoCode}
                          onChange={handlePromoChange}
                          style={{ width: "330px" }}
                        >
                          {promoList.map((promo: any) => {
                            return (
                              <MenuItem value={promo.name}>
                                {promo.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
              )}
            </Box>
            <Box style={{ marginTop: "30px" }}>
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                value={user.email}
                onChange={handleEmailChange}
                placeholder="Email"
                style={{ width: "700px" }}
              />
            </Box>
            {emailErr ? (
              <span style={{ color: "red" }}>Email Not Valid</span>
            ) : (
              ""
            )}
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "30px",
              }}
            >
              <Box style={{ display: "flex", flexDirection: "column" }}>
                <TextField
                  id="outlined-basic"
                  label="First Name"
                  variant="outlined"
                  placeholder="First Name"
                  value={user.firstName}
                  onChange={handleFNameChange}
                  style={{ width: "330px", marginRight: "40px" }}
                />
                {firstNameErr ? (
                  <span style={{ color: "red" }}>First Name not Valid</span>
                ) : (
                  ""
                )}
              </Box>

              <Box style={{ display: "flex", flexDirection: "column" }}>
                <TextField
                  id="outlined-basic"
                  label="Last Name"
                  placeholder="Last Name"
                  variant="outlined"
                  value={user.lastName}
                  onChange={handleLNameChange}
                  style={{ width: "330px" }}
                />
                {lastNameErr ? (
                  <span style={{ color: "red" }}>Last Name Not Valid</span>
                ) : (
                  ""
                )}
              </Box>
            </Box>
            {/* <Box style={{ display: "flex", flexDirection: "row" }}>
            <Box>
              <Typography>Password</Typography>
              <OutlinedInput
                type="password"
                value={user.pwd}
                onChange={handlePasswordChange}
              />
            </Box>
            <Box>
              <Typography>Confirm Password</Typography>
              <OutlinedInput
                type="password"
                value={user.cpwd}
                onChange={handleCPasswordChange}
              />
            </Box>
          </Box> */}
            <Box style={{ marginTop: "30px" }}>
              <TextField
                id="outlined-basic"
                label="Address Line 1"
                variant="outlined"
                type="text"
                name="line1"
                placeholder="Line1"
                value={billingAddress.line1}
                onChange={onChangeTextForBilling}
                style={{ width: "700px" }}
              />
            </Box>
            {add1Err ? (
              <span style={{ color: "red" }}>Address cannot be blank</span>
            ) : (
              ""
            )}
            <Box style={{ marginTop: "30px" }}>
              <TextField
                id="outlined-basic"
                label="Address Line 2"
                variant="outlined"
                type="text"
                name="line2"
                placeholder="Line2"
                value={billingAddress.line2}
                onChange={onChangeTextForBilling}
                style={{ width: "700px" }}
              />
            </Box>
            {add2Err ? (
              <span style={{ color: "red" }}>Address cannot be blank</span>
            ) : (
              ""
            )}
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "30px",
              }}
            >
              <Box>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">State</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="State"
                    value={billingAddress.state}
                    onChange={handleChangeState}
                    style={{ width: "330px", marginRight: "40px" }}
                  >
                    {stateList.map((state: any) => {
                      return <MenuItem value={state}>{state}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">City</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={billingAddress.city}
                    onChange={handleChangeCity}
                    label="City"
                    style={{ width: "330px" }}
                  >
                    {cityList.map((city: any) => {
                      return <MenuItem value={city}>{city}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "30px",
              }}
            >
              <Box>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Zip Code
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={billingAddress.pinCode}
                    onChange={handleChangeZipcode}
                    label="Zipcode"
                    style={{ width: "330px", marginRight: "40px" }}
                  >
                    {pinCodeList.map((pinCode: any) => {
                      return <MenuItem value={pinCode}>{pinCode}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <TextField
                  id="outlined-basic"
                  label="Phone"
                  variant="outlined"
                  type="text"
                  name="Phone"
                  placeholder="Phone"
                  value={primaryContactNumber.number}
                  onChange={onChangePhone}
                  style={{ width: "330px" }}
                />
              </Box>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "30px",
              }}
            >
              <Box>
                <Button
                  onClick={handleSubmit}
                  // className={isDesktop ? classes.button : classes.buttonMobile}
                  style={{
                    width: "200px",
                    height: "60px",
                    border: "1px solid #283035",
                    borderRadius: "15px",
                    backgroundColor: "#1A3A6C",
                    color: "#ffffff",
                    marginRight: "300px",
                  }}
                >
                  {loading ? (
                    <img
                      style={{ width: "50%", height: "100%" }}
                      src={loading2}
                    ></img>
                  ) : (
                    <Typography>Add User</Typography>
                  )}
                </Button>
              </Box>
              <Box>
                <Link to="/dashboard">
                  <Button

                    // className={isDesktop ? classes.button : classes.buttonMobile}
                    style={{
                      width: "200px",
                      height: "60px",
                      border: "1px solid #283035",
                      borderRadius: "15px",
                      backgroundColor: "#1A3A6C",
                      color: "#ffffff",
                      marginLeft: "0px",
                    }}
                  >
                    <Typography>Back to DashBoard</Typography>
                  </Button>
                </Link>
              </Box>
            </Box>
          </Container>
        </Box>
        <Box>
          {/* <img src={addright}></img> */}
        </Box>
      </Box>
      <Footer />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
      />
    </>
  );
};

const mapState = (state) => {
  const { authentication, alert, loggedIn } = state;
  return { authentication, alert, loggedIn };
};

const actionCreators = {
  addUser: userActions.addUser,
  clearAlerts: alertActions.clear,
};

export default connect(mapState, actionCreators)(AddUser);
