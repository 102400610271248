import { CardMedia, Grid, Typography, useMediaQuery } from "@mui/material";
import { CustomIcon } from "../../../../global/components";
//@ts-ignore
import DeepSleep from "../../../../assets/video/DeepSleep.mp4";
import loudSnoring from "../../../../assets/images/Symptoms/loudSnoring.png";
import walkingUp from "../../../../assets/images/Symptoms/walkingUp.png";
import dayTime from "../../../../assets/images/Symptoms/dayTime.png";
import wakingUp from "../../../../assets/images/Symptoms/wakingUp.png";
import MoodDisturbance from "../../../../assets/images/Symptoms/MoodDisturbance.png";
import IrregularHeartRhythm from "../../../../assets/images/Symptoms/IrregularHeartRhythm.png";
import RestlessSleep from "../../../../assets/images/Symptoms/RestlessSleep.png";
import MorningHeadache from "../../../../assets/images/Symptoms/MorningHeadache.png";
import WakingUpOften from "../../../../assets/images/Symptoms/WakingUpOften.png";
import TroubleThinking from "../../../../assets/images/Symptoms/TroubleThinking.png";
import ChronicFatigue from "../../../../assets/images/Symptoms/ChronicFatigue.png";
import ErectileSexualdysfunction from "../../../../assets/images/Symptoms/ErectileSexualdysfunction.png";
import SymptomsStyles from "./Symptoms.styles";
import { boldFont } from '../../../../utils/styles';
import { regularFont } from '../../../../utils/styles';
import { useTheme } from "@mui/system";

const symptoms = [
    {
        label: "Loud Snoring",
        image: <img src={loudSnoring} alt={"loudSnoring"} />
    }, {
        label: "Daytime Sleepiness",
        image: <img src={dayTime} alt={"dayTime"} />
    },
    {
        label: "Mood Disturbance",
        image: <img src={MoodDisturbance} alt={"MoodDisturbance"} />
    },
    {
        label: "Restless Sleep",
        image: <img src={RestlessSleep} alt={"RestlessSleep"} />
    },
    {
        label: "Chronic Fatigue",
        image: <img src={ChronicFatigue} alt={"ChronicFatigue"} />
    },
    {
        label: "Waking up often to urinate",
        image: <img src={WakingUpOften} alt={"WakingUpOften"} />
    },
    {
        label: "Waking up choking or gasping",
        image: <img src={walkingUp} alt={"walkingUp"} />
    },
    {
        label: "Waking up feeling unrested or groggy",
        image: <img src={wakingUp} alt={"wakingUp"} />
    },
    {
        label: "Irregular Heart Rhythm",
        image: <img src={IrregularHeartRhythm} alt={"IrregularHeartRhythm"} />
    },
    {
        label: "Morning Headache, Dry Mouth or Sore Throat",
        image: <img src={MorningHeadache} alt={"MorningHeadache"} />
    },
    {
        label: "Trouble thinking clearly or remembering things",
        image: <img src={TroubleThinking} alt={"TroubleThinking"} />
    },
    {
        label: "Erectile/Sexual dysfunction",
        image: <img src={ErectileSexualdysfunction} alt={"ErectileSexualdysfunction"} />
    },

]


const Symptoms = () => {
    const classes = SymptomsStyles()

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

    const getStaticText = () => {
        return <>
            <Typography className={
                isDesktop ? classes.desktopTitle : classes.mobileTitle
            }>Significant Symptoms</Typography>
        </>
    }

    const getSymptoms = () => {
        return <>
            <Grid container spacing={2} direction={isDesktop ? "row" : "column"}>
                <Grid item xs={4} sm={4} md={5} lg={5}>
                    {/* <CardMedia
                        component='iframe'
                        // className={classes.media}
                        image={DeepSleep}
                        autoPlay
                    /> */}
                    <CardMedia component="iframe" src={DeepSleep} allow="autoPlay" className={isDesktop ? classes.media : classes.mediaMobile} />
                </Grid>
                <Grid item xs={8} sm={8} md={7} lg={7}>
                    <Grid container>
                        {symptoms.map((symptom) => (
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <CustomIcon
                                    icon={symptom.image}
                                    text={<Typography style={{ marginLeft: "10px", fontSize: "18px", ...regularFont }}>{symptom.label}</Typography>}
                                    customClass={classes.symptonIcons}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>

        </>
    }

    const getBody = () => {
        return <>
            {getStaticText()}
            {getSymptoms()}
        </>

    }
    return getBody();

}

export default Symptoms;