import Header from "../../../shared/Header/Header";
import { connect } from "react-redux";
import {
  Box,
  Button,
  Divider,
  Container,
  Grid,
  Typography,
  Paper,
  FormControlLabel,
  Checkbox,
  useTheme,
  useMediaQuery,
  TextField,
  FormHelperText,
} from "@mui/material";
import React, { Component, useEffect } from "react";
import Upload from "../../../assets/images/icons/Upload.png";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import loading2 from "../../../assets/images/Login/loading2.gif";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Footer from "../../../shared/Footer/Footer";
import { boldFont } from "../../../utils/styles";
import { regularFont } from "../../../utils/styles";
import { Link, Link as RouterLink } from "react-router-dom";
import icon from "../../../assets/images/BuyTest/icon.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BuyTestStyles from "./BuyTest.styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import { userActions } from "../../../redux/_actions/user.action";
import { useState } from "react";
import { userService } from "../../../services/sleepTestUserService-service";
import { baseService } from "../../../services/base.service";
import { Select } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { Elements } from "@stripe/react-stripe-js";
import { documentPrescription } from "../../../redux/_actions";
import { addToCart } from "../../../redux/_actions";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
  openWarningNotification,
} from "../../../helpers/methods";
import CheckoutForm from "./components/CheckoutForm";
import Loading from "../../Loading/Loading";

import { loadStripe } from "@stripe/stripe-js";
import { deleteFromDocument } from "../../../redux/_actions";
import clsx from "clsx";
import lodash from "lodash";
import { ContactsOutlined } from "@mui/icons-material";
import { DocumentMetadata } from "../../../models/product.models";

const promise = loadStripe(baseService.getStripePublicKey());

const CheckOut = (props) => {
  const classes = BuyTestStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [pinCodeList, setPinCodeList] = useState([]);
  const [isPayment, setIsPayment] = useState(false);
  const [promoCode, setPromoCode] = useState("NA");
  const [promoCodeValue, setPromoCodeValue] = useState(0);
  const [dataIsLoading, setDataIsLoading] = useState(false);
  const [payableAmount, setPayableAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [netValue, setNetValue] = useState(0.0);
  const [paymentSecret, setPaymentSecret] = useState("");
  const [total, setTotal] = useState(175);
  const [userData, setUserData] = useState({
    billingAddress: {},
    shippingAddress: {},
  });
  const [showPaymentCard, setShowPaymentCard] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [orderdata, setOrder] = useState({
    id: "",
    customer: {
      id: "",
      displayId: "",
      type: "",
      subType: "",
      profilePicture: "",
      firstName: "",
      middleName: "",
      lastName: "",
      name: "",
      status: "Active",
      gender: "",
      dateOfBirth: "",
      hireDate: "",
      terminationDate: "",
      nationality: "",
      jobTitle: "",
      workStatus: "",
      managerId: "",
      managerName: "",
      managerEmail: "",
      mobileContactNumber: {
        number: "",
        extension: "",
        field: "",
        type: "",
      },
      homeContactNumber: {
        number: "",
        extension: "",
        field: "",
        type: "",
      },
      workContactNumber: {
        number: "",
        extension: "",
        field: "",
        type: "",
      },
      otherContactNumber: {
        number: "",
        extension: "",
        field: "",
        type: "",
      },
      primaryAddress: {
        type: "",
        line1: "",
        line2: "",
        line3: "",
        city: "",
        state: "",
        pinCode: "",
        country: "",
      },
      otherAddress: {
        type: "",
        line1: "",
        line2: "",
        line3: "",
        city: "",
        state: "",
        pinCode: "",
        country: "",
      },
      primaryEmail: {
        value: "",
        type: "",
      },
      secondaryEmail: {
        value: "",
        type: "",
      },
      primaryLegalIdentifier: {
        name: "",
        id: "",
        type: "",
        value: "",
      },
      metadata: [],
      comments: [],
      app: "",
    },
    customerJson: "",
    status: "",
    shippedDate: "",
    shippingCompany: {
      webSite: "",
    },
    orderItemsJson: "",
    comment: "",
    promotionCode: "",
    paymentSecret: "",
    totalAmount: 0,
    shippingCost: 0,
    shipmentId: "",
    shipment: {
      id: "",
      description: "",
      shippingCompanyName: "",
      shippingCompanyAddress: "",
      shippingCompanyEmail: "",
      shippingCompanyContact: "",
      account: "",
      config: {
        name: "",
        value: "",
      },
      configJson: "",
      weight: 0,
      weightUnit: 0,
    },
    metadata: [],
    shippingCompanyJson: "",
    orderItems: [],
  });


  useEffect(() => {
    document.title = "Checkout";
  }, []);

  const [billingAddress, setBillingAddress] = useState({
    line1: props.user.billingAddress?.line1
      ? props.user.billingAddress.line1
      : "",
    line2: props.user.billingAddress?.line2
      ? props.user.billingAddress.line2
      : "",
    city: props.user.billingAddress?.city ? props.user.billingAddress.city : "",
    state: props.user.billingAddress?.state
      ? props.user.billingAddress.state
      : "",
    pinCode: props.user.billingAddress?.pinCode
      ? props.user.billingAddress.pinCode
      : "",
    country: "United States of America",
  });

  const [billingAddressValidation, setBillingAddressValidation] = useState({
    line1: false,
    line2: false,
    city: false,
    state: false,
    country: false,
    pinCode: false,
  });
  const [shippingAddressValidation, setShippingAddressValidation] = useState({
    line1: false,
    line2: false,
    city: false,
    state: false,
    country: false,
    pinCode: false,
  });

  const [shippingAddress, setShippingAddress] = useState({
    line1: "" ? props.user.shippingAddress.line1 : "",
    line2: "" ? props.user.shippingAddress.line2 : "",
    city: "" ? props.user.shippingAddress.city : "",
    state: "" ? props.user.shippingAddress.state : "",
    pinCode: "" ? props.user.shippingAddress.pinCode : "",
    country: "United States of America",
  });

  const onDelete = async (d) => {
    const response = await userService.deletePrescription(d);
  
    props.deleteFromDocument(d);
    openSuccessNotification("Document Deleted Successfully");
  
  };

  const handleChangeState = (event: any) => {
    if (isTruthy(event.target.value)) {
      setBillingAddressValidation({
        ...billingAddressValidation,
        [event.target.name]: false,
      });
    } else {
      setBillingAddressValidation({
        ...billingAddressValidation,
        [event.target.name]: true,
      });
    }
    setBillingAddress({
      ...billingAddress,
      state: event.target.value,
    });
  };

  const handleChangeCity = (event: any) => {
    if (isTruthy(event.target.value)) {
      setBillingAddressValidation({
        ...billingAddressValidation,
        [event.target.name]: false,
      });
    } else {
      setBillingAddressValidation({
        ...billingAddressValidation,
        [event.target.name]: true,
      });
    }
    setBillingAddress({
      ...billingAddress,
      city: event.target.value,
    });
  };

  const handleChangeZipcode = (event: any) => {
    if (isTruthy(event.target.value)) {
      setBillingAddressValidation({
        ...billingAddressValidation,
        [event.target.name]: false,
      });
    } else {
      setBillingAddressValidation({
        ...billingAddressValidation,
        [event.target.name]: true,
      });
    }
    setBillingAddress({
      ...billingAddress,
      pinCode: event.target.value,
    });
  };
  const onChangeTextForBilling = (event: any) => {
    if (isTruthy(event.target.value)) {
      setBillingAddressValidation({
        ...billingAddressValidation,
        [event.target.name]: false,
      });
    } else {
      setBillingAddressValidation({
        ...billingAddressValidation,
        [event.target.name]: true,
      });
    }
    setBillingAddress({
      ...billingAddress,
      [event.target.name]: event.target.value,
    });
  };
  const onChangeTextForShipping = (event: any) => {
    if (isTruthy(event.target.value)) {
      setShippingAddressValidation({
        ...shippingAddressValidation,
        [event.target.name]: false,
      });
    } else {
      setShippingAddressValidation({
        ...shippingAddressValidation,
        [event.target.name]: true,
      });
    }
    setShippingAddress({
      ...shippingAddress,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeStateShipping = (event: any) => {
    if (isTruthy(event.target.value)) {
      setShippingAddressValidation({
        ...shippingAddressValidation,
        [event.target.name]: false,
      });
    } else {
      setShippingAddressValidation({
        ...shippingAddressValidation,
        [event.target.name]: true,
      });
    }
    setShippingAddress({
      ...shippingAddress,
      state: event.target.value,
    });
  };

  const handleChangeCityShipping = (event: any) => {
    if (isTruthy(event.target.value)) {
      setShippingAddressValidation({
        ...shippingAddressValidation,
        [event.target.name]: false,
      });
    } else {
      setShippingAddressValidation({
        ...shippingAddressValidation,
        [event.target.name]: true,
      });
    }
    setShippingAddress({
      ...shippingAddress,
      city: event.target.value,
    });
  };

  const handleChangeZipcodeShipping = (event: any) => {
    if (isTruthy(event.target.value)) {
      setShippingAddressValidation({
        ...shippingAddressValidation,
        [event.target.name]: false,
      });
    } else {
      setShippingAddressValidation({
        ...shippingAddressValidation,
        [event.target.name]: true,
      });
    }
    setShippingAddress({
      ...shippingAddress,
      pinCode: event.target.value,
    });
  };

  const onChangePromoCode = (event: any) => {
    setPromoCode(event.target.value);
  };

  useEffect(() => {
    document.title = "Buy Test";
    if (billingAddress.state || billingAddress.city) {
      if (billingAddress.state) {
        loadCity("United States of America", billingAddress.state);
      }
      if (billingAddress.state && billingAddress.city) {
        loadZipCode(
          "United States of America",
          billingAddress.state,
          billingAddress.city
        );
      }
    }
    loadState("United States of America");
  }, ["United States of America", billingAddress.state, billingAddress.city]);

  useEffect(() => {
    setUserData(props.user);
  }, []);

  interface addedItem {
    id: string;
    productName: string;
    productCode: string;
    quantity: number;
    actualUnitPrice: number;
    attachmentIds: Array<string>;
  }

  interface order {
    id: string;
    orderItems: Array<object>;
    customer: object;
    customerJson: string;
    status: string;
    shippedDate: string;
    shippingCompany: object;
    shippingCompanyJson: string;
    orderItemsJson: string;
    comment: string;
    promotionCode: string;
    paymentSecret: string;
    totalAmount: number;
    shippingCost: number;
    shipmentId: string;
    shipment: object;
    metadata: Array<object>;
  }

  const handleProductpayment = () => {
    if (handleAddressValidation()) {
      setUserData({
        ...userData,
        billingAddress: billingAddress,
      });
      setUserData({
        ...userData,
        shippingAddress: shippingAddress,
      });
      setIsLoading(true);
      let addedItems: object[] = [];
      props.items.map((product) => {
      
        let tempAttachmentIds: string[] = [];
        props.document.map((eachDoc) => {
          if (eachDoc.productId === product.id) {
            tempAttachmentIds.push(eachDoc.id);
          }
        });
        let addedItem: addedItem = {
          id: product.id,
          productName: product.name,
          productCode: product.code,
          quantity: product.quantity,
          actualUnitPrice: product.unitPrice,
          attachmentIds: tempAttachmentIds,
        };
        addedItems.push(addedItem);
       
      });
      var order: order = {
        id: "",
        customer: {
          id: "",
          displayId: "",
          type: "",
          subType: "",
          profilePicture: "",
          firstName: "",
          middleName: "",
          lastName: "",
          name: "",
          status: "Active",
          gender: "",
          dateOfBirth: "",
          hireDate: "",
          terminationDate: "",
          nationality: "",
          jobTitle: "",
          workStatus: "",
          managerId: "",
          managerName: "",
          managerEmail: "",
          mobileContactNumber: {
            number: "",
            extension: "",
            field: "",
            type: "",
          },
          homeContactNumber: {
            number: "",
            extension: "",
            field: "",
            type: "",
          },
          workContactNumber: {
            number: "",
            extension: "",
            field: "",
            type: "",
          },
          otherContactNumber: {
            number: "",
            extension: "",
            field: "",
            type: "",
          },
          primaryAddress: {
            type: "",
            line1: "",
            line2: "",
            line3: "",
            city: "",
            state: "",
            pinCode: "",
            country: "",
          },
          otherAddress: {
            type: "",
            line1: "",
            line2: "",
            line3: "",
            city: "",
            state: "",
            pinCode: "",
            country: "",
          },
          primaryEmail: {
            value: "",
            type: "",
          },
          secondaryEmail: {
            value: "",
            type: "",
          },
          primaryLegalIdentifier: {
            name: "",
            id: "",
            type: "",
            value: "",
          },
          metadata: [],
          comments: [],
          app: "",
        },
        customerJson: "",
        status: "",
        shippedDate: "",
        shippingCompany: {
          webSite: "",
        },
        orderItemsJson: "",
        comment: "",
        promotionCode: "",
        paymentSecret: "",
        totalAmount: 0,
        shippingCost: 0,
        shipmentId: "",
        shipment: {
          id: "",
          description: "",
          shippingCompanyName: "",
          shippingCompanyAddress: "",
          shippingCompanyEmail: "",
          shippingCompanyContact: "",
          account: "",
          config: {
            name: "",
            value: "",
          },
          configJson: "",
          weight: 0,
          weightUnit: 0,
        },
        metadata: [],
        shippingCompanyJson: "",
        orderItems: [],
      };
      order.orderItems.push(...addedItems);


      userService
        .createPaymentIntent1(order)
        .then((response) => {
        
          setOrder(response);
    
          handleOrderWithPrecreption();
          // setShowPaymentCard(true);
        })
        .catch((error) => {
          setIsLoading(false);
          openErrorNotification(error.toString());
        });
    } else {
      openErrorNotification("Please fill all the requried fildes.");
    }
  };

  const handelchekshpingAdd = (event: any) => {
    if (event.target.checked) {
      // const address = lodash.cloneDeep(billingAddress)
      setShippingAddress(billingAddress);
      setShippingAddressValidation({ ...billingAddressValidation });
    } else {
      setShippingAddress({
        line1: "",
        line2: "",
        city: "",
        state: "",
        pinCode: "",
        country: "United States of America",
      });
    }
  };

  const loadState = async (country: string) => {
    try {
      const response = await userService.getState(country);
      setStateList(response);
    } catch (error: any) {
      openErrorNotification(error.toString());
    }
  };

  const loadCity = async (country: string, state: string) => {
    try {
      const response = await userService.getCity(country, state);
      setCityList(response);
    } catch (error: any) {
      openErrorNotification(error.toString());
    }
  };

  const loadZipCode = async (country: string, state: string, city: string) => {
    try {
      const response = await userService.getZipcode(country, state, city);
      setPinCodeList(response);
    } catch (error: any) {
      openErrorNotification(error.toString());
    }
  };

  const handleOrderWithPrecreption = () => {
    let valid = true;
    props.items.map((product) => {
      if (
        product.metadata !== undefined ||
        product.metadata !== [] ||
        product.metadata !== null
      ) {
        product.metadata.map((meta) => {
          if (meta.name === "Prescription" && meta.value === "true") {
            const a = props.document.filter(
              (doc) => doc.productId === product.id
            );
            if (a.length === 0) {
              valid = false;
            }
          }
        });
      }
    });
    if (valid) {
      setShowPaymentCard(true);
    } else {
      openWarningNotification("Prescription Requried");
      setIsLoading(false);
    }
  };

  const handleAddressValidation = () => {
    let valid = true;
    let billingAddressValidation1 = billingAddressValidation;
    let shippingAddressValidation1 = shippingAddressValidation;
    for (const key in billingAddress) {
      if (isTruthy(billingAddress[key])) {
        billingAddressValidation1[key] = false;
      } else {
        billingAddressValidation1[key] = true;
        valid = false;
      }
    }
    for (const key in shippingAddress) {
      if (isTruthy(shippingAddress[key])) {
        shippingAddressValidation1[key] = false;
      } else {
        shippingAddressValidation1[key] = true;
        valid = false;
      }
    }
    setBillingAddressValidation({ ...billingAddressValidation1 });
    setShippingAddressValidation({ ...shippingAddressValidation1 });
    return valid;
  };

  const getCartValue = () => {
    let cartValue = 0;
    if (props.items.length) {
      cartValue = props.items.reduce((acc, obj) => {
        return acc + obj.totalPrice;
      }, 0);
    }
    return cartValue;
  };

  const uploadFileForPrescription = (event, productId, productName) => {
    const file = event.target.files[0];
    if (file) {
      setDataIsLoading(true);
      // this.setState({ loading: true });
      userService.uploadPrescription(file).then((response) => {
     
        if (response !== undefined) {
          response.productId = productId;
          response.productName = productName;
          props.document.push(response);
          props.documentPrescription(props.document);
        }
     

        // toast.success(
        //   "Prescription has been uploaded successfully.",
        //   { position: toast.POSITION.TOP_CENTER },
        //   {
        //     toastId: "customId",
        //   }
        // );
        // this.setState({ loading: false });
        setDataIsLoading(false);
      });
      event.preventDefault();
    }
  };

  const getAddressData = () => {
    return (
      <>
        <Grid
          direction="column"
          alignItems="start"
          marginLeft="30%"
          marginRight="40px"
          marginTop="40px"
        >
          <Typography
            variant="h5"
            mb={2}
            style={{ fontWeight: "700", color: "#283035" }}
          >
            Upload Prescription
          </Typography>
          {/* error */}
          {props.items.map((product) => (
            <Box>
              {product.metadata.map((meta, indexOuter) => {
                return meta.name === "Prescription" && meta.value === "true" ? (
                  <>
                    <Card style={{ marginBottom: "12px" }}>
                      <CardContent>
                        <div style={{ display: "flex" }}>
                          <Typography
                            sx={{ fontSize: 14 }}
                            color="#283035"
                            gutterBottom
                          >
                            <span>Prescription For {product.name}</span>
                          </Typography>
                        </div>
                        <Grid
                          container
                          style={{ justifyContent: "space-between" }}
                        >
                          <Grid
                            item
                            xs={6}
                            md={9}
                            sm={9}
                            style={{
                              backgroundColor: "#F9F9Ff",
                              marginRight: "6px",
                              padding: "3px 6px",
                              display: "flex",
                              alignItems: "center",
                              color: "#8B8B8B",
                            }}
                          >
                            {props.document
                              .filter(
                                (docdata) =>
                                  docdata.productName === product.name
                              )
                              .map((docM, indexInner) => {
                                return (
                                  <>
                                    <Typography>
                                      <span>{docM.title}</span>
                                    </Typography>

                                    <Button
                                      component="label"
                                      onClick={() => onDelete(docM)}
                                      style={{
                                        backgroundColor: "#1A3A6C",
                                        minWidth: "55px",
                                        height: "55px",
                                        marginLeft: "60px",
                                      }}
                                    >
                                      <DeleteIcon style={{color:"#fff"}} />
                                    </Button>
                                  </>
                                );
                              })}
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            md={2}
                            style={{ alignItems: "center" }}
                          >
                            <Button
                              component="label"
                              style={{
                                backgroundColor: "#1A3A6C",
                                minWidth: "55px",
                                height: "55px",
                                marginTop: "12px",
                              }}
                            >
                              <img src={Upload} alt="upload icon" />
                              <input
                                type="file"
                                accept="application/pdf"
                                hidden
                                onChange={(e) => {
                                  uploadFileForPrescription(
                                    e,
                                    product.id,
                                    product.name
                                  );
                                }}
                              />
                            </Button>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </>
                ) : null;
              })}
            </Box>
          ))}

          <Box>
            <Typography
              className={isDesktop ? classes.Typo1 : classes.Typo1Mobile}
            >
              Contact Information
            </Typography>
            <Typography
              style={{
                marginLeft: "15px",
                ...boldFont,
                fontSize: "20px",
                color: "#1A3A6C",
              }}
            >
              Email
            </Typography>

            <OutlinedInput
              className={isDesktop ? classes.Input1 : classes.Input1Mobile}
              type="text"
              name="email"
              // placeholder="Email Address"
              value={props.user.email}
            />
            <Typography
              className={isDesktop ? classes.Typo2 : classes.Typo2Mobile}
            >
              Billing Address
            </Typography>
            <FormControl variant="filled" sx={{ m: 1, width: "100%" }}>
              <Typography
                style={{
                  marginLeft: "15px",
                  ...boldFont,
                  fontSize: "20px",
                  color: "#1A3A6C",
                }}
              >
                Line 1
              </Typography>

              <OutlinedInput
                className={clsx(
                  isDesktop ? classes.Input2 : classes.Input2Mobile,
                  {
                    [classes.invalidField]: billingAddressValidation.line1,
                  }
                )}
                type="text"
                name="line1"
                // placeholder="Line1"
                value={billingAddress.line1}
                onChange={onChangeTextForBilling}
              />
              {billingAddressValidation.line1 && (
                <FormHelperText error>Line 1 is mandatory!</FormHelperText>
              )}
            </FormControl>
            <FormControl variant="filled" sx={{ m: 1, width: "100%" }}>
              <Typography
                style={{
                  marginLeft: "15px",
                  ...boldFont,
                  fontSize: "20px",
                  color: "#1A3A6C",
                }}
              >
                Line 2
              </Typography>

              <OutlinedInput
                className={clsx(
                  isDesktop ? classes.Input2 : classes.Input2Mobile,
                  {
                    [classes.invalidField]: billingAddressValidation.line2,
                  }
                )}
                type="text"
                name="line2"
                // placeholder="Line2"
                value={billingAddress.line2}
                onChange={onChangeTextForBilling}
              />
              {billingAddressValidation.line2 && (
                <FormHelperText error>Line 2 is mandatory!</FormHelperText>
              )}
            </FormControl>
            <FormControl variant="filled" sx={{ m: 1, width: "100%" }}>
              {/* <InputLabel id="demo-multiple-name-label">State</InputLabel> */}
              <Typography
                style={{
                  marginLeft: "15px",
                  ...boldFont,
                  fontSize: "20px",
                  color: "#1A3A6C",
                }}
              >
                State
              </Typography>

              <Select
                disableUnderline
                labelId="demo-multiple-name-label"
                id="demo-simple-select"
                className={clsx(
                  isDesktop ? classes.Input2 : classes.Input2Mobile,
                  {
                    [classes.invalidField]: billingAddressValidation.state,
                  }
                )}
                value={billingAddress.state}
                onChange={handleChangeState}
                label="State"
                name="state"
                style={{ width: "45%" }}
              >
                {stateList.map((state: any) => {
                  return <MenuItem value={state}>{state}</MenuItem>;
                })}
              </Select>
              {billingAddressValidation.state && (
                <FormHelperText error>State is mandatory!</FormHelperText>
              )}
            </FormControl>
            <FormControl variant="filled" sx={{ m: 1, width: "100%" }}>
              {/* <InputLabel id="demo-simple-select-filled-label">City</InputLabel> */}
              <Typography
                style={{
                  marginLeft: "15px",
                  ...boldFont,
                  fontSize: "20px",
                  color: "#1A3A6C",
                }}
              >
                City
              </Typography>

              <Select
                disableUnderline
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className={clsx(
                  isDesktop ? classes.Input2 : classes.Input2Mobile,
                  {
                    [classes.invalidField]: billingAddressValidation.city,
                  }
                )}
                value={billingAddress.city}
                onChange={handleChangeCity}
                label="City"
                name="city"
                style={{ width: "55%" }}
              >
                {cityList.map((city: any) => {
                  return <MenuItem value={city}>{city}</MenuItem>;
                })}
              </Select>
              {billingAddressValidation.city && (
                <FormHelperText error>City is mandatory!</FormHelperText>
              )}
            </FormControl>
            <FormControl variant="filled" sx={{ m: 1, width: "100%" }}>
              {/* <InputLabel id="demo-simple-select-filled-label">
              ZipCode

            </InputLabel> */}
              <Typography
                style={{
                  marginLeft: "15px",
                  ...boldFont,
                  fontSize: "20px",
                  color: "#1A3A6C",
                }}
              >
                Zip Code
              </Typography>

              <Select
                disableUnderline
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className={clsx(
                  isDesktop ? classes.Input2 : classes.Input2Mobile,
                  {
                    [classes.invalidField]: billingAddressValidation.pinCode,
                  }
                )}
                value={billingAddress.pinCode}
                onChange={handleChangeZipcode}
                label="Zipcode"
                name="pincode"
              >
                {pinCodeList.map((pinCode: any) => {
                  return <MenuItem value={pinCode}>{pinCode}</MenuItem>;
                })}
              </Select>
              {billingAddressValidation.pinCode && (
                <FormHelperText error>Zipcode is mandatory!</FormHelperText>
              )}
            </FormControl>

            <Typography
              className={isDesktop ? classes.Typo2 : classes.Typo2Mobile}
            >
              Shipping Address
            </Typography>
            <Box className={isDesktop ? classes.Box3 : classes.Box3Mobile}>
              <FormControlLabel
                control={
                  <Checkbox
                    value="allowExtraEmails"
                    color="primary"
                    onChange={handelchekshpingAdd}
                  />
                }
                label="Same as billing Address"
              />
            </Box>
            <FormControl variant="filled" sx={{ m: 1, width: "100%" }}>
              <Typography
                style={{
                  marginLeft: "15px",
                  ...boldFont,
                  fontSize: "20px",
                  color: "#1A3A6C",
                }}
              >
                Line 1
              </Typography>

              <OutlinedInput
                className={clsx(
                  isDesktop ? classes.Input2 : classes.Input2Mobile,
                  {
                    [classes.invalidField]: shippingAddressValidation.line1,
                  }
                )}
                type="text"
                name="line1"
                // placeholder="Line1"
                value={shippingAddress.line1}
                onChange={onChangeTextForShipping}
              />
              {shippingAddressValidation.line1 && (
                <FormHelperText error>Line 1 is mandatory!</FormHelperText>
              )}
            </FormControl>
            <FormControl variant="filled" sx={{ m: 1, width: "100%" }}>
              <Typography
                style={{
                  marginLeft: "15px",
                  ...boldFont,
                  fontSize: "20px",
                  color: "#1A3A6C",
                }}
              >
                Line 2
              </Typography>

              <OutlinedInput
                className={clsx(
                  isDesktop ? classes.Input2 : classes.Input2Mobile,
                  {
                    [classes.invalidField]: shippingAddressValidation.line2,
                  }
                )}
                type="text"
                name="line2"
                // placeholder="Line2"
                value={shippingAddress.line2}
                onChange={onChangeTextForShipping}
              />
              {shippingAddressValidation.line2 && (
                <FormHelperText error>Line 2 is mandatory!</FormHelperText>
              )}
            </FormControl>
            <FormControl variant="filled" sx={{ m: 1, width: "100%" }}>
              {/* <InputLabel id="demo-simple-select-label">State</InputLabel> */}
              <Typography
                style={{
                  marginLeft: "15px",
                  ...boldFont,
                  fontSize: "20px",
                  color: "#1A3A6C",
                }}
              >
                State
              </Typography>
              <Select
                disableUnderline
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className={clsx(
                  isDesktop ? classes.Input2 : classes.Input2Mobile,
                  {
                    [classes.invalidField]: shippingAddressValidation.state,
                  }
                )}
                value={shippingAddress.state}
                onChange={handleChangeStateShipping}
                label="State"
                placeholder="State"
                name="state"
                style={{ width: "55%" }}
              >
                {stateList.map((state: any) => {
                  return <MenuItem value={state}>{state}</MenuItem>;
                })}
              </Select>
              {shippingAddressValidation.state && (
                <FormHelperText error>State is mandatory!</FormHelperText>
              )}
            </FormControl>
            <FormControl variant="filled" sx={{ m: 1, width: "100%" }}>
              {/* <InputLabel id="demo-simple-select-filled-label">City</InputLabel> */}
              <Typography
                style={{
                  marginLeft: "15px",
                  ...boldFont,
                  fontSize: "20px",
                  color: "#1A3A6C",
                }}
              >
                City
              </Typography>

              <Select
                disableUnderline
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className={clsx(
                  isDesktop ? classes.Input2 : classes.Input2Mobile,
                  {
                    [classes.invalidField]: shippingAddressValidation.city,
                  }
                )}
                value={shippingAddress.city}
                onChange={handleChangeCityShipping}
                label="City"
                name="city"
                style={{ width: "55%" }}
              >
                {cityList.map((city: any) => {
                  return <MenuItem value={city}>{city}</MenuItem>;
                })}
              </Select>
              {shippingAddressValidation.city && (
                <FormHelperText error>City is mandatory!</FormHelperText>
              )}
            </FormControl>
            <FormControl variant="filled" sx={{ m: 1, width: "100%" }}>
              {/* <InputLabel id="demo-simple-select-filled-label">
              ZipCode
            </InputLabel> */}
              <Typography
                style={{
                  marginLeft: "15px",
                  ...boldFont,
                  fontSize: "20px",
                  color: "#1A3A6C",
                }}
              >
                Zip Code
              </Typography>

              <Select
                disableUnderline
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className={clsx(
                  isDesktop ? classes.Input2 : classes.Input2Mobile,
                  {
                    [classes.invalidField]: shippingAddressValidation.pinCode,
                  }
                )}
                value={shippingAddress.pinCode}
                onChange={handleChangeZipcodeShipping}
                label="Zipcode"
                name="pinCode"
              >
                {pinCodeList.map((pinCode: any) => {
                  return <MenuItem value={pinCode}>{pinCode}</MenuItem>;
                })}
              </Select>
              {shippingAddressValidation.pinCode && (
                <FormHelperText error>Zipcode is mandatory!</FormHelperText>
              )}
            </FormControl>
          </Box>

          <Box
            style={{
              display: "flex",
              alignItems: "center",
              margin: "80px 0px",
            }}
          >
            <Grid item md={6}>
              <Link to="/products" style={{ textDecoration: "none" }}>
                <Button
                  style={{
                    backgroundColor: "#1A3A6C",
                    borderRadius: "15px",
                    padding: "6px 12px",
                  }}
                >
                  <span style={{ color: "#ffffff" }}>Back To Products</span>
                </Button>
              </Link>
            </Grid>
            <Grid item md={6} style={{ alignItems: "center" }}>
              <Link to="/cart" style={{ textDecoration: "none" }}>
                <Box
                  style={{
                    color: "#8B8B8B",
                    fontSize: "14px",
                    fontWeight: "400",
                    display: "flex",
                    alignItems: "center",
                    float: "right",
                  }}
                >
                  <KeyboardBackspaceIcon
                    style={{ marginRight: "6px", color: "#1A3A6C" }}
                  />
                  <span style={{ color: "#1A3A6C" }}>Return To cart</span>
                </Box>
              </Link>
            </Grid>
          </Box>
        </Grid>
      </>
    );
  };

  const getStripePaymentCard = () => {
    return (
      <Box
        style={{
          margin: "0 50px",
          border: "1px solid #eee",
          padding: "5px",
          borderRadius: "15px",
          width: "60%",
        }}
      >
        {showPaymentCard === false ? (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Button
              style={{
                width: "200px ",
                height: "50px ",
                border: "1px solid #283035 ",
                borderRadius: "15px ",
                backgroundColor: "#1A3A6C ",
                color: "#ffffff ",
                justifyContent: "center",
                alignContent: "center",
                display: "flex",
              }}
              onClick={handleProductpayment}
            >
              {isLoading ? (
                <img
                  style={{ width: "30%", height: "100%" }}
                  src={loading2}
                ></img>
              ) : (
                <> {"Proceed To Pay"}</>
              )}
            </Button>
          </Box>
        ) : (
          ""
        )}

        {showPaymentCard ? (
          <Elements stripe={promise}>
            <CheckoutForm
              clientSecret={orderdata.paymentSecret}
              user={userData}
              address={shippingAddress}
              cartData={props.items}
              order={orderdata}
              handleAddressValidation={handleAddressValidation}
              totalAmount={orderdata.totalAmount}
            />
          </Elements>
        ) : (
          ""
        )}
      </Box>
    );
  };

  const getPaymentCardStaticDetail = () => {
    return (
      <Box>
        {props.items.map((items) => {
          return (
            <>
              <Box className={isDesktop ? classes.Box6 : classes.Box6Mobile}>
                <Box className={isDesktop ? classes.Box7 : classes.Box7Mobile}>
                  <img
                    src={`data:image/png;base64,${items.productPicture}`}
                    height={80}
                    width={80}
                  />
                  <Typography className={classes.Typo3}>
                    {items.name}
                  </Typography>
                </Box>
                <Box className={isDesktop ? classes.Box8 : classes.Box8Mobile}>
                  <Typography className={classes.Typo4}>
                    ${items.totalPrice}
                  </Typography>
                </Box>
              </Box>
            </>
          );
        })}

        <Divider className={classes.Divider1} />

        {/* <Box className={isDesktop ? classes.Box9 : classes.Box9Mobile}>
          <OutlinedInput
            className={classes.Input3}
            type="text"
            name="email"
            placeholder="Promo Code"
            onChange={onChangePromoCode}
          />

          <Button className={classes.Button3} onClick={() => submitPromoCode()}>
            APPLY
          </Button>
        </Box> */}

        <Box className={classes.Box10}>
          <Box className={classes.Box11}>
            <Typography className={classes.Typo6}>Sub Total</Typography>
          </Box>
          <Box className={classes.Box12}>
            <Typography className={classes.Typo5}>${getCartValue()}</Typography>
          </Box>
        </Box>
        <Box className={classes.Box13}>
          <Box className={classes.Box14}>
            <Typography className={classes.Typo6}>Shipping</Typography>
          </Box>
          <Box className={classes.Box15}>
            <Typography className={classes.Typo5}>$30</Typography>
          </Box>
        </Box>
        {promoCodeValue ? (
          <Box className={classes.Box16}>
            <Box className={classes.Box17}>
              <Typography className={classes.Typo6}>Discount</Typography>
            </Box>
            <Box className={classes.Box18}>
              <Typography className={classes.Typo5}>
                -${promoCodeValue}
              </Typography>
            </Box>
          </Box>
        ) : null}

        <Divider className={classes.Divider2} />
        <Box className={classes.Box19}>
          <Box className={classes.Box20}>
            <Typography className={classes.Typo7}>Total</Typography>
          </Box>
          <Box className={classes.Box21}>
            <Typography className={classes.Typo7}>
              ${getCartValue() + 30}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Header />
      {dataIsLoading ? (
        <Loading />
      ) : (
        <Box display="flex" className={isDesktop ? "" : classes.BoxForMobile}>
          <Box
            style={{
              backgroundColor: "#F9F9FF",
              flex: 1,
              height: "800px",
              overflow: "auto",
              overflowX: "hidden",
            }}
          >
            {isPayment ? "" : getAddressData()}
          </Box>
          <Box style={{ backgroundColor: "#ffff", flex: 1 }}>
            {getPaymentCardStaticDetail()}
            {getStripePaymentCard()}
          </Box>
        </Box>
      )}

      <Footer />
    </>
  );
};

function mapState(state) {
  const { authentication, shopReducer, documentPrescriptionReducer } = state;
  const { user } = authentication;
  const { items } = shopReducer;
  const { document } = documentPrescriptionReducer;
  return {
    user,
    items,
    document,
  };
}
const actionCreators = {
  documentPrescription: documentPrescription,
  addToCart: addToCart,
  deleteFromDocument: deleteFromDocument,
};

export default connect(mapState, actionCreators)(CheckOut);
