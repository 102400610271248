import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { userService } from "../../../../services/sleepTestUserService-service";
import { toast } from "react-toastify";
// import { notifications } from "../_helper/notifications";
// import { Order } from "../_model/product.models";
import history from "../../../../utils/history";
import {
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "../../../../helpers/methods";
import { clearDocumentPrescription } from "../../../../redux/_actions/index";
import { connect } from "react-redux";
import loading2 from "../../../../assets/images/Login/loading2.gif";

import {
  Box,
  Button,
  Typography,
  Paper,
} from "@mui/material";

function CheckoutForm(props) {
  const [succeeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
const [isLoading, setIsLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async (ev) => {
    setIsLoading(true);
    ev.preventDefault();
    // setProcessing(true);
    if (stripe !== null && elements !== null) {
    const payload = await stripe.confirmCardPayment(props.clientSecret, {
      receipt_email: props.user.email,
      payment_method: {
        card: elements.getElement(CardElement)!,
      },
      setup_future_usage: "off_session",
    });
    if (payload.error) {
      setIsLoading(false);
      openErrorNotification(payload.error.message);
      userService.purchaseFailed(props.user);
    } else {
      userService
        .executePaymentSuccessFlowForECommerce(props.order, props.user)
        .then((response) => {
          openSuccessNotification("Order Placed Successfully")
          props.clearDocumentPrescription();
          history.push("/orderDetails?id=" + response.id);
        })
        .catch((error) => {
          openErrorNotification(error.toString());
        });
      props.cartData.splice(0, props.cartData.length);

      setError(null);
      // setProcessing(false);

      // make cart empty ,total 0

      // setSucceeded(true);
      // toast.success(
      //   "Your order has been placed successfully",
      //   { position: toast.POSITION.TOP_CENTER },
      //   {
      //     toastId: "customId",
      //   }
      // );
     
    }
  }
  };

  return (
    <form id="payment-form">
            <Box style={{ border: "1px solid #eee", padding: "10px", borderRadius: "15px", marginBottom: "10px" }}>
                <CardElement
                    id="card-element"
                    options={cardStyle}
                    onChange={handleChange}
                />
            </Box>
            <Box style={{
                justifyContent: "center",
                alignContent: 'center',
                display: "flex",
                top: "20px ",
            }}>
                <Button
                    style={{
                        marginTop: "20px ",
                        width: "200px ",
                        height: "50px ",
                        border: "1px solid #283035 ",
                        borderRadius: "15px ",
                        backgroundColor: "#1A3A6C ",
                        color: "#ffffff ",
                        justifyContent: "center",
                        alignContent: 'center',
                        display: "flex"
                    }}
                    onClick={(event) => handleSubmit(event)}
                >
                    {isLoading ? (
                        <img
                            style={{ width: "30%", height: "100%" }}
                            src={loading2}
                        ></img>
                    ) : (
                        <> {"Pay"}</>
                    )}
                </Button>
            </Box>
        </form>
    );
}

function mapState(state) {}

const actionCreators = {
  clearDocumentPrescription: clearDocumentPrescription,
};

export default connect(mapState, actionCreators)(CheckoutForm);
