import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const profileUpdateStyles = makeStyles((theme: Theme) => ({
  tabStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 0",
    width: "100%",
  },
  tabStyleBox: {
    borderRadius: "15px 0 0 15px",
  },
  active: {
    backgroundColor: "white",
    borderRadius: "15px 0 0 15px",
  },
  personalDetails: {
    display: "flex",
    flexDirection: "column",
    marginTop: "2%",
    justifyContent: "flex-start",
    marginLeft: "5%",
    marginBottom:"30px"
  },
  personalDetailsMobile: {
    display: "flex",
    flexDirection: "column",
    marginTop: "2%",
    alignItems: "center",
  },
  box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
    marginTop: "20px",
  },
  boxMobile: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
    marginLeft: "20px",
  },
}));

export default profileUpdateStyles;
