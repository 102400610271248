import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DownloadReport from "../DownloadReport/DownloadReport";
import { regularFont } from "../../../../../utils/styles";
import { withStyles } from "@mui/styles";
import PreliminaryAssessmentTableStyles from "./PreliminaryAssessmentTable.styles";

interface CustomProps {
    tableData: any[];
}

export default function PreliminaryAssessmentTable(props: CustomProps) {
    const classes = PreliminaryAssessmentTableStyles();
    return (
        <TableContainer>
            <Table size="small" style={{ borderBottom: "none" }}>
                <TableBody>
                    {props.tableData.map((row: any) => (
                        <TableRow >
                            <TableCell style={{ borderBottom: "none", color: "#fff" }}>{row.title}</TableCell>
                            <TableCell
                                style={{
                                    color: "#ffffff",
                                    fontSize: "20px",
                                    fontWeight: 400,
                                    ...regularFont,
                                    marginRight: "30px",
                                    borderBottom: "none"
                                }}
                            >
                                {row.name}
                            </TableCell>
                            <TableCell style={{ borderBottom: "none" }}>
                                <DownloadReport
                                    fileStoragePath={row.path}
                                    app="deepsleeptest"
                                    ownerId={row.ownerId}
                                    status={row.status}
                                ></DownloadReport>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
