import { baseService } from "../services/base.service";
import { service } from "../services/service";

export const userService = {
  login,
  resetUserPwd,
  updatePwd,
  verifyTempPwd,
  getLoggedInUserAccount,
  getUsersByApp,
  getRoleUser,
  adduser,
  getLoggedInUserId,
  getLoggedInUser,
  deleteUser,
  getUserById,
  upsertUser,
  getLoggedInUserRole,
  register,
  getState,
  getCity,
  getZipcode,
  getCurrentApp,
  getPromoOffers,
  executePaymentSuccessFlow,
  purchaseFailed,
  createPaymentIntent,
  updateUserCustomAttributes,
  riskEmail,
  getMyOrders,
  updateProfile,
  getPromoCode,
  getpatients,
  getUser,
  getReport,
  uploadFinalReport,
  downloadReport,
  deleteReport,
  equipmentShipped,
  equipmentRecieved,
  productShipped,
  productDelivered,
  getOrder,
  getSleepPhysicians,
  insertVisitor,
  //product
  getProductsByType,
  getProductById,
  uploadPrescription,
  createPaymentIntent1,
  executePaymentSuccessFlowForECommerce,
  deletePrescription,
  addProduct,
  updateProduct
};



function executePaymentSuccessFlowForECommerce(order,user) {
  let eCommerceOrder={
    user:user,
    order:order,
  }
  let url = baseService.serviceEndPoint() + "/payment/success";
  return service.fetchData(url, "POST", true, eCommerceOrder);
}

function uploadPrescription(file) {
  var formData = new FormData();
  formData.append("file", file);
  let url = baseService.serviceEndPoint() + "/prescription";
  return service.fetchData(url, "POST", true, formData, true);
}

function adduser(user) {
  let url = baseService.serviceEndPoint() + "/create";
  return service.fetchData(url, "POST", true, user);
}

function updateProfile(user) {
  let url = baseService.serviceEndPoint() + "/sleepTestUser";
  return service.fetchData(url, "PATCH", true, user);
}

function getOrder(id) {
  let url = baseService.serviceEndPoint() + "/order/" + id;
  return service.fetchData(url, "GET", true, {});
}

function login(user) {
  let url = baseService.serviceEndPoint() + "/user/login";
  return service.fetchData(url, "POST", false, user);
}

function register(user) {
  let url = baseService.serviceEndPoint() + "/patient/register";
  return service.fetchData(url, "POST", false, user);
}

function riskEmail(user) {
  let url = baseService.serviceEndPoint() + "/freeAssessment/email";
  return service.fetchData(url, "POST", false, user);
}

function getSleepPhysicians() {
  let url = baseService.serviceEndPoint() + "/sleepPhysicians";
  return service.fetchData(url, "GET", true, {});
}

function resetUserPwd(user) {
  let url = baseService.serviceEndPoint() + "/pwd/reset";
  return service.fetchData(url, "POST", false, user);
}

function getCurrentApp() {
  let currentApp = {};
  currentApp.baseUrl = window.location.origin;
  currentApp.name = baseService.getApp();
  return currentApp;
}

function equipmentShipped(user) {
  let url = baseService.serviceEndPoint() + "/equipment/shipped";
  return service.fetchData(url, "POST", true, user);
}


function productShipped(orderId,trackingId) {
  let url = baseService.serviceEndPoint() + "/order/shipped/"+orderId+"/"+trackingId;
  console.log(url);
  return service.fetchData(url, "POST", true);
}

function productDelivered(orderId,trackingId) {
  let url = baseService.serviceEndPoint() + "/order/delivered/"+orderId
  console.log(url);
  return service.fetchData(url, "POST", true);
}

function equipmentRecieved(user) {
  let url = baseService.serviceEndPoint() + "/equipment/received";
  return service.fetchData(url, "POST", true, user);
}

function updatePwd(user) {
  let url = baseService.serviceEndPoint() + "/pwd/update";
  return service.fetchData(url, "POST", true, user);
}
function verifyTempPwd(user) {
  let app = baseService.getApp();
  let url = baseService.serviceEndPoint() + "/pwd/verify";
  return service.fetchData(url, "POST", false, user);
}

async function getLoggedInUserAccount() {
  let user;
  if (localStorage.getItem("current_user")) {
    user = JSON.parse(localStorage.getItem("current_user"));
  }
  return user.account;
}

function getUsersByApp() {
  let url = baseService.serviceEndPoint() + "/users";
  return service.fetchData(url, "GET", true, {});
}

function getRoleUser() {
  let url = baseService.serviceEndPoint() + "/roles";
  return service.fetchData(url, "GET", true, {});
}

function upsertUser(user) {
  let url = baseService.serviceEndPoint() + "/user";
  return service.fetchData(url, "PATCH", true, user);
}

function getLoggedInUserId() {
  let user = this.getLoggedInUser();
  if (user) {
    return user.email;
  }
  return undefined;
}

function getLoggedInUser() {
  if (
    localStorage.getItem("current_user") !== "undefined" &&
    localStorage.getItem("current_user") !== ""
  ) {
    return JSON.parse(localStorage.getItem("current_user"));
  }
}

function deleteUser(user) {
  let url = baseService.serviceEndPoint() + "/user/delete";
  return service.fetchData(url, "POST", true, user);
}

function getUserById(id) {
  let url = baseService.serviceEndPoint() + "/user/" + id;
  return service.fetchData(url, "GET", true, {});
}

function getLoggedInUserRole() {
  let user = getLoggedInUser();
  if (user) {
    return user.role;
  }
  return undefined;
}

function getState(country) {
  let url = baseService.serviceEndPoint() + "/location/country/" + country;
  return service.fetchData(url, "GET", true, {});
}

function getCity(country, state) {
  let url =
    baseService.serviceEndPoint() +
    "/location/country/" +
    country +
    "/state/" +
    state;
  return service.fetchData(url, "GET", true, {});
}

function getZipcode(country, state, city) {
  let url =
    baseService.serviceEndPoint() +
    "/location/country/" +
    country +
    "/state/" +
    state +
    "/city/" +
    city;
  return service.fetchData(url, "GET", true, {});
}

function getPromoOffers(productCode) {
  let url = baseService.serviceEndPoint() + "/product/code/" + productCode;
  return service.fetchData(url, "GET", true, {});
}
function executePaymentSuccessFlow(user) {
  let url = baseService.serviceEndPoint() + "/payment/success/ResMed";
  return service.fetchData(url, "POST", true, user);
}

function purchaseFailed(user) {
  let url = baseService.serviceEndPoint() + "/payment/failure";
  return service.fetchData(url, "POST", true, user);
}

function createPaymentIntent(productCode, promotionCode) {
  let url =
    baseService.serviceEndPoint() +
    "/payment/product/" +
    productCode +
    "/promotion/" +
    promotionCode;
  return service.fetchData(url, "POST", true, {});
}

function createPaymentIntent1(data) {
  let url = baseService.serviceEndPoint() + "/payment";
  return service.fetchData(url, "POST", true, data);
}

function updateUserCustomAttributes(user) {
  let url = baseService.serviceEndPoint() + "/user/custom";
  return service.fetchData(url, "PATCH", true, user);
}

function getMyOrders() {
  let url = baseService.serviceEndPoint() + "/orders";
  return service.fetchData(url, "GET", true, {});
}

function getPromoCode(code, offer) {
  let url =
    baseService.serviceEndPoint() + "/product/code/" + code + "/offer/" + offer;
  return service.fetchData(url, "GET", true, {});
}

function getpatients() {
  let url = baseService.serviceEndPoint() + "/patients";
  return service.fetchData(url, "GET", true, {});
}

function getUser(id) {
  let url = baseService.serviceEndPoint() + "/user/" + id;
  return service.fetchData(url, "GET", true, {});
}

function getReport(id) {
  let url = baseService.serviceEndPoint() + "/reports/" + id;
  return service.fetchData(url, "GET", true, {});
}

function uploadFinalReport(file, id, name) {
  var formData = new FormData();
  formData.append("file", file, file.name);
  let url =
    baseService.serviceEndPoint() + "/report/id/" + id + "/name/" + name;
  return service.fetchData(url, "POST", true, formData, true);
}

function downloadReport(document) {
  let url = baseService.serviceEndPoint() + "/report/download";
  return service.fetchDocument(url, "POST", document);
}

function deleteReport(document) {
  let url = baseService.serviceEndPoint() + "/document";
  return service.fetchData(url, "DELETE", true, document);
}

function deletePrescription(document) {
  let url = baseService.serviceEndPoint() + "/deleteDocumentPrescription";
  return service.fetchData(url, "DELETE", true, document);
}



function insertVisitor(visitor) {
  let url = baseService.serviceEndPoint() + "/visitor";
  return service.fetchData(url, "POST", true, visitor);
}

//product

function getProductsByType() {
  let url =
    baseService.serviceEndPoint() + "/public/products/type?name=" + "Equipment";
  return service.fetchData(url, "GET", true, {});
}

function getProductById(id) {
  let url = baseService.serviceEndPoint() + "/public/product/id?id=" + id;
  return service.fetchData(url, "GET", true, {});
}

function addProduct(product) {
  let url = baseService.serviceEndPoint() + "/product" ;
  return service.fetchData(url, "POST", true, product);
}

function updateProduct(product) {
  let url = baseService.serviceEndPoint() + "/product" ;
  return service.fetchData(url, "PATCH", true, product);
}