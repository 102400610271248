import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { boldFont } from "../../../../../../utils/styles";
import { regularFont } from "../../../../../../utils/styles";

const EpworthSleepinessStyles = makeStyles((theme: Theme) => ({
  buttonGroup: {
    padding: "5px 14px !important",
    backgroundColor: "#F7E1DB",
    borderRadius: "15px !important",
  },
  numScaleBtn: {
    backgroundColor: "white !important",
    margin: "5px !important",
    borderRadius: "50% !important",
    "&:hover": {
      backgroundColor: "#EB6523 !important",
    },
  },
  activeBtn: {
    backgroundColor: "#EB6523 !important",
  },
  activeBtn1: {
    backgroundColor: "#EB6523 !important",
  },

  cards: {
    // backgroundColor: "#eee",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 0",
    paddingBottom: "20px",
    borderRadius: "3% !important",
    width: '100%',
  },
}));

export default EpworthSleepinessStyles;
