import { Badge, Box, Divider, Grid, Paper, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import TestStagesStepsStyles from "./TestStagesSteps.styles";
import { CustomIcon } from "../../../../global/components";
import orderKit from "../../../../assets/images/SleeptestStep/orderKit.svg";
import takeTest from "../../../../assets/images/SleeptestStep/takeTest.svg";
import report from "../../../../assets/images/SleeptestStep/report.svg";
import returnTest from "../../../../assets/images/SleeptestStep/returnTest.svg";
import { boldFont } from '../../../../utils/styles';
import { regularFont } from '../../../../utils/styles';

const data = [
    {
        image: <img src={orderKit} alt={"orderKit"} />,
        title1: "Order the Home",
        title2: "Sleep Test Kit.",
        description1: "Do you suspect having Sleep Apnea but",
        description2: "are not willing to spend a night in a",
        description3: "medical facility? Get tested today with",
        description4: "home diagnostic equipment!"
    },
    {
        image: <img src={takeTest} alt={"takeTest"} />,
        title1: "Take the",
        title2: "test",
        description1: "The Home Sleep Test Kit comes with",
        description2: "easy-to-understand instructions",
        description3: "that should be followed accordingly",
        description4: "as per your nighttime schedule.",
    },
    {
        image: <img src={report} alt={"report"} />,
        title1: "Return the",
        title2: "equipment",
        description1: "The Test kit will arrive with a return.",
        description2: "label valid for up to 7 days and will",
        description3: "save you from any return charges",
        description4: "that may apply."
    },
    {
        image: <img src={returnTest} alt={"returnTest"} />,
        title1: "Get the",
        title2: "report ",
        description1: "A board-certified expert sleep physician",
        description2: "will interpret the result obtained from the",
        description3: "test kit.The results will be shared within",
        description4: "7 business days.",
    }
]


const TestStagesSteps = () => {
    const classes = TestStagesStepsStyles();

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

    const getStaticContent = () => {
        return <Box my={5} mb={7} className={classes.staticContentBox}>
            <Box mb={2} className={classes.staticContentBox2}>
                <Divider className={isDesktop ? classes.staticContentBoxDividerForDeskTop : classes.staticContentBoxDividerForMobile}>Test Stages</Divider >
            </Box>
            <Typography className={isDesktop ? classes.TitleForDeskTop : classes.TitleBoxForMobile} style={{ fontSize: "48px", textAlign: "center", color: "#1A3A6C", fontWeight: 900, ...boldFont }}>At-home Test Stages.</Typography>
            <Typography style={{ fontSize: "20px", textAlign: "center", wordWrap: "break-word", fontWeight: 400, ...regularFont, color: "#283035" }}>The Home Sleep Apnea Test Kit is a non-invasive device which helps physicians to diagnose sleep apnea.The test’s results are accurate, precise, and trustworthy.Claim back the sleep you deserve!</Typography>
        </Box>
    }

    const getTestStagesSteps = () => {
        return (<>
            <Box style={{ display: "flex", justifyContent: "center" }}>
                <Stack direction={isDesktop ? "row" : "column"} spacing={10}>
                    {
                        data.map((ele) => (
                            <Box>
                                <Badge anchorOrigin={{ vertical: 'top', horizontal: 'right' }} badgeContent={<CheckIcon style={{ backgroundColor: "#EB6523", padding: "5px", borderRadius: "50%", marginLeft: "-20px", marginBottom: "-20px", color: "white" }} />} >
                                    <Paper elevation={3} style={{ width: "150px", height: "150px", borderRadius: "50px", display: "flex", justifyContent: "center", alignItems: "center", boxShadow: "0px 15px 30px rgba(0, 0, 0, 0.05)" }}>
                                        <CustomIcon
                                            icon={ele.image}
                                        />
                                    </Paper>
                                </Badge>
                                <Box style={{ marginTop: "20px" }}>
                                    <Typography style={{ fontSize: "24px", fontWeight: 900, ...boldFont, color: "#283035" }}>{ele.title1}</Typography>

                                </Box>
                                <Typography style={{ fontSize: "24px", fontWeight: 900, ...boldFont, color: "#283035" }}>{ele.title2}</Typography>
                                <Box style={{ marginTop: "10px" }}>
                                    <Typography style={{ fontSize: "15px", wordWrap: "break-word", color: "#8B8B8B", fontWeight: 400, ...regularFont }}>{ele.description1}</Typography>
                                </Box>
                                <Typography style={{ fontSize: "15px", wordWrap: "break-word", color: "#8B8B8B", fontWeight: 400, ...regularFont }}>{ele.description2}</Typography>
                                <Typography style={{ fontSize: "15px", wordWrap: "break-word", color: "#8B8B8B", fontWeight: 400, ...regularFont }}>{ele.description3}</Typography>
                                <Typography style={{ fontSize: "15px", wordWrap: "break-word", color: "#8B8B8B", fontWeight: 400, ...regularFont }}>{ele.description4}</Typography>
                            </Box>
                        ))
                    }
                </Stack>

            </Box>
        </>)
    };

    const getBody = () => <>
        {getStaticContent()}
        {getTestStagesSteps()}
    </>

    return getBody();
};

export default TestStagesSteps;