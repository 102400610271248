import { autocompleteClasses, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { boldFont } from '../../../../utils/styles';
import { regularFont } from '../../../../utils/styles';

const SymptomsStyles = makeStyles((theme: Theme) => ({
    symptonIcons: {
        justifyContent: "flex-start",
        marginBottom: "10px",
    },
    desktopTitle: {
        fontSize: "48px", fontWeight: 900, color: "#1A3A6C", ...boldFont

    },
    mobileTitle: {
        fontSize: "22px", fontWeight: 900, color: "#1A3A6C", ...boldFont
    },
    media: {
        height: "550px",
        width: "100%"
    },
    mediaMobile: {
        height: "300px",
        width: "100%"
    }
}));

export default SymptomsStyles;