import Header from "../../../shared/Header/Header";
import {
  Box,
  Button,
  Divider,
  Container,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Footer from "../../../shared/Footer/Footer";
import { boldFont } from "../../../utils/styles";
import { regularFont } from "../../../utils/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { userService } from "../../../services/sleepTestUserService-service";
import Loading from "../../Loading/Loading";
import ViewProductDetailStyles from "./ViewProductDetail.styles";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";
import { CardMedia } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Counter from "../common/counter";
import { connect } from "react-redux";
import { addToCart } from "../../../redux/_actions/cart.actions";
import history from "../../../utils/history";
import { openErrorNotification } from "../../../helpers/methods";
import { stateToHTML } from "draft-js-export-html";
import { convertFromRaw } from "draft-js";

const ViewProductDetail = (props) => {
  const classes = ViewProductDetailStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [productDetail, setproductDetail] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [isPayment, setIsPayment] = useState(false);
  const [value, setValue] = React.useState("1");
  const id = new URLSearchParams(props.location.search).get("id");
  const [role, setRole] = useState("");
  const [price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState(productDetail?.quantity || 1);
  // const [presentCartQuantity, setpresentCartQuantity] = useState(1);

  useEffect(() => {
    let username = JSON.parse(localStorage.getItem("current_user")!);
    if (username) {
      var role = username.role;
      setRole(role);
      presentCarditem();
    }
  }, []);

  useEffect(() => {
    document.title = "Product Details";
  }, []);

  let current_user = localStorage.getItem("current_user");

  useEffect(() => {
    getProductById(id);
  }, []);

  useEffect(() => {
    setPrice(quantity * productDetail?.unitPrice);
  }, [quantity]);

  const presentCarditem = () => {
    let arr = props.items.find((item) => {
      if (item.id === id) {
        return item;
      }
    });
    if (arr?.quantity > 1) {
      setQuantity(arr.quantity);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const productValueHandler = (type: string) => {
    if (type === "increase") {
      setQuantity(quantity + 1);
      setPrice((quantity + 1) * productDetail.unitPrice);
    } else if (type === "decrease") {
      setQuantity(quantity - 1);
      setPrice((quantity - 1) * productDetail.unitPrice);
    }
  };

  const getProductById = async (id) => {
    try {
      setIsLoading(true);
      const response = await userService.getProductById(id);
    
      setproductDetail(response);
      setPrice(response.unitPrice);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(error.toString());
    }
  };
  const getProductImage = () => {
    return (
      <>
        <Grid
          lg
          container
          direction="column"
          alignItems="end"
          marginRight={isDesktop ? "25px" : "0"}
        >
          <Box className={classes.productPicture}>
            <Card style={{ height: "400px", borderRadius: "20px" }}>
              <CardMedia
                className={classes.media}
                component="img"
                src={`data:image/png;base64,${productDetail.productPicture}`} // require image
                title="Contemplative Reptile"
              />
            </Card>
          </Box>
        </Grid>
      </>
    );
  };
  const getStaticImage = () => {
    return (
      <>
        {productDetail ? (
          <Grid
            item
            lg
            container
            direction="column"
            alignItems="start"
            paddingLeft="30px"
            marginTop="40px"
          >
            <Box style={{ display: "flex", flexDirection: "row" }}>
              {role == "Admin" ? (
                <Box>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={"/editproduct?id=" + id}
                  >
                    <Button
                      style={{
                        boxShadow: "0px 15px 30px 0px rgba(0, 0, 0, 0.09)",
                        height: "60px",
                        borderRadius: "20px",
                        textTransform: "none",
                        marginRight: "15px",
                      }}
                    >
                      <Typography
                        style={{
                          color: "#1A3A6C",
                          fontSize: "18px",
                          fontWeight: 700,
                          ...regularFont,
                        }}
                      >
                        Edit Product
                      </Typography>
                    </Button>
                  </Link>
                </Box>
              ) : (
                ""
              )}

              <Box>
                <Link style={{ textDecoration: "none" }} to="/products">
                  {/* <ArrowBackIcon style={{ color: "#EB6523" }} > */}
                  <Button
                    style={{
                      boxShadow: "0px 15px 30px 0px rgba(0, 0, 0, 0.09)",
                      height: "60px",
                      borderRadius: "20px",
                      textTransform: "none",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#1A3A6C",
                        fontSize: "18px",
                        fontWeight: 700,
                        ...regularFont,
                      }}
                    >
                      Back To Store
                    </Typography>
                  </Button>
                </Link>
              </Box>
            </Box>

            <Typography
              style={{
                color: "#283035",
                fontSize: "32px",
                fontWeight: 900,
                ...boldFont,
                marginBottom: "10px",
              }}
            >
              {productDetail.name}
            </Typography>
            <Typography
              style={{
                color: "#283035",
                fontSize: "32px",
                fontWeight: 900,
                ...boldFont,
              }}
            >
              {productDetail.currency}
              {productDetail.unitPrice}
            </Typography>
            {getTabpan()}
          </Grid>
        ) : null}
      </>
    );
  };

  const addToCartProductItem = async (product, quantity) => {
    const existing = props.items.filter((items) => items.name === product.name);
    if (existing.length === 0) {
      const newProduct = {
        ...product,
        quantity,
        totalPrice: price,
      };
      props.addToCart(newProduct);
    } else {
      for (let i = 0; i < props.items.length; i++) {
        if (props.items[i].name === product.name) {
          const newQuantity = quantity;
          const newTotalPrice =
            props.items[i].totalPrice +
            quantity * productDetail.unitPrice -
            props.items[i].quantity * productDetail.unitPrice;
          props.items[i].quantity = newQuantity;
          props.items[i].totalPrice = newTotalPrice;
        }
      }
      history.push("/cart");
    }
  };

  const getFloatingBox = () => {
    return (
      <Box className={classes.makeItFloat}>
        <Box display="flex" justifyContent="center">
          <Box display="flex" className={classes.withWidthBox}>
            <Box display="flex" pl={1} py={2} alignItems="center">
              <Box>
                <Card style={{ height: "50px" }}>
                  <CardMedia
                    className={classes.media}
                    component="img"
                    src={`data:image/png;base64,${productDetail.productPicture}`} // require image
                    title="Contemplative Reptile"
                  />
                </Card>
              </Box>
              <Box ml={1}>{productDetail.name}</Box>
            </Box>
            <Box>
              <Counter
                inStockQuantity={productDetail.inStockQuantity}
                currentQuantity={quantity}
                productDetials={productDetail}
                componentName="product detail"
                setQuantity={setQuantity}
              />
            </Box>
            <Box display="flex" pr={1} py={2}>
              <Box justifyContent="center" display="flex" alignItems="center">
                {productDetail.currency}
                {price}
                {productDetail.inStockQuantity < 1 && (
                  <span style={{ color: "#b7adad", margin: "4px 4px" }}>
                    Product out of Stock
                  </span>
                )}
              </Box>
              &nbsp;&nbsp;
              <Box>
                {" "}
                {current_user ? (
                  <Box>
                    {props.user.role === "Patient" ? (
                      <Button
                        style={{
                          ...regularFont,
                          fontWeight: 500,
                          fontSize: "16px",
                          color: "#ffffff",
                          backgroundColor: "#1A3A6C",
                          borderRadius: "30px",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                          whiteSpace: "nowrap",
                          padding: "4px 8px",
                          position: "relative",
                        }}
                        disabled={productDetail.inStockQuantity < 1}
                        onClick={() =>
                          addToCartProductItem(productDetail, quantity)
                        }
                      >
                        {productDetail.inStockQuantity < 1 && (
                          <div
                            style={{
                              position: "absolute",
                              height: "100%",
                              width: "100%",
                              backgroundColor: "#6c4848",
                              opacity: "0.5",
                              borderRadius: "30px",
                            }}
                          ></div>
                        )}
                        Add To Cart
                      </Button>
                    ) : (
                      ""
                    )}
                  </Box>
                ) : (
                  <Button
                    style={{
                      ...regularFont,
                      fontWeight: 500,
                      fontSize: "16px",
                      color: "#ffffff",
                      backgroundColor: "#1A3A6C",
                      borderRadius: "30px",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      whiteSpace: "nowrap",
                      padding: "4px 8px",
                      position: "relative",
                    }}
                    disabled={productDetail.inStockQuantity < 1}
                    onClick={() =>
                      addToCartProductItem(productDetail, quantity)
                    }
                  >
                    {productDetail.inStockQuantity < 1 && (
                      <div
                        style={{
                          position: "absolute",
                          height: "100%",
                          width: "100%",
                          backgroundColor: "#6c4848",
                          opacity: "0.5",
                          borderRadius: "30px",
                        }}
                      ></div>
                    )}
                    Add To Cart
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };
  const getTabpan = () => {
    return (
      <>
        {" "}
        <Box
          sx={{ typography: "body1" }}
          style={{
            marginBottom: "30px",
          }}
        >
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                style={{ color: "red" }}
              >
                <Tab label="Description" value="1" />
                <Tab label="Product Information" value="2" />
                {/* <Tab label="Comment" value="3" /> */}
              </TabList>
            </Box>
            <TabPanel value="1" style={{ padding: "0px 15% 0px 2px" }}>
              {productDetail?.description?( <div
                dangerouslySetInnerHTML={{
                  __html: stateToHTML(
                    convertFromRaw(JSON.parse(productDetail?.description))
                  ),
                }}
              ></div>):""}
             
            </TabPanel>
            <TabPanel value="2"  style={{ padding: "0px" }}>
              <TableContainer>
                <Table
                  // sx={{ minWidth: 700 }}
                  style={{
                    borderCollapse: "unset",
                    borderSpacing: "0px 10px",
                  }}
                  aria-label="customized table"
                >
                  <TableHead>
                    <TableRow>
                      {/* <TableCell align="center">InStock Quantity</TableCell> */}
                      <TableCell align="center">Ordered Quantity</TableCell>
                      <TableCell align="center">Weight</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {/* <TableCell align="center" component="th" scope="row">
                        {productDetail.inStockQuantity}
                      </TableCell> */}
                      <TableCell align="center">
                        {productDetail.orderedQuantity}
                      </TableCell>
                      <TableCell align="center">
                        {productDetail.weight}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
            {/* <TabPanel value="3">{productDetail.comment}</TabPanel> */}
          </TabContext>
        </Box>
      </>
    );
  };
  return (
    <>
      <Header />
      {isLoading ? (
        <Loading />
      ) : productDetail ? (
        <>
          <Box display="flex" className={isDesktop ? "" : classes.BoxForMobile}>
            <Box
              style={{
                backgroundColor: "#F9F9FF",
                display: "flex",
                flex: 1,
                height: "calc(100vh - 200px)",
                overflow: "auto",
                overflowX: "hidden",
                alignItems: "center",
              }}
            >
              {isPayment ? "" : getProductImage()}
            </Box>

            <Box style={{ backgroundColor: "#ffff", flex: 1, width: "100%" }}>
              {getStaticImage()}
            </Box>
          </Box>
          <Footer />
          {getFloatingBox()}
        </>
      ) : null}
    </>
  );
};

function mapState(state) {
  const { authentication, shopReducer } = state;
  const { items } = shopReducer;
  const { user, loggedIn } = authentication;

  return { user, items, loggedIn };
}

const actionCreators = {
  addToCart: addToCart,
};

export default connect(mapState, actionCreators)(ViewProductDetail);
