import { Badge, Box, Divider, Grid, Paper, Typography } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import DataCardStyles from "./DataCard.styles";
import React, { useState } from "react";
import { Link } from "react-router-dom";

interface Props {
  img: string;
  title: string;
  date: string;
  by: string;
}

const DataCard = ({ img, title, date, by }: Props) => {
  const classes = DataCardStyles();
  return (
    <>
      <Grid alignItems="center" justifyContent="center">
        <CardContent>
          <Box
            style={{
              borderRadius: "30px",
              marginBottom: "20px",
            }}
          >
            <img src={img} style={{ width: "100%" }}></img>
          </Box>

          <Typography
            style={{ fontSize: "28px", fontWeight: 800, color: "#000000" }}
          >
            {title}
          </Typography>

          <Box style={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{ mb: 1.5 }}
              color="text.secondary"
              style={{
                textAlign: "center",
                marginRight: "5px",
              }}
            >
              {date}
            </Typography>
            <Typography
              sx={{ mb: 1.5 }}
              color="text.secondary"
              style={{ textAlign: "center" }}
            >
              |
            </Typography>
            <Typography
              sx={{ mb: 1.5 }}
              color="text.secondary"
              style={{ textAlign: "center", marginLeft: "5px" }}
            >
              {by}
            </Typography>
          </Box>
        </CardContent>
      </Grid>
    </>
  );
};

export default DataCard;
