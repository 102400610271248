import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { boldFont } from "../../utils/styles";
import { regularFont } from "../../utils/styles";


const BannerStyles = makeStyles((theme: Theme) => ({

  textWrapper: {
    marginTop: "5%",
    marginLeft: "12%",
  },
  desktopbg:{
    display: "flex",
    paddingBottom: "12vh",
    width: "100%",
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
  },
  mobilebg:{
    display: "flex",
    height:"100%",
    paddingBottom:"30px",
    backgroundRepeat: "no-repeat",
  },
  desktopheaderline:{
    fontSize: "24px !important",
    fontWeight: 400,
    ...regularFont,
    color: "#283035",
  },
  mobileheaderline:{

  },
  desktopsecondaryline:{
    fontSize: "4.42vw !important",
    fontWeight: 900,
    ...boldFont,
    color: "#1A3A6C",
  },
  mobilesecondaryline:{
    fontSize: "60px !important",
    fontWeight: 900,
    ...boldFont,
    color: "#1A3A6C", 

  }
}));

export default BannerStyles;
