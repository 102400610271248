import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const customPaperStyles = makeStyles((theme: Theme) => ({
  customPaper: {
    borderRadius: "13px",
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
  },
  customPaperMobile: {
    border: "none",
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
  },
}));

export default customPaperStyles;
