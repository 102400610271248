import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const ProductCardStyles = makeStyles((theme: Theme) => ({
  media: {
    objectFit: "contain",
    height: "100%",
    width: "100%",
    border: "0px !important",
  },
}));

export default ProductCardStyles;
