import { autocompleteClasses, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const FreeAssessmentStyles = makeStyles((theme: Theme) => ({
  grid: {
   
    alignItems: "center",
  },
  banner: {
    width: "100%",
    position: "relative",
    height: "auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
}));

export default FreeAssessmentStyles;
