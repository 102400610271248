import {
    Box,
    Button,
    Divider,
    Container,
    Grid,
    Typography,
    Paper,
  } from "@mui/material";
  import { Repeat } from "@mui/icons-material";
  import React, { useState } from "react";
  import Header from "../../../../shared/Header/Header";
  import Footer from "../../../../shared/Footer/Footer";
  import Banner from "../../../../shared/Banner/Banner";
  import expandblog3 from "../../../../assets/images/Blog/expandblog3.png";
  import blogbanner from "../../../../assets/images/Blog/blogbanner.png";
  
  const BlogExpand3 = () => {
    return (
      <>
        <Header />
        <Banner img={blogbanner} header1="Home/ Blog" header2="Blog" />
        <Container maxWidth="lg" style={{ marginBottom: "20px", width: "100%" }}>
          <Grid
            item
            xl
            container
            direction="column"
            justifyContent="center"
            alignContent="center"
            marginTop="30px"
          >
            <img style={{width:"85%"}} src={expandblog3} />
            <Box>
              <Typography
                style={{
                  marginTop: "30px",
                  fontSize: "42px",
                  color: "#EB6523",
                  fontWeight: 600,
                }}
              >
                Sleep Problems Individuals
              </Typography>
              <Typography
                style={{ marginTop: "30px", fontSize: "20px", color: "#8B8B8B" }}
              >
                By Admin | Oct 18- 2020
              </Typography>
              <Typography
                style={{
                  marginTop: "60px",
                  marginBottom: "100px",
                  fontSize: "20px",
                  color: "#283035",
                }}
              >
                <span style={{ fontStyle: "italic" ,fontFamily:"bold"}}>
                “Sleep Apnea is a serious health problem that impacts
                approximately 43 million US adults. It is estimated that more
                than 80% of the US population remain undiagnosed.”
              </span>
              <br />
              <br />
              If not diagnosed on time, individuals suffering from Sleep Apnea
              have to face various health complications such as daytime fatigue,
              high blood pressure, heart complications, metabolic syndrome, Type
              II Diabetes, liver problems, etc. Apart from all the health risks,
              Sleep Apnea also cause disturbance in the everyday schedule, such
              as difficulty concentrating and tired feeling that results from
              lack of sleep.
              </Typography>
            </Box>
          </Grid>
        </Container>
        <Footer />
      </>
    );
  };
  
  export default BlogExpand3;
  