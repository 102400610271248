import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Slider from "@material-ui/core/Slider";
import Button from "@material-ui/core/Button";
import { Checkbox, Typography, Box } from "@mui/material";
import { boldFont } from "../../../../utils/styles";
import { regularFont } from "../../../../utils/styles";
import { Link } from "react-router-dom";
import FormStyles from "./Form.styles";
import { userService } from "../../../../services/sleepTestUserService-service";
import history from "../../../../utils/history";
import urls from "../../../../global/constants/UrlConstants";
import {
  openErrorNotification,
  openSuccessNotification,
} from "../../../../helpers/methods";
import loading2 from "../../../../assets/images/Login/loading2.gif";

const Form = () => {
  const classes = FormStyles();
  const [firstNameErr, setFirstNameErr] = useState(false);
  const [lastNameErr, setLastNameErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [phoneErr, setPhoneErr] = useState(false);
  const [messageErr, setMessageErr] = useState(false);
  const [checkbox, setCheckBox] = useState(false);
  const [checkboxerr, setCheckBoxErr] = useState(false);
  const [loading, setLoading] = useState(false);

  const [visitor, setVisitor] = useState({
    email: "",
    firstName: "",
    lastName: "",
    contactNumber: "",
    message: "",
  });

  const handleEmailChange = (event: any) => {
    setLoading(false);
    setVisitor({
      ...visitor,
      email: event.target.value,
    });
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (regex.test(event.target.value)) {
      setEmailErr(false);
    } else {
      setEmailErr(true);
    }
  };
  const handleFNameChange = (event: any) => {
    setLoading(false);
    setVisitor({
      ...visitor,
      firstName: event.target.value,
    });
    const regex = /^[A-Za-z]+$/;
    if (regex.test(event.target.value)) {
      setFirstNameErr(false);
    } else {
      setFirstNameErr(true);
    }
  };

  const onChangePhone = (event: any) => {
    setLoading(false);
    setVisitor({
      ...visitor,
      contactNumber: event.target.value,
    });
  };

  const onChangeMessage = (event: any) => {
    setLoading(false);
    setVisitor({
      ...visitor,
      message: event.target.value,
    });
  };

  const handleLNameChange = (event: any) => {
    setLoading(false);
    setVisitor({
      ...visitor,
      lastName: event.target.value,
    });
    const regex = /^[A-Za-z]+$/;
    if (regex.test(event.target.value)) {
      setLastNameErr(false);
    } else {
      setLastNameErr(true);
    }
  };

  const handleSubmit = async () => {
    console.log(visitor);

    if (visitor.email == "") {
      setEmailErr(true);
    }
    if (visitor.firstName == "") {
      setFirstNameErr(true);
    }
    if (visitor.lastName == "") {
      setLastNameErr(true);
    }
    if (visitor.contactNumber == "") {
      setPhoneErr(true);
    }
    if (visitor.message == "") {
      setMessageErr(true);
    }
    if (!checkbox) {
      setCheckBoxErr(true);
    } else {
      try {
        setLoading(true);
        const response = await userService.insertVisitor(visitor);
        history.push(urls.landingViewPath);
        openSuccessNotification("Your information is saved successfully");
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        openErrorNotification(error);
      }
    }
  };

  return (
    // <form className={classes.form} method="post" noValidate>
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            autoComplete="fname"
            name="firstName"
            variant="outlined"
            fullWidth
            id="firstName"
            label="First Name"
            value={visitor.firstName}
            onChange={handleFNameChange}
            style={{ backgroundColor: "#ffffff" }}
          />
          {firstNameErr ? (
            <span style={{ color: "red" }}>Please Enter First Name</span>
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            fullWidth
            id="lastName"
            label="Last Name"
            name="lastName"
            autoComplete="lname"
            value={visitor.lastName}
            onChange={handleLNameChange}
            style={{ backgroundColor: "#ffffff" }}
          />
          {lastNameErr ? (
            <span style={{ color: "red" }}>Please Enter Last Name</span>
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            fullWidth
            id="mobileNumber"
            label="Mobile Number"
            autoComplete="email"
            value={visitor.contactNumber}
            onChange={onChangePhone}
            style={{ backgroundColor: "#ffffff" }}
          />
          {phoneErr ? (
            <span style={{ color: "red" }}>Please Enter Phone Number</span>
          ) : (
            ""
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            style={{ backgroundColor: "#ffffff" }}
            value={visitor.email}
            onChange={handleEmailChange}
          />
          {emailErr ? (
            <span style={{ color: "red" }}>Please Enter Email</span>
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows="4"
            name="password"
            label="Enter Your Message"
            type="text"
            id="test"
            value={visitor.message}
            onChange={onChangeMessage}
            style={{ backgroundColor: "#ffffff" }}
          />
          {messageErr ? (
            <span style={{ color: "red" }}>Please Enter Messaege</span>
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={12}>
          <Box style={{ display: "flex", flexDirection: "row" }}>
            <Checkbox
              onChange={() => {
                setCheckBox(true);
              }}
              style={{ color: "#1A3A6C", marginRight: "10px" }}
            ></Checkbox>
            <Typography
              style={{ ...regularFont, fontWeight: 500, fontSize: "20px" }}
            >
              I agree that my submitted data is being collected and stored.
            </Typography>
          </Box>
          {checkboxerr ? (
            <span style={{ color: "red" }}>Please accept T&C</span>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
      <Box
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        {loading ? (
          <img style={{ width: "10%", height: "40%" }} src={loading2}></img>
        ) : (
          <button
            onClick={handleSubmit}
            className={classes.submit}
            style={{
              ...regularFont,
            }}
          >
            Sign Up
          </button>
        )}
      </Box>
    </>
  );
};
export default Form;
