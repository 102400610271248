import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { boldFont } from "../../utils/styles";

const NavbarStyles = makeStyles((theme: Theme) => ({
  navlinks: {
    display: "flex",
  },
  link: {
    textDecoration: "none",
    fontSize: "16px",
    textTransform: "uppercase",
    color: "black",
    ...boldFont,
    fontWeight: 900,
    "&:hover": {
      color: "#EB6523",
      borderBottom: "1.5px solid #EB6523",
    },
  },
  active: {
    color: "#EB6523",
  },
  customMargin: {
    "& .MuiTypography-root": {
      margin: '0px 16px',
    }
  },
}));

export default NavbarStyles;
