import Header from "../../../shared/Header/Header";
import Footer from "../../../shared/Footer/Footer";
import { connect } from "react-redux";
import {
    Box,
    Container,
    Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { regularFont } from "../../../utils/styles";
import { userService } from "../../../services/sleepTestUserService-service";
import ViewUserTable from "./Component/ViewUserTable/ViewUserTable";
import { alertActions } from "../../../redux/_actions/alert.actions";
import patientDashboard from "../../../assets/images/Patient/patientDashboard.png";
import {
    useTheme,
    useMediaQuery,
} from "@mui/material";
import Banner from "../../../shared/Banner/Banner";
import ViewUserStyles from "./ViewUser.style"
import Loading from "../../Loading/Loading";
import { ToastContainer, toast } from "react-toastify";
import { openErrorNotification } from "../../../helpers/methods";



const ViewUser = (props) => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
    const [patients, setPatients] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getpatients()
    }, [])


    useEffect(() => {
        document.title = "User";
      }, []);

    const getpatients = async () => {
        try {
            setIsLoading(true);
            const response = await userService.getpatients();
         
            setPatients(response);
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            openErrorNotification(error.toString())
        }
    }

    return (
        <>
            <Header />
            <Banner
                img={patientDashboard}
                header1="Home/ Patient"
                header2="Patient Data"
            />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <Container
                        maxWidth={false}
                        style={{ marginBottom: "20px", width: "71%", marginTop: "50px" }}
                    >
                        <Box
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                margin: "5% 0 3% 0",
                            }}
                        >
                            <Typography
                                style={{
                                    color: "#1A3A6C",
                                    fontSize: "32px",
                                    fontWeight: 900,
                                    ...regularFont,
                                }}
                            >
                                Manage Patient Data
                            </Typography>
                        </Box>
                        <ViewUserTable tableData={patients} />
                    </Container>
                    <Footer />
                </>
            )}
        </>
    );
};

const mapState = (state) => {
    const { authentication, alert, loggedIn } = state;
    return { authentication, alert, loggedIn };
};

const actionCreators = {
    clearAlerts: alertActions.clear,
};

export default connect(mapState, actionCreators)(ViewUser);
