import Header from "../../../shared/Header/Header";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";

import DisplayData from "./components/DisplayData/DisplayData";
import ServicesFooter from "../ServicesFooter";
import { Repeat } from "@mui/icons-material";
import Footer from "../../../shared/Footer/Footer";
import Banner from "../../../shared/Banner/Banner";
import services_banner from "../../../assets/images/Services/services_banner.png";
import { useEffect } from "react";

const Services = () => {
  useEffect(() => {
    document.title = "Services";
  }, []);
  const getServices = () => {
    return (
      <>
        <Header />
        <Banner
          img={services_banner}
          header1="Home / Services / Sleep Problem Individuals"
          header2="Sleep Problem Individuals"
        />
        <DisplayData />
        <ServicesFooter />
        <Footer />
      </>
    );
  };
  return getServices();
};

export default Services;
