import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import footerbg from "../../assets/images/Footer/footerbg.png"

const FooterStyles = makeStyles((theme: Theme) => ({
 
    grid:{
        display: "flex",
        alignItems: "center",
        backgroundImage:`url(${footerbg})`,
        
    },
    topHeaderBLOG: {
        listStyle: "none",
        display: "inline-block",
        padding: "6px",
        textDecoration:"none"
      },
      topHeaderLi: {
        listStyle: "none",
        display: "inline-block",
        borderLeft: "3px solid #FFE0D0",
        padding: "6px",
        textDecoration:"none"
      }

}));

export default FooterStyles;
