import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Header from "../../../shared/Header/Header";
import { useState, useEffect } from "react";
import Footer from "../../../shared/Footer/Footer";
import history from "../../../utils/history";
import { userService } from "../../../services/sleepTestUserService-service";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import rightarrow from "../../../assets/images/ProfileUpdate/rightarrow.png";
import { userActions } from "../../../redux/_actions/user.action";
import { connect } from "react-redux";
import Loading from "../../Loading/Loading";
import urls from "../../../global/constants/UrlConstants";
import {
  openSuccessNotification,
  openErrorNotification,
} from "../../../helpers/methods";
import loading2 from "../../../assets/images/Login/loading2.gif";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import { boldFont, regularFont } from "../../../utils/styles";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ToastContainer, toast } from "react-toastify";
import { alertActions } from "../../../redux/_actions/alert.actions";

import {
  Box,
  Button,
  Divider,
  Container,
  Grid,
  Typography,
  Paper,
  useTheme,
  useMediaQuery,
  TextField,
} from "@mui/material";
import profileUpdateStyles from "./ProfileUpdate.styles";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const UpdateProfile = (props) => {
  const classes = profileUpdateStyles();

  const [eye, setEye] = useState(true);
  const [eye1, setEye1] = useState(true);
  const [stateList, setStateList] = useState([]);
  const [promoList, setPromoList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [pinCodeList, setPinCodeList] = useState([]);
  const [isrole, setRole] = useState("");
  const [firstNameErr, setFirstNameErr] = useState(false);
  const [lastNameErr, setLastNameErr] = useState(false);
  const [passwordErr, setPasswordErr] = useState(false);
  const [cPassswordErr, setCPasswordErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [add1Err, setAdd1Err] = useState(false);
  const [add2Err, setAdd2Err] = useState(false);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [user, setUser] = useState({
    id: "",
    role: "",
    email: "",
    firstName: "",
    lastName: "",
    middleName: "",
    displayName: "",
    shippingAddress: {},
    billingAddress: {},
    primaryContactNumber: { number: "", extension: "" },
    secondaryContactNumber: {},
    promoCode: "",
    productCode: "ResMed",
    authToken: "string",
    status: "string",
  });
  let current_user = localStorage.getItem("current_user");
  if (current_user) {
    let username = JSON.parse(localStorage.getItem("current_user")!);
    var firstName = username?.firstName;
    var lastName = username.lastName;
    var initial1 = firstName.charAt(0);
    var initial2 = lastName.charAt(0);
  }

  const [password, setPassword] = useState({
    pwd: "default", // as Api declared this field as mandatory with any value
    newPwd: "",
    email: "",
    cPwd: "",
    pwdType: "P",
    showPassword: false,
    showPassword1: false,
    showPassword2: false,
  });

  const [billingAddress, setBillingAddress] = useState({
    line1: "",
    line2: "",
    line3: "",
    line4: "",
    city: "",
    state: "",
    pinCode: "",
    country: "United States of America",
  });

  const [primaryContactNumber, setPrimaryContactNumber] = useState({
    number: "",
    extension: "",
  });

  const onChangeTextForBilling = (event: any) => {
    setLoading(false);
    setBillingAddress({
      ...billingAddress,
      [event.target.name]: event.target.value,
    });
    setAdd1Err(false);
    setAdd2Err(false);
  };

  const handleChangeState = (event: any) => {
    setLoading(false);
    setBillingAddress({
      ...billingAddress,
      state: event.target.value,
    });
  };

  const handleChangeCity = (event: any) => {
    setLoading(false);
    setBillingAddress({
      ...billingAddress,
      city: event.target.value,
    });
  };

  const handleChangeZipcode = (event: any) => {
    setLoading(false);
    setBillingAddress({
      ...billingAddress,
      pinCode: event.target.value,
    });
  };

  const handleFNameChange = (event: any) => {
    setLoading(false);
    setUser({
      ...user,
      firstName: event.target.value,
    });
    const regex = /^[A-Za-z]+$/;
    if (regex.test(event.target.value)) {
      setFirstNameErr(false);
    } else {
      setFirstNameErr(true);
    }
  };

  const onChangePhone = (event: any) => {
    setLoading(false);
    setPrimaryContactNumber({
      ...primaryContactNumber,
      number: event.target.value,
    });
  };

  const handleExistingPwdChange = (event: any) => {
    setLoading(false);
    setPassword({
      ...password,
      pwd: event.target.value,
    });
  };

  const handleNewPwdChange = (event: any) => {
    setLoading(false);
    setPassword({
      ...password,
      newPwd: event.target.value,
    });
  };

  const handleCPwdChange = (event: any) => {
    setLoading(false);
    setPassword({
      ...password,
      cPwd: event.target.value,
    });
  };

  const handleLNameChange = (event: any) => {
    setLoading(false);
    setUser({
      ...user,
      lastName: event.target.value,
    });
    const regex = /^[A-Za-z]+$/;
    if (regex.test(event.target.value)) {
      setLastNameErr(false);
    } else {
      setLastNameErr(true);
    }
  };

  useEffect(() => {
    document.title = "Update Profile";
    if (billingAddress.state || billingAddress.city) {
      loadCity("United States of America", billingAddress.state);
      loadZipCode(
        "United States of America",
        billingAddress.state,
        billingAddress.city
      );
    }
    loadState("United States of America");
    loadPromo(user.productCode);
  }, ["United States of America", billingAddress.state, billingAddress.city]);

  useEffect(() => {
    let username = JSON.parse(localStorage.getItem("current_user")!);
    setUser(username);
    var isrole = username?.role;
    primaryContactNumber.number = username?.primaryContactNumber?.number;
    billingAddress.line1 = username?.billingAddress?.line1;
    billingAddress.line2 = username?.billingAddress?.line2;
    billingAddress.state = username?.billingAddress?.state;
    billingAddress.city = username?.billingAddress?.city;
    billingAddress.pinCode = username?.billingAddress?.pinCode;
    password.email = username?.email;
    setRole(isrole);
  }, []);

  const loadState = async (country: string) => {
    try {
      setDataLoading(true);

      const response = await userService.getState(country);
      setStateList(response);
      setDataLoading(false);
    } catch (error) {
      setDataLoading(false);

      // notifications.openErrorNotification(error);
    }
  };

  const loadPromo = async (productCode: string) => {
    try {
      setDataLoading(true);
      const response = await userService.getPromoOffers(productCode);
      const promo_object = response.offers;
      setPromoList(promo_object);
      setDataLoading(false);
    } catch (error) {
      setDataLoading(false);
      // notifications.openErrorNotification(error);
    }
  };

  const loadCity = async (country: string, state: string) => {
    try {
      setDataLoading(true);

      const response = await userService.getCity(country, state);
      setCityList(response);
      setDataLoading(false);
    } catch (error) {
      setDataLoading(false);

      // notifications.openErrorNotification(error);
    }
  };

  const loadZipCode = async (country: string, state: string, city: string) => {
    try {
      setDataLoading(true);

      const response = await userService.getZipcode(country, state, city);
      setPinCodeList(response);
      setDataLoading(false);
    } catch (error) {
      setDataLoading(false);

      // notifications.openErrorNotification(error);
    }
  };

  const handleSubmit = () => {
    user.billingAddress = billingAddress;
    user.primaryContactNumber = primaryContactNumber;
    userService.updateProfile(user).then(
      (user) => {
       
        localStorage.setItem("current_user", JSON.stringify(user));
        history.push(urls.dashboardpath);
        openSuccessNotification("Profile has been updated successfully.");
      },
      (error: any) => {
        var errorMsg = error.toString();
        openErrorNotification(errorMsg);
      }
    );
  };

  const passwordChangeSubmit = () => {
    setLoading(true);
    if (password.newPwd.length < 8) {
      setPasswordErr(true);
    }
    if (password.cPwd.length < 8) {
      setCPasswordErr(true);
    }
    if (password.newPwd != password.cPwd) {
      setCPasswordErr(true);
    } else {
      userService.updatePwd(password).then(
        (updateUserPwd) => {
          localStorage.clear();

          openSuccessNotification(
            "Your password has been changed successfully!"
          );
          history.push(urls.SignInPath);
        },
        (error: any) => {
          setLoading(false);
          var errorMsg = error.toString();
          openErrorNotification(errorMsg);
        }
      );
    }
  };

  const handleClickShowPassword = () => {
    setPassword({
      ...password,
      showPassword: !password.showPassword,
    });
  };

  const handleClickShowPassword1 = () => {
    setPassword({
      ...password,
      showPassword1: !password.showPassword1,
    });
  };

  const handleClickShowPassword2 = () => {
    setPassword({
      ...password,
      showPassword2: !password.showPassword2,
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");

  useEffect(() => {
    let username = JSON.parse(localStorage.getItem("current_user")!);
    var fname = username.firstName;
    setFName(fname);
    var lname = username.lastName;
    setLName(lname);
  }, []);

  return (
    <>
      <Header />

      {dataLoading ? (
        <Loading />
      ) : (
        <Box
          style={{
            display: "flex",
            flexDirection: isDesktop ? "row" : "column",
           
          }}
        >
          <Box
            style={{
              width: isDesktop ? "50%" : "100% ",
              height: isDesktop ? "800px" : "auto",
              backgroundColor: "#F9F9FF",
            }}
          >
            <Box
              className={isDesktop ? classes.box : classes.boxMobile}
              style={{}}
            >
              <Box>
                <Typography
                  style={{
                    fontWeight: 900,
                    fontSize: "42px",
                    ...boldFont,
                    color: "#1A3A6C",
                    marginRight: "15px",
                  }}
                >
                  Update Profile
                </Typography>
                <Box style={{ display: "flex", flexDirection: "row" }}>
                  <Box
                    style={{
                      width: "80px",
                      height: "80px",
                      backgroundColor: "#fbe0d3",
                      borderRadius: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: "15px",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#1A3A6C",
                        fontSize: "40px",
                        ...boldFont,
                      }}
                    >
                      {initial1}
                    </Typography>
                    <Typography
                      style={{
                        color: "#EB6523",
                        fontSize: "40px",
                        ...boldFont,
                      }}
                    >
                      {initial2}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      marginRight: "15px",
                    }}
                  >
                    <Typography>{fname}</Typography>
                    <Typography>{lname}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              style={{
                display: "flex",
                marginTop: isDesktop ? "150px" : "20px",
                justifyContent: "flex-end",
              }}
            >
              <Tabs
                orientation={isDesktop ? "vertical" : "horizontal"}
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
              >
                <Tab
                  className={value === 0 ? classes.active : classes.tabStyleBox}
                  label={
                    <Box className={classes.tabStyle}>
                      <div style={{margin:'auto'}}>
                      <Box>
                        <Typography
                          style={{
                            fontSize: "20px",
                            fontWeight: 900,
                            ...boldFont,
                            color: "#283035",
                          }}
                        >
                          Personal Details
                        </Typography>
                        {isDesktop && (
                          <Typography
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              ...regularFont,
                              color: "#8B8B8B",
                            }}
                          >
                            Edit Your Details
                          </Typography>
                        )}
                      </Box>
                      </div>
                      {isDesktop && (
                        <Box>
                          <img src={rightarrow} />
                        </Box>
                      )}
                    </Box>
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  className={value === 1 ? classes.active : classes.tabStyleBox}
                  label={
                    <Box className={classes.tabStyle}>
                      <Box>
                        <Typography
                          style={{
                            fontSize: "20px",
                            fontWeight: 900,
                            ...boldFont,
                            color: "#283035",
                          }}
                        >
                          Change Password
                        </Typography>
                        {isDesktop && (
                          <Typography
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              ...regularFont,
                              color: "#8B8B8B",
                            }}
                          >
                            Minimum of 6 characters including atleast 1 number.
                          </Typography>
                        )}
                      </Box>
                      {isDesktop && (
                        <Box>
                          <img src={rightarrow} />
                        </Box>
                      )}
                    </Box>
                  }
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
          </Box>
          <Box
            style={{
              width: isDesktop ? "50%" : "100% ",
              height: "760px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <TabPanel value={value} index={0}>
              <Box
                className={
                  isDesktop
                    ? classes.personalDetails
                    : classes.personalDetailsMobile
                }
              >
                <Box>
                  <Typography
                    style={{
                      fontWeight: 900,
                      fontSize: "32px",
                      ...boldFont,
                      color: "#1A3A6C",
                      marginBottom: "10px",
                    }}
                  >
                    Personal Details
                  </Typography>
                </Box>
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  {" "}
                  <TextField
                    id="outlined-basic"
                    label="First Name"
                    placeholder="Last Name"
                    variant="outlined"
                    value={user.firstName}
                    onChange={handleFNameChange}
                    style={{ width: "330px", marginBottom: "20px" }}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Last Name"
                    placeholder="Last Name"
                    variant="outlined"
                    value={user.lastName}
                    onChange={handleLNameChange}
                    style={{ width: "330px", marginBottom: "20px" }}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Phone"
                    variant="outlined"
                    type="text"
                    name="Phone"
                    placeholder="Phone"
                    value={primaryContactNumber.number}
                    onChange={onChangePhone}
                    style={{ width: "330px", marginBottom: "20px" }}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Address Line 1"
                    variant="outlined"
                    type="text"
                    name="line1"
                    placeholder="Line1"
                    value={billingAddress.line1}
                    onChange={onChangeTextForBilling}
                    style={{ width: "330px", marginBottom: "20px" }}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Address Line 2"
                    variant="outlined"
                    type="text"
                    name="line2"
                    placeholder="Line2"
                    value={billingAddress.line2}
                    onChange={onChangeTextForBilling}
                    style={{ width: "330px", marginBottom: "20px" }}
                  />
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">State</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="State"
                      value={billingAddress.state}
                      onChange={handleChangeState}
                      style={{
                        width: "330px",
                        marginRight: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      {stateList.map((state: any) => {
                        return <MenuItem value={state}>{state}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">City</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={billingAddress.city}
                      onChange={handleChangeCity}
                      label="City"
                      style={{ width: "330px", marginBottom: "20px" }}
                    >
                      {cityList.map((city: any) => {
                        return <MenuItem value={city}>{city}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Zip Code
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={billingAddress.pinCode}
                      onChange={handleChangeZipcode}
                      label="Zipcode"
                      style={{
                        width: "330px",
                        marginRight: "40px",
                        marginBottom: "20px",
                      }}
                    >
                      {pinCodeList.map((pinCode: any) => {
                        return <MenuItem value={pinCode}>{pinCode}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                  <Button
                    onClick={handleSubmit}
                    style={{
                      backgroundColor: "#1A3A6C",
                      color: "#FFFFFF",
                      height: "50px",
                      width: isDesktop ? "40%" : "90%",
                    }}
                  >
                    <Typography>Submit</Typography>
                  </Button>
                </Box>
              </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "22%",
                  marginRight: isDesktop ? "40%" : "auto",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography
                    style={{
                      fontWeight: 900,
                      fontSize: "32px",
                      ...boldFont,
                      color: "#1A3A6C",
                    }}
                  >
                    Change Password
                  </Typography>
                </Box>
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  {" "}
                  {/* <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                  Existing Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={password.showPassword ? "text" : "password"}
                    value={password.pwd}
                    onChange={handleExistingPwdChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {password.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Existing Password"
                  />
                </FormControl> */}
                  <FormControl sx={{ m: 1, width: "30ch" }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">
                      New Password
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={password.showPassword1 ? "text" : "password"}
                      value={password.newPwd}
                      onChange={handleNewPwdChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword1}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {password.showPassword1 ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="New Password"
                    />
                  </FormControl>
                  {passwordErr ? (
                    <span style={{ color: "red" }}>Password Invalid</span>
                  ) : (
                    ""
                  )}
                  <FormControl sx={{ m: 1, width: "30ch" }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">
                      Confirm Password
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={password.showPassword2 ? "text" : "password"}
                      value={password.cPwd}
                      onChange={handleCPwdChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword2}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {password.showPassword2 ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Confirm Password"
                    />
                  </FormControl>
                  {cPassswordErr ? (
                    <span style={{ color: "red" }}>
                      Confirm passsword not matched
                    </span>
                  ) : (
                    ""
                  )}
                  <Button
                    style={{
                      backgroundColor: "#1A3A6C",
                      color: "#FFFFFF",
                      width: "90%",
                      height: "50px",
                      marginTop: "10px",
                      marginLeft: "5%",
                    }}
                    onClick={passwordChangeSubmit}
                  >
                    {loading ? (
                      <img
                        style={{ width: "50%", height: "100%" }}
                        src={loading2}
                      ></img>
                    ) : (
                      <Typography>Submit</Typography>
                    )}
                  </Button>
                  <br />
                  {/* <Typography style={{ fontSize: "12px", color: "#e6e6e6", ...regularFont, fontWeight: 500 }}>
                  Minimum of 6 characters
                </Typography> */}
                  {/* <Typography style={{ fontSize: "12px", color: "#e6e6e6" }}>
                  including atleast 1 number.
                </Typography> */}
                </Box>
              </Box>
            </TabPanel>
          </Box>
        </Box>
      )}

      <Footer />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
      />
    </>
  );
};

const mapState = (state) => {
  const { authentication, alert, loggedIn } = state;
  return { authentication, alert, loggedIn };
};

const actionCreators = {
  UpdateProfile: userActions.updateProfile,
  ChangePassword: userActions.updatePwd,
  clearAlerts: alertActions.clear,
};

export default connect(mapState, actionCreators)(UpdateProfile);
