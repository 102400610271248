
import TestimonialsStyles from "./Testimonials.styles";
import { Box, Grid, Typography, Divider, useMediaQuery, useTheme } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import star from "../../../../assets/images/Testomonial/star.svg";
import star3 from "../../../../assets/images/Testomonial/3star.svg";
import left from "../../../../assets/images/Testomonial/left-quote 1.svg";
import { boldFont } from '../../../../utils/styles';
import { regularFont } from '../../../../utils/styles';

const data = [
    {
        description: "At first my wife was very skeptical about using this device, but Deep Sleep’s team was very kind to speak to my wife about the process on the phone. She felt much calmer and at ease before, during and after.",
        personName: "Macial White",
        img: <img src={star} alt="star" />

    },
    {
        description: "I tried the at-home testing kit from Deep Sleep and it was very easy to set up, use and send back. They were quick and efficient!",
        personName: "Johan Williams",
        img: <img src={star3} alt="star" />


    }, {
        description: "The physician who I consulted with after the test was approachable, friendly and conversational. He explained the entire report with clarity and answered all my follow-up questions. I was satisfied by the service and conversation!",
        personName: "Macial White",
        img: <img src={star3} alt="star" />

    }
]

const Testimonials = () => {
    const classes = TestimonialsStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

    const getStaticText = () => {
        return <Box className={classes.getStaticBox} >
            <Divider className={isDesktop ? classes.staticContentBoxDividerForDeskTop : classes.staticContentBoxDividerForMobile}>Clients Reviews</Divider >
            <Typography className={
                isDesktop
                    ? classes.staticTextDeskTop
                    : classes.staticTextMobile
            }>Testimonials</Typography>
            <Box>
                <img src={left} alt="left" />
            </Box>
        </Box>
    }


    const getTestimonialsText = () => {
        return <>
            <Carousel autoPlay navButtonsAlwaysVisible={true} indicators={false} navButtonsProps={{
                style: {
                    backgroundColor: '#fff',
                    color: "black",
                }
            }}>
                {data.map((ab) => (
                    <Box className={classes.getTestominalBox}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} >
                                <Typography className={
                                    isDesktop
                                        ? classes.tabDescriptionDeskTop
                                        : classes.tabDescriptionMobile
                                }>{ab.description}</Typography>
                                <Typography className={
                                    isDesktop
                                        ? classes.tabPersonNameDeskTop
                                        : classes.tabPersonNameMobile
                                }>{ab.personName}</Typography>
                                <Typography className={classes.getTestominalTypo}>{ab.img}</Typography>
                            </Grid>

                        </Grid>

                    </Box>


                ))}
            </Carousel>
        </>
    }


    const getBody = () => {
        return <Box className={classes.backgroundImg}>
            {getStaticText()}
            <Box display="flex" justifyContent="center">
                <Box className={
                    isDesktop ? classes.desktopCarousel : classes.mobileCarousel
                } >
                    {getTestimonialsText()}
                </Box>
            </Box>
        </Box>

    }
    return getBody();


}

export default Testimonials;