import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Obj } from "reselect/es/types";
import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { boldFont, regularFont } from "../../../utils/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#DEE1EB",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

interface CustomProps {
    tabledata: any[];
    user: any;
}

export default function CustomizedTables(props: CustomProps) {
    const [userDetails, setUserDetails] = useState({});
    let user = props.user;
    let ShippingCost = user.ShippingCost;
    let Total = user.total;
    useEffect(() => {
        setUserDetails(user);
    }, []);
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell
                            style={{ color: "#283035", ...boldFont, fontSize: "24px" }}
                            align="left"
                        >
                            Product
                        </StyledTableCell>
                        <StyledTableCell
                            style={{ color: "#283035", ...boldFont, fontSize: "24px" }}
                            align="left"
                        >
                            Sub Total
                        </StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.tabledata.map((row) => (
                        <StyledTableRow>
                            <StyledTableCell
                                style={{ color: "#283035", ...regularFont, fontSize: "18px" }}
                                component="th"
                                scope="row"
                            >
                                {row.productName}
                            </StyledTableCell>
                            <StyledTableCell
                                style={{ color: "#283035", ...boldFont, fontSize: "18px" }}
                                align="left"
                            >
                                ${row.actualUnitPrice}
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                    <StyledTableRow>
                        <StyledTableCell
                            style={{ color: "#283035", ...regularFont, fontSize: "18px" }}
                            component="th"
                            scope="row"
                        >
                            Shipping
                        </StyledTableCell>

                        <StyledTableCell
                            style={{ color: "#283035", ...boldFont, fontSize: "18px" }}
                            align="left"
                        >
                            ${30}
                        </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <StyledTableCell
                            style={{ color: "#283035", ...boldFont, fontSize: "18px" }}
                            component="th"
                            scope="row"
                        >
                            Total
                        </StyledTableCell>

                        <StyledTableCell
                            style={{ color: "#283035", ...boldFont, fontSize: "18px" }}
                            align="left"
                        >
                            ${Total}
                        </StyledTableCell>
                    </StyledTableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}
