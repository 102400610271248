import * as React from "react";
import { useEffect, useState } from "react";
import { styled, Box } from "@mui/system";
import ModalUnstyled from "@mui/base/ModalUnstyled";
// import high from "../../../../assets/images/Model/high.png";
// import low from "../../../../assets/images/Model/low.png";
import { Button, Typography } from "@material-ui/core";
import { boldFont, regularFont } from "../../../utils/styles";
// import AddUser from "../../../../assets/images/Model/AddUser.png";
// import Login from "../../../../assets/images/Model/Login.png";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import LoginIcon from "@mui/icons-material/Login";
import { TextField } from "@mui/material";
import { Link } from "react-router-dom";
import urls from "../../../global/constants/UrlConstants";
import history from "../../../utils/history";
import loading2 from "../../../assets/images/Login/loading2.gif";
import { userActions } from "../../../redux/_actions/user.action";
import { userService } from "../../../services/sleepTestUserService-service";
import { openErrorNotification } from "../../../helpers/methods";

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  p: 2,
  px: 4,
  pb: 3,
};

function ModalUnstyledDemo2(open) {
  const [email, setEmail] = useState("");
  const [tracking, setTracking] = useState("");
  const [loading, setLoading] = useState(false);
  const [invalid, setInvalid] = useState(false);

  const changetracking = (e) => {
    setLoading(false);
    setTracking(e.target.value);
  };

  let current_user = localStorage.getItem("current_user");
  // let username = JSON.parse(localStorage.getItem("current_user")!);
  // var useremail = username.email;
  // console.log(useremail);

  const EquipmentShipped = (e) => {
    var user = open.user;

    user.trackingNumber = tracking;
    if (tracking !== undefined && tracking !== "" && tracking !== null) {
      setInvalid(false);
      setLoading(true);

      userService.productShipped(user.id,tracking).then(
        
        () => {

          setLoading(false);
          open.handleClose();
        },
        (error: any) => {

          // alert("something Went wrong");
          openErrorNotification(error);
        }
      );
      // console.log(user);
    } else {
      setInvalid(true);
    }
  };

  return (
    <div>
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open.open}
        onClose={open.handleClose}
        BackdropComponent={Backdrop}
      >
        <Box
          style={{
            backgroundColor: "white",
            width: "20%",
            height: "50%",
            borderRadius: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "0% 2% 1% 2%",
          }}
        >
          {/* <img src={high}></img> */}
          <h2 id="unstyled-modal-title">Product Shipping Details</h2>
          <Typography style={{ textAlign: "center" }}>
            Please enter UPS tracking number to send the shipping details to
            User.
          </Typography>

          <TextField
            id="outlined-basic"
            label="Tracking Number"
            variant="outlined"
            name="tracking"
            value={tracking}
            onChange={changetracking}
            placeholder="Tracking Number"
            style={{ width: "300px", marginTop: "30px" }}
          />
          {invalid ? (
            <span style={{ color: "red" }}>Please enter tracking number</span>
          ) : (
            ""
          )}
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Button
              type="submit"
              fullWidth
              onClick={EquipmentShipped}
              variant="contained"
              style={{
                ...regularFont,
                fontWeight: 500,
                fontSize: "16px",
                color: "#ffffff",
                backgroundColor: "#EB6523",
                width: "130px",
                height: "55px",
                borderRadius: "15px",
                marginTop: "20px",
                marginRight: "20px",
              }}
            >
              {loading ? (
                <img
                  style={{ width: "30%", height: "100%" }}
                  src={loading2}
                ></img>
              ) : (
                "Submit"
              )}
            </Button>
            <Button
              type="submit"
              fullWidth
              onClick={open.handleClose}
              variant="contained"
              style={{
                ...regularFont,
                fontWeight: 500,
                fontSize: "16px",
                color: "#ffffff",
                backgroundColor: "#EB6523",
                width: "130px",
                height: "55px",
                borderRadius: "15px",
                marginTop: "20px",
                marginLeft: "20px",
              }}
            >
              Back
            </Button>
          </Box>
        </Box>
      </StyledModal>
    </div>
  );
}

export default ModalUnstyledDemo2;
