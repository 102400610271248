import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { boldFont } from "../../utils/styles";
import { regularFont } from "../../utils/styles";

const BuyTestStyles = makeStyles((theme: Theme) => ({
  Box1: { display: "flex" },
  Box2: {
    display: "flex",
    flexDirection: "column",
    width: "50% !important",
    height: "100% !important",
    backgroundColor: "#F9F9FF !important",
  },
  Box3: {},
  Box4: {
    marginLeft: "30% !important",
    marginBottom: "30px !important",
    display: "flex",
    justifyContent: "space-between",
    marginRight: "10% !important",
  },
  Box5: {
    width: "50% !important",
    height: "100% !important",
    backgroundColor: "#ffffff",
  },
  Box6: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginLeft: "8% !important",
    marginTop: "40px !important",
    marginBottom: "50px !important",
  },
  Box7: { display: "flex", flexDirection: "row", alignItems: "center" },
  Box8: { marginRight: "40%", display: "flex", alignItems: "center" },
  Box9: {
    marginLeft: "8%",
    display: "flex",
    marginTop: "20px",
    flexDirection: "row",
  },
  Box10: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "40px",
  },
  Box11: { display: "flex", flexDirection: "row", marginLeft: "8%" },
  Box12: { marginRight: "40%" },
  Box13: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  Box14: { display: "flex", flexDirection: "row", marginLeft: "8%" },
  Box15: { marginRight: "40%" },
  Box16: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  Box17: { display: "flex", flexDirection: "row", marginLeft: "8%" },
  Box18: { marginRight: "40%" },
  Box19: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  Box20: { display: "flex", flexDirection: "row", marginLeft: "8%" },
  Box21: { marginRight: "40%" },

  Typo1: {
    fontSize: "3vh !important",
    fontWeight: 750,
    color: "#283035 !important",
    ...boldFont,
  },
  Typo2: {
    fontSize: "3vh !important",
    fontWeight: 750,
    color: "#283035 !important",
    marginTop: "20px !important",
    ...boldFont,
  },
  Typo3: {
    fontSize: "2.47vh !important",
    fontWeight: 750,
    color: "#283035 !important",
    marginLeft: "20px !important",
    ...boldFont,
  },
  Typo4: {
    fontSize: "2.4vh !important",
    fontWeight: 750,
    color: "#283035 !important",
    ...boldFont,
  },
  Typo5: {
    fontSize: "18px !important",
    fontWeight: 750,
    color: "#283035 !important",
    ...boldFont,
  },
  Typo6: { ...regularFont },
  Typo7: {
    fontSize: "22px !important",
    fontWeight: 750,
    color: "#283035 !important",
    ...boldFont,
  },

  Input1: {
    marginTop: "5px !important",
    width: "55% !important",
    height: "55px !important",
    fontSize: "20px !important",
    paddingLeft: "15px !important",
    paddingRight: "15px !important",
    border: "1px solid rgba(40, 48, 53, 0.15) !important",
    borderRadius: "15px",
  },
  Input2: {
    marginTop: "5px !important",
    width: "55% !important",
    height: "55px !important",
    fontSize: "20px !important",
    paddingLeft: "15px !important",
    paddingRight: "15px !important",
    border: "1px solid rgba(40, 48, 53, 0.15) !important",
    borderRadius: "15px !important",
  },
  Input3: {
    marginTop: "18px !important",
    marginRight: "5% !important",
    width: "32% !important",
    height: "55px !important",
    fontSize: "20px !important",
    paddingLeft: "15px !important",
    paddingRight: "15px !important",
    border: "1px solid rgba(40, 48, 53, 0.15) !important",
    borderRadius: "15px !important",
  },
  Button1: {
    marginTop: "20px !important",
    width: "200px !important",
    height: "65px !important",
    border: "1px solid rgba(40, 48, 53, 0.15) !important",
    borderRadius: "15px !important",
    backgroundColor: "#1A3A6C !important",
    color: "#ffffff !important",
  },
  Button2: {
    marginTop: "20px !important",
    width: "200px !important",
    height: "65px !important",
    borderRadius: "15px !important",
    backgroundColor: "#F9F9FF !important",
    color: "#1A3A6C !important",
  },
  Button3: {
    marginTop: "20px !important",
    width: "18% !important",
    height: "55px !important",
    border: "1px solid rgba(40, 48, 53, 0.15) !important",
    borderRadius: "15px !important",
    backgroundColor: "#1A3A6C !important",
    color: "#ffffff !important",
  },
  Divider1: {
    marginRight: "30% !important",
    marginLeft: "6% !important",
    borderBottomWidth: 3,
    backgroundColor: "#8B8B8B !important",
  },
  Divider2: {
    marginRight: "30% !important",
    marginLeft: "6% !important",
    marginTop: "20px !important",
    marginBottom: "20px !important",
  },

  Box1Mobile: { display: "flex", flexDirection: "column" },
  Box2Mobile: {
    display: "flex",
    flexDirection: "column",
    width: "100% !important",
    height: "100% !important",
    backgroundColor: "#F9F9FF !important",
  },
  Box3Mobile: {},
  Box4Mobile: {
    marginBottom: "30px !important",
    display: "flex",
    justifyContent: "space-evenly",
  },
  Box5Mobile: {
    width: "100% !important",
    height: "100% !important",
    backgroundColor: "#ffffff",
  },
  Box6Mobile: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginLeft: "8% !important",
    marginRight: "8% !important",
    marginTop: "40px !important",
    marginBottom: "50px !important",
  },
  Box7Mobile: { display: "flex", flexDirection: "row", alignItems: "center" },
  Box8Mobile: { display: "flex", alignItems: "center" },
  Box9Mobile: {
    marginLeft: "8%",
    display: "flex",
    marginTop: "20px",
    flexDirection: "row",
    justifyContent: "space-between",
    marginRight: "8%",
  },
  Box10Mobile: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "40px",
    marginRight: "8% !important",
  },
  Box11Mobile: { display: "flex", flexDirection: "row", marginLeft: "8%" },
  Box12Mobile: {},
  Box13Mobile: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginRight: "8% !important",
  },
  Box14Mobile: { display: "flex", flexDirection: "row", marginLeft: "8%" },
  Box15Mobile: {},
  Box16Mobile: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginRight: "8% !important",
  },
  Box17Mobile: { display: "flex", flexDirection: "row", marginLeft: "8%" },
  Box18Mobile: {},
  Box19Mobile: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginRight: "8% !important",
  },
  Box20Mobile: { display: "flex", flexDirection: "row", marginLeft: "8%" },
  Box21Mobile: {},

  Typo1Mobile: {
    fontSize: "2.88vh !important",
    fontWeight: 750,
    color: "#283035 !important",
    ...boldFont,
  },
  Typo2Mobile: {
    fontSize: "3vh !important",
    fontWeight: 750,
    color: "#283035 !important",
    marginTop: "20px !important",
    ...boldFont,
  },
  Typo3Mobile: {
    fontSize: "2.47vh !important",
    fontWeight: 750,
    color: "#283035 !important",
    marginLeft: "20px !important",
    ...boldFont,
  },
  Typo4Mobile: {
    fontSize: "2.4vh !important",
    fontWeight: 750,
    color: "#283035 !important",
    ...boldFont,
  },
  Typo5Mobile: {
    fontSize: "18px !important",
    fontWeight: 750,
    color: "#283035 !important",
    ...boldFont,
  },
  Typo6Mobile: { ...regularFont },
  Typo7Mobile: {
    fontSize: "22px !important",
    fontWeight: 750,
    color: "#283035 !important",
    ...boldFont,
  },
  Typo8Mobile: {
    fontSize: "1.5vh !important",
  },

  Input1Mobile: {
    marginTop: "20px !important",
    width: "70% !important",
    height: "55px !important",
    fontSize: "20px !important",
    paddingLeft: "15px !important",
    paddingRight: "15px !important",
    border: "1px solid rgba(40, 48, 53, 0.15) !important",
    borderRadius: "15px",
  },
  Input2Mobile: {
    marginTop: "10px !important",
    width: "70% !important",
    height: "55px !important",
    fontSize: "20px !important",
    paddingLeft: "15px !important",
    paddingRight: "15px !important",
    border: "1px solid rgba(40, 48, 53, 0.15) !important",
    borderRadius: "15px !important",
  },
  Input3Mobile: {
    marginTop: "18px !important",

    width: "55% !important",
    height: "55px !important",
    fontSize: "20px !important",
    paddingLeft: "15px !important",
    paddingRight: "15px !important",
    border: "1px solid rgba(40, 48, 53, 0.15) !important",
    borderRadius: "15px !important",
  },
  Button1Mobile: {
    marginTop: "20px !important",
    width: "30% !important",
    height: "65px !important",
    border: "1px solid rgba(40, 48, 53, 0.15) !important",
    borderRadius: "15px !important",
    backgroundColor: "#1A3A6C !important",
    color: "#ffffff !important",
  },
  Button2Mobile: {
    marginTop: "20px !important",
    width: "30% !important",
    height: "65px !important",
    borderRadius: "15px !important",
    backgroundColor: "#F9F9FF !important",
    color: "#1A3A6C !important",
  },
  Button3Mobile: {
    marginTop: "20px !important",
    width: "30% !important",
    height: "55px !important",
    border: "1px solid rgba(40, 48, 53, 0.15) !important",
    borderRadius: "15px !important",
    backgroundColor: "#1A3A6C !important",
    color: "#ffffff !important",
  },
  Divider1Mobile: {
    marginLeft: "6% !important",
    marginRight: "6% !important",
    borderBottomWidth: 3,
    backgroundColor: "#8B8B8B !important",
  },
  Divider2Mobile: {
    marginLeft: "6% !important",
    marginRight: "6% !important",
    marginTop: "20px !important",
    marginBottom: "20px !important",
  },
  BoxForDeskTop: {},
  BoxForMobile: {
    flexDirection: "column-reverse",
  },
  invalidField: {
    border: "1px solid red !important",
  },
}));

export default BuyTestStyles;
