import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { regularFont } from "../../utils/styles";

const DrawerComponentStyles = makeStyles((theme: Theme) => ({
  link: {
    textDecoration: "none",
    color: "black",
    fontSize: "16px",
    backgroundColor: "none",
    ...regularFont,
    fontWeight: 900,
    "&:hover": {
      color: "#EB6523",
      borderBottom: "5px solid #EB6523",
    },
  },
  icon: {
    color: "white",
  },
  deepsleepLogo: {
    maxHeight: "42px",
    padding: "5px",
  },
  displayFlexAlignCenter: {
    display: "flex",
    alignItems: "center",
  },
  topHeaderImg: {
    display: "inline-block",
    marginLeft: "20px",
    marginTop: "1px",
  },
}));

export default DrawerComponentStyles;
