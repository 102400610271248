import { autocompleteClasses, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import bg from "../../../../assets/images/bg.png";
import { boldFont, regularFont } from "../../../../utils/styles";

const HomeSliderStyles = makeStyles((theme: Theme) => ({
  grid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: "url(" + bg + ")",
    backgroundColor: "none",
    padding: "40px 0 ",
    backgroundSize: "cover",
  },
  desktopCarousel: {
    width: "80%",
    minHeight: "200px",
  },
  mobileCarousel: {
    width: "100%",
  },
  desktopTitle: {
    ...boldFont,
    fontSize: "75px",
    fontWeight: 900,
    color: "#1A3A6C",
    marginBottom: "0px",
    justifyContent: "flex-start",
    width: "100%",
    lineHeight: "1",
  },
  mobileTitle: {
    ...boldFont,
    textAlign: "center",
    fontWeight: 900,
    color: "#1A3A6C",
  },
  desktopCarouselImage: {
    width: "100%",
  },
  mobileCarouselImage: {
    width: "300px",
  },
  desktopDescription: {
    ...regularFont,
    fontSize: "24px",
    fontWeight: 900,
    justifyContent: "flex-start",
    width: "100%",
  },
  mobileDescription: {
    ...regularFont,
    textAlign: "center",
    fontSize: "16px",
    fontWeight: 400,
  },
  desktopBox: {
    padding: "20px 0",
    marginLeft: "100px",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  mobileBox: {
    padding: "20px 0",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
}));

export default HomeSliderStyles;
