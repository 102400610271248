import {
  Box,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import clsx from "clsx"
import EpworthCardStyles from "./EpworthCard.styles";

interface CustomProps {
  question: string;
  scaleValue: string
}

const Epworth = (props: CustomProps) => {
  const classes = EpworthCardStyles()

  return (
    <>
      <Box
        style={{
          boxShadow: "0px 15px 30px 0px rgba(0, 0, 0, 0.05)",
          borderRadius: "20px",
          backgroundColor: "#FFFFFF",
          height: "100px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Box style={{ marginLeft: "30px" }}>
          <Typography>{props.question}</Typography>
        </Box>

        <Box
          style={{
            width: "222px",
            height: 60,
            backgroundColor: "#F7E1DB",
            borderRadius: 15,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
            marginRight: "30px",
          }}
        >
          {
            [0, 1, 2, 3].map((ele: any) => {
              return <Box
                className={
                  clsx(classes.defaultStyle, {
                    [classes.activeIndex]: ele.toString() === props.scaleValue
                  })
                }
              >
                <Typography>{ele}</Typography>
              </Box>
            })
          }
          {/* 
          <Box
            style={{
              width: 30,
              height: 30,
              display: "flex",
              backgroundColor: "#ffffff",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 30,
            }}
          >
            <Typography>1</Typography>
          </Box>
          <Box
            style={{
              width: 30,
              height: 30,
              backgroundColor: "#EB6523",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 30,
            }}
          >
            <Typography>2</Typography>
          </Box>
          <Box
            style={{
              width: 30,
              height: 30,
              display: "flex",
              backgroundColor: "#ffffff",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 30,
            }}
          >
            <Typography>3</Typography>
          </Box> */}
        </Box>
      </Box>
    </>
  );
};

export default Epworth;
