import Header from "../../../shared/Header/Header";
import Footer from "../../../shared/Footer/Footer";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Stack,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import report from "../../../assets/images/Dashboard/report.png";
import React, { useEffect, useState } from "react";
import Banner from "../../../shared/Banner/Banner";
import { regularFont } from "../../../utils/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Loading from "../../Loading/Loading";
import PreliminaryAssessmentTable from "./Component/PreliminaryAssessmentTable/PreliminaryAssessmentTable";
import FinalReport from "./Component/FinalReport/FinalReport";
import { userService } from "../../../services/sleepTestUserService-service";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import detailbg from "../../../assets/images/Dashboard/detailbg.png";
import loading2 from "../../../assets/images/Login/loading2.gif";
import ViewUserDetailStyle from "./ViewUserDetail.style";
import {
  openErrorNotification,
  openSuccessNotification,
} from "../../../helpers/methods";
import { DropzoneArea } from "react-mui-dropzone";
import StopBang from "./Component/StopBangCard/StopBangCard";

import Epworth from "./Component/EpworthCard/EpworthCard";
const notToInclude = [
  "Date Of Birth",
  "Total ESS Score",
  "Total Score",
  "totalCal",
  "height",
  "weight",
];

const ViewUserDetail = (props) => {
  const classes = ViewUserDetailStyle();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("");

  const [patientsDetails, setpatientsDetails] = useState<any>();
  const [assessment, setAssessment] = useState([]);
  const [finalReport, setFinalReport] = useState<any[]>([]);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [isStopBangQuestions, setIsStopBangQuestions] =
    useState<boolean>(false);
  const id = new URLSearchParams(props.location.search).get("id");
  const name = new URLSearchParams(props.location.search).get("name");

  useEffect(() => {
    getUser(id);
    let username = JSON.parse(localStorage.getItem("current_user")!);
    var role = username.role;
    setRole(role);
    getMyReports(id);
  }, [id, name]);


  useEffect(() => {
    document.title = "User Details";
  }, []);

  const getUser = async (id) => {
    try {
      setIsLoading(true);
      const response = await userService.getUser(id);
      setIsLoading(false);
      setpatientsDetails(response);
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(error.toString())
    }
  };

  const getMyReports = async (id) => {
    try {
      const response = await userService.getReport(id);
      setAssessment(response.Assessment);
      setFinalReport([...response.Report]);
    } catch (error: any) {
      openErrorNotification(error.toString())
    }
  };

  const changeBox = (id: string) => {
    if (id === "Epworth Sleepiness") {
      setIsStopBangQuestions(false);
    } else if (id === "Stop Bang Questions") {
      setIsStopBangQuestions(true);
    }
  };

  const handleFileUploadForBanner = async (files: File[]) => {
    try {
      let type;
      files.map((ele) => {
        type = ele.type;
      });
      if (files.length === 1) {
        if (type === "application/pdf") {
          setLoading(true);
          const response = await userService.uploadFinalReport(
            files[0],
            id,
            name
          );
          openSuccessNotification("Upload Final Report SuccessFully.");
          setLoading(false);
          getMyReports(id);
        } else {
          openErrorNotification(
            "Files with pdf extension only allowed. Please select PDF file only."
          );
        }
      }
    } catch (error: any) {
      setLoading(false);
      openErrorNotification(error);
    }
  };

  const onDelete = (documentId, path) => {
    let tempDocument = {
      id: documentId,
      path: path,
    };
    userService.deleteReport(tempDocument).then(
      (response) => {
        getMyReports(id);
        openSuccessNotification("Document deleted successfully.");
      },
      (error) => {}
    );
  };

  const replaceAll = (str) => {
    var re = new RegExp("/\\|\\/|\\:|\\*|\\?|\\<|\\>|\\|", "gi");

    return str.replace(re, "");
  };

  return (
    <>
      <Header />
      <Banner img={report} header1="Home/ Dashboard/ Report" header2="Report" />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Container
            maxWidth={false}
            className={
              isDesktop ? classes.containerDesktop : classes.containerMobile
            }
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "1% 0 3% 0",
                }}
              >
                <Typography
                  style={{
                    color: "#1A3A6C",
                    fontSize: "32px",
                    fontWeight: 900,
                    ...regularFont,
                  }}
                >
                  My Report
                </Typography>
              </Box>
              <Box>
                <Link style={{ textDecoration: "none" }} to="/dashboard">
                  <Button
                    startIcon={<ArrowBackIcon style={{ color: "#EB6523" }} />}
                    style={{
                      boxShadow: "0px 15px 30px 0px rgba(0, 0, 0, 0.09)",
                      borderRadius: "20px",
                      textTransform: "none",
                      padding: "15px",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#1A3A6C",
                        fontSize: "18px",
                        fontWeight: 700,
                        ...regularFont,
                      }}
                    >
                      Back To Dashboard
                    </Typography>
                  </Button>
                </Link>
              </Box>
            </Box>
            <Grid container style={{ backgroundImage: `url(${detailbg})` }}>
              <Grid
                item
                xs={12}
                md={4}
                sm={12}
                lg={4}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "10px",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <Box display="flex">
                    <Typography className={classes.patientDetailsText}>
                      Name:{" "}
                    </Typography>
                    <Typography className={classes.patientDetailsText}>
                      {patientsDetails && patientsDetails.firstName}
                    </Typography>
                  </Box>
                  <Box display="flex">
                    <Typography className={classes.patientDetailsText}>
                      Gender:{" "}
                    </Typography>
                    <Typography className={classes.patientDetailsText}>
                      {patientsDetails &&
                        patientsDetails.customAttributes.map((ele: any) => {
                          if (ele.name === "Are you male?") {
                            if (ele.value) {
                              return ele.value === "true" ? "Female" : "Male";
                            }
                          }
                        })}
                    </Typography>
                  </Box>
                  {/* <Box display="flex">
                                <Typography className={classes.patientDetailsText}>
                                    Order ID:
                                </Typography>
                                <Typography className={classes.patientDetailsText}>
                                    {patientsDetails && patientsDetails.id}
                                </Typography>
                            </Box> */}
                  <Box display="flex">
                    <Typography className={classes.patientDetailsText}>
                      Status:
                    </Typography>
                    <Typography className={classes.patientDetailsText}>
                      {patientsDetails && patientsDetails.status}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={4} sm={12} lg={4}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    className={
                      isDesktop
                        ? classes.preliminaryTextDesktop
                        : classes.preliminaryTextMobile
                    }
                  >
                    Preliminary Assessment Reports
                  </Typography>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      maxHeight: "280px",
                      overflow: "auto",
                    }}
                  >
                    <PreliminaryAssessmentTable tableData={assessment} />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={4} sm={12} lg={4}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    className={
                      isDesktop
                        ? classes.preliminaryTextDesktop
                        : classes.preliminaryTextMobile
                    }
                  >
                    Final Reports
                  </Typography>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      height: "125px",
                      overflow: "auto",
                    }}
                  >
                    <FinalReport
                      tableData={finalReport}
                      deleteDocument={onDelete}
                    />
                  </Box>

                  {props.user.role === "Admin" ||
                  props.user.role === "SleepPhysician" ? (
                    <Box
                      style={{
                        height: "150px",
                        width: "100%",
                        padding: "5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {loading ? (
                        <img
                          style={{
                            width: "20%",
                            height: "50%",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          src={loading2}
                        ></img>
                      ) : (
                        <Box style={{ width: "90%" }}>
                          <DropzoneArea
                            onChange={(files) =>
                              handleFileUploadForBanner(files)
                            }
                            dropzoneText={"Upload PDF"}
                            dropzoneClass={classes.dropZoneArea}
                            acceptedFiles={["image/*", "application/pdf"]}
                            showAlerts={false}
                            showPreviewsInDropzone={false}
                            filesLimit={1}
                          />
                        </Box>
                      )}
                    </Box>
                  ) : null}
                </Box>
              </Grid>
            </Grid>

            {patientsDetails && patientsDetails.customAttributes ? (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "left",
                  paddingBottom: "20px",
                  paddingTop: "20px",
                }}
              >
                <Stack direction={isDesktop ? "row" : "column"} spacing={1}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    onClick={() => changeBox("Epworth Sleepiness")}
                    style={{
                      ...regularFont,
                      fontWeight: 500,
                      fontSize: "16px",
                      color: "#ffffff",
                      backgroundColor: "#1A3A6C",
                      minWidth: "200px",
                      maxWidth: "300px",
                      height: "50px",
                      borderRadius: "30px",
                    }}
                  >
                    Epworth Sleepiness
                  </Button>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    onClick={() => changeBox("Stop Bang Questions")}
                    style={{
                      ...regularFont,
                      fontWeight: 500,
                      fontSize: "16px",
                      color: "#ffffff",
                      backgroundColor: "#1A3A6C",
                      minWidth: "200px",
                      maxWidth: "300px",
                      height: "50px",
                      borderRadius: "30px",
                    }}
                  >
                    Stop Bang Questions
                  </Button>
                </Stack>
              </Box>
            ) : null}
            {isStopBangQuestions ? (
              <Box>
                
                {patientsDetails &&
                  patientsDetails.customAttributes &&
                  patientsDetails.customAttributes.map(
                    (ele: any, index: number) => {
                    
                      if (ele.name !== "Are you male?") {
                        if (
                          ele.value === "Yes" ||
                          ele.value === "No" ||
                          ele.value === "true" ||
                          ele.value === "false"
                        ) {
                          return (
                            <StopBang
                              question={ele.name}
                              answer={ele.value}
                              image={replaceAll(ele.name)}
                            />
                          );
                        }
                      }
                    }
                  )}
              </Box>
            ) : (
              <Box>
               
                {patientsDetails &&
                  patientsDetails.customAttributes &&
                  patientsDetails.customAttributes.map(
                    (ele: any, index: number) => {
                      if (
                        !isNaN(parseInt(ele.value)) &&
                        notToInclude.indexOf(ele.name) === -1
                      ) {
                        return (
                          <Epworth question={ele.name} scaleValue={ele.value} />
                        );
                      }
                    }
                  )}
              </Box>
            )}
          </Container>
        </>
      )}

      <Footer />
    </>
  );
};

function mapState(state) {
  const { authentication } = state;
  const { user } = authentication;
  return { user };
}
const actionCreators = {
  // executePaymentSuccessFlow: userActions.executePaymentSuccessFlow,
};

export default connect(mapState, actionCreators)(ViewUserDetail);
