import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const ModalStyles = makeStyles((theme: Theme) => ({

  root: {
    display: 'flex',
  },
}));

export default ModalStyles;

