import Header from "../../../shared/Header/Header";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import Banner from "../../../shared/Banner/Banner";
import services_banner from "../../../assets/images/Services/services_banner.png"
import DisplayData2 from "./components/DisplayData/DisplayData2";
import ServicesFooter from "../ServicesFooter";
import { Repeat } from "@mui/icons-material";
import Footer from "../../../shared/Footer/Footer";
import { useEffect } from "react";

const Services2 = () => {
  useEffect(() => {
    document.title = "Services";
  }, []);
  const getServices = () => {
    return (
      <>
      <Header/>
      <Banner  img={services_banner} header1="Home / Services / Dental Services patients" header2="Dental Services Patients" />
      <DisplayData2/>
      <ServicesFooter/>
      <Footer/>
      </>
    );
  };
  return getServices();
};

export default Services2;