import { useMediaQuery } from "@mui/material";
import React, { Component, useEffect, useState } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import DrawerComponentStyles from "./DrawerComponent.styles";
import { Menu as MenuIcon } from "@mui/icons-material";
import Menu, { MenuProps } from "@mui/material/Menu";
import { alpha, styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { CustomIcon } from "../../global/components";
import { connect } from "react-redux";
import {
  Button,
  Divider,
  Grid,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
// import PlayStore from "../../assets/images/PlayStore.png";
// import AppleStore from "../../assets/images/AppleStore.svg";
import google from "../../assets/images/google.svg";
import apple from "../../assets/images/apple.svg";
import AppleStore from "../../assets/images/AppleStore.svg";
import dstlogosmall1 from "../../assets/images/dstlogo-small1.png";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import PersonAdd from "@mui/icons-material/PersonAdd";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import { boldFont } from "../../utils/styles";
import { userActions } from "../../redux/_actions/user.action";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    marginTop: "20px !important",
    width: "100%",
    left: "0px !important",
    maxWidth: "100vw",
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
      },
      paddingLeft: 25,
    },
  },
}));

const StyledMenu2 = styled((props: MenuProps) => (
  <Menu
    elevation={1}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    marginTop: "20px !important",
    right: "0px !important",
    maxWidth: "100px",
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
      },
      paddingLeft: 25,
    },
  },
}));

const DrawerComponent = (props) => {
  const classes = DrawerComponentStyles();
  const [expandSubMenu, setExpandSubMenu] = useState(false);
  const [expandSubMenuForMobile, setExpandSubMenuForMobile] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const [user, setUser] = useState("");
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const open = Boolean(anchorEl);
  const openForMobile = Boolean(anchorEl2);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickForMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickForMobile = (event: React.MouseEvent<HTMLElement>) => {
    setExpandSubMenuForMobile(true);
    setAnchorEl2(event.currentTarget);
  };
  const handleClickForMenuForMobile = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleCloseForMobile = () => {
    setExpandSubMenuForMobile(false);
    setAnchorEl2(null);
  };
  let current_user = localStorage.getItem("current_user");
  if (current_user) {
    let username = JSON.parse(localStorage.getItem("current_user")!);
    var name = username.name;
  }
  useEffect(() => {
    if (current_user) {
      let username = JSON.parse(localStorage.getItem("current_user")!);
      var name = username.name;
    }
  });

  const handleLogoutClick = async (e) => {
    await props.logout();
  };

  if (current_user) {
    let username = JSON.parse(localStorage.getItem("current_user")!);
    var name = username.name;
    if (username.firstName || username.lastName) {
      var firstName = username.firstName;
      var lastName = username.lastName;
      var initial1 = firstName.charAt(0);
      var initial2 = lastName.charAt(0);
    }
  }

  return (
    <>
      <Box m={2}>
        <Grid container>
          <Grid
            item
            xs={3}
            sm={3}
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <Button
              id="demo-customized-button"
              aria-controls="demo-customized-menu"
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              disableElevation
              onClick={handleClick}
              style={{
                color: "black",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <CustomIcon icon={<MenuIcon />} />
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>
                <Link to="/" className={classes.link}>
                  HOME
                </Link>
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleClose}>
                <Link to="/" className={classes.link}>
                  STORE
                </Link>
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleClose}>
                {" "}
                <Link to="/freeAssessment" className={classes.link}>
                  FREE ASSESSMENT
                </Link>
              </MenuItem>
              <Divider />
              <MenuItem
                className={classes.link}
                onClick={() => setExpandSubMenu(!expandSubMenu)}
              >
                SERVICES
              </MenuItem>
              {expandSubMenu && (
                <>
                  <MenuItem onClick={handleClose}>
                    <Link
                      to="/sleepProblemIndIviduals"
                      className={classes.link}
                    >
                      Sleep Problem Individuals
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Link
                      to="/commercialDriversLicenseHolders"
                      className={classes.link}
                    >
                      Commerical Driving Licence Holders
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Link
                      to="/dentistRecommendPatients"
                      className={classes.link}
                    >
                      Dentist Recommend Patients
                    </Link>
                  </MenuItem>
                </>
              )}
              <Divider />
              <MenuItem onClick={handleClose}>
                {" "}
                <Link to="/contact" className={classes.link}>
                  CONTACT US
                </Link>
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleClose}>
                {" "}
                <Link to="/about" className={classes.link}>
                  ABOUT US
                </Link>
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleClose}>
                <Link to="/blog" className={classes.link}>
                  {" "}
                  BLOG
                </Link>
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleClose}>
                {" "}
                <Link to="/faq" className={classes.link}>
                  {" "}
                  FAQ
                </Link>
              </MenuItem>
              <Box p={1}>
                <Stack direction="row" spacing={2}>
                  <Box className={classes.displayFlexAlignCenter}>
                    <CustomIcon
                      icon={
                        <img
                          src={google}
                          alt="google"
                          className={classes.topHeaderImg}
                        />
                      }
                    />
                    <CustomIcon
                      icon={
                        <img
                          src={apple}
                          alt="apple"
                          className={classes.topHeaderImg}
                        />
                      }
                    />
                  </Box>
                </Stack>
              </Box>
            </StyledMenu>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Typography>
              <img
                src={dstlogosmall1}
                alt={"dstlogosmall1"}
                className={classes.deepsleepLogo}
              />
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {current_user ? (
              <>
                {isDesktop ? (
                  <CustomIcon
                    icon={<PersonAdd />}
                    onClick={handleClickForMobile}
                  />
                ) : (
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      backgroundColor: "#fbe0d3",
                      width: "52px",
                      height: "50px",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50px",
                      // paddingTop: "3px",
                      marginTop: "5px",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#1A3A6C",
                        fontSize: "30px",
                        ...boldFont,
                      }}
                    >
                      {initial1}
                    </Typography>
                    <Typography
                      style={{
                        color: "#EB6523",
                        fontSize: "30px",
                        ...boldFont,
                      }}
                    >
                      {initial2}
                    </Typography>
                  </Box>
                )}
                <StyledMenu2
                  id="demo-customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={anchorEl2}
                  open={openForMobile}
                  onClose={handleCloseForMobile}
                >
                  {expandSubMenuForMobile && (
                    <>
                      <MenuItem style={{ padding: "5px" }}>
                        <ShoppingBagIcon
                          style={{
                            marginRight: "10px",
                            color: "#EB6523",
                          }}
                        />
                        <Link to="/dashboard" className={classes.link}>
                          Orders{" "}
                        </Link>
                      </MenuItem>
                      <Divider />
                      {props.user.role === "Admin" ||
                      props.user.role === "SleepPhysician" ||
                      props.user.role === "GeneralPhysician" ||
                      props.user.role === "Dentist" ? (
                        <MenuItem style={{ padding: "5px" }}>
                          <AccountCircleIcon
                            style={{
                              marginRight: "10px",
                              color: "#EB6523",
                            }}
                          />
                          <Link to="/viewUser" className={classes.link}>
                            Account
                          </Link>
                        </MenuItem>
                      ) : null}
                      <Divider />
                      <MenuItem style={{ padding: "5px" }}>
                        <LogoutIcon
                          style={{
                            marginRight: "10px",
                            color: "#EB6523",
                          }}
                        />
                        <Link
                          to="/login"
                          onClick={handleLogoutClick}
                          className={classes.link}
                        >
                          Sign Out
                        </Link>
                      </MenuItem>
                    </>
                  )}
                </StyledMenu2>
              </>
            ) : (
              <Link to="/login" style={{ textDecoration: "none" }}>
                <CustomIcon icon={<PersonIcon style={{ margin: "15px" }} />} />
              </Link>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const mapState = (state) => {
  const { authentication, alert, loggedIn } = state;
  const { user } = authentication;
  return { authentication, user, alert, loggedIn };
};

const actionCreators = {
  logout: userActions.logout,
};

export default connect(mapState, actionCreators)(DrawerComponent);
