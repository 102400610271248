import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { boldFont, regularFont } from "../../../utils/styles";

const ViewProductDetailStyles = makeStyles((theme: Theme) => ({
  media: {
    objectFit: "contain",
    height: "100%",
    width: "100%",
    backgroundColor: "transparent",
  },
  BoxForDeskTop: {},
  BoxForMobile: {
    flexDirection: "column-reverse",
  },
  invalidField: {
    border: "1px solid red !important",
  },
  productPicture: {
    alignItems: "center",
  },
  makeItFloat: {
    position: "fixed",
    bottom: "100px",
    left: "0",
    width: "100%",
  },
  withWidthBox: {
    width: "70%",
    backgroundColor: "white",
    borderRadius: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "0px 6px 20px rgb(0 0 0 / 5%)",
  },
  mediaFloat: {
    objectFit: "contain",
    height: "100%",
    width: "100%",
    borderRadius: "20px",
  },
}));

export default ViewProductDetailStyles;
