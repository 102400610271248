import Header from "../../../shared/Header/Header";
import {
  Box,
  Button,
  Divider,
  Container,
  Grid,
  Typography,
  Paper,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Repeat } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Footer from "../../../shared/Footer/Footer";
import UpdatePasswordStyles from "./UpdatePassword.styles";
import { alertActions } from "../../../redux/_actions/alert.actions";
import { userActions } from "../../../redux/_actions/user.action";
import { useState, useEffect } from "react";
import loading2 from "../../../assets/images/Register/loading2.gif";
import { ToastContainer, toast } from "react-toastify";
import OutlinedInput from "@mui/material/OutlinedInput";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { openErrorNotification } from "../../../helpers/methods";
import { userService } from "../../../services";
import history from "../../../utils/history";
import urls from "../../../global/constants/UrlConstants";
import { openSuccessNotification } from "../../../helpers/methods";
import notifications from "../../../utils/notifications";

const UpdatePassword = (props) => {
  const classes = UpdatePasswordStyles();
  var errorMsg = "";
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [passErr, setPassErr] = useState(false);
  const [cpassErr, setCPassErr] = useState(false);
  const [loading, setLoading] = useState(Boolean);
  const [authdata, setauthData] = useState("");
  const [eye, setEye] = useState(true);
  const [eye1, setEye1] = useState(true);
  const [user, setUser] = useState({
    authToken: "",
    pwd: "",
    cpwd: "",
    newPwd: "",
    pwdType: "P",
    email: "",
  });
  useEffect(() => {
    localStorage.clear();
    const authToken = new URLSearchParams(props.location.search).get("context");
    const a = user;
    if (authToken) {
      a.authToken = authToken;
    }
    callUser(a);
  }, []);

  function passwordHandler(e) {
    setLoading(false);
    let pwd = e.target.value;
    if (pwd.length < 8) {
      setPassErr(true);
    } else {
      setPassErr(false);
    }
    setUser({ ...user, pwd });
  }

  function cpasswordHandler(e) {
    setLoading(false);
    let cpwd = e.target.value;
    if (cpwd.length < 8 || cpwd !== user.pwd) {
      setCPassErr(true);
    } else {
      setCPassErr(false);
    }
    setUser({ ...user, cpwd });
  }

  const callUser = async (authex) => {
    try {
      const response = await userService.verifyTempPwd(authex);
      setUser({
        ...user,
        email: response.email,
        authToken: response.authToken,
      });
    } catch (error) {
      openErrorNotification(error);
    }
  };

  useEffect(() => {
    document.title = "Update Password";
  }, []);

  const handletempverify = async () => {
    if (user.pwd != user.cpwd) {
      openErrorNotification("Password do not match. please re-enter password.");
    } else {
      let regex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      if (regex.test(user.pwd)) {
        // if (user.pwd === user.cpwd) {
        user.newPwd = user.pwd;
        props.updatePwd(user);
        // } else {
        //   openErrorNotification(
        //     "Please provide password which has atleast 8 characters including 1 special character [@#$%] 1 Upper & lower case letter"
        //   );
        // }
      } else {
        openErrorNotification(
          "Please provide password which has atleast 8 characters including 1 special character [@#$%] 1 Upper & lower case letter"
        );
      }
    }
  };

  return (
    <>
      <Header />
      <Box className={isDesktop ? classes.Box : classes.MobileBox}>
        <form>
          <Grid
            item
            lg
            container
            direction="column"
            alignItems="center"
            marginTop="30px"
          >
            <Typography
              className={isDesktop ? classes.Typo1 : classes.Typo1Mobile}
              style={{}}
            >
              Update Password
            </Typography>
            <OutlinedInput
              className={isDesktop ? classes.input2 : classes.input2Mobile}
              type={eye ? "password" : "text"}
              name="pwd"
              onChange={passwordHandler}
              onClick={() => {
                setLoading(false);
              }}
              placeholder="Password"
              endAdornment={
                <InputAdornment
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setEye(!eye);
                  }}
                  position="end"
                >
                  {eye ? <VisibilityOff /> : <Visibility />}
                </InputAdornment>
              }
              style={
                isDesktop
                  ? {
                      marginTop: "30px",
                      width: "450px",
                      height: "70px",
                      fontSize: "20px !important",
                      // border: "1px solid #283035",
                      borderRadius: "15px",
                      backgroundColor: "#ffffff",
                    }
                  : {
                      marginTop: "20px",
                      width: "80%",
                      height: "65px",
                      fontSize: "20px",
                      // border: "1px solid #283035",
                      borderRadius: "15px",
                      backgroundColor: "#ffffff",
                    }
              }
            />
            {passErr ? (
              <span style={{ color: "red" }}>Password Not Valid</span>
            ) : (
              ""
            )}
            <OutlinedInput
              className={isDesktop ? classes.input2 : classes.input2Mobile}
              type={eye1 ? "password" : "text"}
              name="cpwd"
              onChange={cpasswordHandler}
              onClick={() => {
                setLoading(false);
              }}
              placeholder="Confirm Password"
              endAdornment={
                <InputAdornment
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setEye1(!eye1);
                  }}
                  position="end"
                >
                  {eye1 ? <VisibilityOff /> : <Visibility />}
                </InputAdornment>
              }
              style={
                isDesktop
                  ? {
                      marginTop: "30px",
                      width: "450px",
                      height: "70px",
                      fontSize: "20px !important",
                      // border: "1px solid #283035",
                      borderRadius: "15px",
                      backgroundColor: "#ffffff",
                    }
                  : {
                      marginTop: "20px",
                      width: "80%",
                      height: "65px",
                      fontSize: "20px",
                      // border: "1px solid #283035",
                      borderRadius: "15px",
                      backgroundColor: "#ffffff",
                    }
              }
            />
            {cpassErr ? (
              <span style={{ color: "red" }}>Confirm password not matched</span>
            ) : (
              ""
            )}
            <Button
              type="submit"
              onClick={handletempverify}
              className={isDesktop ? classes.button : classes.buttonMobile}
              style={{}}
            >
              {props.authentication.loading ? (
                <img
                  style={{ width: "20%", height: "100%" }}
                  src={loading2}
                ></img>
              ) : (
                <Typography>Update Password</Typography>
              )}
            </Button>
            <Link to="/login">
              <Typography
                className={isDesktop ? classes.Typo2 : classes.Typo2Mobile}
                style={{}}
              >
                Already Have a Account? Go to Login
              </Typography>
            </Link>
          </Grid>
        </form>
      </Box>
      <Footer />
    </>
  );
};

const mapState = (state) => {
  const { authentication, alert, loggedIn } = state;
  return { authentication, alert, loggedIn };
};

const actionCreators = {
  updatePwd: userActions.updatePwd,
  verifyTempPwd: userActions.verifyTempPwd,
  clearAlerts: alertActions.clear,
};

export default connect(mapState, actionCreators)(UpdatePassword);
