import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const ContactUsStyles = makeStyles((theme: Theme) => ({
  formWrapper: {
    backgroundColor: "#F9F9FF",
    justifyContent: "center",
    display: "grid",
    marginTop: '16px',
  },
  formContainer: {
    textAlign: "center",
    marginTop: "60px",
    width: "100%",
  },
  formInput: {
    width: "80%",
    marginTop: "60px",
    display: "grid",
    alignItems: "center",
  },
  header: {
    textAlign: "center",
    color: "#1A3A6C",
    fontSize: "48px",
    fontWeight: 900,
  },
  subHeader: {
    textAlign: "center",
    color: "#283035",
    fontSize: "20px",
    marginTop: "10px",
    fontWeight: 400,
  }
}));

export default ContactUsStyles;
