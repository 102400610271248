import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { CardMedia, TablePagination } from "@mui/material";
import ProductCardStyles from "./ProductCard.styles";
import { Box } from "@mui/system";
import { boldFont, regularFont } from "../../../../utils/styles";

interface CustomProps {
  tableData: any[];
}

export default function ProductCard(props: CustomProps) {
  const [rowsPerPage, setRowsPerPage] = React.useState(6);
  const [page, setPage] = React.useState(0);
  const classes = ProductCardStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Grid container spacing={3}>
          {(rowsPerPage > 0
            ? props.tableData.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : props.tableData
          ).map((row, index) => (
            <Grid item xs={12} md={4} lg={4}>
              <Link
                style={{
                  color: "#1A3A6C",
                  ...boldFont,
                  fontSize: "15px",
                  textDecoration: "none",
                }}
                to={{
                  pathname: "/viewProductDetail/",
                  search: `?id=${row.id}`,
                  state: row,
                }}
                className="dashboard-link"
              >
                <Box style={{ borderRadius: "10px", border: "1px solid #eee" }}>
                  <Box style={{ height: "450px" }}>
                    <img
                      className={classes.media}
                      src={`data:image/png;base64,${row.productPicture}`}
                    />
                  </Box>
                </Box>
              </Link>
              {/* <Card style={{ height: "450px" }}>
                                    
                                        <CardMedia
                                            className={classes.media}
                                            component='img'
                                            src={`data:image/png;base64,${row.productPicture}`} // require image
                                            title="Contemplative Reptile"
                                        />
                                    </Link>
                                </Card> */}
              <Typography
                component="div"
                style={{
                  ...regularFont,
                  fontWeight: 400,
                  fontSize: "20px",
                  color: "#283035",
                }}
              >
                {row.name}
              </Typography>
              <Typography
                component="div"
                style={{
                  ...boldFont,
                  fontWeight: 900,
                  fontSize: "24px",
                  color: "#283035",
                }}
              >
                {row.currency}&nbsp;{row.unitPrice}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {row.metadata && row.metadata.length > 0
                  ? row.metadata.map((meta) => {
                      return meta.name === "Prescription" &&
                        meta.value == "true" ? (
                        <Typography
                          component="div"
                          style={{
                            fontSize: "16px",
                            color: "#012168",
                            ...boldFont,
                            fontWeight: 900,
                          }}
                        >
                          Prescription Requried
                        </Typography>
                      ) : null;
                    })
                  : null}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>

      <TablePagination
        rowsPerPageOptions={[6, 12, 24]}
        component="div"
        count={props.tableData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
