import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
  Stack,
  Menu,
  MenuItem,
} from "@mui/material";
import HeaderStyles from "./Header.styles";
// import AppleStore from "../../assets/images/AppleStore.svg";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import dstlogosmall1 from "../../assets/images/dstlogo-small1.png";
// import PlayStore from "../../assets/images/PlayStore.png";
import google from "../../assets/images/google.svg";
import apple from "../../assets/images/apple.svg";
import Bag from "../../assets/images/icons/Bag.png";
import Buy from "../../assets/images/icons/Buy.png";
import DrawerComponent from "../DrawerComponent/DrawerComponent";
import Navbar from "../Navbar/Navbar";
import { connect } from "react-redux";
import { CustomIcon } from "../../global/components";
import {
  convertPriceToDollarFormat,
  openWarningNotification,
} from "../../helpers/methods";
import { Link } from "react-router-dom";
import { boldFont, regularFont } from "../../utils/styles";
import adduser from "../../assets/images/adduser.png";
import React, { Component, useEffect, useState } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import history from "../../helpers/history";
import { userActions } from "../../redux/_actions/user.action";
import PersonIcon from "@mui/icons-material/Person";
import jwt_decode from "jwt-decode";
import urls from "../../global/constants/UrlConstants";
import { useParams } from "react-router-dom";

const Header = (props) => {
  const classes = HeaderStyles();
  const theme = useTheme();
  interface MyToken {
    exp: number;
  }
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  let current_user = localStorage.getItem("current_user");
  if (current_user) {
    let username = JSON.parse(localStorage.getItem("current_user")!);
    var name = username.name;
    if (username.firstName || username.lastName) {
      var firstName = username.firstName;
      var lastName = username.lastName;
      var initial1 = firstName.charAt(0);
      var initial2 = lastName.charAt(0);
    }
  }

  useEffect(() => {
    if (current_user) {
      let username = JSON.parse(localStorage.getItem("current_user")!);
      var name = username.name;
      var authToken = username.authToken;
      const decodedToken = jwt_decode<MyToken>(authToken);
      if (decodedToken.exp < Date.now() / 1000) {
        openWarningNotification("session expired");
        localStorage.clear();
        history.push(urls.SignInPath);
      }
    }
  }, [props.items]);

  const handleLogoutClick = async (e) => {
    await props.logout();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getUpperHeader = () => {
    return (
      <>
        <Box className={classes.header}>
          {isDesktop && (
            <Box className={classes.displayFlexAlignCenter}>
              <CustomIcon
                icon={
                  <img
                    src={google}
                    alt="google"
                    className={classes.topHeaderImg}
                  />
                }
              />
              <CustomIcon
                icon={
                  <img
                    src={apple}
                    alt="apple"
                    className={classes.topHeaderImg}
                  />
                }
              />
            </Box>
          )}
          <Box>
            <Typography className={classes.offerText}>
              {/* Click here! Save on Sleep Apneo Kit plus 15% off everything else! */}
            </Typography>
          </Box>
          {isDesktop && (
            <Box className={classes.displayFlexAlignCenter}>
              <Stack
                direction="row"
                divider={
                  <Divider
                    orientation="vertical"
                    flexItem
                    style={{ backgroundColor: "#FFE0D0" }}
                  />
                }
                spacing={2}
              >
                <Typography
                  style={{ ...boldFont, fontWeight: 900, fontSize: "16px" }}
                >
                  <Link to="/blog" className={classes.topHeaderLi}>
                    {" "}
                    BLOG
                  </Link>
                </Typography>
                <Typography
                  style={{ ...boldFont, fontWeight: 900, fontSize: "16px" }}
                >
                  <Link to="/faq" className={classes.topHeaderLi}>
                    {" "}
                    FAQ
                  </Link>
                </Typography>
                <Typography
                  style={{ ...boldFont, fontWeight: 900, fontSize: "16px" }}
                >
                  <Link to="/about" className={classes.topHeaderLi}>
                    ABOUT US
                  </Link>
                </Typography>
              </Stack>
            </Box>
          )}
        </Box>
      </>
    );
  };

  const getNavigationWithLogo = () => {
    return (
      <Box className={classes.header}>
        <Box>
          <Link to="/">
            <img
              src={dstlogosmall1}
              alt={"dstlogosmall1"}
              className={classes.deepsleepLogo}
            />
          </Link>
        </Box>
        <Box>
          <Navbar />
        </Box>
        <Box className={classes.displayFlexAlignCenter}>
          {current_user ? (
            <Box mr={2} display="flex">
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: "#fbe0d3",
                  width: "52px",
                  height: "50px",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50px",
                  // paddingTop: "3px",
                  marginTop: "5px",
                }}
              >
                <Typography
                  style={{ color: "#1A3A6C", fontSize: "30px", ...boldFont }}
                >
                  {initial1}
                </Typography>
                <Typography
                  style={{ color: "#EB6523", fontSize: "30px", ...boldFont }}
                >
                  {initial2}
                </Typography>
              </Box>
              <Box
                display="flex"
                alignItems="flex-start"
                style={{ cursor: "pointer" }}
              >
                <Box
                  mx={2}
                  onClick={handleClick}
                  // className={classes.link}
                  style={{ paddingTop: "10px" }}
                >
                  <Box style={{ fontSize: "14px", ...regularFont }}>
                    Welcome,
                  </Box>
                  <Box style={{ fontSize: "20px" }}>{firstName}</Box>
                </Box>
              </Box>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    display: "flex",
                    flexDirection: "column",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 65,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "center", vertical: "top" }}
                anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: "5px",
                  }}
                >
                  <Link to="/dashboard" className={classes.link}>
                    <MenuItem style={{ padding: "5px" }}>
                      <ShoppingBagIcon
                        style={{
                          marginRight: "10px",
                          color: "#EB6523",
                        }}
                      />{" "}
                      <Typography
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          ...boldFont,
                          color: "#283035",
                        }}
                      >
                        Orders
                      </Typography>
                    </MenuItem>
                  </Link>
                  {props.user.role === "Admin" ||
                  props.user.role === "SleepPhysician" ||
                  props.user.role === "GeneralPhysician" ||
                  props.user.role === "Dentist" ? (
                    <Link to="/viewUser" className={classes.link}>
                      <MenuItem style={{ padding: "5px" }}>
                        <AccountCircleIcon
                          style={{
                            marginRight: "10px",
                            color: "#EB6523",
                          }}
                        />
                        <Typography
                          style={{
                            fontSize: "16px",
                            fontWeight: "400",
                            ...boldFont,
                            color: "#283035",
                          }}
                        >
                          Account
                        </Typography>
                      </MenuItem>
                    </Link>
                  ) : null}
                  <Link
                    to="/login"
                    onClick={handleLogoutClick}
                    className={classes.link}
                  >
                    <MenuItem style={{ padding: "5px" }}>
                      <LogoutIcon
                        style={{
                          marginRight: "10px",
                          color: "#EB6523",
                        }}
                      />
                      <Typography
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          ...boldFont,
                          color: "#283035",
                        }}
                      >
                        {" "}
                        Sign Out
                      </Typography>
                    </MenuItem>
                  </Link>
                </Box>
              </Menu>
            </Box>
          ) : (
            <Box mr={2}>
              <CustomIcon
                icon={<img style={{ marginRight: "5px" }} src={adduser}></img>}
                text={
                  <Box>
                    <Typography
                      style={{
                        ...boldFont,
                        fontWeight: 900,
                        fontSize: "16px",
                      }}
                    >
                      <Link
                        to="/register"
                        style={{
                          textDecoration: "none",
                          color: "#283035",
                          ...regularFont,
                          fontSize: "14px",
                          fontWeight: 500,
                        }}
                      >
                        Register
                      </Link>
                    </Typography>
                    <Typography
                      style={{
                        ...boldFont,
                        fontWeight: 900,
                        fontSize: "16px",
                      }}
                    >
                      <Link
                        to="/login"
                        style={{
                          textDecoration: "none",
                          color: "black",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Or Sign In
                      </Link>
                    </Typography>
                  </Box>
                }
              />
            </Box>
          )}

          <Box mx={2}>
            <Link
              to="/cart"
              style={{ textDecoration: "none", display: "flex" }}
            >
              <img src={Buy} style={{ marginRight: "6px" }} />
              <Typography
                style={{
                  ...boldFont,
                  fontWeight: 900,
                  fontSize: "16px",
                  color: "black",
                }}
              >
                {convertPriceToDollarFormat(getCartValue())}
              </Typography>
            </Link>
          </Box>
          <Box ml={2}>
            {current_user ? (
              <Box>
                {props.user.role === "Patient" ? (
                  <Link to="/buytest" style={{ textDecoration: "none" }}>
                    <Button className={classes.btn}>
                      <img src={Bag} />
                      <Typography
                        style={{
                          ...boldFont,
                          fontWeight: 500,
                          fontSize: "12px !important",
                          whiteSpace: "nowrap",
                          marginLeft: "6px",
                          color: "white",
                        }}
                      >
                        Buy Test
                      </Typography>
                    </Button>
                  </Link>
                ) : (
                  ""
                )}
              </Box>
            ) : (
              <Link to="/login" style={{ textDecoration: "none" }}>
                <Button className={classes.btn}>
                  <img src={Bag} />
                  <Typography
                    style={{
                      ...boldFont,
                      fontWeight: 500,
                      fontSize: "12px !important",
                      whiteSpace: "nowrap",
                      marginLeft: "6px",
                      color: "white",
                    }}
                  >
                    Buy Test
                  </Typography>
                </Button>
              </Link>
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  const getMobileNav = () => {
    return <DrawerComponent />;
  };

  const getMobileView = () => {
    return (
      <Box>
        {getMobileNav()}
        <Divider />
        {getUpperHeader()}
      </Box>
    );
  };

  const getDesktopView = () => {
    return (
      <>
        {getUpperHeader()}
        <Divider />
        <Box
          style={{
            position: "sticky",
            top: 0,
            backgroundColor: "#fff",
            zIndex: 200,
            boxShadow: "0px 5px 5px 0px rgb(0 0 0 / 5%)",
            paddingBottom: "5px",
          }}
        >
          {getNavigationWithLogo()}
        </Box>
      </>
    );
  };

  const getNavigationPage = () => {
    return isDesktop ? getDesktopView() : getMobileView();
  };

  const getCartValue = () => {
    let cartValue = 0;
    if (props.shopReducer.items.length) {
      cartValue = props.shopReducer.items.reduce((acc, obj) => {
        return acc + obj.totalPrice;
      }, 0);
    }
    return cartValue;
  };

  return getNavigationPage();
};

const mapState = (state) => {
  const { authentication, alert, loggedIn, shopReducer } = state;
  const { user } = authentication;
  return { authentication, user, alert, loggedIn, shopReducer };
};

const actionCreators = {
  logout: userActions.logout,
};

export default connect(mapState, actionCreators)(Header);
