import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import aboutusfooter from "../../assets/images/AboutUs/aboutusfooter.png";
import { boldFont } from '../../utils/styles';
import { regularFont } from '../../utils/styles';
import { Link } from "react-router-dom";

const ServicesFooter = () => {
  return (
    <>
      <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", height: "350px", backgroundImage: "url(" + aboutusfooter + ")" }}>
        <Typography style={{ marginTop: "90px", color: "#ffffff", fontSize: "48px", fontWeight: 900, textAlign: "center", ...regularFont }}>Sleep Well, Live Well.</Typography>
        <Button
          endIcon={<ArrowRightAltIcon />} style={{ width: "300px", backgroundColor: "#ffffff", borderRadius: "15px", marginBottom: "100px", marginTop: "20px", color: "#283035", marginLeft: "350px", marginRight: "350px", height: "55px" }}
        >
          <Link to="/login" style={{ textDecoration: "none" }}>
            <Typography style={{
              ...regularFont,
              fontWeight: 500,
              fontSize: "16px",
              color: "#283035"
            }}>Buy Sleep Apnea Test</Typography></Link>
        </Button>
      </Box>
    </>
  );
};

export default ServicesFooter;
