import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import aboutusfooter from "../../../../assets/images/AboutUs/aboutusfooter.png";
import { boldFont } from "../../../../utils/styles";
import { regularFont } from "../../../../utils/styles";

const AboutUsFooterStyles = makeStyles((theme: Theme) => ({
  Box: {
    backgroundImage: "url(" + aboutusfooter + ")",
    display: "grid ",
    justifyContent: "center",
    padding:"100px 0 100px 0"
  },
  Button: {
      backgroundColor:"#ffffff !important",
      color: "#283035 !important",
      padding:"20px 20px 20px 20px !important",
      margin:"20px 400px 0px 400px !important",
      borderRadius:"20px !important"
  },
  Typo1: {
    ...boldFont,
    fontSize: "2.5vw !important",
    fontWeight: 900,
    color: "#ffffff",
    textAlign:"center"
  },
  Typo2: {},
  BoxMobile: {
    backgroundImage: "url(" + aboutusfooter + ")",
    display: "grid ",
    justifyContent: "center",
    padding:"5% 0 5% 0",
    height:"100%"
  },
  ButtonMobile: {
    backgroundColor:"#ffffff !important",
    color: "#283035 !important",
    padding:"10px 50px 10px 50px !important",
    margin:"20px 50px 0px 50px !important",
    borderRadius:"20px !important"
  },
  Typo1Mobile: {
    ...boldFont,
    fontSize: "20px !important",
    fontWeight: 900,
    color: "#ffffff",
    textAlign:"center"
  },
  Typo2Mobile: {

  },
}));

export default AboutUsFooterStyles;
