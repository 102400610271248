import Header from "../../shared/Header/Header";
import Footer from "../../shared/Footer/Footer";
import {
  Box,
  Button,
  Container,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Banner from "../../shared/Banner/Banner";
import { regularFont } from "../../utils/styles";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import CustomizedTables from "./components/Table";
import { userService } from "../../services/sleepTestUserService-service";
import { Link } from "react-router-dom";
import orderDashboard from "../../assets/images/OrderDetails/orderDashboard.png";
import Loading from "../Loading/Loading";
import { openErrorNotification } from "../../helpers/methods";
import AssessmentIcon from '@mui/icons-material/Assessment';


const Dashboard = () => {
  const [name, setName] = useState("");
  const [firstName, setFName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [orderItem, setOrderItem] = useState([]);
  const [isLoading, setIsLoading] = useState(false);



  useEffect(() => {
    let username = JSON.parse(localStorage.getItem("current_user")!);
    var name = username.name;
    var fname = username.firstName;
    var email = username.email;

    setName(name);
    setFName(fname);
    setEmail(email);
    var role = username.role;
    setRole(role);
  }, []);

  useEffect(() => {
    getMyOrder()
  }, [])


  useEffect(() => {
    document.title = "Dashboard";
  }, []);

  const getMyOrder = async () => {
    try {
      setIsLoading(true);
      const response = await userService.getMyOrders();
      setOrderItem(response);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      openErrorNotification(error);
    }
  }

  return (
    <>
      <Header />
      <Banner
        img={orderDashboard}
        header1="Home/ Orders"
        header2="Orders"
      />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Container
            maxWidth={false}
            style={{ marginBottom: "20px", width: "71%", marginTop: "50px" }}
          >
            <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <Box style={{ width: "30%" }}>
                <Typography
                  style={{
                    color: "#000000",
                    fontSize: "28px",
                    fontWeight: 400,
                    ...regularFont,
                  }}
                >
                  Hi, {name}
                </Typography>
              </Box>
              <Box style={{ width: "70%", display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "flex-end" }}>
                {role != "Patient" ? (
                  <Box style={{ width: "18%" }}>
                    <Link style={{ textDecoration: "none" }} to="/adduser">
                      <Button
                        onClick={() => {
                        
                        }}
                        startIcon={<PersonOutlineIcon style={{ color: "#EB6523" }} />}
                        style={{
                          boxShadow: "0px 15px 30px 0px rgba(0, 0, 0, 0.09)",
                          height: "60px",
                          borderRadius: "20px",
                          textTransform: "none",
                        }}
                      >
                        <Typography
                          style={{
                            color: "#1A3A6C",
                            fontSize: "18px",
                            fontWeight: 700,
                            ...regularFont,
                          }}
                        >
                          Add user
                        </Typography>
                      </Button>
                    </Link>
                  </Box>
                ) : (
                  <Box style={{ width: "18%",marginRight:"10px" }}>
                  <Link style={{ textDecoration: "none" }} to={
                      "viewUserDetail?id=" +
                      email +
                      "&name=" +
                      firstName
                    }>
                    <Button
                      onClick={() => {
                      
                      }}
                      startIcon={<AssessmentIcon style={{ color: "#EB6523" }} />}
                      style={{
                        boxShadow: "0px 15px 30px 0px rgba(0, 0, 0, 0.09)",
                        height: "60px",
                        borderRadius: "20px",
                        textTransform: "none",
                      }}
                    >
                      <Typography
                        style={{
                          color: "#1A3A6C",
                          fontSize: "18px",
                          fontWeight: 700,
                          ...regularFont,
                        }}
                      >
                        My Reports
                      </Typography>
                    </Button>
                  </Link>
                </Box>
                )}
                <Box style={{ width: "22%" }}>
                  <Link style={{ textDecoration: "none" }} to="/profileupdate">
                    <Button
                      startIcon={<PersonOutlineIcon style={{ color: "#EB6523" }} />}
                      style={{
                        boxShadow: "0px 15px 30px 0px rgba(0, 0, 0, 0.09)",
                        height: "60px",
                        borderRadius: "20px",
                        textTransform: "none",
                      }}
                    >
                      <Typography
                        style={{
                          color: "#1A3A6C",
                          fontSize: "18px",
                          fontWeight: 700,
                          ...regularFont,
                        }}
                      >
                        Update Profile
                      </Typography>
                    </Button>
                  </Link>
                </Box>
              </Box>
            </Box>

            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "5% 0 3% 0",
              }}
            >
              <Typography
                style={{
                  color: "#1A3A6C",
                  fontSize: "32px",
                  fontWeight: 900,
                  ...regularFont,
                }}
              >
                Your Order History
              </Typography>
            </Box>
            <CustomizedTables tableData={orderItem} order={getMyOrder} />
          </Container>
          <Footer />
        </>
      )}

    </>
  );
};

export default Dashboard;


