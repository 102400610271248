import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const EpworthCardStyles = makeStyles((theme: Theme) => ({
  defaultStyle: {
    display: "flex",
    width: 30,
    height: 30,
    backgroundColor: "#ffffff",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 30,
  },
  activeIndex: {
    backgroundColor: "red",
  },
}));

export default EpworthCardStyles;
