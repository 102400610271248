import { Box, Button, useForkRef } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useState } from "react";
import { connect } from "react-redux";
import history from "../../../utils/history";

interface CustomProps {
  inStockQuantity: number;
  currentQuantity: number;
  productDetials: object;
  componentName: string;
  setQuantity: Function;
  items: any[];
}

const Counter = (props: CustomProps) => {
  const [quantity, setQuantity] = useState(props.currentQuantity || 1);

  const productValueHandler = (type: string, componentName: string) => {
    if (type === "increase") {
      setQuantity(quantity + 1);
      if (componentName === "cart") {
        addToCartProductItem(props.productDetials, quantity + 1);
      } else {
        props.setQuantity(quantity + 1)
      }
    } else if (type === "decrease") {
      setQuantity(quantity - 1);
      if (componentName === "cart") {
        addToCartProductItem(props.productDetials, quantity - 1);
      } else {
        props.setQuantity(quantity - 1)
      }
    }
  };

  const addToCartProductItem = async (product, receivedQuantity) => {
   
    for (let i = 0; i < props.items.length; i++) {
      if (props.items[i].name === product.name) {
        const newQuantity = receivedQuantity;
        const newTotalPrice =
          props.items[i].totalPrice +
          receivedQuantity * product.unitPrice -
          props.items[i].quantity * product.unitPrice;
        props.items[i].quantity = newQuantity;
        props.items[i].totalPrice = newTotalPrice;
        history.push("/cart");
      }
    }
  };

  return (
    <Box
      className="counter-container"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <Button disabled={quantity <= 1 || props.inStockQuantity < 1}>
        <RemoveIcon
          onClick={() => productValueHandler("decrease", props.componentName)}
          style={{
            border: "1px solid rgba(40, 48, 53, 0.15)",
            padding: "4px",
            borderRadius: "12px",
          }}
        />
      </Button>
      <span style={{ display: "flex", alignItems: "center" }}>{quantity}</span>
      <Button
        disabled={
          props.inStockQuantity === quantity || props.inStockQuantity < 1
        }
      >
        <AddIcon
          onClick={() => productValueHandler("increase", props.componentName)}
          style={{
            border: "1px solid rgba(40, 48, 53, 0.15)",
            padding: "4px",
            borderRadius: "12px",
          }}
        />
      </Button>
    </Box>
  );
};

function mapState(state) {
  const { authentication, shopReducer } = state;
  const { items } = shopReducer;
  const { user, loggedIn } = authentication;
  return { user, items, loggedIn };
}

const actionCreators = {
  // addToCart: addToCart,
};

export default connect(mapState, actionCreators)(Counter);
