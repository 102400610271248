import {
  Box,
  Divider,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { display } from "@mui/system";
import seconds from "../../../../../assets/images/Services/seconds.png";
import second from "../../../../../assets/images/Services/second.png";
import DisplayData1Styles from "./DisplayData1.styles";
import { boldFont } from "../../../../../utils/styles";
import { regularFont } from "../../../../../utils/styles";
const DisplayData1 = () => {
  const classes = DisplayData1Styles();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <>
      {isDesktop ? (
        <Box
          className={isDesktop ? classes.Box4 : classes.Box4Mobile}
          style={{}}
        >
          <Box
            className={isDesktop ? classes.Box5 : classes.Box5Mobile}
            style={{}}
          >
            <Typography
              className={isDesktop ? classes.Typo2 : classes.Typo2Mobile}
              style={{}}
            >
              <span style={{ fontStyle: "italic", fontFamily: "bold" }}>
                “A study by the Federal Motor Carrier Safety Administration
                (FMCSA) found that nearly 30% of truck drivers have mild to
                severe sleep apnea.”
              </span>
              <br />
              <br />
              Sleep Apnea symptoms can range from mild to severe. Still,
              irrespective of the severity, they are known to cause regular
              disruption in the sleeping pattern, resulting in a lack of
              alertness during the daytime. While driving, it is crucial to be
              vigilant and focused on the changing traffic conditions, which can
              be hampered if the driver lacks alertness.
              <br />
              <br />
              For Commercial Driving License holders, getting tested for Sleep
              Apnea is necessary as drowsy driving can be hazardous, leading to
              terrible accidents.
            </Typography>
            <Typography
              className={isDesktop ? classes.Typo1 : classes.Typo1Mobile}
              style={{}}
            >
              Test At Your Home!
            </Typography>
          </Box>
          <Box
            className={isDesktop ? classes.Box6 : classes.Box6Mobile}
            style={{}}
          >
            <img
              className={
                isDesktop ? classes.imagestyle : classes.imagestyleMobile
              }
              style={{}}
              src={seconds}
            />
          </Box>
        </Box>
      ) : (
        <Box
          className={isDesktop ? classes.Box1 : classes.Box1Mobile}
          style={{}}
        >
          <Box
            className={isDesktop ? classes.Box2 : classes.Box2Mobile}
            style={{}}
          >
            <img
              className={
                isDesktop ? classes.imagestyle : classes.imagestyleMobile
              }
              style={{}}
              src={seconds}
            />
          </Box>
          <Box
            className={isDesktop ? classes.Box3 : classes.Box3Mobile}
            style={{}}
          >
            <Typography
              className={isDesktop ? classes.Typo2 : classes.Typo2Mobile}
              style={{}}
            >
              Due to the stressful environments they work within,
              <br /> Truckers have a much higher risk of Sleep Apnea.
              <br />
              <br /> Sleep Apnea causes higher fatal accidents on our
              <br /> roadways.
              <br />
              <br /> As per the American Association of sleep medicine
              <br /> guidelines, sleep physicians recommend that truckers
              <br /> get at least 7 hours of restful sleep.
              <br />
              <br /> As per DOT regulations, CDL drivers need to get tested
              <br /> for Sleep Apnea to ensure road safety for all.
            </Typography>
            <Typography
              className={isDesktop ? classes.Typo1 : classes.Typo1Mobile}
              style={{}}
            >
              Get Sleep Apnea tested today.
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default DisplayData1;
