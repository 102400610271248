import Footer from "../../../shared/Footer/Footer";
import Header from "../../../shared/Header/Header";
import { Container, Grid, Typography } from "@mui/material";
import { boldFont } from "../../../utils/styles";
import { regularFont } from "../../../utils/styles";
import { useEffect } from "react";

const TermAndCondition = () => {
  useEffect(() => {
    document.title = "Terms & Conditions";
  }, []);
  return (
    <div>
      <Header />
      <Container>
        <Grid
          container
          spacing={2}
          mt={5}
          style={{ ...regularFont ,fontSize:"20px"}}
        >
          <Grid item sm={12} md={12} lg={12}>
          <Typography variant="h3" mb={4} style={{ color: "#EB6523",...boldFont}}>
              Term and Condition
            </Typography>
            <Typography variant="h4" style={{ color: "#1A3A6C",...boldFont }}>
              1. Introduction
            </Typography>
            <p>
              These Website Standard Terms And Conditions (these “Terms” or
              these “Website Standard Terms And Conditions”) contained herein on
              this webpage, shall govern your use of this website, including all
              pages within this website (collectively referred to herein below
              as this “Website”). These Terms apply in full force and effect to
              your use of this Website and by using this Website, you expressly
              accept all terms and conditions contained herein in full. You must
              not use this Website, if you have any objection to any of these
              Website Standard Terms And Conditions.
            </p>
            <p>
              This Website is not for use by any minors (defined as those who
              are not at least 18 years of age), and you must not use this
              Website if you a minor.
            </p>
          </Grid>
          <Grid item sm={12} md={12} lg={12}>
          <Typography variant="h4" style={{ color: "#1A3A6C",...boldFont }}>
              2. Intellectual Property Rights
            </Typography>
            <p>
              Other than content you own, which you may have opted to include on
              this Website, under these Terms, [Sender.Company] and/or its
              licensors own all rights to the intellectual property and material
              contained in this Website, and all such rights are reserved. You
              are granted a limited license only, subject to the restrictions
              provided in these Terms, for purposes of viewing the material
              contained on this Website.
            </p>
          </Grid>
          <Grid item sm={12} md={12} lg={12}>
          <Typography variant="h4" style={{ color: "#1A3A6C",...boldFont }}>
              3. Restrictions
            </Typography>
            <p>1. publishing any Website material in any media;</p>
            <p>
              2. selling, sublicensing and/or otherwise commercializing any
              Website material;
            </p>
            <p>3. publicly performing and/or showing any Website material;</p>
            <p>
              4. using this Website in any way that is, or may be, damaging to
              this Website;
            </p>
            <p>
              5. using this Website in any way that impacts user access to this
              Website;
            </p>
            <p>
              6. using this Website contrary to applicable laws and regulations,
              or in a way that causes, or may cause, harm to the Website, or to
              any person or business entity;
            </p>
            <p>
              7. engaging in any data mining, data harvesting, data extracting
              or any other similar activity in relation to this Website, or
              while using this Website;
            </p>
            <p>
              8. using this Website to engage in any advertising or marketing;
            </p>
            <p>
              Certain areas of this Website are restricted from access by you
              and [Sender.Company] may further restrict access by you to any
              areas of this Website, at any time, in its sole and absolute
              discretion. Any user ID and password you may have for this Website
              are confidential and you must maintain confidentiality of such
              information.
            </p>
          </Grid>
          <Grid item sm={12} md={12} lg={12} mb={4}>
          <Typography variant="h4" style={{ color: "#1A3A6C",...boldFont }}>
              4. Your Content
            </Typography>
            <p>
              In these Website Standard Terms And Conditions, “Your Content”
              shall mean any audio, video, text, images or other material you
              choose to display on this Website. With respect to Your Content,
              by displaying it, you grant [Sender.Company] a non-exclusive,
              worldwide, irrevocable, royalty-free, sublicensable license to
              use, reproduce, adapt, publish, translate and distribute it in any
              and all media.
            </p>
            <p>
              Your Content must be your own and must not be infringing on any
              third partys rights. [Sender.Company] reserves the right to remove
              any of Your Content from this Website at any time, and for any
              reason, without notice.
            </p>
          </Grid>
        </Grid>
      </Container>

      <Footer />
    </div>
  );
};

export default TermAndCondition;
