import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Pagination, TablePagination } from "@mui/material";
import { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import ModalUnstyledDemo from "./Model";
import { Button } from "@mui/material";
import ModalUnstyledDemo1 from "./Model1";
import ModalUnstyledDemo2 from "./Model2";
import ModalUnstyledDemo3 from "./Model3";

import { Link } from "react-router-dom";
import { boldFont } from "../../../utils/styles";
import { userService } from "../../../services/sleepTestUserService-service";

interface CustomProps {
  tableData: any[];
  order: any;
}

const getProductNames = (orderItems: any) => {
  return orderItems.map((orderItem) => {
    return <p>{orderItem.productName}</p>;
  });
};

export default function CustomizedTables(props: CustomProps) {
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [model1, setModel1] = useState(false);
  const [physician, setphysician] = useState("");
  const [paitent, setPaitent] = useState({});
  const [model2, setModel2] = useState(false);
  const [model3, setModel3] = useState(false);
  const [model4, setModel4] = useState(false);


  const [user, setUser] = useState({
    id: "",
    email: "",
    parentEmail: "",
    firstName: "",
    lastName: "",
    role: "",
    trackingNumber: "",
    physician: "",
  });

  useEffect(() => {
    let username = JSON.parse(localStorage.getItem("current_user")!);
    var name = username.name;
    setName(name);
    var role = username.role;
    setRole(role);
  }, []);

  const handleShip = (row) => {
    setUser({
      ...user,
      email: row.customer.primaryEmail.value,
      firstName: row.customer.firstName,
      id: row.id,
    });
    setModel1(true);
  };

  const handleRecieved = async (row) => {
    const response = await userService.getUser(row.customer.primaryEmail.value);
    setPaitent(response);
    setModel2(true);
  };

  const handleOrderShipped=(row)=>{
    setUser({
      ...user,
      email: row.customer.primaryEmail.value,
      firstName: row.customer.firstName,
      id: row.id,
    });
    setModel3(true);

  }

  const handleOrderDelivered=(row)=>{
    setUser({
      ...user,
      email: row.customer.primaryEmail.value,
      firstName: row.customer.firstName,
      id: row.id,
    });
    setModel4(true);
    
  }





  const handleclose = () => {
    props.order();
    setModel1(false);
  };
  const handleclose1 = () => {
    props.order();
    setModel2(false);
    setphysician("");
  };


  const handleclose2 = () => {
    props.order();
    setModel3(false);
  };


  const handleclose3 = () => {
    props.order();
    setModel4(false);
  };



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer>
        <Table
          sx={{ minWidth: 700 }}
          style={{
            borderCollapse: "unset",
          }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">Order Id</TableCell>
              <TableCell align="center">Product Name</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Total Amount</TableCell>
              {role == "Admin" ? (
                <TableCell align="center">Update Status</TableCell>
              ) : (
                ""
              )}
              {role !== "Admin" ? (
                <TableCell align="center">Receipt</TableCell>
              ) : null}
              {role !== "Admin" ? (
                <TableCell align="center">Order Date</TableCell>
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? props.tableData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : props.tableData
            ).map((row, index) => (
              <TableRow>
                <TableCell
                  style={{
                    padding: "20px",
                    color: "#1A3A6C",
                    ...boldFont,
                    fontSize: "15px",
                    fontWeight: 900,
                  }}
                  align="center"
                  component="th"
                  scope="row"
                >
                  <Link
                    to={"/orderdetails?id=" + row.id}
                    style={{
                      color: "#1A3A6C",
                      ...boldFont,
                      fontSize: "15px",
                      textDecoration: "none",
                    }}
                  >
                    {row.id.substr(row.id.length - 5).toUpperCase()}
                  </Link>
                </TableCell>
                <TableCell align="center">
                  {getProductNames(row.orderItems)}
                </TableCell>
                <TableCell align="center">{row.status}</TableCell>
                <TableCell align="center">{row.totalAmount}</TableCell>
                {role == "Admin" ? (
                  <TableCell align="center">
                    {row.status == "Agreement Signed" ? (
                      <Button
                        onClick={() => {
                          handleShip(row);
                        }}
                      >
                        Equipment Shipped
                      </Button>
                    ) : (
                      ""
                    )}

                    {row.status == "Order Created" ? (
                      <Button
                        onClick={() => {
                          handleOrderShipped(row);
                        }}
                      >
                        Order Shipped
                      </Button>
                    ) : (
                      ""
                    )}

                    {row.status == "OrderShipped" ? (
                      <Button
                        onClick={() => {
                          handleOrderDelivered(row);
                        }}
                      >
                        Order Delivered
                      </Button>
                    ) : (
                      ""
                    )}

                    {row.status == "Equipment Shipped" ? (
                      <Button
                        onClick={() => {
                          handleRecieved(row);
                        }}
                      >
                        Equipment Recieved
                      </Button>
                    ) : (
                      ""
                    )}
                  </TableCell>
                ) : (
                  ""
                )}
                {/* <TableCell align="center">{row.PendingActions}</TableCell> */}
                {role !== "Admin" ? (
                  <TableCell align="center">
                    <Link
                      to={"/invoice?id=" + row.id + ""}
                      style={{
                        color: "#1A3A6C",
                        ...boldFont,
                        fontSize: "15px",
                        textDecoration: "none",
                      }}
                    >
                      View Recepit
                    </Link>
                  </TableCell>
                ) : null}

                {role !== "Admin" ? (
                  <TableCell align="center">
                    <Box
                      style={{
                        color: "#1A3A6C",
                        ...boldFont,
                        fontSize: "15px",
                        textDecoration: "none",
                      }}
                    >
                      {row.audit.createdOn.substring(0, 10)}
                    </Box>
                  </TableCell>
                ) : null}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {role == "Admin" ? (
          <ModalUnstyledDemo
            open={model1}
            handleClose={handleclose}
            user={user}
          />
        ) : (
          ""
        )}
        {role == "Admin" ? (
          <ModalUnstyledDemo1
            open={model2}
            handleClose={handleclose1}
            user={paitent}
          />
        ) : (
          ""
        )}
         {role == "Admin" ? (
          <ModalUnstyledDemo2
            open={model3}
            handleClose={handleclose2}
            user={user}
          />
        ) : (
          ""
        )}
         {role == "Admin" ? (
          <ModalUnstyledDemo3
            open={model4}
            handleClose={handleclose3}
            user={user}
          />
        ) : (
          ""
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={props.tableData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
