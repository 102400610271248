import { StringConstants } from "./StringConstants";

class UrlConstants extends StringConstants {
  PROD = false;

  url_prod = "https://prod.com";
  url_dev = "https://dev.com";
  // url_dev = "http://127.0.0.1:3000";

  url = this.PROD ? this.url_prod : this.url_dev;
  landingViewPath = "/";
  FreeAssessmentViewPath = "/freeAssessment";
  pageNotFoundViewPath = "/404";
  ServiesPath = "/services";
  sleepProblemIndIviduals = "/sleepProblemIndIviduals";
  commercialDriversLicenseHolders = "/commercialDriversLicenseHolders";
  dentistRecommendPatients = "/dentistRecommendPatients";
  ContactUsPath = "/contact";
  SignInPath = "/login";
  RegisterPath = "/register";
  ForgotPasswordPath = "/forgotpassword";
  AboutUsPath = "/about";
  faqPath = "/faq";
  blogPath = "/blog";
  buytestPath = "/buytest";
  blogexpand = "/dsp";
  orderdetailsexpand = "/orderdetails";
  dashboardpath = "/dashboard";
  updatepasspath = "/updatepassword";
  paymentdetailspath = "/payment";
  epworth = "/epworth";
  adduserpath = "/adduser";
  updateprofilepath = "/profileupdate";
  viewUser = "/viewUser";
  viewUserDetail = "/viewUserDetail";
  reciepturl = "/invoice";
  blogexpand1 = "/blog1";
  blogexpand2 = "/blog2";
  blogexpand3 = "/blog3";
  blogexpand4 = "/blog4";
  viewProduct = "/products";
  viewProductDetail = "/viewProductDetail";
  addproduct = "/addproduct";
  editproduct = "/editproduct";
  checkoutproduct="/checkout";
  privacyPolicy = "/privacy";
  termsConditions = "/terms";
  cart = "/cart";
  // Example of API Endpoint
  // GETFILTERDATA = ${this.url}/${this.restaurant}/getFilteredData;
}

let urls = new UrlConstants();
export default urls;