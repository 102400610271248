import {
  Box,
  Button,
  Divider,
  Container,
  Grid,
  Typography,
  Paper,
} from "@mui/material";
import { Repeat } from "@mui/icons-material";
import React, { useState } from "react";
import Header from "../../../../shared/Header/Header";
import Footer from "../../../../shared/Footer/Footer";
import Banner from "../../../../shared/Banner/Banner";
import expandblog from "../../../../assets/images/Blog/expandblog.png";
import blogbanner from "../../../../assets/images/Blog/blogbanner.png";

const BlogExpand = () => {
  return (
    <>
      <Header />
      <Banner img={blogbanner} header1="Home/ Blog" header2="Blog" />
      <Container maxWidth="lg" style={{ marginBottom: "20px", width: "100%" }}>
        <Grid
          item
          xl
          container
          direction="column"
          justifyContent="center"
          alignContent="center"
          marginTop="30px"
        >
          <img style={{ width: "85%" }} src={expandblog} />
          <Box>
            <Typography
              style={{
                marginTop: "30px",
                fontSize: "42px",
                color: "#EB6523",
                fontWeight: 600,
              }}
            >
              Dental Services Patients
            </Typography>
            <Typography
              style={{ marginTop: "30px", fontSize: "20px", color: "#8B8B8B" }}
            >
              By Admin | Oct 18- 2020
            </Typography>
            <Typography
              style={{
                marginTop: "60px",
                marginBottom: "100px",
                fontSize: "20px",
                color: "#283035",
              }}
            >
              Sleep apnea is commonly known for causing physical and mental
              fatigue, but another frequent symptom noted in this disorder is a
              rapid deterioration in oral health. Dental problems caused due to
              Sleep Apnea such as gingivitis, tooth decay, dry mouth, and
              bruxism leads to chipped teeth, bad breath, and severe gum
              diseases.<br/><br/> Diagnosis and treatment of Sleep Apnea in the early
              stage can significantly improve these bad oral conditions. Timely
              treatment ensures quality sleep that can result in improved oral
              condition and lesser chances of tooth decay.
            </Typography>
          </Box>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default BlogExpand;
