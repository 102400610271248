import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { boldFont } from "../../utils/styles";
import { regularFont } from "../../utils/styles";
import aboutuslastdiv from "../../assets/images/AboutUs/aboutuslastdiv.png";
const AboutUsStyles = makeStyles((theme: Theme) => ({
  Box1: { display: "flex", marginTop: "5%" },
  Box2: { width: "45%" },
  Box3: { padding: "4% 4%", width: "40%" },
  Box4: { display: "flex", marginTop: "5%" },
  Box5: { padding: "4% 4% 0 15%", width: "40%" },
  Box6: { width: "50%" },
  Box7: {
    display: "flex",
    backgroundImage: `url(${aboutuslastdiv})`,
    backgroundRepeat: "no-repeat",
  },
  Box8: {
    width: "50%", padding: "5% 0 5% 10%" 
  },
  Box9: { padding: "5% 10% 100px 0", width: "50%" },
  Box10: {},
  Box11: {},
  Typo1: {
    fontSize: "2.5vw !important",
    fontWeight: 800,
    color: "#1A3A6C",
    ...boldFont,
  },
  Typo2: {
    fontSize: "1.04vw !important",
    fontWeight: 400,
    color: "#283035",
    ...regularFont,
  },
  Typo3: {
    fontSize: "2.5vw !important",
    color: "#1A3A6C",
    paddingLeft: "40px",
    fontWeight: 900,
    ...boldFont,
  },
  liststyle: {
    fontSize: "1.4vw",
    color: "#283035",
    fontWeight: 900,
    ...regularFont,
  },
  imagestyle: { width: "100%" },
  imagestyle1: { height: "67.07vh" },
  imagestyle2: {},

  Box1Mobile: { display: "grid", marginTop: "5%" },
  Box2Mobile: {},
  Box3Mobile: { padding: "", width: "100%" },
  Box4Mobile: { display: "grid", marginTop: "5%" },
  Box5Mobile: { padding: " ", width: "100%" },
  Box6Mobile: { padding: "", width: "100%" },
  Box7Mobile: {
    display: "grid",
    justifyContent:"center",
    backgroundImage: `url(${aboutuslastdiv})`,
    backgroundRepeat: "no-repeat",
  },
  Box8Mobile: {
    width: "100%"
  },
  Box9Mobile: { display:"grid",justifyContent:"center",padding: "" },
  Box10Mobile: {},
  Box11Mobile: {},
  Typo1Mobile: {
    fontSize: "25px !important",
    fontWeight: 800,
    color: "#1A3A6C",
    ...boldFont,
    padding:"10px 30px 0px 20px" 
  },
  Typo2Mobile: {
    fontSize: "15px !important",
    fontWeight: 400,
    color: "#283035",
    ...regularFont,
    padding:"5px 30px 30px 20px" 
  },
  Typo3Mobile: {
    fontSize: "25px !important",
    color: "#1A3A6C",
    paddingLeft: "40px",
    fontWeight: 900,
    ...boldFont,
  },
  liststyleMobile: {
    fontSize: "15px",
    color: "#283035",
    fontWeight: 900,
    ...regularFont,
  },
  imagestyleMobile: { width: "100%" },
  imagestyle1Mobile: { width: "80%",height:"20%",padding:"30px 30px 30px 50px" },
  imagestyle2Mobile: {},
}));

export default AboutUsStyles;
