import { User } from "../models/user.models";

let current_user = localStorage.getItem("current_user");
let user;
if (current_user) {
  user = JSON.parse(current_user);
}

const loggedInUser = user
  ? { loggedIn: true, user, loading: false }
  : { loggedIn: false, user: {}, loading: false };

let initialState = {
  user: {
    loading: false,
    error: "",
    verifyTempPassword: new User(),
    updateUserPwd: new User(),
    resetUser: new User(),
    cartValue: 0
  },
  auth: {
    ...loggedInUser,
  },
  purchase:
  {
    user: [],
  },
  user_cart: {
    items: []
  }
};

export default initialState;
