import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { Repeat } from "@mui/icons-material";
import Header from "../../shared/Header/Header";
import Footer from "../../shared/Footer/Footer";
import Banner from "../../shared/Banner/Banner";
import { boldFont } from "../../utils/styles";
import { regularFont } from "../../utils/styles";
import DataList from "./components/List/DataList";
import faqbanner from "../../assets/images/Faq/faqbanner.png";
import { useEffect } from "react";

const Faq = () => {
  useEffect(() => {
    document.title = "FAQ";
  }, []);
  return (
    <>
      <Header />
      <Banner img={faqbanner} header1="Home/ FAQ" header2="FAQ" />
      <Grid
        item
        lg
        container
        direction="row"
        justifyContent="center"
        style={{ padding: "0 20px 0 20px" }}
      >
        <Box
          style={{
            display: "grid",
            marginTop: "40px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              fontSize: "48px",
              color: "#1A3A6C",
              fontWeight: 900,
              ...boldFont,
              textAlign: "center",
            }}
          >
            Frequently Asked Questions
          </Typography>
          <Typography
            style={{
              fontSize: "20px",
              color: "#283035",
              fontWeight: 400,
              ...regularFont,
              textAlign: "center",
            }}
          >
            Below you’ll find answers to some of the most frequently asked
            questions at Tectxon. We are
            <br /> constantly adding most asked question to this page so if you
            have a question and don’t see
            <br /> your answer, don’t hesitate to email us at
            <span style={{ color: "#EB6523" }}> info@deepsleeptest.com</span>
          </Typography>
        </Box>
        <Box style={{ margin: "50px 15% 0 15%" }}>
          <DataList
            header="What Is Sleep Apnea?"
            data="Obstructive Sleep Apnea is a potentially serious sleep disorder. It causes breathing to repeatedly stop and start during sleep without the patient knowing.

The involuntary pause in breathing can result either from a blocked airway or a signaling problem in the brain.

Approximately 22 million Americans have this condition, but only 20 percent have been diagnosed and treated."
          />
          <DataList
            header="Is Sleep Apnea Dangerous?"
            data="Undiagnosed Sleep Apnea is directly tied to an increased risk in cardiovascular and metabolic health. The scariest part? You might not even know you have this very common problem.

          Several studies have shown an association between Sleep Apnea and problems like type 2 diabetes , strokes , heart attacks and even a shortened lifespan
          
          It’s important to treat Sleep Apnea, because it can have long-term consequences for your health."
          />
          <DataList
            header="Should I See A Doctor Or Nurse?"
            data="Yes. If you think you might have Sleep Apnea, see your primary care physician or our american board-certified sleep physician via telemedicine. Our “Deep sleep technology Home Sleep Apnea Test” will help to identify you have Sleep Apnea or not."
          />
          <DataList
            header="Is There A Test For Sleep Apnea?"
            data="A polysomnogram — or sleep study — is a multiple-component test that electronically transmits and records specific physical activities while you sleep.

          There are also other tests for Sleep Apnea
          EEG (electroencephalogram) to measure and record brain wave activity. EMG (electromyogram) to record muscle activity such as face twitches, teeth grinding, and leg movements, and to determine the presence of REM stage sleep. During REM sleep, intense dreams often occur as the brain undergoes heightened activity.
          
          EOG (electro-oculogram) to record eye movements. These movements are important in determining the different sleep stages, particularly REM stage sleep.
          
          ECG (electrocardiogram) to record heart rate and rhythm.
          Nasal airflow sensor to record airflow.
          Snore microphone to record snoring activity."
          />
          <DataList
            header="How Is Sleep Apnea Treated?"
            data="You may be able to treat mild Sleep Apnea with some lifestyle changes. They are
          Lose weight
          Not use alcohol and sleeping pills
          Change sleep positions to improve breathing
          Stop smoking
          
          Step further, is the Continuous Positive Airway Pressure (CPAP) device.
          Here, you wear a mask over your nose and/or mouth while you sleep.
          The mask is hooked up to a machine that delivers a constant flow of air into your nose.
          
          Oral Appliances for Sleep Apnea
          Dental devices can help keep your airway open while you sleep.
          Dentists with special expertise in treating sleep apnea can design them for you.
          
          Surgery for Sleep Apnea in very serious cases
          You may need to undergo surgery If you have a medical condition that makes your throat too narrow."
          />
        </Box>
      </Grid>
      <Footer />
    </>
  );
};

export default Faq;
