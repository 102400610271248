import Header from "../../shared/Header/Header";
import {
  Box,
  Button,
  Divider,
  Container,
  Grid,
  Typography,
  Paper,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import { Repeat } from "@mui/icons-material";
import FooterStyles from "./Footer.styles";
import { Link } from "react-router-dom";
import fb from "../../assets/images/Footer/fb.png";
import insta from "../../assets/images/Footer/insta.png";
import linkedin from "../../assets/images/Footer/linkedin.png";
import twitter from "../../assets/images/Footer/twitter.png";
import { regularFont } from "../../utils/styles";

const Footer = () => {
  const classes = FooterStyles();
  return (
    <>
      <Grid
        item
        lg
        container
        direction="row"
        justifyContent="space-evenly"
        className={classes.grid}
      >
        <Box
          style={{
            display: "flex",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <Box
            style={{
              display: "flex",
              width: "50px",
              height: "50px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: "50px",
              marginRight: "7px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={fb} />
          </Box>

          <Box
            style={{
              display: "flex",
              width: "50px",
              height: "50px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: "50px",
              marginRight: "7px",
              marginLeft: "7px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={insta} />
          </Box>
          <Box
            style={{
              display: "flex",
              width: "50px",
              height: "50px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: "50px",
              marginRight: "7px",
              marginLeft: "7px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={linkedin} />
          </Box>
          <Box
            style={{
              display: "flex",
              width: "50px",
              height: "50px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: "50px",
              marginLeft: "7px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={twitter} />
          </Box>
        </Box>
        <Box style={{ marginTop: "20px", marginBottom: "20px" }}>
          <Typography style={{
            ...regularFont,
            fontWeight: 400,
            fontSize: "20px",
          }}>© 2022 Deepsleeptest All rights reserved</Typography>
        </Box>
        <Box style={{ marginTop: "20px", marginBottom: "20px" }}>
          <Link to="/privacy" className={classes.topHeaderBLOG}>
            <Typography style={{
              ...regularFont,
              fontWeight: 900,
              fontSize: "16px",
              color: "#283035"
            }}>PRIVACY POLICY</Typography>
          </Link>
          <Link to="/terms" className={classes.topHeaderLi}>
            <Typography style={{
              ...regularFont,
              fontWeight: 900,
              fontSize: "16px",
              color: "#283035"
            }}>TERMS & CONDITIONS</Typography>
          </Link>
        </Box>
      </Grid>
    </>
  );
};

export default Footer;
