import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { boldFont } from "../../utils/styles";
import { regularFont } from "../../utils/styles";

const OrderDetailsStyles = makeStyles((theme: Theme) => ({
  
}));

export default OrderDetailsStyles;
