import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { boldFont, customButtonStyle, primaryColor } from "../../utils/styles";
import { regularFont } from "../../utils/styles";

const HeaderStyles = makeStyles((theme: Theme) => ({
  deepsleepLogo: {
    maxHeight: "42px",
    padding: "5px",
  },
  displayFlexAlignCenter: {
    display: "flex",
    alignItems: "center",
  },
  btn: {
    ...boldFont,
    ...customButtonStyle,
    backgroundColor: "#EB6523 !important",
    "&:hover": {
      backgroundColor: "#EB6523 !important",
    },
  },
  link: {
    textDecoration: "none",
    color: "black",
    fontSize: "16px",
    backgroundColor: "none",
    ...boldFont,
    fontWeight: 900,
    "&:hover": {
      color: "#EB6523",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 10%",
  },
  topHeaderImg: {
    display: "inline-block",
    marginLeft: "20px",
    marginTop: "1px",
  },
  offerText: {
    ...boldFont,
    textAlign: "center",
    fontWeight: 900,
    fontSize: "16px",
  },
  topHeaderLi: {
    textDecoration: "none",
    color: "black",
    fontSize: "16px",
    backgroundColor: "none",
    ...boldFont,
    fontWeight: 900,
    "&:hover": {
      color: "#EB6523",
      borderBottom: "1.5px solid #EB6523",
    },
  },
}));

export default HeaderStyles;
