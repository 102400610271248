import { autocompleteClasses, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Symptoms from "../../../../assets/images/whichOneYouAre/Symptoms.jpg";
import { customButtonStyle } from "../../../../utils/styles";
import { boldFont } from "../../../../utils/styles";
import { regularFont } from "../../../../utils/styles";

const IconTabsStyles = makeStyles((theme: Theme) => ({
  backgroundImg: {
    backgroundImage: "url(" + Symptoms + ")",
    marginTop: "-50px",
  },
  overStyle: {
    ".MuiTab-root": {
      backgroundColor: "white !important",
    },
  },
  btn: {
    ...customButtonStyle,
    backgroundColor: "#EB6523 !important",
    "&:hover": {
      backgroundColor: "#EB6523 !important",
    },
  },
  test: {
    width: "35px",
    // backgroundColor: "red",
  },
  mobileTabFix: {
    width: "100px",
  },
  desktopTitle: {
    fontSize: "48px",
    fontWeight: 900,
    color: "#283035",
    ...boldFont,
  },
  mobileTitle: {
    fontSize: "28px",
    fontWeight: 900,
    color: "#283035",
    ...boldFont,
  },
  staticTextDeskTop: {
    fontWeight: 900,
    color: "#fff",
    fontSize: "48px",
    ...boldFont,
  },
  staticTextMobile: {
    fontWeight: 900,
    color: "#fff",
    fontSize: "28px",
    ...boldFont,
  },
  iconTabDescriptionBoxDesktop: {
    backgroundColor: "white",
    padding: "30px",
    borderRadius: "15px",
    width: "60%",
  },
  iconTabDescriptionBoxMobile: {
    backgroundColor: "white",
    borderRadius: "15px",
    width: "70%",
  },
}));

export default IconTabsStyles;
