import React, { useEffect, useState } from "react";
import { userService } from "../../../../../services/sleepTestUserService-service";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import DownloadReportStyles from "./DownloadReport.styles";
import { openErrorNotification } from "../../../../../helpers/methods";

interface CustomProps {
    fileStoragePath: string,
    app: string,
    ownerId: string,
    status: string

}

export default function DownloadReport(props: CustomProps) {
    const classes = DownloadReportStyles();

    const downloadReport = async () => {
        let tempDocument = {
            id: null,
            app: props.app,
            type: null,
            audit: null,
            title: null,
            status: props.status,
            ownerId: props.ownerId,
            ownerEmail: null,
            ownerName: null,
            ownerType: null,
            contextId: null,
            path: props.fileStoragePath,
            metadata: null,
            content: null,
            tagss: null,
            issueDate: "",
            expiryDate: "",
            expriyMandatory: null,
            issueMandatory: null,
        };
        try {
            const response = await userService.downloadReport(tempDocument)
            var a = document.createElement("a");
            var url = window.URL.createObjectURL(response);
            a.href = url;
            a.download = props.fileStoragePath;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
            // notifications.openSuccessNotification("Downloaded successfully");
        } catch (error: any) {
            openErrorNotification(error.toString())
        }
    }



    return (<>
        <span
            className="download"
            onClick={() => { downloadReport() }}
        >
            <SystemUpdateAltIcon
                style={{ color: "#EB6523", cursor: "pointer" }}
            ></SystemUpdateAltIcon>
        </span>

    </>

    );
}
