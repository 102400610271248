import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { userService } from "../../../../services/sleepTestUserService-service";
import {
  Box,
  Stack,
  useMediaQuery,
  useTheme,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { openErrorNotification } from "../../../../helpers/methods";
import Header from "../../../../shared/Header/Header";
import Banner from "../../../../shared/Banner/Banner";
import OutlinedInput from "@mui/material/OutlinedInput";
import epworthBanner from "../../../../../src/assets/images/epworth/epworthBanner.png";
import Footer from "../../../../shared/Footer/Footer";
// import EpworthStyles from "./Epworth.styles";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import EpworthSleepiness from "../../../BuyTest/components/Epworth/components/EpworthSleepiness/EpworthSleepiness";
import StopBangQuestions from "../Epworth/components/StopBangQuestions/StopBangQuestions";
import Button from "@material-ui/core/Button";
import { boldFont } from "../../../../utils/styles";
import { isTruthy } from "../../../../helpers/methods";
import { regularFont } from "../../../../utils/styles";
import { ToastContainer, toast } from "react-toastify";
import history from "../../../../utils/history";
import loading2 from "../../../../assets/images/Login/loading2.gif";
// import { notifications } from '../../../../helpers/notifications';

const Epworth = (props) => {
  // const classes = EpworthStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const [gender, setGender] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [age, setAge] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isStopBangQuestions, setIsStopBangQuestions] =
    useState<boolean>(false);
  const [flagState, setFlagState] = useState<any>(null);
  const [questions, setQuestions] = useState({
    ques1: false,
    ques2: false,
    ques3: false,
    ques4: false,
    ques5: false,
    ques6: false,
    ques7: false,
  });
  const [bmi, setBMI] = useState({
    height: 0,
    weight: 0,
    totalCal: 0,
  });

  const [customArray, setCustomArray] = useState([
    {
      name: "Sitting and reading",
      value: null,
    },
    {
      name: "Watching TV",
      value: null,
    },
    {
      name: "Sitting inactive in a public place (e.g. a theatre or a meeting)",
      value: null,
    },
    {
      name: "As a passenger in a car for an hour without break",
      value: null,
    },
    {
      name: "Lying down to rest in the afternoon when circumstances permit",
      value: null,
    },
    {
      name: "Sitting and talking to someone",
      value: null,
    },
    {
      name: "Seating quietly after a lunch without alcohol",
      value: null,
    },
    {
      name: "In a car, while stopped for a few minutes in the traffic",
      value: null,
    },
  ]);

  useEffect(() => {
    nextButtonVaidation();
  }, [flagState]);

  useEffect(() => {
    document.title = "Epworth";
  }, []);

  const nextButtonVaidation = () => {
    let arr = customArray.find((item) => {
      if (item.value === null) {
        return item;
      }
    });
    setFlagState(arr?.value);
  };

  const changeView = (id: string) => {
    if (id === "Epworth Sleepiness") {
      setIsStopBangQuestions(false);
    } else if (id === "Stop Bang Questions") {
      if (flagState === null) {
        openErrorNotification("Please answer all the questions");
      } else {
        setIsStopBangQuestions(true);
      }
    }
  };

  const handleGenderChnage = (event) => {
    setGender(event.target.value);

  };

  const submit = async () => {
    if (age === 0) {
      toast.error("Please enter you date of birth");
    }

    if (!isTruthy(gender)) {
      toast.error("Please enter your gender");
    }
    if (bmi.height < 1 || bmi.weight < 1) {
      toast.error("Please enter bmi score");
    }
    if (age === 0 || !isTruthy(gender) || bmi.height < 1 || bmi.weight < 1) {
      return;
    } else {
      try {
        setIsLoading(true);
        const userData = await userService.getUserById(props.user.email);

        customArray.forEach((arr) => {
          handleReplace(arr, userData.customAttributes);
        });
        handleReplace(
          {
            name: "So you snore loudly?",
            value: questions.ques1 ? "Yes" : "No",
          },
          userData.customAttributes
        );
        handleReplace(
          {
            name: "Do you often feel tired, fatigued, or sleepy during the daytime?",
            value: questions.ques2 ? "Yes" : "No",
          },
          userData.customAttributes
        );
        handleReplace(
          {
            name: "Has anyone observed you stop breathing?",
            value: questions.ques3 ? "Yes" : "No",
          },
          userData.customAttributes
        );
        handleReplace(
          {
            name: "Do you have or are being treated for high blood pressure?",
            value: questions.ques4 ? "Yes" : "No",
          },
          userData.customAttributes
        );
        handleReplace(
          { name: "Are you male?", value: questions.ques5 ? "Yes" : "No" },
          userData.customAttributes
        );
        handleReplace(
          {
            name: "Are you over 50 years old?",
            value: questions.ques6 ? "Yes" : "No",
          },
          userData.customAttributes
        );
        handleReplace(
          {
            name: "Is your neck size bigger than 16in or 41cm? (use Shirt collar for reference)",
            value: questions.ques7 ? "Yes" : "No",
          },
          userData.customAttributes
        );
        handleReplace(
          { name: "totalCal", value: bmi.totalCal },
          userData.customAttributes
        );
        handleReplace(
          { name: "weight", value: bmi.weight },
          userData.customAttributes
        );
        handleReplace(
          { name: "gender", value: gender },
          userData.customAttributes
        );
        handleReplace(
          { name: "height", value: bmi.height },
          userData.customAttributes
        );
        if (age > 50) {
          handleReplace(
            { name: "Are you over 50 years old?", value: "Yes" },
            userData.customAttributes
          );
        } else {
          handleReplace(
            { name: "Are you over 50 years old?", value: "No" },
            userData.customAttributes
          );
        }
        if (bmi.totalCal > 35) {
          handleReplace(
            { name: "Is your BMI more than 35?", value: "Yes" },
            userData.customAttributes
          );
        } else {
          handleReplace(
            { name: "Is your BMI more than 35?", value: "No" },
            userData.customAttributes
          );
        }
        
        const response = await userService.updateUserCustomAttributes(userData);
      
        history.push("/dashboard");
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        // notifications.openErrorNotification(error);
      }
    }
  };

  const handleReplace = (attributeToAdd, hereTOAdd) => {
    const index = hereTOAdd.map((e) => e.name).indexOf(attributeToAdd.name);
    if (index === -1) {
     
    }
    if (index >= 0) {
      if (attributeToAdd.value === null) attributeToAdd.value = 0;
      hereTOAdd.splice(index, 1, attributeToAdd);

    } else {
      if (attributeToAdd.value === null) attributeToAdd.value = 0;
      hereTOAdd.push(attributeToAdd);
    }
  };

  const handleDOBChange = (value) => {
    setDateOfBirth(value);
    var today = new Date();
    var birthDate = new Date(value);
    var ageNow = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      ageNow--;
    }
    setAge(ageNow);
   
  };

  const getCalenderComponent = () => {
    return (
      <>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Date Of Birth"
            value={dateOfBirth}
            onChange={(newValue) => {
              handleDOBChange(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </>
    );
  };

  const getGenderSelectDropDown = () => {
    return (
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-label">Gender</InputLabel>
        <Select value={gender} onChange={handleGenderChnage} label="Gender">
          <MenuItem value={"Male"} style={{ display: "flex", width: "100%" }}>
            Male
          </MenuItem>
          <MenuItem value={"Female"} style={{ display: "flex", width: "100%" }}>
            Female
          </MenuItem>
        </Select>
      </FormControl>
    );
  };

  const getStaticContent = () => {
    return (
      <>
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <Stack
            direction={isDesktop ? "row" : "column"}
            spacing={isDesktop ? 5 : 2}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              <OutlinedInput
                type="text"
                name="fistName"
                placeholder="Patient First Name"
                value={props.user.firstName}
              />
            </Box>
            <Box>
              {" "}
              <OutlinedInput
                type="text"
                name="lastName"
                placeholder="Patient Last Name"
                value={props.user.lastName}
              />
            </Box>
            <Box>{getCalenderComponent()}</Box>
            <Box>{getGenderSelectDropDown()}</Box>
          </Stack>
        </Box>
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <Box
            style={{
              display: "flex",
              justifyContent: "left",
              paddingBottom: "20px",
            }}
          >
            <Stack direction={isDesktop ? "row" : "column"} spacing={1}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                // onClick={() => changeView("Epworth Sleepiness")}
                style={{
                  ...regularFont,
                  fontWeight: 500,
                  fontSize: "16px",
                  color: "#ffffff",
                  backgroundColor: "#1A3A6C",
                  width: "300px",
                  height: "50px",
                  borderRadius: "30px",
                }}
              >
                Epworth Sleepiness
              </Button>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                // onClick={() => changeView("Stop Bang Questions")}
                style={{
                  ...regularFont,
                  fontWeight: 500,
                  fontSize: "16px",
                  color: "#ffffff",
                  backgroundColor: "#1A3A6C",
                  width: "300px",
                  height: "50px",
                  borderRadius: "30px",
                }}
              >
                Stop Bang Questions
              </Button>
            </Stack>
          </Box>
        </Box>
      </>
    );
  };

  const handleChangeInCustomArray = (value) => {
    setCustomArray(value);
    nextButtonVaidation();
  };

  const handleQuetionsSwitch = (event, key) => {
    setQuestions({ ...questions, [key]: event.target.checked });
  };

  const handleBmiChange = (value, key) => {
    setBMI({ ...bmi, [key]: value });
  };
  return (
    <>
      <Box>
        <Header />
        <Banner
          img={epworthBanner}
          header1="Home / Epworth Sleepiness"
          header2="Epworth Sleepiness Situations"
        />
        {getStaticContent()}
        {isStopBangQuestions ? (
          <>
            {" "}
            <StopBangQuestions
              questions={questions}
              handleQuetionsSwitch={handleQuetionsSwitch}
              bmi={bmi}
              handleBmiChange={handleBmiChange}
            />
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "20px",
              }}
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{
                  ...regularFont,
                  fontWeight: 500,
                  fontSize: "16px",
                  color: "#ffffff",
                  backgroundColor: "#EB6523",
                  width: "200px",
                  height: "50px",
                  borderRadius: "30px",
                }}
                onClick={submit}
              >
                {isLoading ? (
                  <img
                    style={{ width: "30%", height: "100%" }}
                    src={loading2}
                  ></img>
                ) : (
                  <> Submit</>
                )}
              </Button>
            </Box>
          </>
        ) : (
          <>
            <EpworthSleepiness
              customArray={customArray}
              handleChangeInCustomArray={handleChangeInCustomArray}
            />
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "20px",
              }}
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{
                  ...regularFont,
                  fontWeight: 500,
                  fontSize: "16px",
                  color: "#ffffff",
                  backgroundColor: "#EB6523",
                  width: "200px",
                  height: "50px",
                  borderRadius: "15px",
                  marginTop: "18px",
                }}
                onClick={() => changeView("Stop Bang Questions")}
              >
                Next
              </Button>
            </Box>
          </>
        )}

        <Footer />
      </Box>
    </>
  );
};

function mapState(state) {
  const { authentication } = state;
  const { user } = authentication;
  return { user };
}
const actionCreators = {
  // executePaymentSuccessFlow: userActions.executePaymentSuccessFlow,
};

export default connect(mapState, actionCreators)(Epworth);
